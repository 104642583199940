import {
  calDandoriAmt,
  calDandoriTime,
  calSagyoAmt,
  calSagyoTime,
  getProductionQuantityCoeff,
  getSetupCoeff,
} from './IQ3KouteiCalculate_Common';
import { DataType, WorkType } from '../common/Constant';

export const calKensa = (
  curAddition,
  processInput,
  processSelect,
  curDeviceMst,
  calcParameters,
  curIQ3DataDetail,
  orderQuantity
) => {
  let kensaAddition = curAddition;
  if (Object.keys(kensaAddition).length > 0) {
    let processMst = [];
    let deviceMst = []; //テスト用ID;

    let parameters = calcParameters;
    if (parameters?.process?.length > 0) {
      processMst = parameters?.process
        ? parameters.process?.filter((item) => item.workType === WorkType.SmInspection)[0]
        : [];
    }
    let patternInfo = processSelect;
    if (patternInfo == undefined) return;
    if (patternInfo?.length > 0 && patternInfo[0]?.isUsedForCalc == false) return;
    deviceMst = curDeviceMst[0]; //テスト用ID;
    let kensaAdditions = kensaAddition;
    // 検査工程入力情報取得
    let kensaProcessInput = processInput[0];
    let newKensaAddition = {};
    let detailItems = [];
    let editDetailItems = [];
    let processName = '検査金額/検査時間';
    let processTime = 0;
    // 検査詳細情報
    detailItems.push({
      id: 1,
      processName: processName,
      dandoriAmt: 0,
      dandoriTime: 0,
      sagyoAmt: 0,
      sagyoTime: 0,
      processCount: Number(kensaProcessInput?.details?.[0]?.numOfCuts),
      processTime: processTime,
      kihon: true,
      formula: '',
    });
    newKensaAddition = {
      no: processMst?.id,
      processId: processMst?.id,
      dataType: kensaAdditions?.dataType ? kensaAdditions.dataType : DataType.Data,
      dataItems: detailItems,
      editItems: kensaAdditions?.editItems ? kensaAdditions.editItems : editDetailItems,
      totalDandori: {
        dataItemsAmt: 0,
        dataItemsTime: 0,
        editItemsAmt: 0,
        editItemsTime: 0,
      },
      totalSagyo: {
        dataItemsAmt: 0,
        dataItemsTime: 0,
        editItemsAmt: 0,
        editItemsTime: 0,
      },
      totalDataPrice: 0,
      totalDataTime: 0,
      totalEditPrice: 0,
      totalEditTime: 0,
      editDataFlg: false,
    };
    kensaAdditions = newKensaAddition;
    if (kensaAdditions?.dataItems) {
      let orgData = JSON.parse(JSON.stringify(kensaAdditions?.dataItems));
      if (deviceMst != undefined && orgData?.length > 0) {
        // 重量
        let weight = curIQ3DataDetail?.weight;
        // 面積
        let area = Number(curIQ3DataDetail?.totalSurfaceArea);
        // 数量
        let quantity = orderQuantity;
        // 員数
        let partCnt = curIQ3DataDetail?.partCount;
        //総重量が0、子部品数が0の場合、段取と加工を計算しない。
        if (weight === 0 && area === 0) {
          /** 段取金額/時間 */
          // 段取時間設定
          kensaAdditions.dataItems[0].dandoriTime = 0;
          orgData[0].dandoriTime = 0;
          // 段取金額設定
          kensaAdditions.dataItems[0].dandoriAmt = 0;
          orgData[0].dandoriAmt = 0;
          /** 作業金額/時間 */
          // 作業時間
          // 1検査時間＊検査箇所＊サイズ係数
          kensaAdditions.dataItems[0].processCount = 0;
          orgData[0].processCount = 0;
          kensaAdditions.dataItems[0].sagyoTime = 0;
          orgData[0].sagyoTime = 0;
          // 1検査時間
          kensaAdditions.dataItems[0].processTime = 0;
          orgData[0].processTime = 0;
          // 作業金額
          kensaAdditions.dataItems[0].sagyoAmt = 0;
          orgData[0].sagyoAmt = 0;
          kensaAdditions.totalDataPrice = 0;
          kensaAdditions.totalDandori.dataItemsAmt = 0;
          kensaAdditions.totalSagyo.dataItemsAmt = 0;
          kensaAdditions.totalDataTime = 0;
          kensaAdditions.totalDandori.dataItemsTime = 0;
          kensaAdditions.totalSagyo.dataItemsTime = 0;
        } else {
          //検査箇所
          let processCount = Number(kensaProcessInput?.details?.[0]?.numOfCuts);
          let dandoriTimeSec = 0;
          let dandoriTimeHms = 0;
          let dandoriAmt = 0;
          // 検査箇所が０の場合、段取計算しない。（IQX_WEBEST-63）
          if (processCount > 0) {
            /** 段取金額/時間 */
            // 段取時間設定
            //　基本段取時間＊サイズ係数
            dandoriTimeSec = calDandoriTime(WorkType.SmInspection, deviceMst, 0, weight, area);
            dandoriTimeSec = dandoriTimeSec * getSetupCoeff(deviceMst, quantity, partCnt); // 基本段取時間＊段取個数係数
            dandoriTimeHms = dandoriTimeSec;
            kensaAdditions.dataItems[0].dandoriTime = dandoriTimeHms;
            orgData[0].dandoriTime = dandoriTimeHms ? dandoriTimeHms : 0;
            // 段取金額設定
            //段階時間＊時間チャージ
            dandoriAmt = calDandoriAmt(dandoriTimeSec, deviceMst);
            kensaAdditions.dataItems[0].dandoriAmt = dandoriAmt;
            orgData[0].dandoriAmt = dandoriAmt ? dandoriAmt : 0;
          } else {
            kensaAdditions.dataItems[0].dandoriTime = 0;
            orgData[0].dandoriTime = 0;
            kensaAdditions.dataItems[0].dandoriAmt = 0;
            orgData[0].dandoriAmt = 0;
          }
          /** 作業金額/時間 */
          // 作業時間
          // 1検査時間＊検査箇所＊サイズ係数
          kensaAdditions.dataItems[0].processCount = processCount;
          orgData[0].processCount = processCount ? processCount : 0;
          let cSagyoTimeSec = calSagyoTime(WorkType.SmInspection, deviceMst, 0, 0, 0, 0, weight, area, processCount);
          cSagyoTimeSec = cSagyoTimeSec * getProductionQuantityCoeff(deviceMst, quantity, partCnt); // 作業時間＊生産個数係数
          let cSagyoTime = cSagyoTimeSec;
          kensaAdditions.dataItems[0].sagyoTime = cSagyoTime;
          orgData[0].sagyoTime = cSagyoTime ? cSagyoTime : 0;
          // 1検査時間
          kensaAdditions.dataItems[0].processTime = deviceMst?.details?.inspectionWorkTime;
          orgData[0].processTime = deviceMst?.details?.inspectionWorkTime;
          // 作業金額
          //　作業時間＊時間チャージ
          let cSagyoAmt = calSagyoAmt(cSagyoTimeSec, deviceMst);
          kensaAdditions.dataItems[0].sagyoAmt = cSagyoAmt;
          orgData[0].sagyoAmt = cSagyoAmt ? cSagyoAmt : 0;
          let totalSum = (dandoriAmt ? Math.round(dandoriAmt) : 0) + (cSagyoAmt ? Math.round(cSagyoAmt) : 0); // 画面表示用（四捨五入した金額を足す）
          kensaAdditions.totalDataPrice = totalSum;
          kensaAdditions.totalDandori.dataItemsAmt = dandoriAmt ? Math.round(dandoriAmt) : 0;
          kensaAdditions.totalSagyo.dataItemsAmt = cSagyoAmt ? Math.round(cSagyoAmt) : 0;
          let totalTimeSec = dandoriTimeSec + cSagyoTime;
          kensaAdditions.totalDataTime = totalTimeSec;
          kensaAdditions.totalDandori.dataItemsTime = dandoriTimeSec;
          kensaAdditions.totalSagyo.dataItemsTime = cSagyoTime;
          kensaAdditions.dataItems = orgData;
          kensaAddition = kensaAdditions;
        }
      }
    }
  }
  return kensaAddition;
};
