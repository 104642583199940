/**
 * クラス名：システム全体の共通ファイル
 * 説明：システム全体に共通使っているファイルである。
 * 作成者：ナンス
 * 作成日：2024/11/25
 * バージョン：1.0
 */
import {
  getInitFreeMaterialSizeInfo,
  getInitMaterialPatternsInfo,
  getInitMaterialSizeInfo,
  getMaterialAutoSize,
  getMaterialSizeData,
  getMaxYieldRecord,
  initEpCustomCost,
  initEpCustomDate,
  initEpCustomLabel,
  initEpCustomMemo,
  isInfinity,
  toDecimal,
} from '../common/Common';
import { ClientType, DataType, PatternType, WorkType } from '../common/Constant';
import { cal2jikakou } from './IQ3KouteiCalculate_2jikakou';
import { calAutoBari } from './IQ3KouteiCalculate_AutoBari';
import { calBending } from './IQ3KouteiCalculate_Bending';
import { calBlankCombi } from './IQ3KouteiCalculate_BlankCombi';
import { calBlankLaser } from './IQ3KouteiCalculate_BlankLaser';
import { calBlankPunch } from './IQ3KouteiCalculate_BlankPunch';
import { calMenualBari } from './IQ3KouteiCalculate_MenualBari';
import { calProgramProcess } from './IQ3KouteiCalculate_Program';
import { calwelding } from './IQ3KouteiCalculate_Yosetsu';
import { calweldFinish } from './IQ3KouteiCalculate_YosetsuFinish';
import { calKensa } from './IQ3KouteiCalculate_Kensa';
import { MaterialSurfaceBillingUnit } from '../common/enums';

export const calEstimateInfo = (selectedDataDetail, orderConditions) => {
  let estimateProducts = selectedDataDetail.estimateProducts;
  if (estimateProducts === undefined || estimateProducts?.calcParameters === undefined) {
    return selectedDataDetail;
  }
  //パラメータ情報再設定
  let orderCoeffs = [];
  let deliDateCoeffs = [];
  let purCoeffs = [];
  let mngCoeffs = [];
  let orderTypeCoeffs = [];
  let toleranceCoeffs = [];
  let weldFinishLevelCoeffs = [];
  let finalPurposeCoeffs = [];
  let costLst = [];
  let labelLst = [];
  let memoLst = [];
  let dateLst = [];
  let purchasesData = [];
  let parameters = estimateProducts?.calcParameters?.parameters;
  let settings = estimateProducts?.calcParameters?.settings;
  if (parameters) {
    let client =
      parameters.client?.length > 0 ? parameters.client?.filter((item) => item.id == estimateProducts.clientId) : [];
    let companyInfo = parameters.company;
    let processInfo = parameters.process;
    let deviceMasterInfo = parameters.device;
    let processPattern = parameters.processPattern;
    let estimateStatus =
      parameters.estimateStatus?.length > 0
        ? parameters?.estimateStatus?.filter((item) => item.id == estimateProducts?.estimateStatusId)
        : [];
    purchasesData = parameters?.purchase;

    if (companyInfo?.length > 0) {
      orderCoeffs = companyInfo?.[0]?.coeffOrders?.filter((i) => i.id === estimateProducts.coeffOrdersId);

      deliDateCoeffs = companyInfo?.[0]?.coeffDeliveryDates?.filter(
        (i) => i.id === estimateProducts.coeffDeliveryDatesId
      );
      purCoeffs = companyInfo?.[0]?.coeffPurchases?.filter((i) => i.id === estimateProducts.coeffPurchasesId);
      mngCoeffs = companyInfo?.[0]?.coeffManagements?.filter((i) => i.id === estimateProducts.coeffManagementsId);

      orderTypeCoeffs = companyInfo?.[0]?.coeffOrderTypes?.filter((i) => i.id === orderConditions?.order);
      toleranceCoeffs = companyInfo?.[0]?.coeffTolerance?.filter((i) => i.id === orderConditions?.tolerance);
      weldFinishLevelCoeffs = companyInfo?.[0]?.coeffWeldFinishLevel?.filter(
        (i) => i.id === orderConditions?.weldFinishLevel
      );
      finalPurposeCoeffs = companyInfo?.[0]?.coeffFinalPurpose?.filter((i) => i.id === orderConditions?.finalPurpos);

      costLst = companyInfo[0]?.additionalItemCosts
        ?.filter((item) => item.isUsed && !item.isDeleted)
        ?.map((item) => {
          let initItem = initEpCustomCost(item.id, item.code, item.name);
          initItem.companyId = estimateProducts.companyId;
          initItem.estimateProductId = estimateProducts.id;
          initItem.modified = item.modified;
          initItem.info = item.info;
          return initItem;
        });

      labelLst = companyInfo[0]?.additionalItemLabels
        ?.filter((item) => item.isUsed && !item.isDeleted)
        ?.map((item) => {
          let initItem = initEpCustomLabel(item.id, item.code, item.name);
          initItem.companyId = estimateProducts.companyId;
          initItem.estimateProductId = estimateProducts.id;
          initItem.modified = item.modified;
          initItem.info = item.info;
          return initItem;
        });

      memoLst = companyInfo[0]?.additionalItemMemos
        ?.filter((item) => item.isUsed && !item.isDeleted)
        ?.map((item) => {
          let initItem = initEpCustomMemo(item.id, item.code, item.name);
          initItem.companyId = estimateProducts.companyId;
          initItem.estimateProductId = estimateProducts.id;
          initItem.modified = item.modified;
          initItem.info = item.info;
          return initItem;
        });

      dateLst = companyInfo[0]?.additionalItemDates
        ?.filter((item) => item.isUsed && !item.isDeleted)
        ?.map((item) => {
          let initItem = initEpCustomDate(item.id, item.code, item.name);
          initItem.companyId = estimateProducts.companyId;
          initItem.estimateProductId = estimateProducts.id;
          initItem.modified = item.modified;
          initItem.info = item.info;
          return initItem;
        });
    }
    estimateProducts.clientId = client ? client?.[0]?.id : estimateProducts.clientId;
    estimateProducts.clientName = client ? client?.[0]?.name : estimateProducts.clientName;
    estimateProducts.clientCode = client ? client?.[0]?.code : estimateProducts.clientCode;
    estimateProducts.coeffOrdersId = orderCoeffs?.length > 0 ? orderCoeffs?.[0]?.id : undefined;
    estimateProducts.coeffOrdersName = orderCoeffs?.length > 0 ? orderCoeffs?.[0]?.name : '';
    estimateProducts.coeffDeliveryDatesId = deliDateCoeffs?.length > 0 ? deliDateCoeffs?.[0]?.id : undefined;
    estimateProducts.coeffDeliveryDatesName = deliDateCoeffs?.length > 0 ? deliDateCoeffs?.[0]?.name : '';
    estimateProducts.coeffPurchasesId = purCoeffs?.length > 0 ? purCoeffs?.[0]?.id : undefined;
    estimateProducts.coeffPurchasesName = purCoeffs?.length > 0 ? purCoeffs?.[0]?.name : '';
    estimateProducts.coeffManagementsId = mngCoeffs?.length > 0 ? mngCoeffs?.[0]?.id : undefined;
    estimateProducts.coeffManagementsName = mngCoeffs?.length > 0 ? mngCoeffs?.[0]?.name : '';
    estimateProducts.estimateStatusId = estimateStatus?.length > 0 ? estimateStatus?.[0]?.id : '';
    estimateProducts.estimateStatus = estimateStatus?.length > 0 ? estimateStatus?.[0]?.name : '';
    estimateProducts.otherProduct.coeffClient = client
      ? toDecimal(client?.[0]?.coeffClients?.find((item) => item.clientType === ClientType.Customer)?.coeff, 2)
      : toDecimal(0, 2);
    estimateProducts.otherProduct.coeffOrders =
      orderCoeffs?.length > 0 ? toDecimal(orderCoeffs?.[0]?.coeff, 2) : toDecimal(0, 2);
    estimateProducts.otherProduct.coeffDeliveryDates =
      deliDateCoeffs?.length > 0 ? toDecimal(deliDateCoeffs?.[0]?.coeff, 2) : toDecimal(0, 2);
    estimateProducts.otherProduct.coeffPurchases =
      purCoeffs?.length > 0 ? toDecimal(purCoeffs?.[0]?.coeff, 2) : toDecimal(0, 2);
    estimateProducts.otherProduct.coeffManagements =
      mngCoeffs?.length > 0 ? toDecimal(mngCoeffs?.[0]?.coeff, 2) : toDecimal(0, 2);
    //追加項目情報再設定
    let updateCosts = [];
    for (let cost = 0; cost < costLst?.length; cost++) {
      let updateCostFilter = selectedDataDetail?.epCustomCosts?.filter(
        (item) => item.additionalItemId == costLst[cost]?.additionalItemId
      );
      let updateCost = costLst[cost];
      if (updateCostFilter?.length > 0) {
        updateCostFilter[0].additionalItemCode = updateCost.additionalItemCode;
        updateCostFilter[0].additionalItemName = updateCost.additionalItemName;
        updateCostFilter[0].modified = updateCost.modified;
        updateCostFilter[0].info = updateCost.info;
        updateCost = updateCostFilter[0];
      }
      updateCosts.push(updateCost);
    }
    selectedDataDetail.epCustomCosts = updateCosts;
    let updateDates = [];
    for (let date = 0; date < dateLst?.length; date++) {
      let updateDateFilter = selectedDataDetail?.epCustomDates?.filter(
        (item) => item.additionalItemId == dateLst[date]?.additionalItemId
      );
      let updateDate = dateLst[date];
      if (updateDateFilter?.length > 0) {
        updateDateFilter[0].additionalItemCode = updateDate.additionalItemCode;
        updateDateFilter[0].additionalItemName = updateDate.additionalItemName;
        updateDateFilter[0].modified = updateDate.modified;
        updateDateFilter[0].info = updateDate.info;
        updateDate = updateDateFilter[0];
      }
      updateDates.push(updateDate);
    }
    selectedDataDetail.epCustomDates = updateDates;
    let updateLabels = [];
    for (let label = 0; label < labelLst?.length; label++) {
      let updateLabelFilter = selectedDataDetail?.epCustomLabels?.filter(
        (item) => item.additionalItemId == labelLst[label]?.additionalItemId
      );
      let updateLabel = labelLst[label];
      if (updateLabelFilter?.length > 0) {
        updateLabelFilter[0].additionalItemCode = updateLabel.additionalItemCode;
        updateLabelFilter[0].additionalItemName = updateLabel.additionalItemName;
        updateLabelFilter[0].modified = updateLabel.modified;
        updateLabelFilter[0].info = updateLabel.info;
        updateLabel = updateLabelFilter[0];
      }
      updateLabels.push(updateLabel);
    }
    selectedDataDetail.epCustomLabels = updateLabels;
    let updateMemos = [];
    for (let memo = 0; memo < memoLst?.length; memo++) {
      let updateMemoFilter = selectedDataDetail?.epCustomMemos?.filter(
        (item) => item.additionalItemId == memoLst[memo]?.additionalItemId
      );
      let updateMemo = memoLst[memo];
      if (updateMemoFilter?.length > 0) {
        updateMemoFilter[0].additionalItemCode = updateMemo.additionalItemCode;
        updateMemoFilter[0].additionalItemName = updateMemo.additionalItemName;
        updateMemoFilter[0].modified = updateMemo.modified;
        updateMemoFilter[0].info = updateMemo.info;
        updateMemo = updateMemoFilter[0];
      }
      updateMemos.push(updateMemo);
    }
    selectedDataDetail.epCustomMemos = updateMemos;
  }
  //再計算
  // 数量
  let quantity = selectedDataDetail?.estimateProducts?.quantity
    ? Number(selectedDataDetail?.estimateProducts?.quantity)
    : 1;
  let device = estimateProducts?.calcParameters?.parameters?.device;
  //板金子部品の工程積算情報
  let esIq3Infos = selectedDataDetail?.esIq3Info;
  let iq3Weight = 0;
  let iq3Area = 0;
  let setupCostUnitPrice = 0;
  estimateProducts.workCostUnitPrice = 0;
  estimateProducts.setupCostTotalPrice = 0;
  estimateProducts.workCostTotalPrice = 0;
  estimateProducts.materialCostUnitPrice = 0;
  estimateProducts.materialCostTotalPrice = 0;
  estimateProducts.purchaseUnitPrice = 0;
  estimateProducts.purchaseTotalPrice = 0;
  let iq3PurUnitPrice = 0;
  let iq3PurTotalPrice = 0;
  let iq3ManufacturingCostTotalPrice = 0;
  let iq3EstimateMaterialTotalPrices = 0;
  let iq3SetupCostPrice_total = 0;
  let iq3WorkCostPrice_total = 0;
  for (let s = 0; s < esIq3Infos?.length; s++) {
    // 材料、材質、材料表面保護、材料サイズパラメータ情報
    let materialIq3 = estimateProducts?.calcParameters?.parameters?.materialIq3;
    let materialTypeIq3 = estimateProducts?.calcParameters?.parameters?.materialTypeIq3;
    let materialSizeIq3 = estimateProducts?.calcParameters?.parameters?.materialSizeIq3;
    let materialSurfaceIq3 = estimateProducts?.calcParameters?.parameters?.materialSurfaceIq3;
    // 材料情報を再設定
    let materialIq3Info = materialIq3?.find((item) => item.id === esIq3Infos[s]?.materialIq3Id && item?.info?.isUsed);
    esIq3Infos[s].materialIq3Id = materialIq3Info ? esIq3Infos[s]?.materialIq3Id : materialIq3?.[0]?.id;
    esIq3Infos[s].materialName = materialIq3Info ? esIq3Infos[s]?.materialName : materialIq3?.[0]?.name;
    materialIq3Info = materialIq3?.find((item) => item.id === esIq3Infos[s]?.materialIq3Id);

    // 材質情報を再設定
    let materialTypeIq3Info = materialTypeIq3?.find(
      (item) =>
        item.id === esIq3Infos[s]?.materialTypeIq3Id &&
        item.id === materialIq3Info?.materialTypeIq3Id &&
        item?.info?.isUsed
    );
    let initMaterialTypeIq3Info = materialTypeIq3?.find(
      (item) => item.id === materialIq3Info?.materialTypeIq3Id && item?.info?.isUsed
    );
    esIq3Infos[s].materialTypeIq3Id = materialTypeIq3Info
      ? esIq3Infos[s]?.materialTypeIq3Id
      : initMaterialTypeIq3Info?.id;
    esIq3Infos[s].materialTypeName = materialTypeIq3Info
      ? esIq3Infos[s]?.materialTypeName
      : initMaterialTypeIq3Info?.name;
    // 材料表面保護情報を再設定
    let materialSurfaceIq3Info = materialSurfaceIq3?.find(
      (item) =>
        item.id === esIq3Infos[s]?.materialSurfaceIq3Id &&
        item.materialTypeIq3Id === esIq3Infos[s].materialTypeIq3Id &&
        item?.info?.isUsed
    );
    let initMaterialSurfaceIq3Info = materialSurfaceIq3?.find(
      (item) => item.materialTypeIq3Id === esIq3Infos[s].materialTypeIq3Id && item?.info?.isUsed
    );

    esIq3Infos[s].materialSurfaceIq3Id = materialSurfaceIq3Info
      ? esIq3Infos[s]?.materialSurfaceIq3Id
      : initMaterialSurfaceIq3Info?.id;
    esIq3Infos[s].materialSurfaceName = materialSurfaceIq3Info
      ? esIq3Infos[s]?.materialSurfaceName
      : initMaterialSurfaceIq3Info?.name;

    // 板厚情報を再設定
    esIq3Infos[s].thickness = esIq3Infos[s].materialIq3Id;

    // 材料サイズを再設定
    esIq3Infos[s].materialDetailSizes?.map((item) => {
      if (item.sizeName !== 'Free' && item.sizeName !== 'Other') {
        let materialSizeInfo = materialSizeIq3?.find((mst) => mst.id === item?.materialSizeIq3Id && mst.info.isUsed);
        if (materialSizeInfo) {
          // マスタの情報を材料詳細のサイズに更新
          item.sizeName = materialSizeInfo.name;
          esIq3Infos[s].materialDetailPatterns
            ?.filter((pattern) => pattern?.materialSizeIq3Id === materialSizeInfo?.id)
            ?.map((pattern) => {
              pattern.sizeName = materialSizeInfo.name;
              pattern.sizeX = materialSizeInfo.info.sizeX;
              pattern.sizeY = materialSizeInfo.info.sizeY;
            });
        }
      }
    });

    // マスタに存在するサイズ情報のみを利用
    esIq3Infos[s].materialDetailSizes = esIq3Infos[s].materialDetailSizes?.filter(
      (item) =>
        item.sizeName === 'Free' ||
        materialSizeIq3?.findIndex((mst) => mst.id == item.materialSizeIq3Id && mst.info.isUsed) > -1
    );

    // マスタに追加した情報を追加
    materialSizeIq3
      ?.filter(
        (mst) =>
          mst.name !== 'Free' &&
          mst.info.isUsed &&
          mst?.materialTypeIq3Id?.findIndex((id) => id === esIq3Infos[s]?.materialTypeIq3Id) > -1 &&
          esIq3Infos[s].materialDetailSizes?.findIndex((item) => mst.id == item.materialSizeIq3Id) === -1
      )
      ?.map((item, index) => {
        let data = getInitMaterialSizeInfo(index, item);
        esIq3Infos[s].materialDetailSizes.push(data);
        esIq3Infos[s].materialDetailPatterns = getInitMaterialPatternsInfo(esIq3Infos[s].materialDetailPatterns, item);
      });

    let iq3SetupCostTotalPrice = 0;
    let iq3WorkCostTotalPrice = 0;
    let iq3PurchaseUnitPrice = 0;
    let iq3PurchaseTotalPrice = 0;
    selectedDataDetail.esIq3Info[s].surfaceUnitPrice = 0;
    selectedDataDetail.esIq3Info[s].surfaceTotalPrice = 0;
    let materialCoeff = 0;

    let partCnt = Number(esIq3Infos[s]?.partCount); // 員数
    let manufacturingCnt = quantity * partCnt; // 製造数 * 員数
    manufacturingCnt = manufacturingCnt ? manufacturingCnt : 1;

    // IQX_WEBEST-315 板厚を変更しても重量が更新されない
    const thickId = esIq3Infos[s].materialIq3Id;
    const thicknessInfo = materialIq3?.find((item) => item.id === thickId);
    let thicknessVal = thicknessInfo?.info?.thick;
    let gravityVal = thicknessInfo?.info?.gravity;
    let xsize = esIq3Infos[s]?.partSizeX ? toDecimal(esIq3Infos[s]?.partSizeX, 2) : 0;
    let ysize = esIq3Infos[s]?.partSizeY ? toDecimal(esIq3Infos[s]?.partSizeY, 2) : 0;
    let weightVal = ((thicknessVal * parseInt(xsize) * parseInt(ysize)) / 1000000) * gravityVal;
    let areaVal = (toDecimal(xsize, 2) * toDecimal(ysize, 2)) / 100;
    esIq3Infos[s].weight = weightVal ? weightVal : esIq3Infos[s].weight;
    esIq3Infos[s].totalSurfaceArea = areaVal ? areaVal : esIq3Infos[s].totalSurfaceArea;

    // 材料計算
    // 材料サイズ採用Id
    let materialSizeIq3Id = esIq3Infos[s].materialDetailSizes?.[0]?.materialSizeIq3Id;
    // Pattern1情報
    let pattern1Info = esIq3Infos[s].materialDetailPatterns?.filter(
      (item) => item.patternType === PatternType.Pattern1 && item.materialSizeIq3Id === materialSizeIq3Id
    );
    pattern1Info = pattern1Info?.[0];
    // Pattern2情報
    let pattern2Info = esIq3Infos[s].materialDetailPatterns?.filter(
      (item) => item.patternType === PatternType.Pattern2 && item.materialSizeIq3Id === materialSizeIq3Id
    );
    pattern2Info = pattern2Info?.[0];
    // 材料Pattern1、 Pattern2計算
    let calculatedIq3Data = calculateMaterialDetailSize(
      { ...esIq3Infos[s] },
      false,
      false,
      pattern1Info,
      pattern2Info,
      quantity,
      materialIq3,
      estimateProducts
    );
    let materialDetailSizeLists = calculatedIq3Data?.materialDetailSizes;
    let materialDetailPatternLists = calculatedIq3Data?.materialDetailPatterns;
    esIq3Infos[s].materialDetailSizes = materialDetailSizeLists;
    esIq3Infos[s].materialDetailPatterns = materialDetailPatternLists;

    // 一番合計歩留まりが良い材料サイズ情報
    let selectedRecord = {};
    let maxYieldRecord = {};
    // 材料詳細タブは開くまではOtherで最小の材料サイズを採用
    if (materialDetailSizeLists?.length > 0) {
      maxYieldRecord = getMaxYieldRecord(materialDetailSizeLists);
      selectedRecord = maxYieldRecord;
    }
    const rowInfo = selectedRecord?.no
      ? selectedRecord
      : materialDetailSizeLists?.length > 0
      ? materialDetailSizeLists?.[0]
      : 0;
    materialDetailSizeLists?.map((item) => {
      if (item && item.materialSizeIq3Id === rowInfo?.materialSizeIq3Id) {
        item['isUsed'] = true;
      } else {
        item['isUsed'] = false;
      }
    });
    let calMaterialPriceInfo = updateMaterialSizePrice(materialDetailSizeLists, esIq3Infos[s], quantity);
    esIq3Infos[s].materialCostUnitPrice = calMaterialPriceInfo.materialCostUnitPrice;
    esIq3Infos[s].materialCostTotalPrice = calMaterialPriceInfo.materialCostTotalPrice;

    //工程積算計算
    iq3Weight += Number(esIq3Infos[s]?.weight) * partCnt; // IQX_WEBEST-308 親工程 －表面処置、工程入力画面の「面積/重量」の値変更
    iq3Area += Number(esIq3Infos[s]?.totalSurfaceArea) * partCnt; // IQX_WEBEST-308 親工程 －表面処置、工程入力画面の「面積/重量」の値変更

    let iq3ProcessPattern = esIq3Infos[s]?.processSelect;
    let iq3ProcessInput = esIq3Infos[s]?.processInput;

    //各子部品の工程積算合計計算
    let iq3Additions = esIq3Infos[s]?.additions;
    if (iq3Additions) {
      // 計算した工程データの足し算
      iq3Additions?.forEach((iq3Addition) => {
        let deviceMstFilter = [];
        //利用する工程パターンを取得
        let patternInfoFilter = [];
        let curPatternInfoFilter = iq3ProcessPattern
          ? iq3ProcessPattern?.details?.filter((item) => item.processId == iq3Addition?.processId && item.isUsedForCalc)
          : [];
        if (curPatternInfoFilter && curPatternInfoFilter?.length > 0) {
          patternInfoFilter = curPatternInfoFilter[0];
          //複数処理（追加工程）の場合、工程IDで判断できないため、工程詳細IDで判断して対象データを取得
          if (curPatternInfoFilter?.length > 1) {
            let patternInfoFilterByTypeId = curPatternInfoFilter?.filter(
              (item) =>
                item.multiprocesses?.[0]?.processDetailTypeId === iq3Addition?.dataItems?.[0]?.processDetailTypeNo
            );
            if (patternInfoFilterByTypeId && patternInfoFilterByTypeId?.length > 0) {
              patternInfoFilter = patternInfoFilterByTypeId[0];
            }
          }
          deviceMstFilter = device
            ? device?.filter(
                (item) => patternInfoFilter?.processId == item.processId && patternInfoFilter?.deviceId == item.id
              )
            : [];
          let curProcessInput = iq3ProcessInput?.filter((item) => item.processId == iq3Addition?.processId);
          //　現在の工程タイプ取得
          let curWorkType = parameters?.process?.filter((item) => item.id == iq3Addition?.processId)?.[0]?.workType;
          if (curWorkType === WorkType.SmProgram) {
            iq3Addition = calProgramProcess(
              iq3Addition,
              curProcessInput,
              curPatternInfoFilter,
              deviceMstFilter,
              parameters
            );
          } else if (curWorkType === WorkType.SmBlankLaser) {
            iq3Addition = calBlankLaser(
              iq3Addition,
              curProcessInput,
              curPatternInfoFilter,
              deviceMstFilter,
              parameters,
              esIq3Infos[s],
              quantity
            );
          } else if (curWorkType === WorkType.SmBlankPunch) {
            iq3Addition = calBlankPunch(
              iq3Addition,
              curProcessInput,
              curPatternInfoFilter,
              deviceMstFilter,
              parameters,
              esIq3Infos[s],
              quantity
            );
          } else if (curWorkType === WorkType.SmBlankCombi) {
            iq3Addition = calBlankCombi(
              iq3Addition,
              curProcessInput,
              curPatternInfoFilter,
              deviceMstFilter,
              parameters,
              esIq3Infos[s],
              quantity
            );
          } else if (curWorkType === WorkType.SmSecondaryWork) {
            iq3Addition = cal2jikakou(
              iq3Addition,
              curProcessInput,
              curPatternInfoFilter,
              deviceMstFilter,
              parameters,
              esIq3Infos[s],
              quantity
            );
          } else if (curWorkType === WorkType.SmDeburringAuto) {
            iq3Addition = calAutoBari(
              iq3Addition,
              curProcessInput,
              curPatternInfoFilter,
              deviceMstFilter,
              parameters,
              esIq3Infos[s],
              quantity
            );
          } else if (curWorkType === WorkType.SmDeburringManual) {
            iq3Addition = calMenualBari(
              iq3Addition,
              curProcessInput,
              curPatternInfoFilter,
              deviceMstFilter,
              parameters,
              esIq3Infos[s],
              quantity
            );
          } else if (curWorkType === WorkType.SmWelding) {
            iq3Addition = calwelding(
              iq3Addition,
              curProcessInput,
              curPatternInfoFilter,
              deviceMstFilter,
              parameters,
              esIq3Infos[s],
              quantity
            );
          } else if (curWorkType === WorkType.SmWeldFinish) {
            iq3Addition = calweldFinish(
              iq3Addition,
              curProcessInput,
              curPatternInfoFilter,
              deviceMstFilter,
              parameters,
              esIq3Infos[s],
              quantity
            );
          } else if (curWorkType === WorkType.SmBending) {
            iq3Addition = calBending(
              iq3Addition,
              curProcessInput,
              curPatternInfoFilter,
              deviceMstFilter,
              parameters,
              esIq3Infos[s],
              quantity
            );
          } else if (curWorkType === WorkType.SmInspection) {
            iq3Addition = calKensa(
              iq3Addition,
              curProcessInput,
              curPatternInfoFilter,
              deviceMstFilter,
              parameters,
              esIq3Infos[s],
              quantity
            );
          }

          //　段取金額
          let iq3SetupCostPrice =
            iq3Addition.dataType == DataType.Data
              ? iq3Addition.totalDandori.dataItemsAmt
              : iq3Addition.totalDandori.editItemsAmt;
          iq3SetupCostPrice = iq3SetupCostPrice ? iq3SetupCostPrice : 0; // NaNチェック
          iq3SetupCostTotalPrice += iq3SetupCostPrice;

          //　作業/加工金額
          let iq3WorkCostPrice =
            iq3Addition.dataType == DataType.Data
              ? iq3Addition.totalSagyo.dataItemsAmt
              : iq3Addition.totalSagyo.editItemsAmt;
          // 作業/加工金額
          iq3WorkCostPrice = iq3WorkCostPrice ? iq3WorkCostPrice : 0; // NaNチェック

          // プログラム工程の場合、プログラム工程の原価は子部品の員数、全体の数量に影響されないため、分けて計算
          if (curWorkType == WorkType.SmProgram) {
            //　プログラム工程の合計の場合、そのまま、製造数と掛けない
            iq3WorkCostTotalPrice += iq3WorkCostPrice;
          } else if (curWorkType == WorkType.SmShearing) {
            // シャーリングOne by one項目チェックを取得
            let oneByoneCheck = deviceMstFilter[0]?.details?.isOneCut;
            if (oneByoneCheck === false) {
              //　One by one項目チェックがOFFの場合、そのまま、製造数と掛けない
              iq3WorkCostTotalPrice += iq3WorkCostPrice;
            } else {
              //　One by one項目チェックがONの場合、製造数と掛ける
              iq3WorkCostTotalPrice += iq3WorkCostPrice * manufacturingCnt;
            }
          } else {
            // 加工時間×員数
            iq3WorkCostTotalPrice += iq3WorkCostPrice * manufacturingCnt;
          }
          //板金の基本情報に表面処理費表示のため設定
          if (curWorkType == WorkType.SmSurface) {
            if (iq3SetupCostPrice !== undefined && iq3WorkCostPrice !== undefined) {
              // (段取時間＋(加工時間×製造数))
              selectedDataDetail.esIq3Info[s].surfaceTotalPrice =
                iq3SetupCostPrice + iq3WorkCostPrice * manufacturingCnt;
              // (合計÷製造数)
              selectedDataDetail.esIq3Info[s].surfaceUnitPrice = Number.isFinite(
                selectedDataDetail.esIq3Info[s].surfaceTotalPrice / manufacturingCnt
              )
                ? selectedDataDetail.esIq3Info[s].surfaceTotalPrice / manufacturingCnt
                : 0;
            }
          }
        }
      });
      //板金原価
      if (iq3SetupCostTotalPrice !== undefined && iq3WorkCostTotalPrice !== undefined) {
        //　合計原価（段取金額＋（加工金額＊員数））
        selectedDataDetail.esIq3Info[s].workCostTotalPrice = iq3SetupCostTotalPrice + iq3WorkCostTotalPrice;
        iq3SetupCostPrice_total += iq3SetupCostTotalPrice;
        iq3WorkCostPrice_total += iq3WorkCostTotalPrice;
        //　単品原価（合計原価 ÷ 製造数　)
        selectedDataDetail.esIq3Info[s].workCostUnitPrice = Number.isFinite(
          selectedDataDetail.esIq3Info[s].workCostTotalPrice / manufacturingCnt
        )
          ? selectedDataDetail.esIq3Info[s].workCostTotalPrice / manufacturingCnt
          : 0;
      }
    }

    // 親部品材料原価更新
    estimateProducts.materialCostUnitPrice += selectedDataDetail?.esIq3Info[s]?.materialCostUnitPrice;
    estimateProducts.materialCostTotalPrice += selectedDataDetail?.esIq3Info[s]?.materialCostTotalPrice;
    // 材料粗利係数
    let curMaterialTypeIq3 = parameters?.materialTypeIq3?.filter(
      (item) => item.id === esIq3Infos[s]?.materialTypeIq3Id
    );
    if (curMaterialTypeIq3?.length > 0) {
      materialCoeff = curMaterialTypeIq3?.[0]?.info.lossCoeff;
      materialCoeff = materialCoeff ? materialCoeff : 1;
    }
    // 各板金の材料原価更新
    let iq3EstimateMaterialTotalPrice = selectedDataDetail?.esIq3Info[s]?.materialCostTotalPrice * materialCoeff;
    iq3EstimateMaterialTotalPrices += iq3EstimateMaterialTotalPrice;

    // 板金の購入品合計
    let iq3Purchase = esIq3Infos[s]?.purchases;
    for (let p = 0; p < iq3Purchase?.length; p++) {
      if (iq3Purchase[p]?.isDeleted === false) {
        // IQX_WEBEST-388 親部品リスト、一括編集ボタンからのcurt.パラ更新で、購入品の情報が最新情報とならない
        const filteredData = purchasesData?.filter(
          (masterItem) => masterItem.id === selectedDataDetail?.esIq3Info[s]?.purchases[p]?.purchaseMasterId
        );
        let getPriceLots = [];
        let unitPriceByQty = 0;
        if (filteredData?.length > 0) {
          getPriceLots = filteredData[0].priceLots;
          unitPriceByQty = getPriceForUpperThVal(
            selectedDataDetail?.esIq3Info[s]?.purchases[p].required * quantity,
            getPriceLots
          );
          selectedDataDetail.esIq3Info[s].purchases[p].name = filteredData[0]?.name;
          selectedDataDetail.esIq3Info[s].purchases[p].model = filteredData[0]?.info?.model;
          selectedDataDetail.esIq3Info[s].purchases[p].purchaseCategoryId = filteredData[0]?.purchaseCategoryId;
          selectedDataDetail.esIq3Info[s].purchases[p].processTime = filteredData[0]?.info?.processTime;
          selectedDataDetail.esIq3Info[s].purchases[p].supplierCode = filteredData[0]?.info?.supplierCode;
          selectedDataDetail.esIq3Info[s].purchases[p].supplierName = filteredData[0]?.info?.supplierName;
          selectedDataDetail.esIq3Info[s].purchases[p].makerName = filteredData[0]?.info?.makerName;
          selectedDataDetail.esIq3Info[s].purchases[p].remarks = filteredData[0]?.info?.remarks;
          selectedDataDetail.esIq3Info[s].purchases[p].totalProcessTime = filteredData[0]?.info?.processTime * quantity;
        } else {
          unitPriceByQty = 0;
        }
        selectedDataDetail.esIq3Info[s].purchases[p].unitPriceByQuantity = unitPriceByQty;
        selectedDataDetail.esIq3Info[s].purchases[p].price =
          unitPriceByQty > 0
            ? selectedDataDetail?.esIq3Info[s]?.purchases[p].isKeepPrice
              ? unitPriceByQty * selectedDataDetail?.esIq3Info[s]?.purchases[p].required
              : unitPriceByQty
            : selectedDataDetail?.esIq3Info[s]?.purchases[p].isKeepPrice
            ? selectedDataDetail?.esIq3Info[s]?.purchases[p].unitPrice *
              selectedDataDetail?.esIq3Info[s]?.purchases[p].required
            : selectedDataDetail?.esIq3Info[s]?.purchases[p].unitPrice; // IQX_WEBEST-290 単価保持のチェックがオフの場合、金額＝単価,
        selectedDataDetail.esIq3Info[s].purchases[p].totalPrice =
          unitPriceByQty > 0
            ? selectedDataDetail?.esIq3Info[s]?.purchases[p].isKeepPrice
              ? unitPriceByQty * selectedDataDetail?.esIq3Info[s]?.purchases[p].required * partCnt * quantity
              : unitPriceByQty
            : selectedDataDetail?.esIq3Info[s]?.purchases[p].isKeepPrice
            ? selectedDataDetail?.esIq3Info[s]?.purchases[p].unitPrice *
              selectedDataDetail?.esIq3Info[s]?.purchases[p].required *
              partCnt *
              quantity
            : selectedDataDetail?.esIq3Info[s]?.purchases[p].unitPrice; // IQX_WEBEST-231 単価保持のチェックがオフの場合、合計金額＝単価

        iq3PurUnitPrice += selectedDataDetail?.esIq3Info[s]?.purchases[p]?.price;
        iq3PurTotalPrice += selectedDataDetail?.esIq3Info[s]?.purchases[p]?.totalPrice; //単価保持
        // iq3PurTotalPrice += iq3Purchase[p]?.isKeepPrice === true ? iq3Purchase[p]?.price : iq3Purchase[p]?.totalPrice;
        // 各板金の購入品合計
        // iq3PurchaseUnitPrice += iq3Purchase[p]?.price;
        iq3PurchaseUnitPrice += selectedDataDetail?.esIq3Info[s]?.purchases[p]?.price;
        // iq3Purchase[p].totalPrice = iq3Purchase[p]?.price * partCnt;
        // iq3PurchaseTotalPrice += iq3Purchase[p]?.totalPrice;
        iq3PurchaseTotalPrice += selectedDataDetail?.esIq3Info[s]?.purchases[p]?.totalPrice;
      }
    }
    iq3PurUnitPrice = iq3PurUnitPrice ? iq3PurUnitPrice * partCnt : 0;
    // 各板金の購入品費
    selectedDataDetail.esIq3Info[s].purchaseUnitPrice = iq3PurchaseUnitPrice ? iq3PurchaseUnitPrice : 0;
    selectedDataDetail.esIq3Info[s].purchaseTotalPrice = iq3PurchaseTotalPrice ? iq3PurchaseTotalPrice : 0;

    //板金の合計原価計算(材料原価＋製造原価＋購入品費)
    if (
      selectedDataDetail.esIq3Info[s]?.materialCostTotalPrice !== undefined &&
      selectedDataDetail.esIq3Info[s]?.workCostTotalPrice !== undefined &&
      selectedDataDetail.esIq3Info[s]?.purchaseTotalPrice !== undefined
    ) {
      selectedDataDetail.esIq3Info[s].costTotalPrice =
        selectedDataDetail.esIq3Info[s]?.materialCostTotalPrice +
        selectedDataDetail.esIq3Info[s]?.workCostTotalPrice +
        selectedDataDetail.esIq3Info[s]?.purchaseTotalPrice;
      // 各板金の合計原価
      iq3ManufacturingCostTotalPrice += selectedDataDetail.esIq3Info[s]?.workCostTotalPrice;
      // 板金金額NaNチェック(プログラム工程とシャーリング工程以外)
      iq3ManufacturingCostTotalPrice = iq3ManufacturingCostTotalPrice ? iq3ManufacturingCostTotalPrice : 0;
    }

    //板金の単品原価計算(板金の合計原価 ÷ 製造数)
    if (selectedDataDetail.esIq3Info[s].costTotalPrice !== undefined) {
      selectedDataDetail.esIq3Info[s].costUnitPrice = selectedDataDetail.esIq3Info[s].costTotalPrice / manufacturingCnt;
    }
  }

  //板金子部品より重量と面積データ更新
  estimateProducts.totalWeight = iq3Weight;
  estimateProducts.totalWeightSm = iq3Weight;
  estimateProducts.totalSurfaceArea = iq3Area;
  let iq3TotalPartCount = esIq3Infos?.reduce((total, item) => {
    return total + Number(item.partCount) * quantity; // 板金子部品製作数（板金子部品の員数＊数量）
  }, 0);
  estimateProducts.total_quantity_sm = iq3TotalPartCount; // 板金子部品製作数（板金子部品の員数＊数量）

  //費用合計
  let costPriceTotal = 0;
  let epCustomCosts = selectedDataDetail.epCustomCosts;
  for (let i = 0; i < epCustomCosts?.length; i++) {
    if (epCustomCosts[i]?.isUseUnit) {
      costPriceTotal += epCustomCosts[i]?.unitPrice * quantity; // IQX_WEBEST-228
    } else {
      costPriceTotal += epCustomCosts[i]?.totalPrice;
    }
  }

  /** 合計原価計算 */
  // 親部品製造合計原価(段取金額+(作業/加工金額*数量))
  let parentManufacturingCostTotalPrice = 0;
  if (setupCostUnitPrice !== undefined && estimateProducts?.workCostUnitPrice !== undefined) {
    parentManufacturingCostTotalPrice = setupCostUnitPrice + estimateProducts?.workCostUnitPrice * quantity;
    estimateProducts.setupCostTotalPrice = setupCostUnitPrice + iq3SetupCostPrice_total;
    estimateProducts.workCostTotalPrice = estimateProducts?.workCostUnitPrice * quantity + iq3WorkCostPrice_total;
    estimateProducts.manufacturingCostTotalPrice = parentManufacturingCostTotalPrice
      ? parentManufacturingCostTotalPrice
      : 0;
  }
  //　製造合計原価（親部品製造合計原価　＋　板金製造合計原価*数量＋プログラム工程合計原価＋シャーリング合計原価 + 形鋼製造合計原価）
  estimateProducts.manufacturingCostTotalPrice = parentManufacturingCostTotalPrice + iq3ManufacturingCostTotalPrice;
  // 製造合計原価
  let totalProcessGenka = estimateProducts.manufacturingCostTotalPrice
    ? estimateProducts.manufacturingCostTotalPrice
    : 0;

  // 合計原価（材料原価+段取金額＋(加工金額×数量)+購入品費+追加項目(費用)）
  // 材料原価合計
  let totalMaterialGenka = estimateProducts?.materialCostTotalPrice ? estimateProducts?.materialCostTotalPrice : 0;
  estimateProducts.materialCostTotalPrice = totalMaterialGenka ? totalMaterialGenka : 0;

  // 合計原価
  let totalGenka = totalMaterialGenka + totalProcessGenka + costPriceTotal;
  estimateProducts.costTotalPrice = totalGenka ? totalGenka : 0;

  // 単品原価
  let genka = Number.isFinite(totalGenka / quantity) ? totalGenka / quantity : 0;
  // 原価
  if (genka !== undefined) {
    estimateProducts.costUnitPrice = genka;
  } else {
    estimateProducts.costUnitPrice = 0;
  }

  /** 合計見積計算 */
  // 取引先係数＋納期係数＋受注形態係数
  let coeffsTotal =
    Number(estimateProducts?.otherProduct?.coeffClient) +
    Number(estimateProducts?.otherProduct?.coeffDeliveryDates) +
    Number(estimateProducts?.otherProduct?.coeffOrders);

  // 材料見積合計
  let totalMaterialEstimate = iq3EstimateMaterialTotalPrices ? iq3EstimateMaterialTotalPrices : 0;
  //加工原価の見積合計((加工原価合計*(取引先係数＋納期係数＋受注形態係数-2))*管理係数)
  let totalProcessEstimate =
    coeffsTotal && estimateProducts?.otherProduct?.coeffManagements
      ? estimateProducts?.manufacturingCostTotalPrice *
        (coeffsTotal - 2) *
        Number(estimateProducts?.otherProduct?.coeffManagements)
      : 0;
  //購入品費見積(購入品費*"仕入先係数or購入品係数")
  let totalPurchaseEstimate = 0;
  //合計見積(材料見積+加工見積+購入品費+追加項目(費用))
  let totalEstimate = totalMaterialEstimate + totalProcessEstimate + totalPurchaseEstimate + costPriceTotal;

  estimateProducts.estimateTotalPrice = totalEstimate ? totalEstimate : 0;

  /** 見積計算 */
  let estimate = Number.isFinite(totalEstimate / quantity) ? totalEstimate / quantity : 0;
  estimateProducts.estimateUnitPrice = estimate ? estimate : 0;

  // 粗利単価
  estimateProducts.grossUnitPrice = estimate !== undefined && genka !== undefined ? estimate - genka : 0;
  // 粗利合計(合計見積-合計原価)
  estimateProducts.grossTotalPrice =
    totalEstimate !== undefined && totalGenka !== undefined ? totalEstimate - totalGenka : 0;

  // 粗利率(粗利/合計見積x100)
  if (estimateProducts.grossTotalPrice !== undefined && totalEstimate !== undefined) {
    estimateProducts.grossRate =
      estimateProducts.grossTotalPrice === 0 || totalEstimate === 0
        ? 0
        : Number.isFinite(estimateProducts.grossTotalPrice / totalEstimate)
        ? (estimateProducts.grossTotalPrice / totalEstimate) * 100
        : 0;
    estimateProducts.grossRate = estimateProducts.grossRate !== undefined ? estimateProducts.grossRate : 0.0;
  }
  selectedDataDetail.estimateProducts = estimateProducts;
  return selectedDataDetail;
};

// // 材料リストの計算
const calculateMaterialDetailSize = (
  selectedIq3Info,
  isDetail = false,
  isDetailRotate = false,
  pattern1Info,
  pattern2Info,
  orderQuantity,
  materialIq3,
  estimateProducts
) => {
  let materialPatternLists = [];
  let materialDetailSizes = [];
  let materialDetailPatterns = [];
  let detailP1MaterialSizeId = pattern1Info.materialSizeIq3Id;
  let detailP1XSize = pattern1Info.sizeX;
  let detailP1YSize = pattern1Info.sizeY;
  let p1YSetting = pattern1Info.ySetting;
  let p1TopFit = pattern1Info.topFit;
  let p1RightFit = pattern1Info.rightFit;

  let detailP2MaterialSizeId = pattern2Info.materialSizeIq3Id;
  let detailP2XSize = pattern2Info.sizeX;
  let detailP2YSize = pattern2Info.sizeY;
  let p2YSetting = pattern2Info.ySetting;
  let p2TopFit = pattern2Info.topFit;
  let p2RightFit = pattern2Info.rightFit;

  materialDetailSizes = selectedIq3Info?.materialDetailSizes;
  materialDetailPatterns = selectedIq3Info?.materialDetailPatterns;

  // freeの場合、
  if (materialDetailSizes?.length === 0 && detailP1MaterialSizeId === 0) {
    let initFreeSizeInfo = JSON.parse(JSON.stringify(getInitFreeMaterialSizeInfo()));
    materialDetailSizes?.push({
      ...initFreeSizeInfo,
      yRotate: yRotate,
    });
    let freeMaterialDetailPatterns = [];
    materialDetailPatterns = getInitMaterialPatternsInfo(freeMaterialDetailPatterns, materialDetailSizes);
    materialDetailPatterns = materialDetailPatterns?.map((pItem) => {
      return {
        ...pItem,
        materialSizeIq3Id: initFreeSizeInfo?.materialSizeIq3Id,
        sizeName: initFreeSizeInfo?.sizeName,
        sizeX: pItem?.patternType === PatternType.Pattern1 ? detailP1XSize : detailP2XSize,
        sizeY: pItem?.patternType === PatternType.Pattern1 ? detailP1YSize : detailP2YSize,
        ySetting: pItem?.patternType === PatternType.Pattern1 ? p1YSetting : p2YSetting,
        topFit: pItem?.patternType === PatternType.Pattern1 ? p1TopFit : p2TopFit,
        rightFit: pItem?.patternType === PatternType.Pattern1 ? p1RightFit : p2RightFit,
      };
    });
  }
  let p1MaterialYSize = 0;
  let p1MaterialXSize = 0;
  let p2MaterialYSize = 0;
  let p2MaterialXSize = 0;
  let isExistPattern2 = true;
  // 材料サイズ
  let materialSizeData = getMaterialSizeData(estimateProducts); // 材料サイズ情報;
  materialDetailPatterns?.map((patternInfo) => {
    let info = { ...patternInfo };
    let isFree = info.sizeName === 'Free' ? true : false;
    let xSize = 0;
    let ySize = 0;
    let xPart = 0;
    let yPart = 0;
    let totalNoOfMaterial = 0;
    let materialPrice = 0; // 合計金額
    let pattern1Price = 0; // パターン１金額
    let pattern2Price = 0; // パターン２金額
    let yiedlRate = 0;
    let pattern1Rate = 0;
    let pattern2Rate = 0;

    let totalPlacement = 0; // 合計配置数
    let pattern1Placement = 0; // パターン１配置数
    let pattern2Placement = 0; // パターン２配置数
    let pattern1NoOfMaterial = 0; // パターン１枚数
    let pattern2NoOfMaterial = 0; // パターン２枚数

    // 最大歩留まり
    let maxYieldRate = 0;
    // 最大加工数
    let maxNoOfPlace = 0;

    // 材料サイズ情報
    let sizeInfo = materialDetailSizes?.find((item) => item.materialSizeIq3Id === info.materialSizeIq3Id);

    // 材料パラメータ情報
    // 材料マスタ情報
    let materialListData = materialIq3?.filter((i) => i?.info?.isUsed);
    let materialInfo = materialListData?.find((item) => item.id === selectedIq3Info.materialIq3Id);

    let freeMaterialX = '';
    let freeMaterialY = '';

    freeMaterialX =
      info.patternType === PatternType.Pattern1 ? detailP1XSize : isExistPattern2 ? detailP2XSize : detailP1XSize;
    freeMaterialY =
      info.patternType === PatternType.Pattern1 ? detailP1YSize : isExistPattern2 ? detailP2YSize : detailP1YSize;

    if (info?.patternType === PatternType.Pattern1) {
      p1MaterialYSize = 0;
      p1MaterialXSize = 0;
      p2MaterialYSize = 0;
      p2MaterialXSize = 0;
    }

    // 材料枚数の計算
    let freeSizeInfo = materialDetailPatterns?.find(
      (item) => item.materialSizeIq3Id === info.materialSizeIq3Id && item.patternType === info?.patternType
    );
    let freeSizeX = freeSizeInfo?.sizeX ? freeSizeInfo?.sizeX : 0;
    let freeSizeY = freeSizeInfo?.sizeY ? freeSizeInfo?.sizeY : 0;
    freeMaterialX = freeSizeX;
    freeMaterialY = freeSizeY;

    let toDrawInfo = settingCalPatternInfo(
      info.materialSizeIq3Id,
      isFree ? freeSizeX : '',
      isFree ? freeSizeY : '',
      sizeInfo?.yRotate,
      selectedIq3Info,
      orderQuantity,
      materialSizeData
    );

    xSize = isFree ? Number(freeMaterialX) : Number(toDrawInfo?.materialInfo?.mSheetX);
    ySize = isFree ? Number(freeMaterialY) : Number(toDrawInfo?.materialInfo?.mSheetY);
    xPart = Number(toDrawInfo?.partInfo?.mPartX);
    yPart = Number(toDrawInfo?.partInfo?.mPartY);

    let partCntInfo = undefined;
    if (isFree) {
      partCntInfo = freeDraw(
        toDrawInfo?.partInfo,
        toDrawInfo?.settingInfo,
        isDetail
          ? { p1MSheetX: detailP1XSize, p1MSheetY: detailP1YSize, p2MSheetX: detailP2XSize, p2MSheetY: detailP2YSize }
          : {
              p1MSheetX: info?.patternType === PatternType.Pattern2 ? p1MaterialXSize : xSize,
              p1MSheetY: info?.patternType === PatternType.Pattern2 ? p1MaterialYSize : ySize,
              p2MSheetX: info?.patternType === PatternType.Pattern2 ? xSize : p2MaterialXSize,
              p2MSheetY: info?.patternType === PatternType.Pattern2 ? ySize : p2MaterialYSize,
            },
        info?.patternType === PatternType.Pattern2 ? true : false,
        info?.ySetting,
        info?.rightFit,
        info?.topFit,
        '',
        isExistPattern2
      );
      if (
        partCntInfo &&
        (partCntInfo?.invalidMaterial || partCntInfo?.invalidXMaterial || partCntInfo?.invalidYMaterial)
      ) {
        if (partCntInfo?.invalidMaterial) {
          xSize = detailP1XSize;
          ySize = detailP1YSize;
        } else if (partCntInfo?.invalidXMaterial && (activePattern === info.patternType || !isPatternDetail)) {
          xSize = getMaterialAutoSize(selectedIq3Info, yRotate);
        } else if (partCntInfo?.invalidYMaterial && (activePattern === info.patternType || !isPatternDetail)) {
          ySize = getMaterialAutoSize(selectedIq3Info, yRotate, false);
        }
        // 材料・掴み代・部品矩形・桟幅の配置
        partCntInfo = freeDraw(
          toDrawInfo?.partInfo,
          toDrawInfo?.settingInfo,
          isDetail
            ? { p1MSheetX: xSize, p1MSheetY: ySize, p2MSheetX: xSize, p2MSheetY: ySize }
            : {
                p1MSheetX: info?.patternType === PatternType.Pattern2 ? p1MaterialXSize : xSize,
                p1MSheetY: info?.patternType === PatternType.Pattern2 ? p1MaterialYSize : ySize,
                p2MSheetX: info?.patternType === PatternType.Pattern2 ? xSize : p2MaterialXSize,
                p2MSheetY: info?.patternType === PatternType.Pattern2 ? ySize : p2MaterialYSize,
              },
          info?.patternType === PatternType.Pattern2 ? true : false,
          info?.ySetting,
          info?.rightFit,
          info?.topFit,
          '',
          isExistPattern2
        );
      }
    } else {
      // 材料・掴み代・部品矩形・桟幅の配置
      partCntInfo = draw(
        toDrawInfo?.partInfo,
        toDrawInfo?.settingInfo,
        toDrawInfo?.materialInfo,
        info?.patternType === PatternType.Pattern2 ? true : false,
        info?.ySetting,
        info?.rightFit,
        info?.topFit,
        ''
      );
    }
    if (partCntInfo !== undefined) {
      if (info?.patternType === PatternType.Pattern1) {
        p1MaterialXSize = xSize;
        p1MaterialYSize = ySize;
      } else {
        p2MaterialXSize = xSize;
        p2MaterialYSize = ySize;
      }

      // Y方向をFixの場合、
      if (isDetail && info?.topFit) {
        // 製品の矩形Y
        let partY = toDrawInfo?.partInfo.mPartY + toDrawInfo?.settingInfo.mMarginY;
        if (info?.patternType === PatternType.Pattern1) {
          let p1YSize = partY * partCntInfo?.p1PartCntY + toDrawInfo?.settingInfo.mClampdzY;
          if (p1YSize > 0) p1MaterialYSize = p1YSize;
        } else {
          let p2YSize = partY * partCntInfo?.p2PartCntY + toDrawInfo?.settingInfo.mClampdzY;
          if (p2YSize > 0) p2MaterialYSize = p2YSize;
        }
      }
      // 右方向をFixの場合、
      if (isDetail && info?.rightFit) {
        // 製品の矩形X
        let partX = toDrawInfo?.partInfo.mPartX + toDrawInfo?.settingInfo.mMarginX;
        if (info?.patternType === PatternType.Pattern1) {
          let p1XSize = partX * partCntInfo?.p1PartCntX + toDrawInfo?.settingInfo.mClampdzX;
          if (p1XSize > 0) p1MaterialXSize = p1XSize;
        } else {
          let p2XSize = partX * partCntInfo?.p2PartCntX + toDrawInfo?.settingInfo.mClampdzX;
          if (p2XSize > 0) p2MaterialXSize = p2XSize;
        }
      }

      // 最大加工数
      maxNoOfPlace = partCntInfo.maxNoOfPlace < 0 ? 0 : partCntInfo.maxNoOfPlace;

      // 最大数
      let maxPlacement = Number(selectedIq3Info.partCount * orderQuantity); //　員数＊数量

      // パターン１枚数
      pattern1NoOfMaterial = isInfinity(partCntInfo.p1NoOfMaterial) ? 0 : partCntInfo.p1NoOfMaterial;
      // パターン１配置数
      pattern1Placement = partCntInfo.p1NoOfPlace * pattern1NoOfMaterial;

      isExistPattern2 = true;
      if (maxPlacement == pattern1Placement * pattern1NoOfMaterial && maxPlacement == pattern1NoOfMaterial) {
        isExistPattern2 = false;
      } else if (maxPlacement <= pattern1Placement * pattern1NoOfMaterial && pattern1NoOfMaterial == 1) {
        isExistPattern2 = false;
      } else if (maxPlacement == pattern1Placement) {
        isExistPattern2 = false;
      } else if (isInfinity(pattern1NoOfMaterial)) {
        isExistPattern2 = false;
      }

      if (isExistPattern2) {
        pattern2NoOfMaterial = info.sizeName === 'Other' ? 0 : 1;
        totalNoOfMaterial = pattern1NoOfMaterial + pattern2NoOfMaterial;
      } // パターン1の材料枚数＋パターン2の材料枚数
      else {
        totalNoOfMaterial = pattern1NoOfMaterial;
        pattern2NoOfMaterial = 0;
      }

      // 材料費の計算=材料X(mm)*材料Y(mm)*板厚(mm)*比重(kg/c㎥)/1000000*重量単価(¥/kg)*材料枚数
      pattern1Price =
        p1MaterialXSize *
        p1MaterialYSize *
        materialInfo?.info?.thick *
        (materialInfo?.info?.gravity / 1000000) *
        materialInfo?.info?.unitPrice *
        pattern1NoOfMaterial;

      // IQX_WEBEST-356 材料代に表面保護が含まれていない
      // 材料表面保護マスタ情報
      let materialSurfaceListData = estimateProducts?.calcParameters?.parameters?.materialSurfaceIq3?.filter(
        (i) => i?.info?.isUsed
      );
      let surfaceProtectionId = isDetail ? detailSurfaceId : selectedIq3Info?.materialSurfaceIq3Id;
      let surfaceProtectionInfo = materialSurfaceListData?.find((item) => item.id === surfaceProtectionId);
      if (surfaceProtectionInfo) {
        let p1MaterialSize = p1MaterialXSize * p1MaterialYSize; // 材料X(mm)*材料Y(mm)
        if (surfaceProtectionInfo?.info?.unit === MaterialSurfaceBillingUnit.SquareMeter) {
          // 加算方法が平米の場合
          let p1MaterialXSizeM = Number.isFinite(p1MaterialXSize / 1000) ? p1MaterialXSize / 1000 : 0; // 材料X(mm)をｍに変換
          let p1MaterialYSizeM = Number.isFinite(p1MaterialYSize / 1000) ? p1MaterialYSize / 1000 : 0; // 材料Y(mm)をｍに変換
          // 材料X(m)*材料Y(m)
          p1MaterialSize = p1MaterialXSizeM * p1MaterialYSizeM;
        } else {
          // 加算方法が重量の場合
          //材料X(mm)*材料Y(mm)*板厚(mm)*比重(kg/c㎥)/1000000
          p1MaterialSize = p1MaterialSize * materialInfo?.info?.thick * (materialInfo?.info?.gravity / 1000000);
        }
        // 材料費*加算金額*材料枚数
        let p1SurfaceProtectionAddAmt = 0;
        if (isInfinity(pattern1NoOfMaterial)) {
          p1SurfaceProtectionAddAmt = p1MaterialSize * surfaceProtectionInfo?.info?.price * 0;
        } else {
          p1SurfaceProtectionAddAmt = p1MaterialSize * surfaceProtectionInfo?.info?.price * pattern1NoOfMaterial;
        }
        // 材料費に表面保護の金額を加算
        if (isInfinity(pattern1Price)) {
          pattern1Price = 0;
        }
        pattern1Price += p1SurfaceProtectionAddAmt;
        pattern1Price = pattern1Price ? pattern1Price : 0;
      }

      // 材料費の計算=材料X(mm)*材料Y(mm)*板厚(mm)*比重(kg/c㎥)/1000000*重量単価(¥/kg)*材料枚数
      pattern2Price =
        p2MaterialXSize *
        p2MaterialYSize *
        materialInfo?.info?.thick *
        (materialInfo?.info?.gravity / 1000000) *
        materialInfo?.info?.unitPrice *
        pattern2NoOfMaterial;

      // IQX_WEBEST-356 材料代に表面保護が含まれていない
      if (surfaceProtectionInfo) {
        let p2MaterialSize = p2MaterialXSize * p2MaterialYSize; // 材料X(mm)*材料Y(mm)
        if (surfaceProtectionInfo?.info?.unit === MaterialSurfaceBillingUnit.SquareMeter) {
          // 加算方法が平米の場合
          let p2MaterialXSizeM = Number.isFinite(p2MaterialXSize / 1000) ? p2MaterialXSize / 1000 : 0; // 材料X(mm)をｍに変換
          let p2MaterialYSizeM = Number.isFinite(p2MaterialYSize / 1000) ? p2MaterialYSize / 1000 : 0; // 材料Y(mm)をｍに変換
          // 材料X(m)*材料Y(m)
          p2MaterialSize = p2MaterialXSizeM * p2MaterialYSizeM;
        } else {
          // 加算方法が重量の場合
          //材料X(mm)*材料Y(mm)*板厚(mm)*比重(kg/c㎥)/1000000
          p2MaterialSize = p2MaterialSize * materialInfo?.info?.thick * (materialInfo?.info?.gravity / 1000000);
        }
        // 材料費*加算金額*材料枚数
        let p2SurfaceProtectionAddAmt = 0;
        if (isInfinity(pattern1NoOfMaterial)) {
          p2SurfaceProtectionAddAmt = p2MaterialSize * surfaceProtectionInfo?.info?.price * 0;
        } else {
          p2SurfaceProtectionAddAmt = p2MaterialSize * surfaceProtectionInfo?.info?.price * pattern2NoOfMaterial;
        }
        // 材料費に表面保護の金額を加算
        if (isInfinity(pattern2Price)) {
          pattern2Price = 0;
        }
        pattern2Price += p2SurfaceProtectionAddAmt;
        pattern2Price = pattern2Price ? pattern2Price : 0;
      }

      // 合計金額
      materialPrice = pattern1Price + pattern2Price;

      if (isExistPattern2) {
        pattern2Placement = info.sizeName === 'Other' ? 0 : partCntInfo.p2NoOfPlace;
      }
      totalPlacement = pattern1Placement + pattern2Placement; // パターン1の配置数＋パターン2の配置数

      // Otherの材料サイズの計算
      if (info.sizeName === 'Other') {
        // 歩留の計算=矩形X*矩形Y/材料X*材料Y*100
        yiedlRate = ((xPart * yPart) / (p1MaterialXSize * p1MaterialYSize)) * 100;
        pattern1Rate = yiedlRate;
        maxYieldRate = yiedlRate;
        pattern2Rate = 0;
      } else {
        // 材料X*材料Y*材料枚数
        let p1MaterialSize = p1MaterialXSize * p1MaterialYSize * pattern1NoOfMaterial;
        let p2MaterialSize = p2MaterialXSize * p2MaterialYSize * pattern2NoOfMaterial;
        // 矩形X*矩形Y*配置数/材料X*材料Y*材料枚数*100
        yiedlRate = ((xPart * yPart * totalPlacement) / (p1MaterialSize + p2MaterialSize)) * 100;
        pattern1Rate = ((xPart * yPart * pattern1Placement) / p1MaterialSize) * 100;
        if (isExistPattern2) {
          pattern2Rate = ((xPart * yPart * pattern2Placement) / p2MaterialSize) * 100;
        } else {
          pattern2Rate = 0;
        }
        maxYieldRate = ((xPart * yPart * maxNoOfPlace) / (xSize * ySize * totalNoOfMaterial)) * 100;
      }
    }

    if (sizeInfo) {
      sizeInfo.materialQuantity = Number.isInteger(totalNoOfMaterial) ? totalNoOfMaterial : 0;
      sizeInfo.price = Number.isFinite(materialPrice) ? materialPrice : 0;
      sizeInfo.yield = yiedlRate && !isInfinity(yiedlRate) ? yiedlRate : 0;
      sizeInfo.pattern1Rate = pattern1Rate && !isInfinity(pattern1Rate) ? pattern1Rate : 0;
      sizeInfo.pattern1Quantity = pattern1Placement && !isInfinity(pattern1Placement) ? pattern1Placement : 0;
      sizeInfo.pattern2Rate = pattern2Rate && !isInfinity(pattern2Rate) ? pattern2Rate : 0;
      sizeInfo.pattern2Quantity = pattern2Placement && !isInfinity(pattern2Placement) ? pattern2Placement : 0;
      sizeInfo.maxYield = maxYieldRate && !isInfinity(maxYieldRate) ? maxYieldRate : 0;
      sizeInfo.maxQuantity = maxNoOfPlace;
      if (sizeInfo?.yRotate === undefined) sizeInfo['yRotate'] = false;
    }

    // 材料詳細パターン情報
    let isPattern1 = info.patternType === PatternType.Pattern1 ? true : false;
    let materialQuantity = isPattern1 ? pattern1NoOfMaterial : pattern2NoOfMaterial;
    let totalPrice = isPattern1 ? pattern1Price : pattern2Price;
    let rate = isPattern1 ? pattern1Rate : pattern2Rate;
    materialPatternLists?.push({
      patternType: info.patternType,
      totalPrice: totalPrice && !isInfinity(totalPrice) ? totalPrice : 0,
      yield: rate && !isInfinity(rate) ? rate : 0,
      materialQuantity: materialQuantity && !isInfinity(materialQuantity) ? materialQuantity : 0,
      materialSizeIq3Id: info?.materialSizeIq3Id,
      sizeName: info?.sizeName,
      sizeX: info?.rightFit ? (isPattern1 ? p1MaterialXSize : p2MaterialXSize) : xSize,
      sizeY: info?.topFit ? (isPattern1 ? p1MaterialYSize : p2MaterialYSize) : ySize,
      ySetting: info?.ySetting,
      rightFit: info?.rightFit,
      topFit: info?.topFit,
    });
  });

  // 材料サイズリスト情報
  materialDetailSizes?.map((item) => {
    let index = selectedIq3Info.materialDetailSizes?.findIndex(
      (list) => list.materialSizeIq3Id === item.materialSizeIq3Id
    );
    if (index > -1) {
      selectedIq3Info.materialDetailSizes[index] = item;
    } else {
      // Freeサイズの場合、サイズ一覧の先頭行に追加する
      const index = 0;
      selectedIq3Info.materialDetailSizes = [
        ...selectedIq3Info.materialDetailSizes.slice(0, index),
        item,
        ...selectedIq3Info.materialDetailSizes.slice(index),
      ];
    }
  });

  // 材料詳細パターン情報
  materialPatternLists?.map((item) => {
    let index = selectedIq3Info.materialDetailPatterns?.findIndex(
      (list) => list.patternType === item.patternType && list.materialSizeIq3Id === item.materialSizeIq3Id
    );
    if (index > -1) {
      selectedIq3Info.materialDetailPatterns[index] = item;
    } else {
      // Freeサイズの場合、パターン詳細情報に追加する
      selectedIq3Info.materialDetailPatterns.push(item);
    }
  });

  return selectedIq3Info;
};

const settingCalPatternInfo = (
  selectedMaterialSizeId,
  materialX,
  materialY,
  yRotate,
  currentIq3Info,
  orderQuantity,
  materialSizeData
) => {
  let toDrawInfo = {
    partInfo: undefined,
    settingInfo: undefined,
    materialInfo: undefined,
  };
  /** 部品情報 */
  let partInfo = {};
  if (currentIq3Info) {
    let mPartInfo = currentIq3Info;
    partInfo = {
      mPartX: yRotate ? Number(mPartInfo.partSizeY) : Number(mPartInfo.partSizeX),
      mPartY: yRotate ? Number(mPartInfo.partSizeX) : Number(mPartInfo.partSizeY),
      partCnt: Number(mPartInfo.partCount),
      suryou: Number(orderQuantity),
      totalProcessSuryou: Number(mPartInfo.partCount * orderQuantity), //　員数＊数量
    };
  }

  /** 材料情報 */
  let materialInfo = {};
  let sheetX = 0;
  let sheetY = 0;
  if (materialX && materialY) {
    sheetX = materialX;
    sheetY = materialY;
  } else {
    let selectedMaterialSize = selectedMaterialSizeId;
    let materialSizes = materialSizeData ? materialSizeData?.filter((item) => item.id === selectedMaterialSize) : [];
    if (materialSizes?.length > 0) {
      //材料サイズをOtherに変更した場合、部品矩形サイズ＋桟幅＋掴み代で決定
      if (materialSizes[0]?.sizeX == 0 || materialSizes[0]?.sizeY == 0 || materialSizes[0]?.name === 'Other') {
        // /材料サイズX計算
        sheetX = Number(partInfo.mPartX) + Number(currentIq3Info?.grabbingX) + Number(currentIq3Info?.pierWidthX);
        // /材料サイズY計算
        sheetY = Number(partInfo.mPartY) + Number(currentIq3Info?.grabbingY) + Number(currentIq3Info?.pierWidthY);
      } else {
        sheetX = Number(materialSizes[0]?.sizeX);
        sheetY = Number(materialSizes[0]?.sizeY);
      }
    }
  }
  materialInfo = {
    mSheetX: sheetX ? toDecimal(sheetX, 2) : 0,
    mSheetY: sheetY ? toDecimal(sheetY, 2) : 0,
  };

  toDrawInfo.partInfo = partInfo;
  toDrawInfo.settingInfo = {
    mClampdzX: Number(currentIq3Info?.pierWidthX),
    mClampdzY: Number(currentIq3Info?.pierWidthY),
    mMarginX: Number(currentIq3Info?.grabbingX),
    mMarginY: Number(currentIq3Info?.grabbingY),
  };
  toDrawInfo.materialInfo = materialInfo;
  return toDrawInfo;
};

const freeDraw = (partInfo, settingInfo, materialInfo, isPattern2, ySetting, rightFit, topFit, isExistPattern2) => {
  let partCntInfo = {
    partCntX: 1,
    partCntY: 1,
    p1NoOfMaterial: 0,
    p1NoOfPlace: 0,
    p2NoOfPlace: 0,
    maxNoOfPlace: 0,
    p1PartCntX: 0,
    p1PartCntY: 0,
    p2PartCntX: 0,
    p2PartCntY: 0,
    invalidMaterial: false,
    invalidXMaterial: false,
    invalidYMaterial: false,
  };
  if (partInfo.mPartX <= 0 || partInfo.mPartY <= 0) return;
  if (materialInfo.p1MSheetX < 0 || materialInfo.p1MSheetY < 0) return;

  //　利用可能な材料サイズ（材料ー掴み代）配置
  let materialX = materialInfo.p1MSheetX - settingInfo.mClampdzX;
  let materialY = materialInfo.p1MSheetY - settingInfo.mClampdzY;
  materialX = toDecimal(materialX, 2);
  materialY = toDecimal(materialY, 2);
  // パターン２がない場合、配置しない。
  if (isPattern2 && !isExistPattern2) {
    return partCntInfo;
  } else {
    // 1つの製品を配置する時に使用する矩形サイズを計算する
    // 製品の矩形X
    let settingPartX = partInfo.mPartX + settingInfo.mMarginX;
    let settingPartY = partInfo.mPartY + settingInfo.mMarginY;
    settingPartX = toDecimal(settingPartX, 2);
    settingPartY = toDecimal(settingPartY, 2);
    // 材料に配置できる総数を確認する(X方向)
    let settingPartCntX = Math.floor(materialX / settingPartX);
    if (settingPartCntX === 0) {
      partCntInfo.invalidXMaterial = true;
    }
    let clearSettingPartCntX = settingPartCntX;
    // 材料に配置できる総数を確認する(Y方向)
    let settingPartCntY = Math.floor(materialY / settingPartY);
    if (settingPartCntY === 0) {
      partCntInfo.invalidYMaterial = true;
    }
    // settingPartCntY = settingPartCntY <= 0 ? 1 : settingPartCntY; // IQX_WEBEST-203の対応、クローズする
    let clearSettingPartCntY = settingPartCntY;

    // 材料に配置できる最大数を計算する（パターン１に配置する部品数）
    let settingMaxCnt = Math.floor(settingPartCntX * settingPartCntY);
    partCntInfo.maxNoOfPlace = settingMaxCnt; // 最大加工数

    // パターン校数
    let n = Math.floor(partInfo.totalProcessSuryou / settingMaxCnt);
    //材料校数が1より小さい場合、１校数で配置できるXY方向を再計算
    if (n < 1) {
      // Y方向
      if (!ySetting) {
        settingPartCntY =
          partInfo.totalProcessSuryou % settingPartCntX === 0
            ? Math.floor(partInfo.totalProcessSuryou / settingPartCntX)
            : Math.floor(partInfo.totalProcessSuryou / settingPartCntX) + 1;
      } else {
        settingPartCntX =
          partInfo.totalProcessSuryou % settingPartCntY === 0
            ? Math.floor(partInfo.totalProcessSuryou / settingPartCntY)
            : Math.floor(partInfo.totalProcessSuryou / settingPartCntY) + 1;
      }
      settingMaxCnt = partInfo.totalProcessSuryou;
      // バグ：158の修正-START
      if (!ySetting) {
        if (settingMaxCnt < settingPartCntX) {
          settingPartCntX = settingMaxCnt;
        }
      } else {
        // Y方向
        if (settingMaxCnt < settingPartCntY) {
          settingPartCntY = settingMaxCnt;
        }
      }
      // バグ：158の修正-END
      n = 1;
    }
    partCntInfo.p1NoOfMaterial = n; // パターン1の材料枚数
    partCntInfo.p1NoOfPlace = settingMaxCnt; // パターン1の配置数
    partCntInfo.p1PartCntX = settingPartCntX; // パターン1のX配置数
    partCntInfo.p1PartCntY = settingPartCntY; // パターン1のY配置数
    clearSettingPartCntY = settingPartCntY;

    // パターン２の場合、最大部品数 － パターン１に配置する部品数
    if (isPattern2) {
      if (materialInfo.p2MSheetX < 0 || materialInfo.p2MSheetY < 0) return;

      //　利用可能な材料サイズ（材料ー掴み代）配置
      materialX = materialInfo.p2MSheetX - settingInfo.mClampdzX;
      materialY = materialInfo.p2MSheetY - settingInfo.mClampdzY;

      // 材料に配置できる総数を確認する(X方向)
      settingPartCntX = Math.floor(materialX / settingPartX);
      // 材料に配置できる総数を確認する(Y方向)
      settingPartCntY = Math.floor(materialY / settingPartY);
      settingPartCntY = settingPartCntY <= 0 ? 1 : settingPartCntY;
      let orgP2PartCntY = settingPartCntY; // Drawing前、パターン2のy配置数
      // Totalcount ＞ Maxcount *nかつ n＝1の場合、パターン1の配置が1枚とパターン2の配置が必要となる
      if (partInfo.totalProcessSuryou > settingMaxCnt * n && n == 1) {
        settingMaxCnt = partInfo.totalProcessSuryou - settingMaxCnt;
        if (!ySetting) {
          // settingPartCntY = Math.round(settingMaxCnt / settingPartCntX);
          settingPartCntY =
            settingMaxCnt % settingPartCntX === 0
              ? Math.floor(settingMaxCnt / settingPartCntX)
              : Math.floor(settingMaxCnt / settingPartCntX) + 1;
          if (settingMaxCnt < settingPartCntX) {
            settingPartCntX = settingMaxCnt;
          }
        } else {
          if (settingMaxCnt < settingPartCntY) {
            settingPartCntY = settingMaxCnt;
          }
        }
      } else if (partInfo.totalProcessSuryou > settingMaxCnt * n && n > 1) {
        // Totalcount ＞ Maxcount *nかつ n≠1の場合、パターン1の配置がｎ枚とパターン2の配置が必要となる
        settingMaxCnt = partInfo.totalProcessSuryou - settingMaxCnt * n;
        if (!ySetting) {
          // settingPartCntY = Math.round(settingMaxCnt / settingPartCntX);
          settingPartCntY =
            settingMaxCnt % settingPartCntX === 0
              ? Math.floor(settingMaxCnt / settingPartCntX)
              : Math.floor(settingMaxCnt / settingPartCntX) + 1;
          if (settingMaxCnt < settingPartCntX) {
            settingPartCntX = settingMaxCnt;
          }
        } else {
          if (settingMaxCnt < settingPartCntY) {
            settingPartCntY = settingMaxCnt;
          }
        }
      } else if (partInfo.totalProcessSuryou == settingMaxCnt * n && partInfo.totalProcessSuryou == n) {
        settingPartCntY = 0;
      } else if (partInfo.totalProcessSuryou <= settingMaxCnt * n && n == 1) {
        settingPartCntY = 0;
      }
      if (orgP2PartCntY < settingPartCntY) {
        partCntInfo.invalidMaterial = true;
      }
      clearSettingPartCntY = settingPartCntY;
    }
    if (settingPartCntX > 0 && settingPartCntY > 0) {
      // 部品合計数X
      partCntInfo.partCntX = settingPartCntX;
      partCntInfo.p2PartCntX = settingPartCntX; // パターン2のX配置数
      let orgP2PartCntX = settingPartCntX; // Drawing前、パターン2のX配置数
      let p2settingPartCntY = settingPartCntY;

      // パターン１又パターン２はleftToRight設定の場合
      if ((!ySetting && !isPattern2) || (!ySetting && isPattern2)) {
        // 部品矩形配置Y
        let fillCnt = settingPartCntX;
        for (let y = 0; y < settingPartCntY; y++) {
          if (y > 0) {
            if (fillCnt == settingMaxCnt) {
              settingPartCntX = settingPartCntX;
              fillCnt = fillCnt;
            } else if (fillCnt + settingPartCntX > settingMaxCnt) {
              settingPartCntX = settingMaxCnt - fillCnt;
              fillCnt = fillCnt + settingPartCntX;
            }
          }
          // 部品矩形配置X
          if (fillCnt != settingMaxCnt) {
            fillCnt = settingPartCntX * (y + 1);
          }
        }
      } else {
        // パターン１又パターン２はBottomToTop設定の場合
        let fillCnt = settingPartCntY;
        for (let max = 0; fillCnt < settingMaxCnt || max == 0; max++) {
          // 部品矩形配置X
          if (max > 0) {
            if (fillCnt == settingMaxCnt) {
              settingPartCntY = settingPartCntY;
              fillCnt = fillCnt;
            } else if (fillCnt + settingPartCntY > settingMaxCnt) {
              settingPartCntY = settingMaxCnt - fillCnt;
              fillCnt = fillCnt + settingPartCntY;
            }
          }

          if (fillCnt != settingMaxCnt) {
            fillCnt = settingPartCntY * (max + 1);
          }
          settingPartCntX = max + 1;
          partCntInfo.partCntX = settingPartCntX;
          partCntInfo.p2PartCntX = settingPartCntX; // パターン2のX配置数
          clearSettingPartCntX = settingPartCntX;
        }
        if (orgP2PartCntX < settingPartCntX) {
          partCntInfo.invalidMaterial = true;
        }
        settingPartCntY = p2settingPartCntY;
      }
      // 部品合計数X
      partCntInfo.partCntY = settingPartCntY;
      partCntInfo.p2PartCntY = settingPartCntY; // パターン2のY配置数
    } else {
      if (settingPartCntX === 0) {
        partCntInfo.invalidXMaterial = true;
      }
      if (settingPartCntY === 0) {
        partCntInfo.invalidYMaterial = true;
      }
      // 部品合計数X
      partCntInfo.partCntX = 0;
      partCntInfo.partCntY = 0;
    }
    partCntInfo.p2NoOfPlace = settingMaxCnt; // パターン2の配置数
    return partCntInfo;
  }
};

const draw = (partInfo, settingInfo, materialInfo, isPattern2, ySetting, rightFit, topFit, canvas = '') => {
  let partCntInfo = {
    partCntX: 1,
    partCntY: 1,
    p1NoOfMaterial: 0,
    p1NoOfPlace: 0,
    p2NoOfPlace: 0,
    maxNoOfPlace: 0,
    p1PartCntX: 0,
    p1PartCntY: 0,
    p2PartCntX: 0,
    p2PartCntY: 0,
    invalidMaterial: false,
    invalidXMaterial: false,
    invalidYMaterial: false,
  };
  if (partInfo.mPartX <= 0 || partInfo.mPartY <= 0) return;
  if (materialInfo.mSheetX < 0 || materialInfo.mSheetY < 0) return;

  //　利用可能な材料サイズ（材料ー掴み代）配置
  let materialX = materialInfo.mSheetX - settingInfo.mClampdzX;
  let materialY = materialInfo.mSheetY - settingInfo.mClampdzY;
  materialX = toDecimal(materialX, 2);
  materialY = toDecimal(materialY, 2);

  // 1つの製品を配置する時に使用する矩形サイズを計算する
  // 製品の矩形X
  let settingPartX = partInfo.mPartX + settingInfo.mMarginX;
  let settingPartY = partInfo.mPartY + settingInfo.mMarginY;
  settingPartX = toDecimal(settingPartX, 2);
  settingPartY = toDecimal(settingPartY, 2);
  // 材料に配置できる総数を確認する(X方向)
  let settingPartCntX = Math.floor(materialX / settingPartX);
  let clearSettingPartCntX = settingPartCntX;
  // 材料に配置できる総数を確認する(Y方向)
  let settingPartCntY = Math.floor(materialY / settingPartY);
  // settingPartCntY = settingPartCntY <= 0 ? 1 : settingPartCntY; // IQX_WEBEST-203の対応、クローズする
  let clearSettingPartCntY = settingPartCntY;

  // 材料に配置できる最大数を計算する（パターン１に配置する部品数）
  let settingMaxCnt = Math.floor(settingPartCntX * settingPartCntY);
  partCntInfo.maxNoOfPlace = settingMaxCnt; // 最大加工数

  // パターン校数
  let n = Math.floor(partInfo.totalProcessSuryou / settingMaxCnt);
  //材料校数が1より小さい場合、１校数で配置できるXY方向を再計算
  if (n < 1) {
    // Y方向
    if (!ySetting) {
      settingPartCntY = Math.floor(partInfo.totalProcessSuryou / settingPartCntX) + 1;
    } else {
      settingPartCntX = Math.floor(partInfo.totalProcessSuryou / settingPartCntY) + 1;
    }
    clearSettingPartCntY = settingPartCntY;
    settingMaxCnt = partInfo.totalProcessSuryou;
    // バグ：158の修正-START
    if (!ySetting) {
      if (settingMaxCnt < settingPartCntX) {
        settingPartCntX = settingMaxCnt;
      }
    } else {
      // Y方向
      if (settingMaxCnt < settingPartCntY) {
        settingPartCntY = settingMaxCnt;
      }
    }
    // バグ：158の修正-END
    n = 1;
  }
  partCntInfo.p1NoOfMaterial = n; // パターン1の材料枚数
  partCntInfo.p1NoOfPlace = settingMaxCnt; // パターン1の配置数
  partCntInfo.p1PartCntX = settingPartCntX; // パターン1のX配置数
  partCntInfo.p1PartCntY = settingPartCntY; // パターン1のY配置数

  // パターン２の場合、最大部品数 － パターン１に配置する部品数
  if (isPattern2) {
    // Totalcount ＞ Maxcount *nかつ n＝1の場合、パターン1の配置が1枚とパターン2の配置が必要となる
    if (partInfo.totalProcessSuryou > settingMaxCnt * n && n == 1) {
      settingMaxCnt = partInfo.totalProcessSuryou - settingMaxCnt;
      if (!ySetting) {
        // settingPartCntY = Math.round(settingMaxCnt / settingPartCntX);
        settingPartCntY = Math.floor(settingMaxCnt / settingPartCntX) + 1;
        if (settingMaxCnt < settingPartCntX) {
          settingPartCntX = settingMaxCnt;
        }
      } else {
        if (settingMaxCnt < settingPartCntY) {
          settingPartCntY = settingMaxCnt;
        }
      }
    } else if (partInfo.totalProcessSuryou > settingMaxCnt * n && n > 1) {
      // Totalcount ＞ Maxcount *nかつ n≠1の場合、パターン1の配置がｎ枚とパターン2の配置が必要となる
      settingMaxCnt = partInfo.totalProcessSuryou - settingMaxCnt * n;
      if (!ySetting) {
        // settingPartCntY = Math.round(settingMaxCnt / settingPartCntX);
        settingPartCntY = Math.floor(settingMaxCnt / settingPartCntX) + 1;
        if (settingMaxCnt < settingPartCntX) {
          settingPartCntX = settingMaxCnt;
        }
      } else {
        if (settingMaxCnt < settingPartCntY) {
          settingPartCntY = settingMaxCnt;
        }
      }
    } else if (partInfo.totalProcessSuryou == settingMaxCnt * n && partInfo.totalProcessSuryou == n) {
      settingPartCntY = 0;
    } else if (partInfo.totalProcessSuryou <= settingMaxCnt * n && n == 1) {
      settingPartCntY = 0;
    }
    clearSettingPartCntY = settingPartCntY;
  }

  if (settingPartCntX > 0 && settingPartCntY > 0) {
    // 部品合計数X
    partCntInfo.partCntX = settingPartCntX;
    partCntInfo.p2PartCntX = settingPartCntX; // パターン2のX配置数

    // パターン１又パターン２はleftToRight設定の場合
    if ((!ySetting && !isPattern2) || (!ySetting && isPattern2)) {
      // 部品矩形配置Y
      let fillCnt = settingPartCntX;
      for (let y = 0; y < settingPartCntY; y++) {
        if (y > 0) {
          if (fillCnt == settingMaxCnt) {
            settingPartCntX = settingPartCntX;
            fillCnt = fillCnt;
          } else if (fillCnt + settingPartCntX > settingMaxCnt) {
            settingPartCntX = settingMaxCnt - fillCnt;
            fillCnt = fillCnt + settingPartCntX;
          }
        }
        if (fillCnt != settingMaxCnt) {
          fillCnt = settingPartCntX * (y + 1);
        }
      }
    } else {
      // パターン１又パターン２はBottomToTop設定の場合
      let fillCnt = settingPartCntY;
      let p2settingPartCntY = settingPartCntY;
      for (let max = 0; fillCnt < settingMaxCnt || max == 0; max++) {
        // 部品矩形配置X
        if (max > 0) {
          if (fillCnt == settingMaxCnt) {
            settingPartCntY = settingPartCntY;
            fillCnt = fillCnt;
          } else if (fillCnt + settingPartCntY > settingMaxCnt) {
            settingPartCntY = settingMaxCnt - fillCnt;
            fillCnt = fillCnt + settingPartCntY;
          }
        }

        if (fillCnt != settingMaxCnt) {
          fillCnt = settingPartCntY * (max + 1);
        }
        settingPartCntX = max + 1;
        partCntInfo.partCntX = settingPartCntX;
        partCntInfo.p2PartCntX = settingPartCntX; // パターン2のX配置数
        clearSettingPartCntX = settingPartCntX;
      }
      settingPartCntY = p2settingPartCntY;
    }
    // 部品合計数X
    partCntInfo.partCntY = settingPartCntY;
    partCntInfo.p2PartCntY = settingPartCntY; // パターン2のY配置数
  } else {
    // 部品合計数X
    partCntInfo.partCntX = 0;
    partCntInfo.partCntY = 0;
  }
  partCntInfo.p2NoOfPlace = settingMaxCnt; // パターン2の配置数
  return partCntInfo;
};

// 材料原価合計、材料原価を更新する
const updateMaterialSizePrice = (sizeLists, selectedIq3EstimateData, orderQuantity) => {
  if (sizeLists === undefined) return selectedIq3EstimateData;
  let usedMaterialSizeInfo = sizeLists?.find((item) => item.isUsed); // 採用されるサイズの情報
  if (usedMaterialSizeInfo) {
    let materialTotalPrice = usedMaterialSizeInfo?.price; // 材料原価合計
    let materialUnitPrice = materialTotalPrice / (selectedIq3EstimateData?.partCount * orderQuantity); // 材料原価/員数＊数量
    selectedIq3EstimateData['materialCostUnitPrice'] =
      materialUnitPrice && !isInfinity(materialUnitPrice) ? materialUnitPrice : 0; // 材料原価
    selectedIq3EstimateData['materialCostTotalPrice'] =
      materialTotalPrice && !isInfinity(materialTotalPrice) ? materialTotalPrice : 0; // 材料原価合計
    return selectedIq3EstimateData;
  }
};
