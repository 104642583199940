/**
 * クラス名：システム全体の共通ファイル
 * 説明：システム全体に共通使っているファイルである。
 * 作成者：ナンス
 * 作成日：2024/11/25
 * バージョン：1.0
 */
import { toDecimal } from '../common/Common';
import {
  calDandoriAmt,
  calDandoriTime,
  getDiffCoef,
  getGasCoef,
  getHoseCoeff,
  getJointTm,
  getProductionQuantityCoeff,
  getSagyoSpeed,
  getSagyoSpeedByWeld,
  getSetupCoeff,
  getSizeCoeff,
  getSubWebTm,
  getTempAttachTime,
} from './IQ3KouteiCalculate_Common';
import { DataType, ServiceClass, WorkType, WorkTypeGroup } from '../common/Constant';

// 溶接工程の計算
export const calwelding = (
  curAddition,
  processInput,
  processSelect,
  curDeviceMst,
  calcParameters,
  curIQ3DataDetail,
  orderQuantity
) => {
  let weldAddition = curAddition;
  if (Object.keys(weldAddition).length > 0) {
    let editData = [];
    let totals = [];
    let toBEdit = [];
    let processMst = [];
    let deviceMst = [];

    let parameters = calcParameters;
    if (parameters?.process?.length > 0) {
      processMst = parameters?.process
        ? parameters?.process?.filter(
            (item) => item?.class === ServiceClass.SheetMetal && item.workType === WorkType.SmWelding
          )[0]
        : [];
    }

    let patternInfo = processSelect;
    if (patternInfo == undefined) return;
    if (patternInfo?.length > 0 && patternInfo[0]?.isUsedForCalc == false) return;

    deviceMst = curDeviceMst[0]; //テスト用ID;
    if (deviceMst == undefined) return;
    // 溶接工程入力情報取得
    let weldingProcessInput = processInput[0];
    let detailItems = [];
    let editDetailItems = [];
    //溶接情報設定
    for (let j = 0; j < weldingProcessInput?.details?.[0]?.weldingList?.length; j++) {
      let weldingList = weldingProcessInput?.details?.[0]?.weldingList[j];
      let processTypeNm = processMst?.details?.processDetailTypes?.filter(
        (item) => item.id == weldingList.yousetsuType
      )?.[0];
      detailItems.push({
        id: j + 1,
        processName: '溶接処理(No' + (j + 1) + ')',
        processTypeId: processTypeNm?.id,
        processType: processTypeNm?.name,
        dandoriAmt: 0,
        dandoriTime: 0,
        sagyoAmt: 0,
        sagyoTime: 0,
        senchou: Number(weldingList.senchou), //溶接加工長
        karitsukeCnt: Number(weldingList.karitsukeCnt), //仮付
        senchouKaisu: Number(weldingList.senchouKaisu), //溶接加工長回数
        karitsukeKaisu: Number(weldingList.karitsukeKaisu), //仮付回数
        daimeter: 0, // 径
        number: 0, // 加工数
        count: 0, // 回数
        weldType: 0, // 0:溶接, 1:サブ溶接
        formula: '',
      });
    }

    //サブ溶接情報設定
    for (let j = 0; j < weldingProcessInput?.details?.[0]?.subWeldingList?.length; j++) {
      let subWeldingList = weldingProcessInput?.details?.[0]?.subWeldingList[j];
      let processTypeNm = processMst?.details?.processDetailTypes?.filter(
        (item) => item.id == subWeldingList.subWeldingType
      )?.[0];
      let detailItemsId = detailItems?.length == 0 ? 0 : detailItems?.length;
      detailItems.push({
        id: detailItemsId + 1,
        processName: '溶接処理(No' + (j + 1) + ')',
        processTypeId: processTypeNm?.id,
        processType: processTypeNm?.name,
        dandoriAmt: 0,
        dandoriTime: 0,
        sagyoAmt: 0,
        sagyoTime: 0,
        senchou: 0, //溶接加工長
        karitsukeCnt: 0, //仮付
        senchouKaisu: 0, //溶接加工長回数
        karitsukeKaisu: 0, //仮付回数
        daimeter: subWeldingList.daimeter, // 径
        number: subWeldingList.number, // 加工数
        count: subWeldingList.count, // 回数
        weldType: 1, // 0:溶接, 1:サブ溶接
        formula: '',
      });
    }
    let weldingAdditions = weldAddition;
    editDetailItems = [];
    let weldingAddition = {
      no: processMst?.id,
      processId: processMst?.id,
      dataType: weldingAdditions?.dataType ? weldingAdditions?.dataType : DataType.Data,
      dataItems: detailItems,
      editItems: weldingAdditions?.editItems ? weldingAdditions?.editItems : editDetailItems,
      totalDandori: {
        dataItemsAmt: 0,
        dataItemsTime: 0,
        editItemsAmt: 0,
        editItemsTime: 0,
      },
      totalSagyo: {
        dataItemsAmt: 0,
        dataItemsTime: 0,
        editItemsAmt: 0,
        editItemsTime: 0,
      },
      totalDataPrice: 0,
      totalDataTime: 0,
      totalEditPrice: 0,
      totalEditTime: 0,
      editDataFlg: false,
    };
    weldingAdditions = weldingAddition;

    if (weldingAdditions?.dataItems) {
      let orgData = JSON.parse(JSON.stringify(weldingAdditions?.dataItems));
      if (deviceMst != undefined) {
        let totalDandori = 0;
        let totalSagyo = 0;
        let totalSagyoTm = 0;
        let totalDandoriTm = 0;
        let dataItemsByProcessInput = [];
        // 板厚
        let thickness = parameters?.materialIq3?.filter((item) => item.id == curIQ3DataDetail?.thickness)?.[0]?.info
          ?.thick;
        // 重量
        let weight = curIQ3DataDetail?.weight;
        // 面積
        let area = Number(curIQ3DataDetail?.totalSurfaceArea);

        let quantity = orderQuantity;

        let partCnt = curIQ3DataDetail?.partCount;
        // IQX_WEBEST-278 工程積算タブの表示に生産個数係数が含まれていなかったので、含むようにしてほしい
        let productionQuantityCoeff = getProductionQuantityCoeff(deviceMst, quantity, partCnt);
        //材質
        let materialTypeIq3Id = curIQ3DataDetail?.materialTypeIq3Id;

        //材質区分
        let materialTypeId = parameters?.materialTypeIq3?.filter((item) => item.id == materialTypeIq3Id)?.[0]
          ?.materialTypeId;

        // 選択された部品の板厚により難易度係数を取得
        let diffCoef = getDiffCoef(deviceMst, thickness, materialTypeId);
        // サイズ係数
        let sizeCoef = getSizeCoeff(deviceMst, weight, area);

        //溶接情報設定
        let mainWeldAdditions = orgData?.filter((item) => item.weldType == 0);
        for (let i = 0; i < weldingProcessInput?.details?.[0]?.weldingList?.length; i++) {
          //工程入力情報
          let weldingProcessInputInfo = weldingProcessInput?.details?.[0]?.weldingList[i];
          let dataItemByProcessInput = mainWeldAdditions[i];

          //重量と面積が０の場合、計算しない。
          if (weight === 0 && area === 0) {
            dataItemsByProcessInput.push(dataItemByProcessInput);
            continue;
          }

          // 加工長
          let sagyoLen = Number(weldingProcessInputInfo?.senchou);
          let karitsukeCnt = Number(weldingProcessInputInfo?.karitsukeCnt);

          //加工長が０の場合、計算しない。
          if (sagyoLen === 0 && karitsukeCnt === 0) {
            dataItemsByProcessInput.push(dataItemByProcessInput);
            continue;
          }

          /** 段取金額/時間 */
          // 基本段取時間＊サイズ係数
          let dandoriTimeSec = calDandoriTime(
            WorkType.SmWelding,
            deviceMst,
            0,
            weight,
            area,
            weldingProcessInputInfo?.yousetsuType
          );
          let dandoriTime = dandoriTimeSec;
          dataItemByProcessInput.dandoriTime = dandoriTime ? dandoriTime : 0;
          // 段取時間の合計

          // 段取金額設定
          //段階時間
          let mSetTimeMin = dandoriTimeSec / 60;
          //装置の段階時間チャージ
          let deviceCharges = Object.entries(deviceMst.details?.charges)?.filter(
            ([key, data]) => parseInt(key) === weldingProcessInputInfo?.yousetsuType
          )?.[0];
          let mSetHRate = 0;
          deviceCharges?.map((item, index) => {
            if (index > 0) {
              mSetHRate = item?.prep;
            }
          });
          let dandoriAmt = (mSetTimeMin * mSetHRate) / 60.0; //段階時間＊時間チャージ
          dataItemByProcessInput.dandoriAmt = dandoriAmt ? dandoriAmt : 0;

          // 作業時間（溶接時間＋仮付時間）
          // 溶接線長により該当種類の加工速度を取得
          let sagyoSpeed = getSagyoSpeedByWeld(deviceMst, sagyoLen, weldingProcessInputInfo?.yousetsuType);

          // 溶接時間（加工長*回数/加工速度＊難易度係数＊サイズ係数）
          let totalSagyoLen = sagyoLen * Number(weldingProcessInputInfo?.senchouKaisu);
          let sagyoTimeSec = Number.isFinite(totalSagyoLen / sagyoSpeed)
            ? (totalSagyoLen / sagyoSpeed) * diffCoef * sizeCoef * 60
            : 0;
          // 仮付時間
          let tempAttachTimeSecParm = getTempAttachTime(deviceMst, thickness, weldingProcessInputInfo?.yousetsuType);
          // 仮付時間   仮付時間＊仮付箇所＊仮付回数＊難易度係数＊サイズ係数
          let tempAttachTimeSec =
            tempAttachTimeSecParm * diffCoef * sizeCoef * weldingProcessInputInfo?.karitsukeKaisu * karitsukeCnt;
          // 作業時間（溶接時間＋仮付時間）
          let sagyoTime =
            sagyoTimeSec !== undefined && tempAttachTimeSec !== undefined ? sagyoTimeSec + tempAttachTimeSec : 0;
          sagyoTime = sagyoTime * productionQuantityCoeff; // IQX_WEBEST-278 加工時間＊生産個数係数
          dataItemByProcessInput.sagyoTime = sagyoTime;
          totalSagyoTm += dataItemByProcessInput.sagyoTime;

          // 作業金額
          let mSagyoTimeMin = sagyoTime / 60;
          //装置の作業時間チャージ
          let mSagyoHRate = 0;
          deviceCharges?.map((item, index) => {
            if (index > 0) {
              mSagyoHRate = item?.work;
            }
          });
          let sagyoAmt = (mSagyoTimeMin * mSagyoHRate) / 60.0; //作業時間＊時間チャージ
          dataItemByProcessInput.sagyoAmt = sagyoAmt ? sagyoAmt : 0;
          // 作業金額の合計
          totalSagyo += sagyoAmt ? Math.round(sagyoAmt) : 0;
          dataItemsByProcessInput.push(dataItemByProcessInput);
        }

        //サブ溶接情報設定
        let subWeldAdditions = orgData?.filter((item) => item.weldType == 1);

        for (let i = 0; i < weldingProcessInput?.details?.[0]?.subWeldingList?.length; i++) {
          //工程入力情報
          let weldingProcessInputInfo = weldingProcessInput?.details?.[0]?.subWeldingList[i];
          let dataItemByProcessInput = subWeldAdditions[i];

          // 加工長
          let subSagyoNo = Number(dataItemByProcessInput?.number);
          let subSagyoCnt = Number(dataItemByProcessInput?.count);

          //重量と面積が０の場合、計算しない。
          if (weight === 0 && area === 0) {
            dataItemsByProcessInput.push(dataItemByProcessInput);
            continue;
          }

          // 径が０の場合、計算しない。
          // IQX_WEBEST-330 工程入力で溶接の入力を行っていない状態で、溶接仕上げの加工時間が計上される。
          let total = Number(dataItemByProcessInput?.count) * Number(dataItemByProcessInput?.number);
          if (total === 0) {
            dataItemsByProcessInput.push(dataItemByProcessInput);
            continue;
          }

          /** 段取金額/時間 */
          // 基本段取時間＊サイズ係数
          let dandoriTimeSec = calDandoriTime(
            WorkType.SmWelding,
            deviceMst,
            0,
            weight,
            area,
            weldingProcessInputInfo?.subWeldingType
          );
          let dandoriTime = dandoriTimeSec;
          dataItemByProcessInput.dandoriTime = dandoriTime ? dandoriTimeSec : 0;

          // 段取金額設定
          //段階時間
          let mSetTimeMin = Number.isFinite(dandoriTimeSec / 60) ? dandoriTimeSec / 60 : 0;
          //装置の段階時間チャージ
          let deviceCharges = Object.entries(deviceMst.details?.charges)?.filter(
            ([key, data]) => parseInt(key) === weldingProcessInputInfo?.subWeldingType
          )?.[0];
          let mSetHRate = 0;
          deviceCharges?.map((item, index) => {
            if (index > 0) {
              mSetHRate = item?.prep;
            }
          });
          let dandoriAmt = Number.isFinite((mSetTimeMin * mSetHRate) / 60.0) ? (mSetTimeMin * mSetHRate) / 60.0 : 0; //段階時間＊時間チャージ
          dataItemByProcessInput.dandoriAmt = dandoriAmt ? dandoriAmt : 0;

          // 作業時間（サブ溶接時間＊数量＊難易度係数＊サイズ係数）
          // 作業時間（サブ溶接時間）
          let subWeldTm = getSubWebTm(
            deviceMst,
            thickness,
            weldingProcessInputInfo?.subWeldingType,
            weldingProcessInputInfo?.subWeldingGroupType,
            sizeCoef
          );
          // 作業時間（サブ溶接時間＊数量＊難易度係数＊サイズ係数）
          let subWebSagyoTime = 0;
          if (subWeldTm * subSagyoNo * subSagyoCnt * diffCoef * sizeCoef !== undefined) {
            subWebSagyoTime = subWeldTm * subSagyoNo * subSagyoCnt * diffCoef * sizeCoef;
            subWebSagyoTime = subWebSagyoTime * productionQuantityCoeff; // IQX_WEBEST-278 加工時間＊生産個数係数
            dataItemByProcessInput.sagyoTime = subWebSagyoTime;
          } else {
            subWebSagyoTime = 0;
            dataItemByProcessInput.sagyoTime = 0;
          }
          totalSagyoTm += dataItemByProcessInput.sagyoTime ? dataItemByProcessInput.sagyoTime : 0;

          // 作業金額
          let mSagyoTimeMin = Number.isFinite(subWebSagyoTime / 60) ? subWebSagyoTime / 60 : 0;
          //装置の作業時間チャージ
          let mSagyoHRate = 0;
          deviceCharges?.map((item, index) => {
            if (index > 0) {
              mSagyoHRate = item?.work;
            }
          });
          let sagyoAmt = Number.isFinite((mSagyoTimeMin * mSagyoHRate) / 60.0)
            ? (mSagyoTimeMin * mSagyoHRate) / 60.0
            : 0; //作業時間＊時間チャージ
          dataItemByProcessInput.sagyoAmt = sagyoAmt ? sagyoAmt : 0;
          // 作業金額の合計
          totalSagyo += sagyoAmt ? Math.round(sagyoAmt) : 0;
          dataItemsByProcessInput.push(dataItemByProcessInput);
        }

        // 段取合計
        let weldIdUnq = [];
        let weldIds = dataItemsByProcessInput?.map((item) => item.processTypeId);
        dataItemsByProcessInput?.forEach((e) => {
          if (!weldIdUnq.includes(e.processTypeId) && e.dandoriAmt > 0 && e.dandoriTime > 0) {
            weldIdUnq.push(e.processTypeId);
            totalDandoriTm += e.dandoriTime ? e.dandoriTime : 0;
            totalDandori += e.dandoriAmt ? Math.round(e.dandoriAmt) : 0;
            let index = weldIds.indexOf(e.processTypeId);
            if (dataItemsByProcessInput[index]) {
              dataItemsByProcessInput[index]['dandoriTime'] = e.dandoriTime;
              dataItemsByProcessInput[index]['dandoriAmt'] = e.dandoriAmt;
            }
          }
        });

        // 段取金額の合計
        weldingAdditions.totalDandori.dataItemsAmt = totalDandori;
        // 段取時間の合計
        weldingAdditions.totalDandori.dataItemsTime = totalDandoriTm;
        // 作業金額の合計
        weldingAdditions.totalSagyo.dataItemsAmt = totalSagyo;
        weldingAdditions.totalSagyo.dataItemsTime = totalSagyoTm;
        /** 　合計時間　*/
        let totalTimeSum = totalDandoriTm + totalSagyoTm;
        weldingAdditions.totalDataTime = totalTimeSum;

        /** 　合計金額　*/
        let totalSum = (totalDandori ? Math.round(totalDandori) : 0) + (totalSagyo ? Math.round(totalSagyo) : 0); // 画面表示用（四捨五入した金額を足す）
        weldingAdditions.totalDataPrice = totalSum;

        orgData = dataItemsByProcessInput;
        weldingAdditions.dataItems = orgData;
        weldAddition = weldingAdditions;
      }
    }
  }
  return weldAddition;
};
