/**
 * クラス名：システム全体の共通ファイル
 * 説明：システム全体に共通使っているファイルである。
 * 作成者：ナンス
 * 作成日：2024/11/25
 * バージョン：1.0
 */
import dayjs from 'dayjs';
import {
  DataType,
  estimateInitInfo,
  iq3EstimateInitInfo,
  IQxRealGoku_AccessToken,
  WorkType,
  WorkTypeGroup,
} from '../common/Constant';
import { toDecimal } from '../common/Common';

// 段取時間
export const calDandoriTime = (
  workType,
  deviceMst,
  elementCnt = 0,
  weight = 0,
  area = 0,
  typeId = 0,
  thickness = 0,
  sizeCoeff = 0
) => {
  if (deviceMst == undefined || deviceMst?.length == 0) return 0;
  let dandoriTimeSec = 0;

  // プログラム工程の場合
  if (elementCnt > 0 && workType === WorkType.SmProgram) {
    // 段取時間設定
    let prepElementThMaster = deviceMst?.details?.prepElementThMaster?.filter(
      (item) => (item.value == elementCnt || item.value > elementCnt) && !item.isOutOfRange
    );
    let prepElementThMasterCnt =
      prepElementThMaster?.length > 0 ? prepElementThMaster[0]?.no : deviceMst?.details?.prepElementThMaster[0]?.no;
    //　基本段取時間
    dandoriTimeSec =
      prepElementThMasterCnt == 0
        ? 0
        : deviceMst?.details?.prepElementThItems?.filter(
            (item) => item.no == prepElementThMasterCnt && !item.isOutOfRange
          )?.[0]?.value;
    dandoriTimeSec = dandoriTimeSec ? dandoriTimeSec : 0;
  } else if (
    weight > 0 &&
    area > 0 &&
    (workType === WorkType.SmSecondaryWork || workType === WorkType.SmWelding || workType === WorkType.SmSurface)
  ) {
    // 溶接又は2次加工工程の場合
    // 重量
    let childPartsCoeffWeightThMaster = deviceMst?.details?.childPartsCoeffWeightThMaster?.filter(
      (item) => (item.value == weight || item.value > weight) && !item.isOutOfRange
    );
    // 面積
    let childPartsCoeffAreaThMaster = deviceMst?.details?.childPartsCoeffAreaThMaster?.filter(
      (item) => (item.value == area || item.value > area) && !item.isOutOfRange
    );
    let childPartsCoeffs =
      childPartsCoeffWeightThMaster?.length > 0 && childPartsCoeffAreaThMaster?.length > 0
        ? deviceMst?.details?.childPartsCoeff?.filter((item) => item.weightId == childPartsCoeffWeightThMaster[0]?.no)
        : deviceMst?.details?.childPartsCoeff[0];

    // サイズ係数
    let partsCoeff =
      childPartsCoeffs?.length > 0
        ? childPartsCoeffs?.[0]?.areaCoeffList?.filter(
            (item) => item.no == childPartsCoeffAreaThMaster?.[0]?.no && !item.isOutOfRange
          )?.[0]?.value
        : childPartsCoeffs?.[0]?.areaCoeffList?.[0]?.value;

    //　基本段取時間＊サイズ係数
    let deviceDandori = Object.entries(deviceMst?.details?.prepSecs)?.filter(
      ([key, data]) => parseInt(key) === typeId
    )?.[0];
    let deviceDandoriTm = 0;
    deviceDandori?.map((item, index) => {
      if (index > 0) {
        deviceDandoriTm = item;
      }
    });

    dandoriTimeSec = deviceDandoriTm * partsCoeff;
    dandoriTimeSec = dandoriTimeSec ? dandoriTimeSec : 0;
  } else if (
    weight > 0 &&
    area > 0 &&
    (workType === WorkType.SmDeburringAuto ||
      workType === WorkType.SmCustom ||
      workType === WorkType.SmInspection ||
      workType === WorkType.SmShearing)
  ) {
    // 自動バリの場合
    // 重量
    let childPartsCoeffWeightThMaster = deviceMst?.details?.childPartsCoeffWeightThMaster?.filter(
      (item) => (item.value == weight || item.value > weight) && !item.isOutOfRange
    );
    // 面積
    let childPartsCoeffAreaThMaster = deviceMst?.details?.childPartsCoeffAreaThMaster?.filter(
      (item) => (item.value == area || item.value > area) && !item.isOutOfRange
    );
    let childPartsCoeffs =
      childPartsCoeffWeightThMaster?.length > 0 && childPartsCoeffAreaThMaster?.length > 0
        ? deviceMst?.details?.childPartsCoeff?.filter((item) => item.weightId == childPartsCoeffWeightThMaster[0]?.no)
        : deviceMst?.details?.childPartsCoeff[0];

    // サイズ係数
    let partsCoeff =
      childPartsCoeffs?.length > 0
        ? childPartsCoeffs?.[0]?.areaCoeffList?.filter(
            (item) => item.no == childPartsCoeffAreaThMaster?.[0]?.no && !item.isOutOfRange
          )?.[0]?.value
        : childPartsCoeffs?.[0]?.areaCoeffList?.[0]?.value;

    //　基本段取時間＊サイズ係数
    dandoriTimeSec = deviceMst?.details?.prepBasicTime * partsCoeff;
    dandoriTimeSec = dandoriTimeSec !== undefined ? dandoriTimeSec : 0;
  } else if (thickness > 0 && sizeCoeff > 0 && workType === WorkType.SmBending) {
    // 段取時間設定
    let basicTimeThickThMaster = deviceMst?.details?.basicTimeThickThMaster?.filter(
      (item) => (item.value == thickness || item.value > thickness) && !item.isOutOfRange
    );

    let basicTimeThickItems = Object.entries(deviceMst?.details?.basicTimeThickItems)?.filter(
      ([key, data]) => parseInt(key) === typeId && !data.isOutOfRange
    )?.[0];
    let deviceDandori = [];
    basicTimeThickItems?.map((item, index) => {
      if (index > 0) {
        deviceDandori = item;
      }
    });
    //　基本段取時間
    dandoriTimeSec =
      deviceDandori?.filter((item) => item.no == basicTimeThickThMaster?.[0]?.no && !item.isOutOfRange)?.[0]?.value *
      sizeCoeff;
    dandoriTimeSec = dandoriTimeSec ? dandoriTimeSec : 0;
  }
  return dandoriTimeSec;
};

// 段取金額
export const calDandoriAmt = (dandoriTimeSec, deviceMst) => {
  //段階時間
  let mSetTimeMin = dandoriTimeSec / 60;
  //装置の段階時間チャージ
  let mSetHRate = deviceMst.details?.charges.prep;
  //段階時間＊時間チャージ
  let dandoriAmt = Math.round((mSetTimeMin * mSetHRate) / 60.0);
  dandoriAmt = dandoriAmt ? dandoriAmt : 0;
  return dandoriAmt;
};

// 作業時間
export const calSagyoTime = (
  workType,
  deviceMst,
  elementCnt = 0,
  sizeMm = 0,
  typeId = 0,
  groupTypeId = 0,
  weight = 0,
  area = 0,
  kasho = 0,
  thickness = 0
) => {
  let sagyoTimeSec = 0;
  if (elementCnt > 0 && workType === WorkType.SmProgram) {
    // 作業時間
    //1要素当たりの作業時間
    let workBasicTime = deviceMst?.details?.workBasicTime;
    // 係数
    let otherElementCoeffThMaster = deviceMst?.details?.otherElementCoeffThMaster?.filter(
      (item) => (item.value == elementCnt || item.value > elementCnt) && !item?.isOutOfRange
    );
    let otherElementCoeffThMasterCnt =
      otherElementCoeffThMaster?.length > 0
        ? otherElementCoeffThMaster[0]?.no
        : deviceMst?.details?.otherElementCoeffThMaster[0]?.no;
    let workTimeElementCoeff =
      otherElementCoeffThMasterCnt == 0
        ? 0
        : deviceMst?.details?.workTimeElementCoeffThItems?.filter(
            (item) => item.no == otherElementCoeffThMasterCnt && !item.isOutOfRange
          )[0]?.value;
    //1要素当たりの作業時間＊要素数＊作業時間係数
    sagyoTimeSec = workBasicTime * elementCnt * workTimeElementCoeff;
    sagyoTimeSec = sagyoTimeSec ? sagyoTimeSec : 0;
  } else if (area > 0 && workType === WorkType.SmDeburringAuto) {
    // 作業時間
    // 面積
    let areaThMaster = deviceMst?.details?.areaThMaster?.filter(
      (item) => (item.value == area || item.value > area) && !item.isOutOfRange
    );
    let workTime =
      areaThMaster?.length > 0
        ? deviceMst?.details?.workTimeThItems?.filter(
            (item) => item.no == areaThMaster[0]?.no && !item.isOutOfRange
          )?.[0]?.value
        : deviceMst?.details?.workTimeThItems[0]?.value;
    workTime = workTime ? workTime : 0;
    sagyoTimeSec = workTime;
  } else if (thickness > 0 && workType === WorkType.SmBending) {
    let workTimeThickThMaster = deviceMst?.details?.workTimeThickThMaster?.filter(
      (item) => item.value == thickness || item.value > thickness
    );

    let workTimeThickItems = Object.entries(deviceMst?.details?.workTimeThickItems)?.filter(
      ([key, data]) => parseInt(key) === typeId
    )?.[0];
    let deviceWorkTime = [];
    workTimeThickItems?.map((item, index) => {
      if (index > 0) {
        deviceWorkTime = item;
      }
    });
    //　基本段取時間
    let deviceWorkTimeSec = deviceWorkTime?.filter((item) => item.no == workTimeThickThMaster?.[0]?.no)?.[0]?.value;
    sagyoTimeSec = deviceWorkTimeSec ? deviceWorkTimeSec : 0;
  } else if (
    weight > 0 &&
    area > 0 &&
    kasho > 0 &&
    (workType === WorkType.SmCustom || workType === WorkType.SmInspection || workType === WorkType.SmShearing)
  ) {
    // 作業時間
    // 重量
    let prodSizeWeightThMaster = deviceMst?.details?.prodSizeWeightThMaster?.filter(
      (item) => (item.value == weight || item.value > weight) && !item.isOutOfRange
    );
    // 面積
    let prodSizeAreaThMaster = deviceMst?.details?.prodSizeAreaThMaster?.filter(
      (item) => (item.value == area || item.value > area) && !item.isOutOfRange
    );
    let prodSizeItems =
      prodSizeWeightThMaster?.length > 0 && prodSizeAreaThMaster?.length > 0
        ? deviceMst?.details?.prodSizeItems?.filter((item) => item.weightId == prodSizeWeightThMaster[0]?.no)
        : deviceMst?.details?.prodSizeItems[0];

    // サイズ係数
    let partsCoeff =
      prodSizeItems?.length > 0
        ? prodSizeItems?.[0]?.areaCoeffList?.filter(
            (item) => item.no == prodSizeAreaThMaster?.[0]?.no && !item.isOutOfRange
          )?.[0]?.value
        : prodSizeItems?.[0]?.areaCoeffList?.[0]?.value;

    //　基本段取時間＊箇所＊サイズ係数
    let basicWorkTime = 0;
    if (workType === WorkType.SmInspection) {
      basicWorkTime = deviceMst?.details?.inspectionWorkTime;
    } else if (workType === WorkType.SmCustom || workType === WorkType.SmShearing) {
      basicWorkTime = deviceMst?.details?.charges.work;
    }
    let workTimeSec = basicWorkTime * kasho * partsCoeff;
    sagyoTimeSec = workTimeSec ? workTimeSec : 0;
  }
  return sagyoTimeSec;
};

// 作業金額
export const calSagyoAmt = (cSagyoTimeSec, deviceMst) => {
  // 作業金額
  // 1部品当たりの作業時間
  let mWorkHTime = cSagyoTimeSec;
  let mWorkHTimeMin = mWorkHTime / 60;
  //装置の作業時間チャージ
  let mWorkHRate = deviceMst.details?.charges.work;
  //　加工時間＊時間チャージ
  let cSagyoAmtKensa = Math.round((mWorkHTimeMin * mWorkHRate) / 60.0);
  cSagyoAmtKensa = cSagyoAmtKensa ? cSagyoAmtKensa : 0;
  return cSagyoAmtKensa;
};

// 生産個数係数取得
export const getProductionQuantityCoeff = (deviceMst, qty, partCount) => {
  let productionQuantityCoeff = 1;
  let quantity = Number(qty);
  let partCnt = Number(partCount); // 員数
  let manufacturingCnt = quantity * partCnt; // 製造数 * 員数
  // 生産個数係数
  let productionCoeffTh = deviceMst?.details?.productionQtyCoeffThMaster?.filter(
    (item) => (item.value == manufacturingCnt || item.value > manufacturingCnt) && !item.isOutOfRange
  );
  if (productionCoeffTh) {
    let productionCoeffThNo =
      productionCoeffTh?.length > 0
        ? productionCoeffTh[0]?.no
        : deviceMst?.details?.productionQtyCoeffThMaster?.[0]?.no;
    let productionCoeff =
      productionCoeffThNo == 0
        ? 0
        : deviceMst?.details?.productionQtyCoeffThItems?.filter(
            (item) => item.no == productionCoeffThNo && !item.isOutOfRange
          )[0]?.value;

    productionQuantityCoeff = productionCoeff ? productionCoeff : 1;
  }
  return productionQuantityCoeff;
};

// パンチ数取得
export const calPunchCntByCombi = (inputArr, deviceMst, calcParameters, curIQ3DataDetail) => {
  let quantityList = inputArr.quantityList;
  let punchCnt = 0;
  let onePunchArr = [];
  let parameters = calcParameters;
  // 板厚
  let thickness = parameters?.materialIq3?.filter((item) => item.id == curIQ3DataDetail?.thickness)?.[0]?.info?.thick;
  //材質
  let materialTypeIq3Id = curIQ3DataDetail?.materialTypeIq3Id;

  //材質区分
  let materialTypeId = parameters?.materialTypeIq3?.filter((item) => item.id == materialTypeIq3Id)?.[0]?.materialTypeId;
  let punchesArr = getSagyoPunches(deviceMst, thickness, materialTypeId); // 複合機のパンチ条件を材質、板厚より取得
  quantityList?.forEach((e, index) => {
    let perimeter = Number(e.perimeter);
    let diameter = Number(e.diameter);
    let inputCnt = Number(e.count);
    // let punchesArr = deviceMst?.details?.punches;
    if (e.types === 1) {
      if (
        perimeter !== undefined &&
        perimeter !== null &&
        diameter !== undefined &&
        diameter !== null &&
        diameter <= punchesArr // 複合機工程、1パンチ加工による穴の作業時間がレーザーに計上される。
      ) {
        let count = 0;
        if (diameter !== 0) {
          count = 1;
        }
        onePunchArr.push(index);
        punchCnt += count * inputCnt;
      }
    }
  });
  return { punchCnt, onePunchArr };
};

export const calPunchCntByPunch = (inputArr, deviceMst, curIQ3DataDetail) => {
  let quantityList = inputArr.quantityList;
  let outerDiaX = Number(inputArr.dimensionData.outerDia) / 4;
  let punchCnt = 0;
  // IQX_WEBEST-332 パンチ工程、外径のパンチ数算出方法の変更
  let xSize = curIQ3DataDetail?.partSizeX ? Number(curIQ3DataDetail?.partSizeX) : 0;
  let ySize = curIQ3DataDetail?.partSizeY ? Number(curIQ3DataDetail?.partSizeY) : 0;
  if ((xSize + ySize) * 2 === Number(inputArr.dimensionData.outerDia)) {
    //『（矩形Xサイズ +矩形Yサイズ) * 2= 外形周長』の場合
    let xPunchCnt = 0;
    let yPunchCnt = 0;
    // Xサイズのパンチ数取得
    let overtakeItemsOuter = deviceMst?.details?.odOvertakeItems?.filter(
      (item) => (item.value == xSize || item.value > xSize) && !item?.isOutOfRange
    );
    let overtakeMoldOuter =
      overtakeItemsOuter?.length > 0
        ? deviceMst?.details?.odOvertakeMoldMaster?.filter((item) => item.no == overtakeItemsOuter[0]?.no)
        : [deviceMst?.details?.odOvertakeMoldMaster[0]];
    let xOuter = Number(overtakeMoldOuter[0].name.split('x')[0].trim());
    xPunchCnt = Number.isFinite(xSize / (xOuter * 0.95)) ? Math.ceil(xSize / (xOuter * 0.95)) : 0;
    // Yサイズのパンチ数取得
    overtakeItemsOuter = deviceMst?.details?.odOvertakeItems?.filter(
      (item) => (item.value == ySize || item.value > ySize) && !item?.isOutOfRange
    );
    overtakeMoldOuter =
      overtakeItemsOuter?.length > 0
        ? deviceMst?.details?.odOvertakeMoldMaster?.filter((item) => item.no == overtakeItemsOuter[0]?.no)
        : [deviceMst?.details?.odOvertakeMoldMaster[0]];
    let yOuter = Number(overtakeMoldOuter[0].name.split('x')[0].trim());
    yPunchCnt = Number.isFinite(ySize / (yOuter * 0.95)) ? Math.ceil(ySize / (yOuter * 0.95)) : 0;
    // 外径パンチ数＝（Xサイズのパンチ数＋Yサイズのパンチ数）＊２
    punchCnt = (xPunchCnt + yPunchCnt) * 2;
  } else {
    // 『（矩形Xサイズ +矩形Yサイズ) * 2 != 外形周長』の場合
    let overtakeItemsOuter = deviceMst?.details?.odOvertakeItems?.filter(
      (item) => (item.value == outerDiaX || item.value > outerDiaX) && !item?.isOutOfRange
    );
    let overtakeMoldOuter =
      overtakeItemsOuter?.length > 0
        ? deviceMst?.details?.odOvertakeMoldMaster?.filter((item) => item.no == overtakeItemsOuter[0]?.no)
        : [deviceMst?.details?.odOvertakeMoldMaster[0]];
    let xOuter = Number(overtakeMoldOuter[0].name.split('x')[0].trim());
    // 外径パンチ数＝（外径周長/４）のパンチ数＊４
    punchCnt = Number.isFinite(outerDiaX / (xOuter * 0.95)) ? Math.ceil(outerDiaX / (xOuter * 0.95)) * 4 : 0;
  }
  quantityList?.forEach((e) => {
    let perimeter = Number(e.perimeter);
    let xSize = Number(e.xsize);
    let ySize = Number(e.ysize);
    let diameter = Number(e.diameter);
    let inputCnt = Number(e.count);
    let punchesArr = deviceMst?.details?.punches;

    switch (e.types) {
      case 1:
        if (
          diameter !== undefined &&
          diameter !== null &&
          perimeter !== undefined &&
          perimeter !== null &&
          perimeter !== 0
        ) {
          if (diameter > punchesArr.punchHoleDia) {
            let count = Number.isFinite(perimeter / punchesArr.twoPitch)
              ? Math.ceil(perimeter / punchesArr.twoPitch) + 1
              : 0;
            punchCnt += count * inputCnt;
          } else {
            punchCnt += inputCnt;
          }
        }
        break;
      case 2:
        if (
          diameter !== undefined &&
          diameter !== null &&
          perimeter !== undefined &&
          perimeter !== null &&
          perimeter !== 0
        ) {
          if (perimeter > punchesArr.punchRectanglePer) {
            let overtakeItems = deviceMst?.details?.odOvertakeItems?.filter(
              (item) => (item.value == diameter || item.value > diameter) && !item?.isOutOfRange
            );

            let overtakeMold =
              overtakeItems?.length > 0
                ? deviceMst?.details?.odOvertakeMoldMaster?.filter((item) => item.no == overtakeItems[0]?.no)
                : [deviceMst?.details?.odOvertakeMoldMaster[0]];

            let x = Number(overtakeMold[0].name.split('x')[0]);
            let count = Number.isFinite(diameter / (x * 0.95)) ? Math.ceil(diameter / (x * 0.95)) * 4 : 0;
            punchCnt += count * inputCnt;
          } else {
            punchCnt += inputCnt;
          }
        }
        break;
      case 3:
        if (
          xSize !== undefined &&
          xSize !== null &&
          ySize !== undefined &&
          ySize !== null &&
          perimeter !== undefined &&
          perimeter !== null &&
          perimeter !== 0
        ) {
          if (perimeter > punchesArr.punchRectanglePer) {
            let overtakeItemsX = deviceMst?.details?.odOvertakeItems?.filter(
              (item) => (item.value == xSize || item.value > xSize) && !item?.isOutOfRange
            );

            let overtakeMoldX =
              overtakeItemsX?.length > 0
                ? deviceMst?.details?.odOvertakeMoldMaster?.filter((item) => item.no == overtakeItemsX[0]?.no)
                : [deviceMst?.details?.odOvertakeMoldMaster[0]];

            let x = Number(overtakeMoldX[0].name.split('x')[0].trim());

            let resultX = Number.isFinite(xSize / (x * 0.95)) ? Math.ceil(xSize / (x * 0.95)) : 0;

            let overtakeItemsY = deviceMst?.details?.odOvertakeItems?.filter(
              (item) => (item.value == ySize || item.value > ySize) && !item?.isOutOfRange
            );

            let overtakeMoldY =
              overtakeItemsY?.length > 0
                ? deviceMst?.details?.odOvertakeMoldMaster?.filter((item) => item.no == overtakeItemsY[0]?.no)
                : [deviceMst?.details?.odOvertakeMoldMaster[0]];

            let y = Number(overtakeMoldY[0].name.split('x')[0].trim());
            let resultY = Number.isFinite(ySize / (y * 0.95)) ? Math.ceil(ySize / (y * 0.95)) : 0;
            let count = (resultX + resultY) * 2;
            punchCnt += count * inputCnt;
          } else {
            punchCnt += inputCnt;
          }
        }
        break;
      case 4:
        if (ySize !== undefined && ySize !== null && xSize !== undefined && xSize !== null && perimeter !== 0) {
          let squareSize = xSize - ySize;
          let overtakeItemsSq = deviceMst?.details?.odOvertakeItems?.filter(
            (item) => (item.value == squareSize || item.value > squareSize) && !item?.isOutOfRange
          );

          let overtakeMoldSq =
            overtakeItemsSq?.length > 0
              ? deviceMst?.details?.odOvertakeMoldMaster?.filter((item) => item.no == overtakeItemsSq[0]?.no)
              : [deviceMst?.details?.odOvertakeMoldMaster[0]];

          let xSq = Number(overtakeMoldSq[0].name.split('x')[0].trim());
          let count1 = Number.isFinite(squareSize / (xSq * 0.95)) ? Math.ceil((squareSize / (xSq * 0.95)) * 2) : 0;

          let count2 = Number.isFinite((ySize * Math.PI) / punchesArr.twoPitch)
            ? Math.ceil((ySize * Math.PI) / punchesArr.twoPitch) + 1
            : 0;
          let count = count1 + count2;
          punchCnt += count * inputCnt;
        }
        break;
      case 5:
        if (perimeter !== undefined && perimeter !== null && perimeter !== 0) {
          if (perimeter > punchesArr.punchRectanglePer) {
            let overtakeItems = deviceMst?.details?.odOvertakeItems?.filter(
              (item) => (item.value == perimeter || item.value > perimeter) && !item?.isOutOfRange
            );

            let overtakeMold =
              overtakeItems?.length > 0
                ? deviceMst?.details?.odOvertakeMoldMaster?.filter((item) => item.no == overtakeItems[0]?.no)
                : [deviceMst?.details?.odOvertakeMoldMaster[0]];

            let x = Number(overtakeMold[0].name.split('x')[0]);
            let count = Number.isFinite(perimeter / (x * 0.95)) ? Math.ceil(perimeter / (x * 0.95)) : 0;
            punchCnt += count * inputCnt;
          } else {
            punchCnt += inputCnt;
          }
        }
        break;
    }
  });
  punchCnt = punchCnt ? punchCnt : 0;
  return punchCnt;
};

export const getSagyoPunches = (deviceMst, thickness, materialTypeIq3Id) => {
  if (deviceMst == undefined || deviceMst?.length == 0) return 0;
  // 加工速度
  let punchWorkThickThMaster = deviceMst?.details?.punchConditionThickThMaster?.filter(
    (item) => (item.value == thickness || item.value > thickness) && !item.isOutOfRange
  )?.[0];

  let punchWorkItems = [];
  if (deviceMst?.details?.punchConditionItems) {
    punchWorkItems = Object.entries(deviceMst.details?.punchConditionItems)?.filter(
      ([key, data]) => parseInt(key) === materialTypeIq3Id
    )?.[0];
  }

  // 加工速度
  let childPartsCoeff = 0;
  punchWorkItems?.map((item, index) => {
    if (index > 0) {
      childPartsCoeff = item?.filter(
        (item) => parseInt(item.no) == punchWorkThickThMaster?.no && !item.isOutOfRange
      )?.[0]?.value;
    }
  });
  childPartsCoeff = childPartsCoeff ? childPartsCoeff : 0;
  return childPartsCoeff;
};

export const getSizeCoeff = (deviceMst, weight, area) => {
  if (deviceMst == undefined || deviceMst?.length == 0) return 0;
  // 段取時間設定

  // 重量
  let prodSizeWeightThMaster = deviceMst?.details?.prodSizeWeightThMaster?.filter(
    (item) => (item.value == weight || item.value > weight) && !item?.isOutOfRange
  );
  // 面積
  let prodSizeAreaThMaster = deviceMst?.details?.prodSizeAreaThMaster?.filter(
    (item) => (item.value == area || item.value > area) && !item?.isOutOfRange
  );
  let childPartsCoeffs =
    prodSizeWeightThMaster?.length > 0 && prodSizeAreaThMaster?.length > 0
      ? deviceMst?.details?.prodSizeItems?.filter((item) => item.weightId == prodSizeWeightThMaster[0]?.no)
      : [deviceMst?.details?.prodSizeItems[0]];

  // サイズ係数
  let partsCoeff =
    childPartsCoeffs?.length > 0 && prodSizeAreaThMaster?.length > 0
      ? childPartsCoeffs?.[0]?.areaCoeffList?.filter(
          (item) => item.no == prodSizeAreaThMaster?.[0].no && !item.isOutOfRange
        )?.[0]?.value
      : childPartsCoeffs?.[0]?.areaCoeffList?.[0]?.value;
  partsCoeff = partsCoeff ? partsCoeff : 0;
  return partsCoeff;
};

export const getGasCoef = (deviceMst, thickness, materialTypeId) => {
  if (deviceMst == undefined || deviceMst?.length == 0) return 0;

  let gasCoeffThickThMaster = deviceMst?.details?.gasCoeffThickThMaster?.filter(
    (item) => (item.value == thickness || item.value > thickness) && !item.isOutOfRange
  )?.[0];

  let gasCoeffThItems = Object.entries(deviceMst.details?.gasCoeffThItems)?.filter(
    ([key, data]) => parseInt(key) === materialTypeId
  )?.[0];

  let gasCoeffThItemsVal = 0;
  gasCoeffThItems?.map((item, index) => {
    if (index > 0) {
      gasCoeffThItemsVal = item?.filter(
        (item) => parseInt(item.no) == gasCoeffThickThMaster?.no && !item.isOutOfRange
      )?.[0]?.value;
    }
  });
  // IQX_WEBEST-147
  let gasCoeffThMaster = deviceMst?.details?.gasCoeffListItems?.filter(
    (item) => parseInt(item.no) == gasCoeffThItemsVal && !item?.isOutOfRange
  )?.[0];

  let childPartsCoeff = gasCoeffThMaster?.value;
  childPartsCoeff = childPartsCoeff ? childPartsCoeff : 0;
  return childPartsCoeff;
};

export const getSpeedCoef = (deviceMst, len, processType) => {
  if (deviceMst == undefined || deviceMst?.length == 0) return 0;
  let childPartsCoeff = 0;
  let workSpeedCoeffLengthThMaster = deviceMst?.details?.workSpeedCoeffLengthThMaster?.filter(
    (item) => (item.value == len || item.value > len) && !item.isOutOfRange
  )?.[0];

  if (processType == 1) {
    // 内周の加工速度係数取得
    childPartsCoeff = deviceMst?.details?.workSpeedCoeffInternalWorkItems?.filter(
      (item) => item.no == workSpeedCoeffLengthThMaster?.no && !item.isOutOfRange
    )?.[0]?.value;
  } else {
    // 外周の加工速度係数取得
    childPartsCoeff = deviceMst?.details?.workSpeedCoeffExternalWorkItems?.filter(
      (item) => item.no == workSpeedCoeffLengthThMaster?.no && !item.isOutOfRange
    )?.[0]?.value;
  }
  childPartsCoeff = childPartsCoeff ? childPartsCoeff : 0;
  return childPartsCoeff;
};

export const getSagyoSpeed = (deviceMst, thickness, materialTypeIq3Id) => {
  if (deviceMst == undefined || deviceMst?.length == 0) return 0;
  // 加工速度
  let workSpeedThickThMaster = deviceMst?.details?.workSpeedThickThMaster?.filter(
    (item) => (item.value == thickness || item.value > thickness) && !item.isOutOfRange
  )?.[0];

  let workSpeedItems = Object.entries(deviceMst.details?.workSpeedItems)?.filter(
    ([key, data]) => parseInt(key) === materialTypeIq3Id
  )?.[0];

  // 加工速度
  let childPartsCoeff = 0;
  workSpeedItems?.map((item, index) => {
    if (index > 0) {
      childPartsCoeff = item?.filter(
        (item) => parseInt(item.no) == workSpeedThickThMaster?.no && !item.isOutOfRange
      )?.[0]?.value;
    }
  });
  childPartsCoeff = childPartsCoeff ? childPartsCoeff : 0;
  return childPartsCoeff;
};

export const getSagyoSpeedByWeld = (deviceMst, sencho, weldTypeId) => {
  if (deviceMst == undefined || deviceMst?.length == 0) return 0;
  // 加工速度
  // 溶接線長
  let workSpeedThMaster = deviceMst?.details?.workSpeedThMaster?.filter(
    (item) => (item.value == sencho || item.value > sencho) && !item.isOutOfRange
  )?.[0];

  let workSpeedThItems = Object.entries(deviceMst.details?.workSpeedThItems)?.filter(
    ([key, data]) => parseInt(key) === weldTypeId
  )?.[0];

  // 加工速度
  let childPartsCoeff = 0;
  workSpeedThItems?.map((item, index) => {
    if (index > 0) {
      childPartsCoeff = item?.filter((item) => parseInt(item.no) == workSpeedThMaster?.no && !item.isOutOfRange)?.[0]
        ?.value;
    }
  });
  childPartsCoeff = childPartsCoeff ? childPartsCoeff : 0;
  return childPartsCoeff;
};

// IQX_WEBEST-295 詳細入力の値を見積計算で使用してほしい
export const getSagyoOut = (deviceMst, thickness, materialTypeIq3Id) => {
  let speedOut = 0;
  let workSpeedItem = {};
  if (deviceMst == undefined || deviceMst?.length == 0) return sagyoDetailsInfo;
  // 加工速度マスタ
  let workSpeedThickThMaster = deviceMst?.details?.workSpeedThickThMaster?.filter(
    (item) => (item.value == thickness || item.value > thickness) && !item?.isOutOfRange
  )?.[0];
  // 加工速度値
  let workSpeedItems = Object.entries(deviceMst.details?.workSpeedItems)?.filter(
    ([key, data]) => parseInt(key) === materialTypeIq3Id
  )?.[0];
  // 詳細値を取得
  workSpeedItems?.map((item, index) => {
    if (index > 0) {
      workSpeedItem = item?.filter(
        (item) => parseInt(item.no) == workSpeedThickThMaster?.no && !item?.isOutOfRange
      )?.[0];
    }
  });
  speedOut = workSpeedItem?.speedOutLen;
  speedOut = speedOut === undefined ? 0 : speedOut;
  return speedOut;
};

// IQX_WEBEST-295 詳細入力の値を見積計算で使用してほしい
export const getSagyoIn = (deviceMst, thickness, materialTypeIq3Id, sagyoDetails, quantityList, gasCoef, sizeCoef) => {
  let workSpeedItem = {};
  let sagyoDetailsInfo = sagyoDetails;
  if (deviceMst == undefined || deviceMst?.length == 0) return sagyoDetailsInfo;
  // 加工速度マスタ
  let workSpeedThickThMaster = deviceMst?.details?.workSpeedThickThMaster?.filter(
    (item) => (item.value == thickness || item.value > thickness) && !item?.isOutOfRange
  )?.[0];
  // 加工速度値
  let workSpeedItems = Object.entries(deviceMst.details?.workSpeedItems)?.filter(
    ([key, data]) => parseInt(key) === materialTypeIq3Id
  )?.[0];
  // 詳細値を取得
  workSpeedItems?.map((item, index) => {
    if (index > 0) {
      workSpeedItem = item?.filter(
        (item) => parseInt(item.no) == workSpeedThickThMaster?.no && !item?.isOutOfRange
      )?.[0];
    }
  });
  if (workSpeedItem) {
    for (let i = 0; i < quantityList?.length; i++) {
      let holeType = quantityList[i];
      let sizeIndex = 0;
      let holeVal = 0;
      // 丸の場合、丸穴径(Φ) (≦)と条件する
      if (holeType.types === 1) {
        for (let i = 0; i < workSpeedItem.roundHoleDiaThItems?.length; i++) {
          if (Number(holeType.diameter) <= Number(workSpeedItem.roundHoleDiaThItems[i].value)) {
            sizeIndex = i;
            holeVal = Number(holeType.perimeter);
            break;
          }
        }
        // 周長がLサイズより多きの場合、Lサイズを採用
        if (
          sizeIndex === 0 &&
          holeVal > workSpeedItem.roundHoleDiaThItems?.[workSpeedItem.roundHoleDiaThItems?.length - 1]
        ) {
          sizeIndex = 2;
        }
      } else {
        // 丸以外の場合、加工周長(mm)(≦)と条件する
        for (let i = 0; i < workSpeedItem.workLenThItems?.length; i++) {
          if (Number(holeType.perimeter) <= Number(workSpeedItem.workLenThItems[i].value)) {
            sizeIndex = i;
            holeVal = Number(holeType.perimeter);
            break;
          }
        }
        // 周長がLサイズより多きの場合、Lサイズを採用
        if (
          sizeIndex === 0 &&
          Number(holeType.perimeter) > workSpeedItem.workLenThItems?.[workSpeedItem.workLenThItems?.length - 1]?.value
        ) {
          sizeIndex = 2;
          holeVal = Number(holeType.perimeter);
        }
      }

      // 速度取得
      if (sizeIndex === 0) {
        // Sサイズ速度
        let speedInS = workSpeedItem.lenThItems?.[sizeIndex]?.value;
        sagyoDetailsInfo.sagyoInDetails.speedInS = speedInS === undefined ? 0 : speedInS;
        // Sサイズ合計周長
        sagyoDetailsInfo.sagyoInDetails.speedInSLen += holeVal;
        // 加工速度係数
        let speedInCoefS = getSpeedCoef(deviceMst, holeVal, 1);
        // 内周切断時間（内周切断長	*	サイズ係数 /	加工速度	＊	加工速度係数	*	ガス係数）
        let sagyoTmInPerMin = Number.isFinite((holeVal * sizeCoef) / (speedInS * speedInCoefS * gasCoef))
          ? ((holeVal * sizeCoef) / (speedInS * speedInCoefS * gasCoef)) * 60
          : 0;
        sagyoTmInPerMin = sagyoTmInPerMin * getProductionQuantityCoeff(deviceMst); // IQX_WEBEST-278 加工時間＊生産個数係数
        sagyoDetailsInfo.sagyoInDetails.sagyoTmInS += sagyoTmInPerMin === undefined ? 0 : sagyoTmInPerMin;
        let sAmtIn = calSagyoAmt(sagyoTmInPerMin, deviceMst);
        sagyoDetailsInfo.sagyoInDetails.sagyoAmtInS += sAmtIn === undefined ? 0 : sAmtIn;
      } else if (sizeIndex === 1) {
        // Mサイズ速度
        let speedInM = workSpeedItem.lenThItems?.[sizeIndex]?.value;
        sagyoDetailsInfo.sagyoInDetails.speedInM = speedInM === undefined ? 0 : speedInM;
        // Mサイズ合計周長
        sagyoDetailsInfo.sagyoInDetails.speedInMLen += holeVal;
        // 加工速度係数
        let speedInCoefM = getSpeedCoef(deviceMst, holeVal, 1);
        // 内周切断時間（内周切断長	*	サイズ係数 /	加工速度	＊	加工速度係数	*	ガス係数）
        let sagyoTmInPerMin = Number.isFinite((holeVal * sizeCoef) / (speedInM * speedInCoefM * gasCoef))
          ? ((holeVal * sizeCoef) / (speedInM * speedInCoefM * gasCoef)) * 60
          : 0;
        sagyoTmInPerMin = sagyoTmInPerMin * getProductionQuantityCoeff(deviceMst); // IQX_WEBEST-278 加工時間＊生産個数係数
        sagyoDetailsInfo.sagyoInDetails.sagyoTmInM += sagyoTmInPerMin === undefined ? 0 : sagyoTmInPerMin;
        let sAmtIn = calSagyoAmt(sagyoTmInPerMin, deviceMst);
        sagyoDetailsInfo.sagyoInDetails.sagyoAmtInM += sAmtIn === undefined ? 0 : sAmtIn;
      } else if (sizeIndex === 2) {
        // Lサイズ速度
        let speedInL = workSpeedItem.lenThItems?.[sizeIndex]?.value;
        sagyoDetailsInfo.sagyoInDetails.speedInL = speedInL === undefined ? 0 : speedInL;
        // Lサイズ合計周長
        sagyoDetailsInfo.sagyoInDetails.speedInLLen += holeVal;
        // 加工速度係数
        let speedInCoefL = getSpeedCoef(deviceMst, holeVal, 1);
        // 内周切断時間（内周切断長	*	サイズ係数 /	加工速度	＊	加工速度係数	*	ガス係数）
        let sagyoTmInPerMin = Number.isFinite((holeVal * sizeCoef) / (speedInL * speedInCoefL * gasCoef))
          ? ((holeVal * sizeCoef) / (speedInL * speedInCoefL * gasCoef)) * 60
          : 0;
        sagyoTmInPerMin = sagyoTmInPerMin * getProductionQuantityCoeff(deviceMst); // IQX_WEBEST-278 加工時間＊生産個数係数
        sagyoDetailsInfo.sagyoInDetails.sagyoTmInL += sagyoTmInPerMin === undefined ? 0 : sagyoTmInPerMin;
        let sAmtIn = calSagyoAmt(sagyoTmInPerMin, deviceMst);
        sagyoDetailsInfo.sagyoInDetails.sagyoAmtInL += sAmtIn === undefined ? 0 : sAmtIn;
      }
    }
    sagyoDetailsInfo.sagyoInDetails.speedInSLen =
      sagyoDetailsInfo.sagyoInDetails.speedInSLen === undefined
        ? toDecimal(0, 2)
        : toDecimal(sagyoDetailsInfo.sagyoInDetails.speedInSLen, 2);
    sagyoDetailsInfo.sagyoInDetails.speedInMLen =
      sagyoDetailsInfo.sagyoInDetails.speedInMLen === undefined
        ? toDecimal(0, 2)
        : toDecimal(sagyoDetailsInfo.sagyoInDetails.speedInMLen, 2);
    sagyoDetailsInfo.sagyoInDetails.speedInLLen =
      sagyoDetailsInfo.sagyoInDetails.speedInLLen === undefined
        ? toDecimal(0, 2)
        : toDecimal(sagyoDetailsInfo.sagyoInDetails.speedInLLen, 2);
    sagyoDetailsInfo.sagyoTmIn =
      sagyoDetailsInfo.sagyoInDetails.sagyoTmInS +
      sagyoDetailsInfo.sagyoInDetails.sagyoTmInM +
      sagyoDetailsInfo.sagyoInDetails.sagyoTmInL;
    sagyoDetailsInfo.sagyoAmtIn =
      sagyoDetailsInfo.sagyoInDetails.sagyoAmtInS +
      sagyoDetailsInfo.sagyoInDetails.sagyoAmtInM +
      sagyoDetailsInfo.sagyoInDetails.sagyoAmtInL;
  }
  return sagyoDetailsInfo;
};

export const getSagyoPiercingTm = (deviceMst, thickness, materialTypeIq3Id) => {
  if (deviceMst == undefined || deviceMst?.length == 0) return 0;
  // 加工速度
  let piercingTimeThickThMaster = deviceMst?.details?.piercingTimeThickThMaster?.filter(
    (item) => (item.value == thickness || item.value > thickness) && !item.isOutOfRange
  )?.[0];

  let piercingTimeThItems = Object.entries(deviceMst.details?.piercingTimeThItems)?.filter(
    ([key, data]) => parseInt(key) === materialTypeIq3Id
  )?.[0];

  // 加工速度
  let childPartsCoeff = 0;
  piercingTimeThItems?.map((item, index) => {
    if (index > 0) {
      childPartsCoeff = item?.filter(
        (item) => parseInt(item.no) == piercingTimeThickThMaster?.no && !item.isOutOfRange
      )?.[0]?.value;
    }
  });
  childPartsCoeff = childPartsCoeff ? childPartsCoeff : 0;
  return childPartsCoeff;
};

export const getSagyoPunchTm = (deviceMst, thickness, materialTypeIq3Id) => {
  if (deviceMst == undefined || deviceMst?.length == 0) return 0;
  // 加工速度
  let punchWorkTimeThickThMaster = deviceMst?.details?.punchWorkTimeThickThMaster?.filter(
    (item) => (item.value == thickness || item.value > thickness) && !item.isOutOfRange
  )?.[0];

  let punchWorkTimeItems = Object.entries(deviceMst.details?.punchWorkTimeItems)?.filter(
    ([key, data]) => parseInt(key) === materialTypeIq3Id
  )?.[0];

  // 加工速度
  let childPartsCoeff = 0;
  punchWorkTimeItems?.map((item, index) => {
    if (index > 0) {
      childPartsCoeff = item?.filter(
        (item) => parseInt(item.no) == punchWorkTimeThickThMaster?.no && !item.isOutOfRange
      )?.[0]?.value;
    }
  });
  childPartsCoeff = childPartsCoeff ? childPartsCoeff : 0;
  return childPartsCoeff;
};

export const getJointTm = (deviceMst, thickness, materialTypeIq3Id) => {
  if (deviceMst == undefined || deviceMst?.length == 0) return 0;
  let jointSeparateThickThMaster = deviceMst?.details?.jointSeparateThickThMaster?.filter(
    (item) => (item.value == thickness || item.value > thickness) && !item.isOutOfRange
  )?.[0];

  let jointSeparateItems = Object.entries(deviceMst.details?.jointSeparateItems)?.filter(
    ([key, data]) => parseInt(key) === materialTypeIq3Id
  )?.[0];

  // ジョイント時間
  let childPartsCoeff = 0;
  jointSeparateItems?.map((item, index) => {
    if (index > 0) {
      childPartsCoeff = item?.filter(
        (item) => parseInt(item.no) == jointSeparateThickThMaster?.no && !item.isOutOfRange
      )?.[0]?.value;
    }
  });
  childPartsCoeff = childPartsCoeff ? childPartsCoeff : 0;
  return childPartsCoeff;
};

// 段取個数係数取得
export const getSetupCoeff = (deviceMstFilter, qty, partCount) => {
  let iq3SetupCoeff = 1;
  let quantity = Number(qty);
  let partCnt = Number(partCount); // 員数
  let manufacturingCnt = quantity * partCnt; // 製造数 * 員数
  // 段取個数係数
  let prepQtyCoeffTh = deviceMstFilter?.details?.prepQtyCoeffThMaster?.filter(
    (item) => (item.value == manufacturingCnt || item.value > manufacturingCnt) && !item.isOutOfRange
  );
  if (prepQtyCoeffTh) {
    let prepQtyCoeffThNo =
      prepQtyCoeffTh?.length > 0 ? prepQtyCoeffTh?.[0]?.no : deviceMstFilter?.details?.prepQtyCoeffThMaster?.[0]?.no;
    let prepQtyCoeff =
      prepQtyCoeffThNo == 0
        ? 0
        : deviceMstFilter?.details?.prepQtyCoeffItems?.filter(
            (item) => item.no == prepQtyCoeffThNo && !item.isOutOfRange
          )[0]?.value;
    iq3SetupCoeff = prepQtyCoeff;
    iq3SetupCoeff = iq3SetupCoeff ? iq3SetupCoeff : 1;
  }
  return iq3SetupCoeff;
};

export const calSagyoAmtTwoStepHole = (dandoriTimeSec, deviceMst) => {
  //段階時間
  let mSetTimeMin = dandoriTimeSec / 60;
  //装置の段階時間チャージ
  let mSetHRate = deviceMst.details?.charges.twoStepHolework;
  //段階時間＊時間チャージ
  let dandoriAmt = (mSetTimeMin * mSetHRate) / 60.0;
  dandoriAmt = Number.isFinite(dandoriAmt) ? dandoriAmt : 0;
  return dandoriAmt;
};

export const calSagyoAmtJoint = (dandoriTimeSec, deviceMst) => {
  //段階時間
  let mSetTimeMin = dandoriTimeSec / 60;
  //装置の段階時間チャージ
  let mSetHRate = deviceMst.details?.charges.jointSeparate;
  //段階時間＊時間チャージ
  let dandoriAmt = (mSetTimeMin * mSetHRate) / 60.0;
  dandoriAmt = Number.isFinite(dandoriAmt) ? dandoriAmt : 0;
  return dandoriAmt;
};

export const calSagyoAmtPunch = (cSagyoTimeSec, deviceMst) => {
  // 作業金額
  // 作業時間
  let mWorkHTime = cSagyoTimeSec;
  let mWorkHTimeMin = mWorkHTime / 60;
  //装置の作業時間チャージ
  let mWorkHRate = deviceMst.details?.charges.punchWork;
  //　加工時間＊時間チャージ
  let cSagyoAmt = (mWorkHTimeMin * mWorkHRate) / 60.0;
  cSagyoAmt = Number.isFinite(cSagyoAmt) ? cSagyoAmt : 0;
  return cSagyoAmt;
};
export const calSagyoAmtCombi = (cSagyoTimeSec, deviceMst) => {
  // 作業金額
  // 作業時間
  let mWorkHTime = cSagyoTimeSec;
  let mWorkHTimeMin = mWorkHTime / 60;
  //装置の作業時間チャージ
  let mWorkHRate = deviceMst.details?.charges.laserWork;
  //　加工時間＊時間チャージ
  let cSagyoAmt = (mWorkHTimeMin * mWorkHRate) / 60.0;
  cSagyoAmt = Number.isFinite(cSagyoAmt) ? cSagyoAmt : 0;
  return cSagyoAmt;
};
export const getHoseCoeff = (deviceMst, weight, area) => {
  if (deviceMst == undefined || deviceMst?.length == 0) return 0;
  // 段取時間設定

  // 重量
  let mtWeightThMaster = deviceMst?.details?.mtWeightThMaster?.filter(
    (item) => (item.value == weight || item.value > weight) && !item.isOutOfRange
  );
  // 面積
  let mtAreaThMaster = deviceMst?.details?.mtAreaThMaster?.filter(
    (item) => (item.value == area || item.value > area) && !item.isOutOfRange
  );
  let childPartsCoeffs =
    mtWeightThMaster?.length > 0 && mtAreaThMaster?.length > 0
      ? deviceMst?.details?.prodSizeItems?.filter((item) => item.weightId == mtWeightThMaster[0]?.no)
      : deviceMst?.details?.prodSizeItems[0];

  // 補正係数
  let partsCoeff =
    childPartsCoeffs?.length > 0
      ? childPartsCoeffs?.[0]?.areaCoeffList?.filter(
          (item) => item.no == mtAreaThMaster?.[0]?.no && !item.isOutOfRange
        )?.[0]?.value
      : childPartsCoeffs?.[0]?.areaCoeffList?.[0]?.value;
  partsCoeff = partsCoeff ? partsCoeff : 0;
  return partsCoeff;
};

export const getSpeedLenTime = (deviceMst, length, thickness) => {
  if (deviceMst == undefined || deviceMst?.length == 0) return 0;
  // 段取時間設定

  // 加工長
  let workSpeedLenThMaster = deviceMst?.details?.workSpeedLenThMaster?.filter(
    (item) => (item.value == length || item.value > length) && !item.isOutOfRange
  );

  // 板厚
  let workSpeedThickThMaster = deviceMst?.details?.workSpeedThickThMaster?.filter(
    (item) => (item.value == thickness || item.value > thickness) && !item.isOutOfRange
  );
  let childPartsCoeffs =
    workSpeedLenThMaster?.length > 0 && workSpeedThickThMaster?.length > 0
      ? deviceMst?.details?.workSpeedItems?.filter(
          (item) => item?.lengthId == workSpeedLenThMaster[0]?.no && !item.isOutOfRange
        )
      : [deviceMst?.details?.workSpeedItems[0]];

  // サイズ係数
  let partsCoeff =
    childPartsCoeffs?.length > 0
      ? childPartsCoeffs?.[0]?.itaatsuCoeffList?.filter(
          (item) => item.no == workSpeedThickThMaster?.[0]?.no && !item.isOutOfRange
        )?.[0]?.value
      : childPartsCoeffs?.[0]?.itaatsuCoeffList?.[0]?.value;
  partsCoeff = partsCoeff ? partsCoeff : 0;
  return partsCoeff;
};

export const getDiffCoef = (deviceMst, thickness, materialTypeId) => {
  if (deviceMst == undefined || deviceMst?.length == 0) return 0;
  // 溶接難易度
  let weldDifficultyCoeffThMaster = deviceMst?.details?.weldDifficultyCoeffThMaster?.filter(
    (item) => (item.value == thickness || item.value > thickness) && !item.isOutOfRange
  )?.[0];

  let weldDifficultyCoeffThItems = Object.entries(deviceMst.details?.weldDifficultyCoeffThItems)?.filter(
    ([key, data]) => parseInt(key) === materialTypeId
  )?.[0];

  // 溶接難易度
  let childPartsCoeff = 0;
  weldDifficultyCoeffThItems?.map((item, index) => {
    if (index > 0) {
      childPartsCoeff = item?.filter(
        (item) => parseInt(item.no) == weldDifficultyCoeffThMaster?.no && !item.isOutOfRange
      )?.[0]?.value;
    }
  });
  childPartsCoeff = childPartsCoeff ? childPartsCoeff : 0;
  return childPartsCoeff;
};

export const getDiffCoefByWeldFinish = (deviceMst, materialTypeId) => {
  if (deviceMst == undefined || deviceMst?.length == 0) return 0;

  let weldDifficultyCoeffThItems = Object.entries(deviceMst.details?.diffFactorCoeff)?.filter(
    ([key, data]) => parseInt(key) === materialTypeId
  )?.[0];

  // 溶接難易度
  let childPartsCoeff = 0;
  weldDifficultyCoeffThItems?.map((item, index) => {
    if (index > 0) {
      childPartsCoeff = item;
    }
  });
  childPartsCoeff = childPartsCoeff ? childPartsCoeff : 0;
  return childPartsCoeff;
};

export const getTempAttachTime = (deviceMst, thickness, weldTypeId) => {
  if (deviceMst == undefined || deviceMst?.length == 0) return 0;

  let tempInstThMaster = deviceMst?.details?.tempInstThMaster?.filter(
    (item) => (item.value == thickness || item.value > thickness) && !item.isOutOfRange
  )?.[0];

  let tempInstThItems = Object.entries(deviceMst.details?.tempInstThItems)?.filter(
    ([key, data]) => parseInt(key) === weldTypeId
  )?.[0];

  // 仮付け時間
  let tempAttachTime = 0;
  tempInstThItems?.map((item, index) => {
    if (index > 0) {
      tempAttachTime = item?.filter((item) => parseInt(item.no) == tempInstThMaster?.no && !item.isOutOfRange)?.[0]
        ?.value;
    }
  });
  tempAttachTime = tempAttachTime ? tempAttachTime : 0;
  return tempAttachTime;
};

export const getSubWebTm = (deviceMst, thickness, weldTypeId, weldTypeGroupId, sizeCoef) => {
  if (deviceMst == undefined || deviceMst?.length == 0) return 0;
  let tempInstThMaster = [];
  let tempInstThItems = [];
  if (weldTypeGroupId == WorkTypeGroup.SmInstThick) {
    tempInstThMaster = deviceMst?.details?.instThickThMaster?.filter(
      (item) => (item.value == thickness || item.value > thickness) && !item.isOutOfRange
    )?.[0];

    tempInstThItems = Object.entries(deviceMst.details?.instThickThItems)?.filter(
      ([key, data]) => parseInt(key) === weldTypeId
    )?.[0];
  } else {
    tempInstThMaster = deviceMst?.details?.instSizeThMaster?.filter(
      (item) => (item.value == sizeCoef || item.value > sizeCoef) && !item.isOutOfRange
    )?.[0];

    tempInstThItems = Object.entries(deviceMst.details?.instSizeThItems)?.filter(
      ([key, data]) => parseInt(key) === weldTypeId
    )?.[0];
  }

  // 仮付け時間
  let tempAttachTime = 0;
  tempInstThItems?.map((item, index) => {
    if (index > 0) {
      tempAttachTime = item?.filter((item) => parseInt(item.no) == tempInstThMaster?.no && !item.isOutOfRange)?.[0]
        ?.value;
    }
  });
  tempAttachTime = tempAttachTime ? tempAttachTime : 0;
  return tempAttachTime;
};
