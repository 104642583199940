/**
 * クラス名：システム全体の共通ファイル
 * 説明：システム全体に共通使っているファイルである。
 * 作成者：ナンス
 * 作成日：2024/11/25
 * バージョン：1.0
 */
import { toDecimal } from '../common/Common';
import {
  calDandoriAmt,
  calSagyoAmt,
  calSagyoAmtJoint,
  calSagyoAmtPunch,
  calSagyoAmtTwoStepHole,
  getGasCoef,
  getJointTm,
  getProductionQuantityCoeff,
  getSagyoIn,
  getSagyoOut,
  getSagyoPiercingTm,
  getSagyoPunches,
  getSagyoPunchTm,
  getSagyoSpeed,
  getSetupCoeff,
  getSizeCoeff,
  getSpeedCoef,
} from './IQ3KouteiCalculate_Common';
import { DataType, ServiceClass, WorkType } from '../common/Constant';

// ブラック（レザー）工程の計算
export const calBlankLaser = (
  curAddition,
  processInput,
  processSelect,
  curDeviceMst,
  calcParameters,
  curIQ3DataDetail,
  orderQuantity
) => {
  let blankLaserAddition = curAddition;
  if (Object.keys(blankLaserAddition).length > 0) {
    let orgData = [];
    let processMst = [];
    let nijiProcessMst;
    let deviceMst = curDeviceMst[0]; //テスト用ID;
    let parameters = calcParameters;
    if (parameters?.process?.length > 0) {
      processMst = parameters?.process
        ? parameters?.process?.filter(
            (item) => item?.class === ServiceClass.SheetMetal && item.workType === WorkType.SmblankLaser
          )[0]
        : [];
    }
    let patternInfo = processSelect;
    if (patternInfo == undefined) return;
    if (deviceMst == undefined) return;
    let laserAddition = blankLaserAddition;
    // プログラムの工程入力情報取得
    let laserProcessInput = processInput;
    //外径含む1から始まる
    let piercingcount = 1;
    let diameter = 0;
    let perimeter = 0;
    if (laserProcessInput.length > 0) {
      if (laserProcessInput?.[0]?.details?.[0]?.quantityList) {
        let quantityList = laserProcessInput?.[0]?.details?.[0]?.quantityList;
        quantityList?.forEach((e) => {
          if (Number(e.perimeter) !== 0) {
            piercingcount += Number(e.count);
          }
          diameter += Number(e.diameter);
          perimeter += Number(e.perimeter);
        });
      }
    }
    let secondaryWorkProcessInput;
    let secondaryUsedForCalc = curIQ3DataDetail?.processSelect?.details?.filter(
      (i) => i.processId === nijiProcessMst?.id
    )?.[0]?.isUsedForCalc;

    if (nijiProcessMst !== undefined && secondaryUsedForCalc) {
      secondaryWorkProcessInput = curIQ3DataDetail?.processInput?.filter(
        (item) => item.processId == nijiProcessMst?.id
      )[0];
    }
    let nijiCnt = 0;
    if (secondaryWorkProcessInput !== undefined && secondaryWorkProcessInput?.details !== undefined) {
      secondaryWorkProcessInput?.details?.forEach((e) => {
        if (e.blankFlag && Number(e.diameter) > 0) {
          nijiCnt += Number(e.count);
        }
      });
    }
    let detailItems = [];
    let editDetailItems = [];
    let sagyoInDetail = {
      sagyoAmtInS: 0,
      sagyoTmInS: 0,
      speedInS: 0,
      speedInSLen: 0,
      sagyoAmtInM: 0,
      sagyoTmInM: 0,
      speedInM: 0,
      speedInMLen: 0,
      sagyoAmtInL: 0,
      sagyoTmInL: 0,
      speedInL: 0,
      speedInLLen: 0,
    };
    let sagyoDetail = {
      sagyoAmtOut: 0,
      sagyoTmOut: 0,
      speedOut: 0,
      speedOutLen: 0,
      sagyoAmtIn: 0,
      sagyoTmIn: 0,
      speedIn: 0,
      speedInLen: 0,
      sagyoAmtOutTwiceCut: 0, // IQX_WEBEST-359 材料表面、レーザー2度切りの対応
      sagyoTmOutTwiceCut: 0, // IQX_WEBEST-359 材料表面、レーザー2度切りの対応
      speedOutTwiceCut: 0, // IQX_WEBEST-359 材料表面、レーザー2度切りの対応
      speedOutTwiceCutLen: 0, // IQX_WEBEST-359 材料表面、レーザー2度切りの対応
      sagyoAmtInTwiceCut: 0, // IQX_WEBEST-359 材料表面、レーザー2度切りの対応
      sagyoTmInTwiceCut: 0, // IQX_WEBEST-359 材料表面、レーザー2度切りの対応
      speedInTwiceCut: 0, // IQX_WEBEST-359 材料表面、レーザー2度切りの対応
      speedInTwiceCutLen: 0, // IQX_WEBEST-359 材料表面、レーザー2度切りの対応
      sagyoAmtTwiceCut: 0, // IQX_WEBEST-359 材料表面、レーザー2度切りの対応
      sagyoTmTwiceCut: 0, // IQX_WEBEST-359 材料表面、レーザー2度切りの対応
      sagyoAmtAirDeli: 0,
      sagyoTmAirDeli: 0,
      speedAirDeli: 0,
      speedAirDeliLen: 0,
      sagyoAmtPiercing: 0,
      sagyoTmPiercing: 0,
      speedPiercing: 0,
      speedPiercingLen: 0,
      sagyoAmtScribing: 0,
      sagyoTmScribing: 0,
      speedScribing: 0,
      speedScribingLen: 0,
      sagyoAmtTwoStepHole: 0,
      sagyoTmTwoStepHole: 0,
      twoStepHoleCnt: 0,
      twoStepHoleTm: 0,
      sagyoAmtJoint: 0,
      sagyoTmJoint: 0,
      jointCnt: 0,
      jointTm: 0,
      sagyoInDetails: sagyoInDetail,
    };
    detailItems.push({
      id: 1,
      processName: processMst?.name,
      dandoriAmt: 0,
      dandoriTime: 0,
      sagyoAmt: 0,
      sagyoTime: 0,
      sagyoDetails: sagyoDetail,
      formula: '',
    });

    editDetailItems = [];
    let newLaserAddition = {
      no: processMst?.id,
      processId: processMst?.id,
      dataType: laserAddition?.dataType ? laserAddition.dataType : DataType.Data,
      dataItems: detailItems,
      editItems: laserAddition?.editItems ? laserAddition.editItems : editDetailItems,
      totalDandori: {
        dataItemsAmt: 0,
        dataItemsTime: 0,
        editItemsAmt: 0,
        editItemsTime: 0,
      },
      totalSagyo: {
        dataItemsAmt: 0,
        dataItemsTime: 0,
        editItemsAmt: 0,
        editItemsTime: 0,
      },
      totalDataPrice: 0,
      totalDataTime: 0,
      totalEditPrice: 0,
      totalEditTime: 0,
      editDataFlg: false,
    };
    laserAddition = newLaserAddition;

    //データ設定
    if (laserAddition?.dataItems) {
      orgData = JSON.parse(JSON.stringify(laserAddition?.dataItems));
      if (deviceMst != undefined) {
        let totalSagyo = 0;
        let totalSagyoTm = 0;
        // 数量
        let quantity = orderQuantity;
        // 員数
        let partCnt = curIQ3DataDetail.partCount;
        // 板厚
        let thickness = parameters?.materialIq3?.filter((item) => item.id == curIQ3DataDetail?.thickness)?.[0]?.info
          ?.thick;
        // 重量
        let weight = curIQ3DataDetail?.weight;
        // 面積
        let area = Number(curIQ3DataDetail?.totalSurfaceArea);

        //総重量が0、子部品数が0の場合、段取と加工を計算しない。
        if (weight === 0 && area === 0) {
          laserAddition.dataItems = orgData;
          laserAddition.editItems = orgData;
        } else {
          // 生産個数係数取得
          let productionQuantityCoeff = getProductionQuantityCoeff(deviceMst, quantity, partCnt);
          let speedInLen = Number(laserProcessInput?.[0]?.details?.[0]?.dimensionData?.innerDia);
          let speedOutLen = Number(laserProcessInput?.[0]?.details?.[0]?.dimensionData?.outerDia);
          let speedAirDeliLen = Number(laserProcessInput?.[0]?.details?.[0]?.dimensionData?.airFeedDistance);
          let speedScribingLen = Number(laserProcessInput?.[0]?.details?.[0]?.dimensionData?.etchingLength); //PMX用ケガキ線長

          //材質
          let materialTypeIq3Id = curIQ3DataDetail?.materialTypeIq3Id;

          //材質区分
          let materialTypeId = parameters?.materialTypeIq3?.filter((item) => item.id == materialTypeIq3Id)?.[0]
            ?.materialTypeId;

          // サイズ係数
          let sizeCoef = getSizeCoeff(deviceMst, weight, area);

          //ガス係数
          let gasCoef = getGasCoef(deviceMst, thickness, materialTypeId);

          /** 段取金額/時間 */
          // 基本段取時間
          let basicSetupTimeSec = deviceMst?.details?.prepBasicTime * getSetupCoeff(deviceMst, quantity, partCnt); // IQX_WEBEST-278 基本段取時間＊段取個数係数
          orgData[0].dandoriTime = basicSetupTimeSec === undefined ? 0 : basicSetupTimeSec;
          let calDanAmt = calDandoriAmt(basicSetupTimeSec, deviceMst);
          orgData[0].dandoriAmt = calDanAmt === undefined ? 0 : calDanAmt;

          // 2工程穴加工金額/時間
          let twoStepHoleTm = deviceMst?.details?.twoStepHoleworkTime;
          let twoStepHoleCnt = nijiCnt;
          orgData[0].sagyoDetails.twoStepHoleTm = twoStepHoleTm === undefined ? 0 : twoStepHoleTm;
          orgData[0].sagyoDetails.twoStepHoleCnt = twoStepHoleCnt === undefined ? 0 : twoStepHoleCnt;
          // 2工程穴加工時間	*	個数
          let twoStepHoleTmsec = twoStepHoleTm * twoStepHoleCnt * productionQuantityCoeff; // IQX_WEBEST-278 加工時間＊生産個数係数
          orgData[0].sagyoDetails.sagyoTmTwoStepHole = twoStepHoleTmsec === undefined ? 0 : twoStepHoleTmsec;

          let sagyoAmt2Step = calSagyoAmtTwoStepHole(twoStepHoleTmsec, deviceMst);
          orgData[0].sagyoDetails.sagyoAmtTwoStepHole = sagyoAmt2Step === undefined ? 0 : sagyoAmt2Step;

          // ジョイントバラシ加工金額/時間
          let jointTimePerSec = getJointTm(deviceMst, thickness, materialTypeId);
          let jointQtySizeThMaster = deviceMst?.details?.jointQtySizeThMaster?.filter(
            (item) => (item.value == area || item.value > area) && !item?.isOutOfRange
          )?.[0];
          jointQtySizeThMaster = jointQtySizeThMaster
            ? jointQtySizeThMaster
            : deviceMst?.details?.jointQtySizeThMaster?.[deviceMst?.details?.jointQtySizeThMaster.length - 1];

          let jointCnt = deviceMst?.details?.jointQtyItems?.filter(
            (item) => item.no == jointQtySizeThMaster?.no && !item?.isOutOfRange
          )?.[0]?.value;
          orgData[0].sagyoDetails.jointTm = jointTimePerSec === undefined ? 0 : jointTimePerSec;
          orgData[0].sagyoDetails.jointCnt = jointCnt === undefined ? 0 : jointCnt;
          // ジョイントバラシ加工時間	*	個数
          let jointTimeSec = jointTimePerSec * jointCnt * productionQuantityCoeff; // IQX_WEBEST-278 加工時間＊生産個数係数
          orgData[0].sagyoDetails.sagyoTmJoint = jointTimeSec;

          let sagyoAmtJt = calSagyoAmtJoint(jointTimeSec, deviceMst);
          orgData[0].sagyoDetails.sagyoAmtJoint = sagyoAmtJt === undefined ? 0 : sagyoAmtJt;

          // 外周切断時間（外周切断長	/	加工速度	＊	加工速度係数	*	ガス係数	*	サイズ係数）
          // 加工速度係数
          let speedOutCoef = getSpeedCoef(deviceMst, speedOutLen, 0);
          let speedOut = 0;
          // IQX_WEBEST-295 詳細入力の値を見積計算で使用してほしい
          if (deviceMst?.details?.isUsedEasyWorkSpeed === undefined || deviceMst?.details?.isUsedEasyWorkSpeed) {
            speedOut = getSagyoSpeed(deviceMst, thickness, materialTypeId);
          } else {
            speedOut = getSagyoOut(deviceMst, thickness, materialTypeId);
          }
          orgData[0].sagyoDetails.speedOut = speedOut;

          orgData[0].sagyoDetails.speedOutLen = speedOutLen === undefined ? toDecimal(0, 2) : toDecimal(speedOutLen, 2);
          speedOut = speedOut * speedOutCoef * gasCoef; // IQX_WEBEST-188
          let sagyoTmOutPerMin = Number.isFinite((speedOutLen * sizeCoef) / speedOut)
            ? ((speedOutLen * sizeCoef) / speedOut) * 60
            : 0;
          sagyoTmOutPerMin = sagyoTmOutPerMin * productionQuantityCoeff; // IQX_WEBEST-278 加工時間＊生産個数係数
          orgData[0].sagyoDetails.sagyoTmOut = sagyoTmOutPerMin === undefined ? 0 : sagyoTmOutPerMin;
          let sAmtOut = calSagyoAmt(sagyoTmOutPerMin, deviceMst);
          orgData[0].sagyoDetails.sagyoAmtOut = sAmtOut === undefined ? 0 : sAmtOut;

          // 内周切断時間（内周切断長	*	サイズ係数 /	加工速度	＊	加工速度係数	*	ガス係数）
          // IQX_WEBEST-295 詳細入力の値を見積計算で使用してほしい
          // 加工速度係数
          let speedInCoef = getSpeedCoef(deviceMst, speedInLen, 1);
          if (deviceMst?.details?.isUsedEasyWorkSpeed === undefined || deviceMst?.details?.isUsedEasyWorkSpeed) {
            let speedIn = getSagyoSpeed(deviceMst, thickness, materialTypeId);
            orgData[0].sagyoDetails.speedIn = speedIn === undefined ? 0 : speedIn;
            orgData[0].sagyoDetails.speedInLen = speedInLen === undefined ? toDecimal(0, 2) : toDecimal(speedInLen, 2);
            speedIn = speedIn * speedInCoef * gasCoef; // IQX_WEBEST-188
            let sagyoTmInPerMin = Number.isFinite((speedInLen * sizeCoef) / speedIn)
              ? ((speedInLen * sizeCoef) / speedIn) * 60
              : 0;
            sagyoTmInPerMin = sagyoTmInPerMin * productionQuantityCoeff; // IQX_WEBEST-278 加工時間＊生産個数係数
            orgData[0].sagyoDetails.sagyoTmIn = sagyoTmInPerMin === undefined ? 0 : sagyoTmInPerMin;
            let sAmtIn = calSagyoAmt(sagyoTmInPerMin, deviceMst);
            orgData[0].sagyoDetails.sagyoAmtIn = sAmtIn === undefined ? 0 : sAmtIn;
          } else {
            let sagyoInDetails = getSagyoIn(
              deviceMst,
              thickness,
              materialTypeId,
              orgData[0].sagyoDetails,
              laserProcessInput?.[0]?.details?.[0]?.quantityList,
              gasCoef,
              sizeCoef
            );
            orgData[0].sagyoDetails.sagyoTmIn = sagyoInDetails?.sagyoTmIn === undefined ? 0 : sagyoInDetails?.sagyoTmIn;
            orgData[0].sagyoDetails.sagyoAmtIn =
              sagyoInDetails?.sagyoAmtIn === undefined ? 0 : sagyoInDetails?.sagyoAmtIn;
            orgData[0].sagyoDetails.sagyoInDetails = sagyoInDetails?.sagyoInDetails;
          }

          // IQX_WEBEST-359 材料表面、レーザー2度切りの対応
          let materialSurfaceIq3Id = curIQ3DataDetail?.materialSurfaceIq3Id;
          let materialSurface = parameters?.materialSurfaceIq3?.filter(
            (item) => item.id === materialSurfaceIq3Id && item.materialTypeIq3Id === materialTypeIq3Id
          );
          let isTwiceCut = materialSurface?.length > 0 ? materialSurface?.[0]?.info?.isTwiceCut : false;
          let twiceCutWorkSpeed = materialSurface?.length > 0 ? materialSurface?.[0]?.info?.workSpeed : 0;
          // 外周の２度切り切断時間（外周切断長＊サイズ係数 /	2度切り加工速度	＊	加工速度係数	*	ガス係数）
          orgData[0].sagyoDetails.speedOutTwiceCutLen =
            speedOutLen === undefined ? toDecimal(0, 2) : toDecimal(speedOutLen, 2);
          if (isTwiceCut) {
            let twiceCutSpeedOut = twiceCutWorkSpeed * speedOutCoef * gasCoef;
            let sagyoTmOutTwiceCutPerMin = Number.isFinite((speedOutLen * sizeCoef) / twiceCutSpeedOut)
              ? ((speedOutLen * sizeCoef) / twiceCutSpeedOut) * 60
              : 0;
            sagyoTmOutTwiceCutPerMin = sagyoTmOutTwiceCutPerMin * productionQuantityCoeff; // IQX_WEBEST-278 加工時間＊生産個数係数
            orgData[0].sagyoDetails.sagyoTmOutTwiceCut =
              sagyoTmOutTwiceCutPerMin === undefined ? 0 : sagyoTmOutTwiceCutPerMin;
            let sAmtOutTwiceCut = calSagyoAmt(sagyoTmOutTwiceCutPerMin, deviceMst);
            orgData[0].sagyoDetails.sagyoAmtOutTwiceCut = sAmtOutTwiceCut === undefined ? 0 : sAmtOutTwiceCut;
            orgData[0].sagyoDetails.speedOutTwiceCut = twiceCutWorkSpeed;
          }
          // 内周の２度切り切断時間（内周切断長＊サイズ係数 /	2度切り加工速度	＊	加工速度係数	*	ガス係数）
          orgData[0].sagyoDetails.speedInTwiceCutLen =
            speedInLen === undefined ? toDecimal(0, 2) : toDecimal(speedInLen, 2);
          if (isTwiceCut) {
            let twiceCutSpeedIn = twiceCutWorkSpeed * speedInCoef * gasCoef;
            let sagyoTmInTwiceCutPerMin = Number.isFinite((speedInLen * sizeCoef) / twiceCutSpeedIn)
              ? ((speedInLen * sizeCoef) / twiceCutSpeedIn) * 60
              : 0;
            sagyoTmInTwiceCutPerMin = sagyoTmInTwiceCutPerMin * productionQuantityCoeff; // IQX_WEBEST-278 加工時間＊生産個数係数
            orgData[0].sagyoDetails.sagyoTmInTwiceCut =
              sagyoTmInTwiceCutPerMin === undefined ? 0 : sagyoTmInTwiceCutPerMin;
            let sAmtInTwiceCut = calSagyoAmt(sagyoTmInTwiceCutPerMin, deviceMst);
            orgData[0].sagyoDetails.sagyoAmtInTwiceCut = sAmtInTwiceCut === undefined ? 0 : sAmtInTwiceCut;
            orgData[0].sagyoDetails.speedInTwiceCut = twiceCutWorkSpeed;
          }

          orgData[0].sagyoDetails.sagyoAmtTwiceCut =
            orgData[0].sagyoDetails.sagyoAmtOutTwiceCut + orgData[0].sagyoDetails.sagyoAmtInTwiceCut;
          orgData[0].sagyoDetails.sagyoTmTwiceCut =
            orgData[0].sagyoDetails.sagyoTmOutTwiceCut + orgData[0].sagyoDetails.sagyoTmInTwiceCut;

          // 空送時間（空送距離	/	送り速度）
          let speedAirDeli = deviceMst?.details?.feedSpeed;
          orgData[0].sagyoDetails.speedAirDeli = speedAirDeli === undefined ? 0 : speedAirDeli;

          orgData[0].sagyoDetails.speedAirDeliLen = speedAirDeliLen === undefined ? 0 : speedAirDeliLen;
          let sagyoTmAirDeli = Number.isFinite(speedAirDeliLen / speedAirDeli)
            ? (speedAirDeliLen / speedAirDeli) * 60
            : 0;
          sagyoTmAirDeli = sagyoTmAirDeli * productionQuantityCoeff; // IQX_WEBEST-278 加工時間＊生産個数係数
          orgData[0].sagyoDetails.sagyoTmAirDeli = sagyoTmAirDeli === undefined ? 0 : sagyoTmAirDeli;
          let sAmtDeli = calSagyoAmt(sagyoTmAirDeli, deviceMst);
          orgData[0].sagyoDetails.sagyoAmtAirDeli = sAmtDeli === undefined ? 0 : sAmtDeli;

          // ピアス加工時間（1ピアス加工時間	*	箇所数	＊	ガス係数）
          let sagyoTmPiercingPerSec = getSagyoPiercingTm(deviceMst, thickness, materialTypeId);
          orgData[0].sagyoDetails.speedPiercing = sagyoTmPiercingPerSec === undefined ? 0 : sagyoTmPiercingPerSec;
          let speedPiercingLen = piercingcount;
          orgData[0].sagyoDetails.speedPiercingLen = speedPiercingLen;
          let sagyoTmPiercing = sagyoTmPiercingPerSec * speedPiercingLen * gasCoef;
          sagyoTmPiercing = sagyoTmPiercing * productionQuantityCoeff; // IQX_WEBEST-278 加工時間＊生産個数係数
          orgData[0].sagyoDetails.sagyoTmPiercing = sagyoTmPiercing === undefined ? 0 : sagyoTmPiercing;
          let sAmtPiercing = calSagyoAmt(sagyoTmPiercing, deviceMst);
          orgData[0].sagyoDetails.sagyoAmtPiercing = sAmtPiercing === undefined ? 0 : sAmtPiercing;

          // ケガキ加工時間（ケガキ線長	/	加工速度）
          let speedScribing = deviceMst?.details?.scribingSpeed;
          orgData[0].sagyoDetails.speedScribing = speedScribing === undefined ? 0 : speedScribing;
          orgData[0].sagyoDetails.speedScribingLen = speedScribingLen === undefined ? 0 : speedScribingLen;
          let sagyoTmScribing = Number.isFinite(speedScribingLen / speedScribing)
            ? (speedScribingLen / speedScribing) * 60
            : 0;
          sagyoTmScribing = sagyoTmScribing * productionQuantityCoeff; // IQX_WEBEST-278 加工時間＊生産個数係数
          orgData[0].sagyoDetails.sagyoTmScribing = sagyoTmScribing === undefined ? 0 : sagyoTmScribing;
          let scribingAmt = calSagyoAmt(sagyoTmScribing, deviceMst);
          orgData[0].sagyoDetails.sagyoAmtScribing = scribingAmt === undefined ? 0 : scribingAmt;
          //作業合計金額・時間
          orgData[0].sagyoAmt =
            orgData[0].sagyoDetails.sagyoAmtOut +
            orgData[0].sagyoDetails.sagyoAmtIn +
            orgData[0].sagyoDetails.sagyoAmtTwiceCut + // IQX_WEBEST-359 材料表面、レーザー2度切りの対応
            orgData[0].sagyoDetails.sagyoAmtAirDeli +
            orgData[0].sagyoDetails.sagyoAmtScribing +
            orgData[0].sagyoDetails.sagyoAmtPiercing;
          orgData[0].sagyoTime =
            orgData[0].sagyoDetails.sagyoTmOut +
            orgData[0].sagyoDetails.sagyoTmIn +
            orgData[0].sagyoDetails.sagyoTmTwiceCut + // IQX_WEBEST-359 材料表面、レーザー2度切りの対応
            orgData[0].sagyoDetails.sagyoTmAirDeli +
            orgData[0].sagyoDetails.sagyoTmScribing +
            orgData[0].sagyoDetails.sagyoTmPiercing;

          totalSagyo =
            (orgData[0].sagyoAmt ? Math.round(orgData[0].sagyoAmt) : 0) +
            (orgData[0].sagyoDetails.sagyoAmtTwoStepHole
              ? Math.round(orgData[0].sagyoDetails.sagyoAmtTwoStepHole)
              : 0) +
            (orgData[0].sagyoDetails.sagyoAmtJoint ? Math.round(orgData[0].sagyoDetails.sagyoAmtJoint) : 0);
          totalSagyoTm =
            orgData[0].sagyoTime + orgData[0].sagyoDetails.sagyoTmTwoStepHole + orgData[0].sagyoDetails.sagyoTmJoint;

          /** 　合計時間　*/
          let totalTimeSum = orgData[0].dandoriTime + totalSagyoTm;
          laserAddition.totalDataTime = totalTimeSum;
          laserAddition.totalDandori.dataItemsTime = orgData[0].dandoriTime;
          laserAddition.totalSagyo.dataItemsTime = totalSagyoTm;

          /** 　合計金額　*/
          let totalSum =
            (orgData[0].dandoriAmt ? Math.round(orgData[0].dandoriAmt) : 0) + (totalSagyo ? Math.round(totalSagyo) : 0); // 画面表示用（四捨五入した金額を足す）
          laserAddition.totalDataPrice = totalSum;
          laserAddition.totalDandori.dataItemsAmt = orgData[0].dandoriAmt ? Math.round(orgData[0].dandoriAmt) : 0;
          laserAddition.totalSagyo.dataItemsAmt = totalSagyo;
          laserAddition.dataItems = orgData;
          laserAddition.editItems = orgData;
        }
        blankLaserAddition = laserAddition;
      }
    }
  }
  return blankLaserAddition;
};
