/**
 * クラス名：TOP＆見積・注文画面
 * 説明：見積・注文を行う。
 * 作成者：ナンス
 * 作成日：2024/11/20
 * バージョン：1.0
 */
import { Button, Image, Table, Layout, Select, Input, Spin, Tooltip, Typography, Row, Popover } from 'antd';
import React, { forwardRef, useEffect, useState, useRef } from 'react';
import TextArea from 'antd/es/input/TextArea';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import * as signalR from '@microsoft/signalr';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import {
  CaretLeftOutlined,
  CaretRightOutlined,
  CloseOutlined,
  DeleteFilled,
  ExclamationCircleOutlined,
  RedoOutlined,
  UploadOutlined,
  WarningOutlined,
} from '@ant-design/icons';

import '../assets/styles/common.css';
import upload_file from '../assets/images/upload_file.png';
import EstimateFooter from '../components/EstimateFooter';
import {
  EstimateType,
  MaterialClass,
  Painting,
  Plating,
  ProcessType,
  UploadStatus,
  EstimateResultClass,
  EstimatePriorityClass,
  UserRole,
} from './common/enums';
import { WithRouter } from '../components/WithRouter';
import copy from '../assets/images/copy.png';
import paste from '../assets/images/paste.png';
import {
  formatDate,
  getRequestHeader,
  convertCadDataToEstimateData,
  getNewEstimateInfo,
  blobToBase64,
  getFileExtension,
  generateTimestamp,
} from './common/Common';
import {
  AllowedExtensions,
  EST_FAIL_AMT_LIMIT,
  FileUploadStatus2D,
  HL,
  IQxRealGoku_CalParameters,
  IQxRealGoku_LoginUser,
  MIRROR_SURFACE,
  ORDER_DATE_INPUT,
  ORDER_DATE_URGENT,
  ORDER_FITST,
  ORDER_REPEAT,
  pageSizes,
  PICKLING,
  PRIORITY_LOW,
  ServiceClass,
  totalUploadFileSizeLimitMB,
  WorkType,
  WorkTypeGroup,
} from './common/Constant';
import { getCad3dDatas, getHubConnectionToken } from './common/CommonAPI';
import { ErrorMessage } from './common/Message';
import { commonDeleteFooter, commonModal, deleteModalTitle, orderModalTitle } from './common/CommonModal';
import { calEstimateInfo } from './estimate/Estimate';

const Home = forwardRef((props, ref) => {
  const [pageSize, setPageSize] = useState(30);
  const [cusCurrentPage, setCusCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [spinLoading, setSpinLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [dragging, setDragging] = useState(false);
  const [cad3dDataLst, setCad3dDataLst] = useState([]);
  const excelFileRef = React.useRef(null);
  const [showDatepicker, setShowDatepicker] = useState(false);
  const [copyEstimateCondition, setCopyEstimateCondition] = useState({});
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isUpload, setIsUpload] = useState(false);
  const [selectedFileNames, setSelectedFileNames] = useState([]);
  const [isDelete, setIsDelete] = useState(false);
  const [deleteCad3DId, setDeleteCad3DId] = useState(0);
  const [hubConnection, setHubConnection] = useState(null);
  const [hubConnectionToken, setHubConnectionToken] = useState(null);
  const [calParameters, setCalParameters] = useState({});
  const [orderClass, setOrderClass] = useState([]);
  const [toleranceClass, setToleranceClass] = useState([]);
  const [weldFinishLevel, setWeldFinishLevel] = useState([]);
  const [finalPurpos, setFinalPurpos] = useState([]);
  const [deliveryTimeCls, setDeliveryTimeCls] = useState([]);
  const [materialClass, setMaterialClass] = useState([]);
  const [processMst, setProcessMst] = useState([]);
  const [tableHeight, setTableHeight] = useState(0);
  const [showDatepickerIndex, setShowDatepickerIndex] = useState(0);

  const titleRef = useRef(null);

  dayjs.extend(isBetween);

  useEffect(() => {
    const updateTableHeight = () => {
      const titleHeight = titleRef.current ? titleRef.current.offsetHeight : 13;
      const headerHeight = document.querySelector('.responsive-table .ant-table-thead')?.offsetHeight || 64;
      // const headerHeight1 = 64;
      const fixedHeight = document.querySelector('.home-tilte-div')?.offsetHeight || 0;
      const searchDivHeight = document.querySelector('.home-tilte-btn-div')?.offsetHeight || 0;
      const paginationElement = document.querySelector('.ant-table-pagination');
      const paginationMarginTop = paginationElement
        ? parseInt(getComputedStyle(paginationElement)?.marginTop || '0', 10)
        : 12;
      const paginationMarginBottom = paginationElement
        ? parseInt(getComputedStyle(paginationElement)?.marginBottom || '0', 10)
        : -10;
      const paginationHeight = document.querySelector('.ant-table-pagination')?.offsetHeight || 32;

      const availableHeight =
        window.innerHeight -
        titleHeight -
        headerHeight -
        fixedHeight -
        searchDivHeight -
        paginationHeight -
        paginationMarginTop -
        paginationMarginBottom -
        60; // Adjust 20px padding/margin if needed;
      setTableHeight(availableHeight);
    };

    updateTableHeight();
    window.addEventListener('resize', updateTableHeight);

    return () => window.removeEventListener('resize', updateTableHeight);
  }, []);

  // CAD 3D データを初期化するための関数呼び出しする
  useEffect(() => {
    setLoading(true);
    settingCad3dDatas();
    // 見積もるパラメータ情報をSessionから取得
    let paramData = JSON.parse(decodeURI(localStorage?.getItem(IQxRealGoku_CalParameters)));
    setCalParameters(paramData);
    if (Object.keys(paramData)?.length > 0) {
      let companyInfo = paramData.company;
      // 受注条件設定
      // 受注種類
      let coeffOrderTypes = companyInfo[0]?.coeffOrderTypes?.filter((i) => !i.isDeleted && i.isUsed);
      setOrderClass(coeffOrderTypes);
      //公差
      let toleranceClass = companyInfo[0]?.coeffTolerance?.filter((i) => !i.isDeleted && i.isUsed);
      setToleranceClass(toleranceClass);
      //仕上げレベル
      let weldFinishLevel = companyInfo[0]?.coeffWeldFinishLevel?.filter((i) => !i.isDeleted && i.isUsed);
      setWeldFinishLevel(weldFinishLevel);
      //最終用途
      let finalPurpos = companyInfo[0]?.coeffFinalPurpose?.filter((i) => !i.isDeleted && i.isUsed);
      setFinalPurpos(finalPurpos);
      // 発注後納期
      let deliveryTime = companyInfo[0]?.coeffDeliveryDates?.filter((i) => !i.isDeleted && i.isUsed);
      setDeliveryTimeCls(deliveryTime);
      // 材質
      let materialClass = paramData.materialTypeIq3?.filter((i) => !i.isDeleted && i.info.isUsed);
      setMaterialClass(materialClass);
      // 処理
      let process = paramData.process?.filter(
        (i) => !i.isDeleted && i.info.isUsed && i.class === ServiceClass.SheetMetal && i.workType === WorkType.SmSurface
      );
      setProcessMst(process);
    }
  }, []);

  // 3Dファイルをアップロードする
  useEffect(() => {
    if (isUpload && uploadedFiles?.length > 0) {
      uploadFileToStorage(uploadedFiles);
      setIsUpload(false);
      setUploadedFiles([]);
    }
  }, [isUpload]);

  // リスト画面表示のため、DBに保存された3Dファイル情報を取得する
  const settingCad3dDatas = async () => {
    let cad3dDatas = await getCad3dDatas(pageSize, 0, 'ASC', '', {});
    if (cad3dDatas) {
      setTotalCount(cad3dDatas?.totalCount);
      cad3dDatas = cad3dDatas.data;
      setCad3dDataLst(cad3dDatas);
    }
    setLoading(false);
  };

  // ストレージに3Dファイルをアップロードする
  const uploadFileToStorage = async (uploadFiles) => {
    // アップロードに成功したファイルIDを保存する配列を初期化します
    const uploadFileIDList = [];

    const uploadPromises = uploadFiles?.map((uploadFile) => {
      const btn = document.getElementById('estimate-btn-' + uploadFile.id);
      const orderJsonData = JSON.stringify([uploadFile.orderConditions]);
      const formData = new FormData();
      formData.append('file', uploadFile.fileObj);
      formData.append('fileId', uploadFile.id);
      formData.append('orderConditions', orderJsonData);

      const apiUrl = `${process.env.REACT_APP_BACKEND_API_URL}Cad3dData/UploadCad3File`;
      const headers = getRequestHeader();

      return axios
        .post(apiUrl, formData, { headers })
        .then((response) => {
          let upCad3dData = response.data?.data;

          if (upCad3dData && upCad3dData?.length > 0) {
            upCad3dData = upCad3dData[0];
            // 表示3Dファイルデータを更新する
            setCad3dDataLst((prevCad3dData) =>
              prevCad3dData?.map((f) =>
                f.id === uploadFile.id
                  ? { ...f, status: upCad3dData.status, estimateResult: upCad3dData.estimateResult }
                  : f
              )
            );

            //　アップロード成功の場合、見積ボタンを活性にする
            //if (btn) btn.disabled = false;

            // 成功したアップロードリストにファイルIDを追加する
            uploadFileIDList.push(upCad3dData.id);
          }
        })
        .catch(async (error) => {
          if (btn) btn.disabled = true;

          if (error.status) {
            const upApiUrl = `${process.env.REACT_APP_BACKEND_API_URL}Cad3dData/UpdateStatus`;
            const upFormData = new FormData();
            upFormData.append('status', 1);
            upFormData.append('fileId', uploadFile.id);

            // 更新
            const upResponse = await axios.put(upApiUrl, upFormData, { headers });
            if (upResponse.status === 200) {
              let upCadData = upResponse.data?.data?.[0];
              //　アップロードステータスを失敗に変更。
              setCad3dDataLst((prevCad3dData) =>
                prevCad3dData?.map((f) =>
                  f.id === uploadFile.id
                    ? { ...f, status: upCadData.status, estimateResult: upCadData.estimateResult }
                    : f
                )
              );
              // ３Ｄファイルアップロード失敗の場合、管理者にメール通知
              const mailApiUrl = `${process.env.REACT_APP_BACKEND_API_URL}Cad3dData/SendMailToZFOperatorByUpdateStatus`;
              await axios.put(mailApiUrl, upFormData, { headers });
            } else {
              alert('Failed to upload the item.');
            }
          }
        });
    });

    // すべてのアップロードが完了するまでお待ちする
    await Promise.all(uploadPromises);

    // アップロードしたファイルサイスを更新する
    if (uploadFileIDList.length > 0) {
      const apiUrl = `${process.env.REACT_APP_BACKEND_API_URL}Cad3dData/UpdateUserFileSize`;
      const headers = getRequestHeader('application/json');
      const payload = {
        uploadFileIDList: uploadFileIDList,
      };

      try {
        await axios.post(apiUrl, payload, { headers });
      } catch (error) {
        alert('Error updating user file size:', error);
      }
    }
  };

  // ドラッグ可能な要素がターゲット領域上にドラッグされたときに、ドラッグ入力イベントを処理する
  const handleDragEnter = (event) => {
    event.preventDefault();
    setDragging(true);
  };

  // ドラッグオーバー イベントを処理して、コンポーネントにアイテムをドロップできるようにする
  const handleDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'copy'; // Shows a "copy" cursor when dragging over the component
  };

  // ドラッグ状態を false に更新してドラッグ終了イベントを処理します
  const handleDragLeave = () => {
    setDragging(false);
  };

  // 3Dファイルアップロードする
  const handleFileUpload = async (excelFiles) => {
    if (excelFiles?.length > 0) {
      try {
        // アップロードしたファイルのサイスを取得する
        const totalFileSize = excelFiles.reduce((total, file) => {
          if (file?.size) {
            return total + file.size;
          }
          return total;
        }, 0);

        // ユーザータイプとファイルサイズ制限の条件を確認する
        if (
          props?.loginUser?.userType === UserRole.HE &&
          props?.loginUser?.usedFileSize + totalFileSize > totalUploadFileSizeLimitMB
        ) {
          // アップロード容量制限ページに移動する
          props.navigate('/home/:upload-capacity-limitation');
        } else {
          const headers = getRequestHeader();
          // 全ファイルをPromise.allで処理する
          await Promise.all(
            excelFiles?.map(async (file, i) => {
              const formData = new FormData();
              formData.append('fileName', file.name);
              // API登録処理する。
              const apiUrl = `${process.env.REACT_APP_BACKEND_API_URL}Cad3dData/CreateCad3dData`;
              // API登録リクエスト
              const response = await axios.post(apiUrl, formData, { headers });
              // 登録成功時にリストを更新する
              if (response.status === 200) {
                let newCadData = response.data?.data?.[0];
                if (newCadData) {
                  // 初期設定
                  newCadData.orderConditions.order = orderClass?.some(
                    (opt) => opt.id === newCadData?.orderConditions?.order
                  )
                    ? newCadData?.orderConditions?.order
                    : orderClass?.length > 0
                    ? orderClass?.[0]?.id
                    : '';
                  newCadData.orderConditions.material = materialClass?.some(
                    (opt) => opt.id === newCadData?.orderConditions?.material
                  )
                    ? newCadData?.orderConditions?.material
                    : materialClass?.length > 0
                    ? materialClass?.[0]?.id
                    : '';
                  let processType = ProcessType?.filter((i) => i.id === newCadData.orderConditions.process);
                  if (processMst && processMst?.length > 0) {
                    let processDetails = processMst?.[0]?.details?.processDetailTypes?.filter(
                      (i) => i.group === processType?.[0]?.group
                    );
                    newCadData.orderConditions.processDetail =
                      processDetails?.length === 0
                        ? 0
                        : processDetails?.some((opt) => opt.id === newCadData?.orderConditions?.processDetail)
                        ? newCadData?.orderConditions?.processDetail
                        : processDetails?.[0]?.id;
                  }
                  newCadData.orderConditions.tolerance = toleranceClass?.some(
                    (opt) => opt.id === newCadData?.orderConditions?.tolerance
                  )
                    ? newCadData?.orderConditions?.tolerance
                    : toleranceClass?.length > 0
                    ? toleranceClass?.[0]?.id
                    : '';

                  newCadData.orderConditions.weldFinishLevel = weldFinishLevel?.some(
                    (opt) => opt.id === newCadData?.orderConditions?.weldFinishLevel
                  )
                    ? newCadData?.orderConditions?.weldFinishLevel
                    : weldFinishLevel?.length > 0
                    ? weldFinishLevel?.[0]?.id
                    : '';

                  newCadData.orderConditions.finalPurpos = finalPurpos?.some(
                    (opt) => opt.id === newCadData?.orderConditions?.finalPurpos
                  )
                    ? newCadData?.orderConditions?.finalPurpos
                    : finalPurpos?.length > 0
                    ? finalPurpos?.[0]?.id
                    : '';

                  setCad3dDataLst((prevList) => [...prevList, newCadData]);
                  // 登録したファイル名を取得してアップロードファイル名を変更する
                  const updatedFileObj = new File([file], newCadData?.name || file.name, {
                    type: file.type,
                    lastModified: file.lastModified,
                  });
                  // 更新されたファイルで新しいオブジェクトを作成する
                  const newUploadedFileObj = {
                    id: newCadData?.id,
                    fileObj: updatedFileObj,
                    orderConditions: newCadData?.orderConditions,
                  };
                  // アップロードファイルを保存する
                  uploadedFiles.push(newUploadedFileObj);
                }
              } else {
                alert('登録に失敗しました。');
              }
            })
          );
          setIsUpload(true); // すべて完了したらステータス更新する
        }
      } catch (error) {
        setIsUpload(false); // エラー発生時
      }
    }
  };

  // ファイルがターゲット領域にドラッグ アンド ドロップされたときにドロップ イベントを処理する
  const handleDrop = (event) => {
    event.preventDefault();
    setDragging(false);
    const droppedFiles = Array.from(event.dataTransfer.files);

    const validFiles = droppedFiles?.filter((file) => {
      const fileExtension = file.name.toLowerCase().slice(file.name.lastIndexOf('.'));
      return AllowedExtensions.includes(fileExtension);
    });
    // 3Dファイルアップロード処理を呼出す
    handleFileUpload(validFiles);
  };

  // Excel ファイルを参照するためのファイル入力クリック イベントをトリガーする関数
  const browseExcelFiles = () => {
    if (excelFileRef.current) {
      excelFileRef.current.click();
    }
  };

  // レコードを選択する
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      // 選択して一時保存する
      const updatedRows = selectedRows?.map((row) => ({
        ...row,
        orderStatus: FileUploadStatus2D?.Blank,
      }));
      setSelectedRows(updatedRows);
      // ファイル名を取得する
      const selectedNames = selectedRows?.map((row) => row?.name);
      // 選択されていないファイル名を除外する
      setSelectedFileNames((prevState) => {
        const newNames = selectedNames;
        // 選択されていないファイル名を除外する
        return newNames;
      });
    },
    // 金額が0の場合、選択できるように設定する
    getCheckboxProps: (record) => ({
      disabled: record?.userEstimateTotalPrice <= 0,
    }),
  };

  // cad3dDataLst 状態内の特定の項目の注文条件を更新する
  const settingOrderConditions = (index, colNm, value) => {
    const upOrderConditions = [...cad3dDataLst];
    if (upOrderConditions[index]) {
      upOrderConditions[index].orderConditions[colNm] = value;
      if (colNm === 'process') {
        let processType = ProcessType?.filter((i) => i.id === value);
        if (processMst && processMst?.length > 0) {
          let processDetails = processMst?.[0]?.details?.processDetailTypes?.filter(
            (i) => i.group === processType?.[0]?.group
          );
          if (processDetails?.length > 0) {
            upOrderConditions[index].orderConditions.processDetailOptions = processDetails;
            upOrderConditions[index].orderConditions.processDetail = processDetails?.[0]?.id;
          }
        }
      }
    }
    setCad3dDataLst(upOrderConditions);
  };

  const getHubConnection = async () => {
    if (hubConnection === null) {
      try {
        // AudiのログインAPIを呼び出して、トークン情報を取得
        let audiLoginToken = await getHubConnectionToken();
        if (audiLoginToken && audiLoginToken != '') {
          setHubConnectionToken(audiLoginToken);
          // SignalRハブへの接続を確立する
          const newConnection = new signalR.HubConnectionBuilder()
            .withUrl(process.env.REACT_APP_HUB_CONNECTION_URL, {
              accessTokenFactory: () => audiLoginToken,
            })
            .withAutomaticReconnect()
            .build();
          newConnection
            .start()
            .then(() => {
              console.log('SignalR Connected.');
              setHubConnection(newConnection);
            })
            .catch((err) => console.error('Connection Error:', err));

          return () => {
            if (newConnection) {
              newConnection.stop();
            }
          };
        }
      } catch (error) {
        console.error('コネクション情報取得が失敗した場合 : ', error);
      }
    }
  };

  // getHubConnection関数を呼び出してハブへの接続を確立する
  useEffect(() => {
    getHubConnection();
  }, []);

  // 自動見積できない加工であることが判明したタイミングにはゼロフォー運用者への通知
  // 自動見積判断処理
  const getIsAutoEstimate = (cad3dData, isEstimateData = false, estimateData = null) => {
    let estimate = [];
    if (Object.keys(calParameters)?.length > 0) {
      let partnerCompSetting = calParameters?.partnerCompanySetting;
      if (Object.keys(partnerCompSetting)?.length > 0) {
        let firstOrderSetting = partnerCompSetting?.firstOrder;
        let repeatOrderSetting = partnerCompSetting?.repeatOrder;
        let inputOrderConditions = cad3dData?.orderConditions;
        // 発注分類情報取得
        // let orderInfo = orderClass?.filter((item) => item.id === inputOrderConditions?.order);
        // let orderNm = ORDER_FITST;
        // if (orderInfo && orderInfo?.length > 0) {
        //   orderNm = orderInfo[0].name;
        // }
        let orderSetting = null; //デフォルト値設定
        orderSetting = firstOrderSetting; // 単発対応設定項目
        // if (orderNm === ORDER_FITST && firstOrderSetting?.orderFlg) {

        // } else if (orderNm === ORDER_REPEAT && firstOrderSetting?.orderFlg) {
        //   orderSetting = repeatOrderSetting; // リピート対応設定項目
        // }
        // PMX情報からのデータチェック
        // if (isEstimateData) {
        //   // 最大サイズチェック
        //   // 最大サイズX
        //   if (cad3dData.sizeX > orderSetting.maxX) {
        //     return false;
        //   }
        //   // 最大サイズY
        //   if (cad3dData.sizeY > orderSetting.maxY) {
        //     return false;
        //   }
        //   // 最大サイズZ
        //   if (cad3dData.sizeZ > orderSetting.maxZ) {
        //     return false;
        //   }
        //   // 最大曲げ長さチェック
        //   let bendingProcessId = calParameters?.process?.filter(
        //     (i) =>
        //       !i.isDeleted &&
        //       i.info.isUsed &&
        //       i.class === ServiceClass.SheetMetal &&
        //       i.workType === WorkType.SmBending
        //   )?.[0]?.id;
        //   let orderSettingMaxBendingLens = orderSetting.maxBendingLen?.filter((i) => i.isUsed);
        //   orderSettingMaxBendingLens = orderSettingMaxBendingLens?.map((length) => length.name.replace(/mm/g, ''));
        //   for (const iq3 of estimateData?.esIq3Info) {
        //     let curProcessInput = iq3.processInput?.filter((i) => i.processId === bendingProcessId);
        //     if (curProcessInput?.length > 0 && curProcessInput?.[0]?.details?.length > 0) {
        //       const isOverMax = orderSettingMaxBendingLens?.some(
        //         (maxLen) => Number(maxLen) < Number(curProcessInput?.[0]?.details?.[0]?.bendingData.maxBendLength)
        //       );
        //       if (isOverMax) {
        //         estimate = false;
        //         break;
        //       }
        //     }
        //   }
        // } else {
        // 個数チェック
        if (
          !(
            orderSetting.quantityFrom <= inputOrderConditions?.quantity &&
            orderSetting.quantityTo >= inputOrderConditions?.quantity
          )
        ) {
          estimate.push(
            '・個数が対応外です。' +
              orderSetting.quantityFrom +
              '～' +
              orderSetting.quantityTo +
              'の範囲で入力してください。'
          );
          //return false;
        }
        // 材質チェック
        // 材質の区分をチェックし、板厚をチェック
        let inputMaterial = calParameters.materialTypeIq3?.filter(
          (i) => !i.isDeleted && i.info.isUsed && i.id === inputOrderConditions.material
        );
        let inputMaterialTypeId = inputMaterial?.[0]?.materialTypeId;
        let usedMaterialType = orderSetting.materialTypeList?.filter((i) => i.materialTypeId === inputMaterialTypeId);
        // 対応できる材質区分の場合、板厚をチェック
        if (usedMaterialType && usedMaterialType?.length > 0) {
          let inputMaterialIq3 = calParameters.materialIq3?.filter(
            (i) => !i.isDeleted && i.info.isUsed && i.materialTypeIq3Id === inputOrderConditions.material
          );
          let usedThickness = inputMaterialIq3?.[0]?.info.thick;
          if (
            !(usedMaterialType[0].thicknessFrom <= usedThickness && usedMaterialType[0].thicknessTo >= usedThickness)
          ) {
            estimate.push('・指定の材質は対応外です。別の材質を選択してください。');
            // return false;
          }
        } else {
          return false;
        }
        // 公差チェック
        let inputTolerance = toleranceClass?.filter((i) => i.id === inputOrderConditions.tolerance);
        let usedTolerance = orderSetting.diff?.filter((i) => i.name === inputTolerance?.[0]?.name && i.isUsed);
        if (usedTolerance && usedTolerance?.length === 0) {
          estimate.push('・指定の公差は対応外です。別の公差を選択してください。');
          // return false;
        }
        // 希望納期
        let inputDeliveryTime = dayjs(inputOrderConditions.deliveryTimeCls);
        // 仕上チェック
        // let inputWeldFinish = weldFinishLevel?.filter((i) => i.id === inputOrderConditions.weldFinishLevel);
        // let usedWeldFinish = orderSetting.weldFinish?.filter(
        //   (i) => i.name === inputWeldFinish?.[0]?.name && i.isUsed
        // );
        // if (usedWeldFinish && usedWeldFinish?.length === 0) {
        //   return false;
        // } else {
        //   if (
        //     usedWeldFinish?.[0]?.name === MIRROR_SURFACE ||
        //     usedWeldFinish?.[0]?.name === PICKLING ||
        //     usedWeldFinish?.[0]?.name === HL
        //   ) {
        //     let supportFrom = dayjs(usedWeldFinish?.[0]?.supportFrom);
        //     let supportTo = dayjs(usedWeldFinish?.[0]?.supportTo);
        //     const isValidDate = inputDeliveryTime.isBetween(supportFrom, supportTo, null, '[]');
        //     if (!isValidDate) {
        //       return false;
        //     }
        //   }
        // }
        // 利用箇所チェック（最終用途）
        let inputFinalPurpos = finalPurpos?.filter((i) => i.id === inputOrderConditions.finalPurpos);
        let usedPlace = orderSetting.usedPlace?.filter((i) => i.name === inputFinalPurpos?.[0]?.name && i.isUsed);
        if (usedPlace && usedPlace?.length === 0) {
          estimate.push('・指定の最終用途は対応外です。別の最終用途を選択してください。');
          // return false;
        }
        // 希望納期が協力会社設定画面の対応可能納期に指定した範囲に収まっていない場合、自動見積対象外とする
        // let deliveryDateFrom = dayjs(orderSetting.deliveryDateFrom);
        // let deliveryDateTo = dayjs(orderSetting.deliveryDateTo);
        // const isValidDate = inputDeliveryTime.isBetween(deliveryDateFrom, deliveryDateTo, null, '[]');
        // if (!isValidDate) {
        //   estimate.push('・指定の希望納期は対応外です。別の日付を選択してください。');
        //   // return false;
        // }
        // 納品可能エリアチェック
        let loginInfo = JSON.parse(decodeURI(localStorage?.getItem(IQxRealGoku_LoginUser)));
        if (loginInfo) {
          let userPrefecture = loginInfo?.prefecture;
          let usedPlace = orderSetting.deliveryArea?.filter((i) => i.name === userPrefecture && i.isUsed);
          if (usedPlace && usedPlace?.length === 0) {
            estimate.push('・ご登録の住所は納品対象外です。');
            // return false;
          }
        }
        //　処理チェック
        // let processType = ProcessType?.filter((i) => i.id === inputOrderConditions.process);
        // let inputProcessDetailName = processMst?.[0]?.details?.processDetailTypes?.filter(
        //   (i) => i.id === inputOrderConditions.processDetail
        // )?.[0]?.name;
        // メッキ処理チェック
        // if (processType?.[0]?.group === WorkTypeGroup.SmPlating) {
        //   let usedPlatingProcess = orderSetting.platingProcess?.filter(
        //     (i) => i.name === inputProcessDetailName && i.isUsed
        //   );
        //   if (usedPlatingProcess && usedPlatingProcess?.length === 0) {
        //     return false;
        //   } else {
        //     let supportFrom = dayjs(usedPlatingProcess?.[0]?.supportFrom);
        //     let supportTo = dayjs(usedPlatingProcess?.[0]?.supportTo);
        //     const isValidDate = inputDeliveryTime.isBetween(supportFrom, supportTo, null, '[]');
        //     if (!isValidDate) {
        //       return false;
        //     }
        //   }
        // } else if (processType?.[0]?.group === WorkTypeGroup.SmCoating) {
        //   // 塗装処理チェック
        //   let usedPaintingProcess = orderSetting.paintingProcess?.filter(
        //     (i) => i.name === inputProcessDetailName && i.isUsed
        //   );
        //   if (usedPaintingProcess && usedPaintingProcess?.length === 0) {
        //     return false;
        //   } else {
        //     let supportFrom = dayjs(usedPaintingProcess?.[0]?.supportFrom);
        //     let supportTo = dayjs(usedPaintingProcess?.[0]?.supportTo);
        //     const isValidDate = inputDeliveryTime.isBetween(supportFrom, supportTo, null, '[]');
        //     if (!isValidDate) {
        //       return false;
        //     }
        //   }
        // }
        // }
      }
    }
    return estimate;
  };

  // 見積ボタンを押下された時
  const estimateBtnProcess = async (targetData, i) => {
    let cad3dData = targetData;
    try {
      // 「見積金額算出中（しばらくお待ちください）」ステータスを画面に表示
      await UpdateEstimateResult(cad3dData, EstimateResultClass.EstimateProcessing, false);
      // 自動見積対象の場合、見積処理を行う
      // 見積するPMX情報を取得
      let cadData = null;
      let headers = getRequestHeader();
      const cad3PMXUrl =
        `${process.env.REACT_APP_BACKEND_API_URL}Cad3dData/GetCadDataById` + '?cad3dId=' + cad3dData?.id;
      const cad3PMXResponse = await axios.get(cad3PMXUrl, { headers });
      if (cad3PMXResponse.status === 200) {
        cadData = cad3PMXResponse.data?.data;
      }
      // 自動見積判断
      let autoEstimateErr = getIsAutoEstimate(cad3dData);
      if (autoEstimateErr?.length > 0) {
        // 自動見積できない加工であることが判明したタイミングにはゼロフォー運用者への通知
        // WebEstimateの受注設定により自動見積できない場合
        cad3dData.orderConditions.priority = PRIORITY_LOW;
        await UpdateEstimateResult(cad3dData, EstimateResultClass.AutoEstimateFail, true, autoEstimateErr);
      } else {
        // 見積するPMX情報がある場合、見積処理を行う。
        if (cadData !== null && cadData?.cad3dDataId > 0) {
          // DBに保存していたデータを見積もる
          calEstimatePMXData(cad3dData, cadData);
        } else {
          // 見積するPMX情報がない場合、AudiからPMX情報を取得
          // Audiへ3Dファイルアップロードのため、RealGokuのストレージに保持した3Dファイル情報を取得
          const cad3dFormData = new FormData();
          cad3dFormData.append('fileName', cad3dData?.name);
          cad3dFormData.append('loginId', cad3dData?.loginId);
          const cad3dFileUrl = `${process.env.REACT_APP_BACKEND_API_URL}Cad3dData/GetCad3dFile`;
          const cad3dFileResponse = await axios.post(cad3dFileUrl, cad3dFormData, {
            headers: headers,
            responseType: 'blob',
          });
          // Audiへ3Dファイルアップロードするための3Dファイル情報取得が成功した場合
          if (cad3dFileResponse.status === 200) {
            // 見積対象データにPMX情報がない場合、Audiへ3DファイルアップロードしてPMX情報を取得
            if (cad3dFileResponse.data) {
              // 命名規則：yyyymmddhhmmssfff+ランダム生成された3桁の数字+拡張子
              let cad3dFileName = generateTimestamp() + getFileExtension(cad3dData?.name);
              // Blob から File オブジェクトを作成
              let cad3dFile = new File([cad3dFileResponse.data], cad3dFileName, {
                type: 'application/octet-stream',
              });
              if (!hubConnection || !cad3dFile) {
                await UpdateEstimateResult(cad3dData, EstimateResultClass.EstimateFail, false);
              } else {
                if (hubConnection.state === signalR.HubConnectionState.Disconnected) {
                  await hubConnection.start();
                }
                // Audiへ3Dファイルアップロード
                const cad3dFileFormData = new FormData();
                cad3dFileFormData.append('file', cad3dFile);
                cad3dFileFormData.append('parentDirectory', process.env.REACT_APP_AUDI_FILE_UPLOAD_URL);
                const generatePMXURL = process.env.REACT_APP_AUDI_BACKEND_API_GENERATE_PMX_URL;
                const generatePMXRes = await axios.post(generatePMXURL, cad3dFileFormData, {
                  headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${hubConnectionToken}`,
                  },
                  reportProgress: true,
                  responseType: 'json',
                });
                // Audiへ3Dファイルアップロードが成功してPMXファイルが生成された場合、
                if (generatePMXRes.status === 200) {
                  //AudiからPMXファイルと画像を取得する（Audiが提供するAPIをコールすることにより3Dファイルと画像を取得する）
                  // PMX情報取得API（AudiからPMX取得）
                  let route = '?filePath=' + generatePMXRes?.data?.data?.fullPath;
                  const audiPMXUrl = process.env.REACT_APP_AUDI_BACKEND_API_DOWNLOAD_PMX_URL + route;
                  const audiPMXResponse = await axios.get(audiPMXUrl, {
                    headers: {
                      Authorization: `Bearer ${hubConnectionToken}`,
                    },
                    responseType: 'blob',
                  });
                  // PMXファイルダウンロードが成功した場合
                  if (audiPMXResponse.status === 200) {
                    let audiPMXFile = audiPMXResponse?.data;
                    if (audiPMXFile) {
                      // StreamをBlobに変換
                      const blob = new Blob([audiPMXFile], { type: 'application/octet-stream' });
                      const base64String = await blobToBase64(blob);
                      const pmxFileNm = cad3dData?.name + '.PMX';
                      const pmxFormData = new FormData();
                      pmxFormData.append('cad3dId', cad3dData.id);
                      pmxFormData.append('pmxBase64String', base64String);
                      pmxFormData.append('fileName', pmxFileNm);
                      const pmxUrl = `${process.env.REACT_APP_BACKEND_API_URL}Cad3dData/GetPMXFileData`;
                      await axios
                        .post(pmxUrl, pmxFormData, { headers })
                        .then(async (pmxDataResponse) => {
                          // AudiからのPMXファイルを解析した結果
                          cadData = pmxDataResponse?.data?.data;
                          // 解析したデータを見積もる
                          calEstimatePMXData(cad3dData, cadData);
                        })
                        .catch(async (error) => {
                          // PMX解析情報取得失敗の場合、Audiから受領したタイミング、ゼロフォー運用者への通知
                          // 異常の場合、
                          //見積処理失敗の場合、失敗ステータスを画面に表示
                          await UpdateEstimateResult(cad3dData, EstimateResultClass.EstimateFail, false);
                        });
                    }
                  } else {
                    // PMXファイルダウンロードが失敗した場合
                    // PMXファイルダウンロード失敗の通知をAudiから受領したタイミング、ゼロフォー運用者への通知
                    //見積処理失敗の場合、失敗ステータスを画面に表示
                    await UpdateEstimateResult(cad3dData, EstimateResultClass.EstimateFail, false);
                  }
                } else {
                  // Audiへ3Dファイルアップロードが失敗した場合
                  // Audiへ3Dファイルアップロード失敗の通知をAudiから受領したタイミング、ゼロフォー運用者への通知
                  //見積処理失敗の場合、失敗ステータスを画面に表示
                  await UpdateEstimateResult(cad3dData, EstimateResultClass.EstimateFail, false);
                }
              }
            }
          } else {
            // Audiへ3Dファイルアップロードするための3Dファイル情報取得が失敗した場合
            // 3Dファイル情報取得処理失敗の通知をAudiから受領したタイミング、ゼロフォー運用者への通知
            //見積処理失敗の場合、失敗ステータスを画面に表示
            await UpdateEstimateResult(cad3dData, EstimateResultClass.EstimateFail, false);
          }
        }
      }
    } catch (error) {
      //見積処理失敗の場合、失敗ステータスを画面に表示
      // 見積データ計算の際にエラーが発生した場合ゼロフォー運用者への通知
      await UpdateEstimateResult(cad3dData, EstimateResultClass.EstimateFail, false);
    }
  };

  // PMX情報で見積処理を行う。
  const calEstimatePMXData = async (cad3dData, cadData) => {
    try {
      if (cadData != null) {
        // 見積計算のため見積データ作成
        let newEstimateInfo = getNewEstimateInfo(cadData?.details?.length, calParameters, cad3dData?.orderConditions);
        if (newEstimateInfo) {
          let estimateData = convertCadDataToEstimateData(newEstimateInfo, cadData);
          // 自動見積判断（最大曲げ長さチェック、最大サイズチェック）
          // let autoEstimateErr = getIsAutoEstimate(cad3dData, true, estimateData);
          // if (autoEstimateErr?.length > 0) {
          //   // 自動見積できない加工であることが判明したタイミングにはゼロフォー運用者への通知
          //   // WebEstimateの受注設定により自動見積できない場合
          //   cad3dData.orderConditions.priority = PRIORITY_LOW;
          //   await UpdateEstimateResult(cad3dData, EstimateResultClass.AutoEstimateFail, true, autoEstimateErr);
          // } else {
          // 見積計算
          estimateData = calEstimateInfo(estimateData, cad3dData?.orderConditions);
          if (estimateData) {
            // 見積もった結果をDBに保持するため、データ準備
            let upCad3dData = cad3dData;
            upCad3dData.estimateResult = EstimateResultClass.DisplayEstimateResult; //金額表示フラグ
            upCad3dData.estimateTotalPrice = estimateData?.estimateProducts?.estimateTotalPrice;
            upCad3dData.estimateUnitPrice = estimateData?.estimateProducts?.estimateUnitPrice;
            // highのものはWeb画面上で見積結果を表示、
            // if (upCad3dData?.orderConditions?.priority == EstimatePriorityClass.High) {
            // 1個あたりの単価が10,000円までの場合は見積金額を画面に表示
            if (upCad3dData.estimateUnitPrice < EST_FAIL_AMT_LIMIT) {
              // 10001
              upCad3dData.userEstimateTotalPrice = upCad3dData?.estimateTotalPrice;
              upCad3dData.userEstimateUnitPrice = upCad3dData?.estimateUnitPrice;
            } else {
              // 1個あたりの単価が10,001円以上の場合は以下の文言を表示してください。
              // 自動見積りができない加工です。
              upCad3dData.estimateResult = EstimateResultClass.AutoEstimateFail; // 自動見積りができない加工です。
              upCad3dData.userEstimateTotalPrice = 0;
              upCad3dData.userEstimateUnitPrice = 0;
            }
            // }
            // 見積もった結果をDBに保持n

            const upCad3dDataReq = {
              upCad3d: {
                id: upCad3dData?.id,
                estimateUnitPrice: upCad3dData?.estimateUnitPrice,
                estimateTotalPrice: upCad3dData?.estimateTotalPrice,
                userEstimateUnitPrice: upCad3dData?.userEstimateUnitPrice,
                userEstimateTotalPrice: upCad3dData?.userEstimateTotalPrice,
                estimateResult: upCad3dData?.estimateResult,
                orderConditions: upCad3dData?.orderConditions,
              },
              estimateProducts: estimateData?.estimateProducts,
              esIq3Info: estimateData?.esIq3Info,
              upCad3dLoginId: upCad3dData?.loginId,
            };
            let headers = getRequestHeader('application/json');
            const saveApiUrl = `${process.env.REACT_APP_BACKEND_API_URL}Cad3dData/SaveEstimateData`;
            const saveApiResponse = await axios.post(saveApiUrl, upCad3dDataReq, { headers });
            // 見積データ保持が成功した場合
            if (saveApiResponse.status === 200) {
              let upCad3dData = saveApiResponse.data?.data;
              if (upCad3dData && upCad3dData?.length > 0) {
                let userEstimateTotalPrice = upCad3dData?.[0]?.userEstimateTotalPrice;
                userEstimateTotalPrice = userEstimateTotalPrice ? Math.round(userEstimateTotalPrice) : 0;
                let userEstimateUnitPrice = upCad3dData?.[0]?.userEstimateUnitPrice;
                userEstimateUnitPrice = userEstimateUnitPrice ? Math.round(userEstimateUnitPrice) : 0;
                setCad3dDataLst((prevCad3dData) =>
                  prevCad3dData?.map((f, i) =>
                    f.id === upCad3dData?.[0]?.id
                      ? {
                          ...f,
                          userEstimateTotalPrice: userEstimateTotalPrice,
                          userEstimateUnitPrice: userEstimateUnitPrice,
                          estimateResult: upCad3dData?.[0]?.estimateResult,
                          sizeX: upCad3dData?.[0]?.sizeX,
                          sizeY: upCad3dData?.[0]?.sizeY,
                          sizeZ: upCad3dData?.[0]?.sizeZ,
                        }
                      : f
                  )
                );
              }
            } else {
              // 見積データ保持の際に失敗の場合、Audiから受領したタイミング、ゼロフォー運用者への通知
              // 見積データ計算の際にエラーが発生した場合ゼロフォー運用者への通知
              await UpdateEstimateResult(cad3dData, EstimateResultClass.EstimateFail, false);
            }
          }
          // }
        }
      }
    } catch (error) {
      //見積処理失敗の場合、失敗ステータスを画面に表示
      // 見積データ計算の際にエラーが発生した場合ゼロフォー運用者への通知
      await UpdateEstimateResult(cad3dData, EstimateResultClass.EstimateFail, false);
    }
  };

  // リスト画面の3Dファイル削除操作を行った時
  const deleteCad3D = async (targetData, i) => {
    setDeleteCad3DId(targetData?.id);
    setIsDelete(true);
  };

  // リスト画面の3Dファイル削除操作を行う
  const deleteOk = () => {
    const reqData = { cad3dId: deleteCad3DId };
    const apiUrl = `${process.env.REACT_APP_BACKEND_API_URL}Cad3dData/DeleteCad3dData`;
    const headers = getRequestHeader('application/json');
    axios
      .put(apiUrl, reqData, { headers })
      .then(async (response) => {
        let upCad3dData = response?.data?.data;
        if (upCad3dData && upCad3dData?.length > 0) {
          settingCad3dDatas();
        }
      })
      .catch(async (error) => {
        // 異常の場合、
        ErrorMessage(error.message);
        return error;
      });
    setDeleteCad3DId(0);
    setIsDelete(false);
  };

  // リスト画面の3Dファイル削除操作をキャンセル
  const deleteCancel = () => {
    setDeleteCad3DId(0);
    setIsDelete(false);
  };

  // ページネーションでページサイズの変更する
  const handlePageSizeChange = async (current, pagesize) => {
    setPageSize(pagesize);
  };

  // テーブルのページネーションの変更を処理する
  const handleTableChange = async (pagination, filters, sorter) => {
    setLoading(true); // Waitingダイアログを表示にする
    let pageBefore = pagination?.current - 1;
    let cad3dDatas = await getCad3dDatas(pagination?.pageSize, pageBefore, 'ASC', '', {});
    if (cad3dDatas) {
      setTotalCount(cad3dDatas?.totalCount);
      cad3dDatas = cad3dDatas?.data;
      setCad3dDataLst(cad3dDatas);
    }
    setLoading(false); // Waitingダイアログを表示にする
  };

  // 見積結果種類を更新する
  const UpdateEstimateResult = async (cad3dData, estResult, orderFlg, autoEstimateErr = []) => {
    let headers = getRequestHeader('application/json');
    const reqData = {
      cad3dId: cad3dData.id,
      orderConditions: cad3dData.orderConditions,
      estimateResult: estResult,
      orderFlg: orderFlg,
      autoEstimateErr: autoEstimateErr,
    };
    const apiUrl = `${process.env.REACT_APP_BACKEND_API_URL}Cad3dData/UpdateEstimateResult`;
    const processingStatusResponse = await axios.put(apiUrl, reqData, { headers });
    if (processingStatusResponse?.status === 200) {
      let processingStatus = processingStatusResponse?.data?.data;
      if (processingStatus && processingStatus?.length > 0) {
        processingStatus = processingStatus[0];
        setCad3dDataLst((prevCad3dData) =>
          prevCad3dData?.map((f, i) =>
            f.id === cad3dData.id
              ? {
                  ...f,
                  estimateResult: processingStatus?.estimateResult,
                  autoEstimateErrDetail: autoEstimateErr,
                }
              : f
          )
        );
        if (
          processingStatus?.estimateResult === EstimateResultClass.AutoEstimateFail ||
          processingStatus?.estimateResult === EstimateResultClass.EstimateFail
        ) {
          // 管理者へメール通知
          const mailApiUrl = `${process.env.REACT_APP_BACKEND_API_URL}Cad3dData/SendMailToZFOperatorByUpdateEstimateResult`;
          await axios.put(mailApiUrl, reqData, { headers });
        }
      }
    }
  };

  // アップロードした３Dファイルリストと状況
  const columns = [
    {
      title: 'No',
      key: 'id',
      width: 65,
      fixed: 'left',
      render: (_, __, index) => (
        <div style={{ textAlign: 'right', paddingRight: 10 }}>{(cusCurrentPage - 1) * pageSize + index + 1}</div>
      ),
    },
    {
      title: '画像',
      dataIndex: 'fileImg',
      width: 110,
      fixed: 'left',
      render: (text, record, i) => (
        <div style={{ display: 'grid', alignItems: 'left', paddingLeft: 20 }}>
          <Typography.Text style={{ fontSize: 13 }} id={'typographyTxt'} ellipsis={{ tooltip: [record.sizeX] }}>
            X : {record.sizeX}
          </Typography.Text>
          <Typography.Text style={{ fontSize: 13 }} id={'typographyTxt'} ellipsis={{ tooltip: [record.sizeX] }}>
            Y : {record.sizeY}
          </Typography.Text>
          <Typography.Text style={{ fontSize: 13 }} id={'typographyTxt'} ellipsis={{ tooltip: [record.sizeX] }}>
            Z : {record.sizeZ}
          </Typography.Text>
        </div>
      ),
    },
    {
      title: 'ファイル名',
      dataIndex: 'name',
      width: 205,
      fixed: 'left',
      render: (record) => (
        <div style={{ textAlign: 'left', paddingLeft: 2 }}>
          <Typography.Text style={{ fontSize: 13 }} id={'typographyTxt'} ellipsis={{ tooltip: [record] }}>
            {record}
          </Typography.Text>
        </div>
      ),
    },
    {
      title: '状態',
      dataIndex: 'status',
      width: 70,
      fixed: 'left',
      render: (status) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Tooltip
            title={status === 0 ? UploadStatus?.find((com) => com?.id === status)?.name : ''}
            placement="top"
            classNames={{ root: 'tooltip-text' }}
          >
            {/** アップロード中の場合 */}
            <div
              className={status === 0 ? 'circle-status-blue' : 'circle-status'}
              style={{ display: status === 0 ? '' : 'none' }}
            ></div>
          </Tooltip>
          <Tooltip
            title={status === 1 ? UploadStatus?.find((com) => com?.id === status)?.name : ''}
            placement="top"
            classNames={{ root: 'tooltip-text' }}
          >
            {/** アップロード失敗の場合 */}
            <div
              className={status === 1 ? 'circle-status-red' : 'circle-status'}
              style={{ display: status === 1 ? '' : 'none' }}
            ></div>
          </Tooltip>
          <Tooltip
            title={status === 2 ? UploadStatus?.find((com) => com?.id === status)?.name : ''}
            placement="top"
            classNames={{ root: 'tooltip-text' }}
          >
            {/** アップロード成功でもファイル容量オーバーの場合 */}
            <div
              className={status === 2 ? 'circle-status-orange' : 'circle-status'}
              style={{ display: status === 2 ? '' : 'none' }}
            ></div>
          </Tooltip>
          <Tooltip
            title={status === 3 ? UploadStatus?.find((com) => com?.id === status)?.name : ''}
            placement="top"
            classNames={{ root: 'tooltip-text' }}
          >
            {/** アップロード成功の場合 */}
            <div
              className={status === 3 ? 'circle-status-green' : 'circle-status'}
              style={{ display: status === 3 ? '' : 'none' }}
            ></div>
          </Tooltip>
        </div>
      ),
    },
    {
      title: '見積金額',
      width: 165,
      fixed: 'left',
      render: (text, record, i) => (
        <div>
          {(() => {
            const estimateComp = [];
            let estimateTotalPrice = record?.userEstimateTotalPrice
              ? Math.round(record?.userEstimateTotalPrice).toLocaleString()
              : 0;
            let estimateUnitPrice = record.userEstimateUnitPrice
              ? Math.round(record?.userEstimateUnitPrice).toLocaleString()
              : 0;
            {
              record.estimateResult === EstimateResultClass.DisplayEstimateResult
                ? estimateComp.push(
                    <>
                      <div key={`${record?.id}-total`} style={{ textAlign: 'center' }}>
                        <Typography.Text
                          style={{ fontSize: 13, fontWeight: 'bold', color: record?.isModifiedPrice ? 'red' : '' }}
                          id={'typographyTxt'}
                          ellipsis={{ tooltip: [estimateTotalPrice + '円'] }}
                        >
                          {estimateTotalPrice + '円'}
                        </Typography.Text>
                      </div>
                      <div key={`${record?.id}-unit`} style={{ textAlign: 'center' }}>
                        <Typography.Text
                          style={{ fontSize: 13, fontWeight: 'bold', color: record?.isModifiedPrice ? 'red' : '' }}
                          id={'typographyTxt'}
                          ellipsis={{
                            tooltip: ['（' + estimateUnitPrice + '円/' + '1個）'],
                          }}
                        >
                          {'（' + estimateUnitPrice + '円/' + '1個）'}
                        </Typography.Text>
                      </div>
                    </>
                  )
                : estimateComp.push(
                    <>
                      <div key={`${record?.id}-status`} style={{ display: 'flex', justifyContent: 'center' }}>
                        <Tooltip
                          title={EstimateType[record?.estimateResult]}
                          placement="top"
                          classNames={{ root: 'tooltip-text' }}
                          // overlayClassName="tooltip-text"
                        >
                          <ExclamationCircleOutlined
                            key={`${record?.id}-icon-display`}
                            style={{
                              color: '#0958d9d1',
                              display: record?.estimateResult === EstimateResultClass.DisplayAmt ? '' : 'none',
                            }}
                            className="estimate-amt-icon"
                          />
                          <RedoOutlined
                            key={`${record?.id}-icon-processing`}
                            style={{
                              color: '#4db6ac',
                              display: record?.estimateResult === EstimateResultClass.EstimateProcessing ? '' : 'none',
                            }}
                            className="circle-status-blue"
                          />
                          <WarningOutlined
                            key={`${record?.id}-icon-fail`}
                            style={{
                              color: '#ffb74d',
                              display: record?.estimateResult === EstimateResultClass.AutoEstimateFail ? '' : 'none',
                            }}
                            className="estimate-amt-icon"
                          />
                          <CloseOutlined
                            key={`${record?.id}-icon-close`}
                            style={{
                              color: '#d32f2f',
                              display: record?.estimateResult === EstimateResultClass.EstimateFail ? '' : 'none',
                            }}
                            className="estimate-amt-icon"
                          />
                        </Tooltip>
                      </div>
                      <div
                        key={`${record?.id}-detail`}
                        style={{
                          display: record?.estimateResult === EstimateResultClass.AutoEstimateFail ? 'flex' : 'none',
                          justifyContent: 'center',
                          marginTop: 7,
                        }}
                      >
                        <Popover
                          content={
                            <div className="err-detail-popover-div">
                              <label>
                                以下理由から自動見積できない加工です。自動見積をご希望の場合は見積条件を変更の上再度お試しください。
                              </label>
                              <br />
                              {record?.autoEstimateErrDetail?.map((item, index) => (
                                <>
                                  <label htmlFor={`item-${index}`}>{item}</label>
                                  <br />
                                </>
                              ))}
                            </div>
                          }
                          trigger="click"
                          overlayClassName="err-detail-popover"
                          autoAdjustOverflow={true}
                          destroyTooltipOnHide={true}
                          // getPopupContainer={(trigger) => trigger.parentElement}
                        >
                          <a className="err-detail-popover-a">詳細を見る</a>
                        </Popover>
                      </div>
                    </>
                  );
            }
            return estimateComp;
          })()}
        </div>
      ),
    },
    {
      title: '操作',
      width: 90,
      fixed: 'left',
      render: (text, record, i) => (
        <div style={{ textAlign: 'center', fontSize: 13 }}>
          <a target="_blank">
            <Button
              id={'estimate-btn-' + record?.id}
              disabled={
                (record?.status === 3 &&
                  !record?.isModifiedPrice &&
                  record?.orderConditions?.process > 0 && // アップロード成功と見積可能な状態の場合、ボタン活性
                  (record?.estimateResult === EstimateResultClass.DisplayAmt ||
                    record?.estimateResult === EstimateResultClass.AutoEstimateFail ||
                    record?.estimateResult === EstimateResultClass.DisplayEstimateResult ||
                    record?.estimateResult === EstimateResultClass.EstimateFail)) ||
                (record?.status === 2 &&
                  !record?.isModifiedPrice &&
                  record?.orderConditions?.process > 0 &&
                  record?.estimateResult === EstimateResultClass.DisplayAmt) // ファイル容量オーバーでもアップロード成功の場合、ボタン活性
                  ? false
                  : true
              }
              className={'estimate-cond-btn'}
              onClick={(e) => {
                estimateBtnProcess(record, i);
              }}
            >
              見積
            </Button>
          </a>
        </div>
      ),
    },
    {
      title: '条件複製',
      width: 135,
      fixed: 'left',
      className: 'upload-table-border',
      render: (text, record, i) => (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div
            id={'copy-paste-div' + i}
            className={record?.id === copyEstimateCondition?.id ? 'copy-paste-div-selected' : 'copy-paste-div'}
            onClick={(e) => {
              setCopyEstimateCondition(JSON.parse(JSON.stringify(record)));
            }}
          >
            <Image preview={false} width={18} src={copy} className="copy-paste-div-pointer"></Image>
            <label className="copy-paste-div-pointer">コピー</label>
          </div>
          <div
            className="copy-paste-div copy-paste-div-mrleft"
            onClick={(e) => {
              if (Object.keys(copyEstimateCondition)?.length > 0) {
                let pasteEstimateCondition = JSON.parse(JSON.stringify(copyEstimateCondition));
                const upOrderConditions = [...cad3dDataLst];
                if (upOrderConditions[i]) {
                  upOrderConditions[i].orderConditions = pasteEstimateCondition?.orderConditions;
                }
                setCad3dDataLst(upOrderConditions);
              }
            }}
          >
            <Image preview={false} width={18} src={paste} className="copy-paste-div-pointer"></Image>
            <label className="copy-paste-div-pointer">ペースト</label>
          </div>
        </div>
      ),
    },
    {
      title: '個数',
      width: 85,
      render: (text, record, i) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Input
            style={{ height: 30, fontSize: 12, width: 70, textAlign: 'right', marginLeft: 5 }}
            placeholder="1"
            value={record?.orderConditions?.quantity}
            onChange={(e) => {
              const value = e.target.value;
              if (/^\d*$/.test(value)) {
                settingOrderConditions(i, 'quantity', value);
              }
            }}
            onBlur={(e) => {
              let value = Number(record?.orderConditions?.quantity) || 1;
              if (value < 1) value = 1;
              settingOrderConditions(i, 'quantity', value);
            }}
          />
        </div>
      ),
    },
    {
      title: '発注分類',
      width: 90,
      render: (text, record, i) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Select
            style={{ width: 80 }}
            popupClassName="popup-select-input-style"
            popupMatchSelectWidth={false}
            defaultValue={0}
            id={'order' + i}
            name={'order' + i}
            value={
              orderClass?.some((opt) => opt.id === record?.orderConditions?.order)
                ? record?.orderConditions?.order
                : orderClass?.length > 0
                ? orderClass?.[0]?.id
                : ''
            }
            onChange={(e) => {
              settingOrderConditions(i, 'order', e);
            }}
          >
            {orderClass?.map((item, index) => (
              <Select.Option key={index} id={'order' + index} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </div>
      ),
    },
    {
      title: '材質',
      width: 120,
      render: (text, record, i) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 15 }}>
          <Select
            style={{ width: 110 }}
            popupClassName="popup-select-input-style"
            popupMatchSelectWidth={false}
            defaultValue={0}
            id={'material' + i}
            name={'material' + i}
            value={
              materialClass?.some((opt) => opt.id === record?.orderConditions?.material)
                ? record?.orderConditions?.material
                : materialClass?.length > 0
                ? materialClass?.[0]?.id
                : ''
            }
            onChange={(e) => {
              settingOrderConditions(i, 'material', e);
            }}
          >
            {materialClass?.map((item, index) => (
              <Select.Option key={index} id={'material' + index} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </div>
      ),
    },
    {
      title: '処理',
      width: 225,
      render: (text, record, i) => (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Select
            style={{
              width: 80,
              // border: record?.orderConditions.process === 0 ? '1px solid #ff000085' : '',
              // borderRadius: 4,
            }}
            popupClassName="popup-select-input-style"
            popupMatchSelectWidth={false}
            defaultValue={0}
            id={'process' + i}
            name={'process' + i}
            value={record?.orderConditions.process}
            onChange={(e) => {
              settingOrderConditions(i, 'process', e);
            }}
          >
            {ProcessType?.map((item, index) => (
              <Select.Option key={index} id={'material' + index} value={item.id}>
                {item.name || <span>&nbsp;</span>} {/* 空欄の処理 */}
              </Select.Option>
            ))}
          </Select>
          {(() => {
            const processDetailComp = [];
            let processDetailOptions = Plating;
            let processType = ProcessType?.filter((i) => i.id === record?.orderConditions.process);
            if (processMst && processMst?.length > 0) {
              let processDetails = processMst?.[0]?.details?.processDetailTypes?.filter(
                (i) => i.group === processType?.[0]?.group
              );
              processDetailOptions = processDetails;
            }
            processDetailComp.push(
              <Select
                popupClassName="popup-select-input-style"
                popupMatchSelectWidth={false}
                disabled={processDetailOptions?.length === 0 ? true : false}
                key={`processDetailSelect-${i}`}
                style={{ width: 130 }}
                defaultValue={0}
                id={'processDetail' + i}
                name={'processDetail' + i}
                className="copy-paste-div-mrleft"
                value={
                  processDetailOptions?.length === 0
                    ? ''
                    : processDetailOptions?.some((opt) => opt.id === record?.orderConditions?.processDetail)
                    ? record?.orderConditions?.processDetail
                    : processDetailOptions?.[0]?.id
                }
                onChange={(e) => {
                  settingOrderConditions(i, 'processDetail', e);
                }}
              >
                {processDetailOptions?.map((item, index) => (
                  <Select.Option key={index} id={'processDetail' + index} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            );
            return processDetailComp;
          })()}
        </div>
      ),
    },
    {
      title: '公差',
      width: 90,
      render: (text, record, i) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Select
            style={{ width: 80 }}
            popupClassName="popup-select-input-style"
            popupMatchSelectWidth={false}
            defaultValue={0}
            id={'tolerance' + i}
            name={'tolerance' + i}
            value={
              toleranceClass?.some((opt) => opt.id === record?.orderConditions?.tolerance)
                ? record?.orderConditions?.tolerance
                : toleranceClass?.length > 0
                ? toleranceClass?.[0]?.id
                : ''
            }
            onChange={(e) => {
              settingOrderConditions(i, 'tolerance', e);
            }}
          >
            {toleranceClass?.map((item, index) => (
              <Select.Option key={index} id={'tolerance' + index} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </div>
      ),
    },
    {
      title: '仕上げレベル',
      width: 120,
      render: (text, record, i) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Select
            style={{ width: 110 }}
            popupClassName="popup-select-input-style"
            popupMatchSelectWidth={false}
            defaultValue={0}
            id={'weldFinishLevel' + i}
            name={'weldFinishLevel' + i}
            value={
              weldFinishLevel?.some((opt) => opt.id === record?.orderConditions?.weldFinishLevel)
                ? record?.orderConditions?.weldFinishLevel
                : weldFinishLevel?.length > 0
                ? weldFinishLevel?.[0]?.id
                : ''
            }
            onChange={(e) => {
              settingOrderConditions(i, 'weldFinishLevel', e);
            }}
          >
            {weldFinishLevel?.map((item, index) => (
              <Select.Option key={index} id={'weldFinishLevel' + index} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </div>
      ),
    },
    {
      title: '最終用途',
      width: 120,
      render: (text, record, i) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Select
            style={{ width: 110 }}
            popupClassName="popup-select-input-style"
            popupMatchSelectWidth={false}
            defaultValue={0}
            id={'finalPurpos' + i}
            name={'finalPurpos' + i}
            value={
              finalPurpos?.some((opt) => opt.id === record?.orderConditions?.finalPurpos)
                ? record?.orderConditions?.finalPurpos
                : finalPurpos?.length > 0
                ? finalPurpos?.[0]?.id
                : ''
            }
            onChange={(e) => {
              settingOrderConditions(i, 'finalPurpos', e);
            }}
          >
            {finalPurpos?.map((item, index) => (
              <Select.Option key={index} id={'finalPurpos' + index} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </div>
      ),
    },
    {
      title: '希望納期',
      width: 135,
      render: (text, record, i) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Select
            disabled={record?.orderConditions?.process > 0 ? false : true}
            style={{ width: 125 }}
            popupClassName="popup-select-input-style"
            popupMatchSelectWidth={false}
            defaultValue={0}
            id={'deliveryDateTypes' + i}
            name={'deliveryDateTypes' + i}
            value={
              deliveryTimeCls?.some((opt) => opt.id === record?.orderConditions?.deliveryDateTypes)
                ? deliveryTimeCls?.filter((i) => i.id === record?.orderConditions?.deliveryDateTypes)?.[0]?.name ===
                  ORDER_DATE_INPUT
                  ? record?.orderConditions?.deliveryTimeCls
                    ? formatDate(record?.orderConditions?.deliveryTimeCls)
                    : ''
                  : record?.orderConditions?.deliveryDateTypes
                : deliveryTimeCls?.length > 0
                ? deliveryTimeCls?.[0]?.id
                : ''
            }
            onChange={(e) => {
              settingOrderConditions(i, 'deliveryDateTypes', e);
              let showDate = deliveryTimeCls?.filter((i) => i.id === e)?.[0]?.name === ORDER_DATE_INPUT;
              setShowDatepicker(showDate);
              setShowDatepickerIndex(i);
            }}
          >
            {deliveryTimeCls?.map((item, index) => (
              <Select.Option key={index} id={'deliveryDateTypes' + index} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
          {/* 日付 */}
          {showDatepicker &&
          showDatepickerIndex === i &&
          deliveryTimeCls?.filter((i) => i.id === record?.orderConditions?.deliveryDateTypes)?.[0]?.name ===
            ORDER_DATE_INPUT ? (
            <div className="date-picker-div">
              {(() => {
                const dateComp = [];
                let curSelectDate = new Date();
                if (dateComp?.length === 0) {
                  dateComp.push(
                    <>
                      <DatePicker
                        id={'delivery-data-' + i}
                        locale="ja"
                        open={
                          showDatepicker &&
                          showDatepickerIndex === i &&
                          deliveryTimeCls?.filter((i) => i.id === record?.orderConditions?.deliveryDateTypes)?.[0]
                            ?.name === ORDER_DATE_INPUT
                        }
                        onChange={(date) => {
                          curSelectDate = date;
                        }}
                        dateFormatCalendar="yyyy年 MM月"
                        dateFormat="yyyy/MM/dd"
                        popperPlacement="bottom-start"
                        renderCustomHeader={({ monthDate, decreaseMonth, increaseMonth }) => (
                          <div style={{ display: 'flex', justifyContent: 'space-between', padding: '0.5rem' }}>
                            <CaretLeftOutlined
                              style={{ fontSize: 20, fontWeight: 'bold' }}
                              onClick={(e) => {
                                decreaseMonth();
                              }}
                            />
                            <span style={{ fontSize: 16, fontWeight: 'bold' }}>
                              {monthDate.toLocaleString('default', { month: 'long', year: 'numeric' })}
                            </span>
                            <CaretRightOutlined
                              style={{ fontSize: 20, fontWeight: 'bold' }}
                              onClick={(e) => {
                                increaseMonth();
                              }}
                            />
                          </div>
                        )}
                      />
                      <div style={{ textAlign: 'right', marginTop: 8, padding: 10 }}>
                        <Button
                          style={{ fontWeight: 'bold', fontSize: 13 }}
                          onClick={(e) => {
                            setShowDatepicker(false);
                            let dayjsCurDate = curSelectDate
                              ? dayjs(curSelectDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
                              : '';
                            settingOrderConditions(i, 'deliveryTimeCls', dayjsCurDate);
                          }}
                        >
                          OK
                        </Button>
                        {/* <Button
                          style={{ border: 'none', fontWeight: 'bold', fontSize: 13, marginLeft: 10 }}
                          onClick={(e) => {
                            settingOrderConditions(i, 'deliveryTimeCls', 0);
                            setShowDatepicker(false);
                          }}
                        >
                          キャンセル
                        </Button> */}
                      </div>
                    </>
                  );
                  return dateComp;
                }
              })()}
            </div>
          ) : (
            ''
          )}
        </div>
      ),
    },
    {
      title: '自由記入欄',
      width: 250,
      render: (text, record, i) => (
        <div style={{ padding: 5 }}>
          <TextArea
            style={{
              verticalAlign: 'Top',
              fontSize: '11px',
              height: '60px',
              borderRadius: '5px',
              padding: '5px',
            }}
            placeholder="ご質問やお悩みなどお気軽にご入力ください。"
            name="comment"
            id="comment"
            value={record?.orderConditions?.comment}
            type="text"
            rows={4}
            onChange={(e) => {
              settingOrderConditions(i, 'comment', e.target.value);
            }}
          />
        </div>
      ),
    },
    {
      title: '削除',
      width: 65,
      //fixed: 'right',
      render: (text, record, i) => (
        <div style={{ textAlign: 'center' }}>
          <DeleteFilled
            className="del-btn"
            onClick={(e) => {
              if (record?.estimateResult !== EstimateResultClass.EstimateProcessing) {
                deleteCad3D(record, i);
              }
            }}
            style={{
              color: record?.estimateResult === EstimateResultClass.EstimateProcessing ? '#ccc' : '#ff4d4f',
              cursor: record?.estimateResult === EstimateResultClass.EstimateProcessing ? 'not-allowed' : 'pointer',
            }}
          />
        </div>
      ),
    },
  ];

  return (
    <Spin spinning={spinLoading} tip="処理中..." size="large">
      <div style={{ padding: 0 }}>
        <Layout className="history-layout">
          <Layout className="history-layout-tb">
            <div className="home-tilte-div">
              <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', width: '60%' }}>
                <label style={{ fontSize: '18px', fontWeight: '500', color: '#333333', marginLeft: 7 }}>
                  3次元CADデータ一覧
                </label>
              </div>
            </div>
            <div className="home-tilte-btn-div" style={{ display: 'flex' }}>
              <Row>
                <div style={{ marginRight: 7 }}>
                  <input
                    type="file"
                    multiple
                    accept={AllowedExtensions}
                    ref={excelFileRef}
                    style={{ display: 'none' }}
                    onChange={async (e) => {
                      const uploadedFiles = [...e.target.files];
                      handleFileUpload(uploadedFiles);
                    }}
                    onClick={(e) => (e.target.value = null)}
                  />
                  <Button
                    key="upload"
                    className="home-upload-btn"
                    onClick={(e) => {
                      browseExcelFiles();
                    }}
                  >
                    {/* または、こちらからアップロード */}
                    <UploadOutlined style={{ fontSize: '14px' }} />
                    アップロード
                  </Button>
                </div>
                {props?.loginUser?.userType === UserRole.HE ? (
                  ''
                ) : (
                  <EstimateFooter
                    selectedRows={selectedRows}
                    page={'home'}
                    updateActionType={props?.updateActionType}
                    columns={columns}
                    selectedFileNames={selectedFileNames}
                    setCad3dDataLst={setCad3dDataLst}
                    cad3dDataLst={cad3dDataLst}
                    setSelectedRows={setSelectedRows}
                    homePageLoading={setSpinLoading}
                    orderClass={orderClass}
                    toleranceClass={toleranceClass}
                    weldFinishLevel={weldFinishLevel}
                    finalPurpos={finalPurpos}
                    materialTypeIq3={materialClass}
                    processMst={processMst}
                  />
                )}
              </Row>
            </div>
            <div className="responsive-table">
              <Table
                rowSelection={{
                  type: 'checkbox',
                  ...rowSelection,
                }}
                scroll={{
                  x: '10vw',
                  y: cad3dDataLst.length > 0 ? tableHeight : 'auto',
                }}
                columns={columns}
                rowKey={(record) => record?.id}
                dataSource={cad3dDataLst}
                className="upload-file-tb"
                loading={loading}
                onChange={handleTableChange}
                pagination={{
                  position: ['topleft'],
                  total: totalCount ? totalCount : 1,
                  showTotal: (total, range) =>
                    totalCount
                      ? `${selectedRows?.length}件を選択中、全${total}件中${range[0]}〜${range[1]}件を表示中`
                      : `${0}件を選択中、全${0}件中${0}〜${0}件を表示中`,
                  defaultCurrent: 1,
                  pageSize: pageSize,
                  pageSizeOptions: pageSizes,
                  showSizeChanger: true,
                  onShowSizeChange: handlePageSizeChange,
                  current: cusCurrentPage,
                  onChange: (page) => {
                    setCusCurrentPage(page);
                  },
                }}
                locale={{
                  emptyText: (
                    <div
                      style={{
                        display: 'grid',
                        padding: 0,
                        textAlign: 'center',
                        marginBottom: 0,
                        cursor: 'pointer',
                      }}
                      onDragEnter={handleDragEnter}
                      onDragOver={handleDragOver}
                      onDragLeave={handleDragLeave}
                      onDrop={handleDrop}
                    >
                      <div className="circle">
                        <Image preview={false} width={74} src={upload_file}></Image>
                      </div>
                      <label style={{ fontSize: '20px', fontWeight: 'bold', color: '#333333' }}>
                        ここに3D CADファイルをドロップしてください。
                      </label>
                      <label style={{ marginTop: 0, fontSize: '13px', fontWeight: 'bold', color: '#333333' }}>
                        ※複数ファイル同時にアップロード可能です。
                      </label>
                      <label style={{ fontSize: '13px', fontWeight: '600', color: '#333333' }}>
                        アップロード可能ファイル：.step .stp .X_T .sldprt .sldasm
                      </label>
                      <Button
                        key="upload"
                        className="home-upload-no-data-btn"
                        onClick={(e) => {
                          browseExcelFiles();
                        }}
                      >
                        または、こちらからアップロード
                      </Button>
                    </div>
                  ),
                }}
                title={() => (
                  <div
                    ref={titleRef}
                    style={{
                      display: 'flex',
                      justifyContent: 'right',
                      alignItems: 'center',
                    }}
                  >
                    <label className="top-table-title">
                      ※注文や金額交渉を行ったファイルについては見積履歴画面を参照ください。本画面では表示されません。
                    </label>
                  </div>
                )}
              />
            </div>
          </Layout>
          {/* 削除確認 */}
          {isDelete
            ? commonModal(
                isDelete,
                deleteModalTitle,
                commonDeleteFooter(deleteOk, deleteCancel),
                null,
                400,
                deleteOk,
                deleteCancel,
                <p style={{ fontSize: '13px', marginBottom: '20px' }}>
                  ファイルを削除します。よろしいですか？
                  <br />
                  削除されたデータを復元することはできません
                </p>,
                null,
                null,
                false
              )
            : ''}
        </Layout>
      </div>
    </Spin>
  );
});

export default WithRouter(Home);
