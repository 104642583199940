/**
 * クラス名：Footer画面
 * 説明：注文、交渉と見積書の出力を行う。
 * 作成者：ナンス
 * 作成日：2024/11/18
 * バージョン：1.0
 */
import { Button, Col, Input, Layout, Row, Space, Spin, Table, Tooltip } from 'antd';
import React, { forwardRef, useEffect, useState } from 'react';
import { WithRouter } from './WithRouter';
import { PDFDocument, rgb } from 'pdf-lib';
import { saveAs } from 'file-saver'; // ファイル保存用ライブラリ

import '../assets/styles/common.css';
import {
  formatDate,
  getEstimateStatusName,
  getMaterialName,
  getOrderConditionsName,
  getProcessName,
  getRequestHeader,
  JPYs,
} from '../views/common/Common';
import { deleteCad2File } from '../views/common/CommonAPI';
import {
  commonFooter,
  commonModal,
  deleteModalTitle,
  orderModalTitle,
  commonResponsiveModal,
} from '../views/common/CommonModal';
import { AllowedExtensions, FileUploadStatus2D } from '../views/common/Constant';
import { ErrorMessage } from '../views/common/Message';
import axios from 'axios';
import {
  CloseOutlined,
  ShopOutlined,
  TransactionOutlined,
  UploadOutlined,
  VerticalAlignBottomOutlined,
} from '@ant-design/icons';
import { EstimateFooterBtnClass } from '../views/common/enums';

const EstimateFooter = forwardRef((props, ref) => {
  const excelFileRef = React.useRef(null);
  const [selectedOrderList, setSelectedOrderList] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selected2DFileNames, setSelected2DFileNames] = useState('');
  const [loading, setLoading] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [btnType, setBtnType] = useState(EstimateFooterBtnClass.Order);

  const pdfTemplateURL = '/pdf_template/pdf_ver1.0.pdf';
  // const pdfTemplateURL = '/pdf_template/test.pdf';
  useEffect(() => {
    // isActiveToken(); // ログインのトークン有効チェック
    setSelectedRows(props?.selectedRows);
  }, [selectedOrderList]);

  const browseExcel2DFiles = () => {
    excelFileRef.current.click();
  };

  // 選択した２Dファイルをアップロードする
  const handle2DFileUpload = async (excelFiles) => {
    setLoading(true);
    if (excelFiles?.length > 0) {
      try {
        const updatedRows = await Promise.all(
          selectedRows.map(async (row) => {
            // 比較のためにファイル拡張子を削除する
            const rowFileNameWithoutExt = row.name.replace(/\.[^/.]+$/, '');
            const fileToUpload = excelFiles?.find((file) => {
              const fileNameWithoutExt = file.name.replace(/\.[^/.]+$/, '');
              return fileNameWithoutExt === rowFileNameWithoutExt;
            });

            if (fileToUpload) {
              // 2Dファイルアップロードする
              const uploadResult = await upload2DFileToStorage({ fileObj: fileToUpload });

              if (uploadResult.success) {
                // アップロード成功
                return { ...row, orderStatus: FileUploadStatus2D.Success };
              } else {
                // アップロード失敗
                return { ...row, orderStatus: FileUploadStatus2D.Fail };
              }
            } else {
              // アップロード2Dファイル名が3Dファイル名と合っていない
              return { ...row, orderStatus: FileUploadStatus2D.Blank };
            }
          })
        );

        setSelectedRows(updatedRows);
        setLoading(false);
      } catch (error) {
        console.error('ファイルのアップロード処理中にエラーが発生しました:', error);
        setIsUpload(false);
      }
    }
  };

  // ストレージに3Dファイルをアップロードする
  const upload2DFileToStorage = async (uploadFile) => {
    const formData = new FormData();
    formData.append('file', uploadFile.fileObj);
    setSelected2DFileNames(uploadFile?.fileObj?.name);
    // 選択した2Dファイルをアップロードする
    const apiUrl = `${process.env.REACT_APP_BACKEND_API_URL}Cad3dData/UploadCad2File`;
    const headers = getRequestHeader();
    try {
      const response = await axios.post(apiUrl, formData, { headers });
      // アップロード成功
      return { success: true, response };
    } catch (error) {
      // アップロード失敗
      return { success: false, error };
    }
  };

  // チェックを付けた見積りをまとめて注文する、まとめて金額交渉する
  const handleBtnAction = (type) => {
    setBtnType(type);
    setConfirm(true);
  };

  // 見積書（pdf版）ダウンロード処理
  const handleDownloadPdf = async () => {
    // 1. PDFをロード
    const templateBytes = await fetch(pdfTemplateURL).then((res) => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(templateBytes);
    const form = pdfDoc.getForm();
    let estimateTotal = 0;
    props?.selectedRows?.forEach((row, i) => {
      let index = i + 1;
      const textField = form.getTextField('productNm' + index);
      textField.setText(row.detail.fileNm);
      textField.enableReadOnly();

      const amtTextField = form.getTextField('total' + index);
      amtTextField.setText(JPYs.format(row.detail.estimateAmt.toString()));
      amtTextField.enableReadOnly();

      estimateTotal += row.detail.estimateAmt;
    });

    const amtTotal = form.getTextField('total');
    amtTotal.setText(JPYs.format(estimateTotal.toString()));
    amtTotal.enableReadOnly();

    form.flatten();

    // 編集した PDF をダウンロード可能に
    const pdfBytes = await pdfDoc.save();
    const blob = new Blob([pdfBytes], { type: 'application/pdf' });
    saveAs(blob, '見積書.pdf');
  };

  // チェックを付けた見積りの部品一覧取得
  const XLSX = require('xlsx');
  const ExcelJS = require('exceljs');
  const handleDownloadEstimateList = async () => {
    let tbHeaders = props?.columns?.filter((item) => item.title != '画像' && item.title != '操作');
    // 1. JSONデータをワークシートに変換
    // 新しいワークブックの作成
    const workbook = new ExcelJS.Workbook();
    // 新しいシートの追加
    const worksheet = workbook.addWorksheet('部品一覧');
    const headerRow = worksheet.getRow(1);
    headerRow.getCell(1).value = 'No.';
    for (let i = 0; i < tbHeaders?.length; i++) {
      let data = tbHeaders[i].title;
      let colNumber = i + 1;
      headerRow.getCell(colNumber).value = data;
    }
    headerRow.commit();
    // リスト
    let curRow = [];
    for (let i = 0; i < props?.selectedRows?.length; i++) {
      let data = props?.selectedRows[i];
      curRow = worksheet.getRow(i + 2);
      headerRow.eachCell(function (cell, colNumber) {
        if (cell.value == 'No') {
          curRow.getCell(colNumber).value = i + 1;
        } else if (cell.value == 'ファイル名') {
          curRow.getCell(colNumber).value = data.detail.fileNm;
        } else if (cell.value == '状態') {
          curRow.getCell(colNumber).value = data.detail.status;
        } else if (cell.value == '見積金額') {
          if (data.detail.estimateResult === 3) {
            curRow.getCell(colNumber).value = data.detail.estimateAmt + '円（' + data.detail.estimateAmt + '円/個）';
          } else {
            curRow.getCell(colNumber).value = data.detail.estimateResult;
          }
        }
      });
      curRow.commit();
    }

    // ファイルを保存
    let uint8Array = await workbook.xlsx.writeBuffer();
    let blob = new Blob([uint8Array], { type: 'application/octet-binary' });
    let link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    let excelName = '部品一覧書.xlsx'; // IQX_WEBEST-365 クイック印刷機能が実装していない。
    link.download = excelName;
    link.click();
    link.remove();
  };

  // 選択して表示している見積データを閉じる
  const onCancel = () => {
    setSelectedOrderList(false);
    if (props?.page === 'home') {
      props?.setSelectedRows([]);
    }
  };

  // 注文するファイルを削除する
  const fileDelete = (data) => {
    setSelectedRows((prev) =>
      prev.map((item) => (item.id === data.id ? { ...item, orderStatus: FileUploadStatus2D.Blank } : item))
    );
    // 削除するファイル名を取得する
    const fileName = data.name;
    if (!fileName) {
      return;
    }
    // 削除するファイル名でS3上に削除する
    deleteCad2File(fileName);
    // ファイルアップロードステータス変更
    setSelectedRows((prev) =>
      prev.map((item) => ({
        ...item,
        orderStatus: FileUploadStatus2D.Blank,
      }))
    );
  };

  // 注文中処理
  const orderProcessing = (targetDataArray, selected2DFileNames) => {
    // 注文中のレコードを選択されたままに設定する
    targetDataArray?.forEach((cad3Data) => {
      const btn = document.getElementById('estimate-btn-' + cad3Data.id);
      if (btn) {
        btn.classList.remove('estimate-cond-btn');
        btn.classList.add('btn-loading');
      }
    });

    const headers = getRequestHeader('application/json');

    // すべてのターゲットデータのリクエストパラメーターを準備する
    const reqData = targetDataArray.map((cad3Data) => ({
      cad3dId: cad3Data.id,
      orderconditions: cad3Data.orderConditions,
      estimateResult: 4,
      File2DName: selected2DFileNames,
      IsOrder: 0, // ０：注文中、１：注文、２：金額交渉
    }));

    const apiUrl = `${process.env.REACT_APP_BACKEND_API_URL}Cad3dData/BatchUpdateEstimateResults`;
    // 注文中ステータス変更する
    axios
      .put(apiUrl, reqData, { headers })
      .then((response) => {
        const updatedDataList = response.data?.data || [];
        if (props?.page === 'home') {
          const result = filterMismatchedRows(props?.cad3dDataLst, updatedDataList);
          props?.setCad3dDataLst(result);
        } else {
          const result = statusChangedInHistory(props?.estimateHistoryList, updatedDataList);
          props?.setEstimateHistoryList(result);
        }
        // 注文中のレコードを選択されたままに設定する
        targetDataArray?.forEach((cad3Data) => {
          const btn = document.getElementById('estimate-btn-' + cad3Data.id);
          if (btn) {
            btn.classList.remove('btn-loading');
            btn.classList.add('estimate-cond-btn');
            btn.disabled = true;
          }
        });
      })
      .catch((error) => {
        // 更新失敗する
        ErrorMessage(error.message);

        // 注文中のレコードを選択されたままに設定する
        targetDataArray?.forEach((cad3Data) => {
          const btn = document.getElementById('estimate-btn-' + cad3Data.id);
          if (btn) {
            btn.classList.remove('btn-loading');
            btn.classList.add('estimate-cond-btn');
          }
        });
      });
  };

  // ステータス更新した後、ステータス{4,5,6}のデータを抜きする
  const filterMismatchedRows = (prevState, newState) => {
    return prevState?.filter((prevItem) => {
      const matchingNewItem = newState?.find((newItem) => newItem.id === prevItem.id);

      return !matchingNewItem || matchingNewItem.estimateResult === prevItem.estimateResult;
    });
  };

  // estimateResultを更新します
  const statusChangedInHistory = (prevState, newState) => {
    return prevState.map((historyItem) => {
      const updatedItem = newState?.find((newItem) => newItem.id === historyItem.id);

      if (updatedItem) {
        return {
          ...historyItem,
          estimateResult: 4,
        };
      }

      return historyItem;
    });
  };

  // アップロードしたファイルをメール送信する
  const sendMailOrder = async () => {
    if (selected2DFileNames) {
      setLoading(true);
      // 2Dファイル名と3Dファイル名が合っているデータを取得する
      const filteredRows = selectedRows.filter((row) => row.orderStatus === FileUploadStatus2D.Success);
      // 注文する
      await orderComplete(filteredRows, selected2DFileNames);
      // 画面切り替え
      props?.updateActionType('order');
      props.navigate('/home/:process-complete-msg');
    }
  };

  //  注文する
  const orderComplete = async (targetDataArray, selected2DFileNames) => {
    targetDataArray?.forEach((cad3Data) => {
      const btn = document.getElementById('estimate-btn-' + cad3Data.id);
      if (btn) {
        btn.classList.remove('estimate-cond-btn');
        btn.classList.add('btn-loading');
      }
    });

    const headers = getRequestHeader('application/json');

    // すべてのターゲットデータのリクエストパラメーターを準備する
    const reqData = targetDataArray.map((cad3Data) => ({
      Cad3dId: cad3Data.id,
      Orderconditions: cad3Data.orderConditions,
      EstimateResult: 5,
      FileName: cad3Data.name,
      File2DName: selected2DFileNames,
      IsOrder: 1, // ０：金額交渉、１：注文、２：金額交渉
    }));

    const apiUrl = `${process.env.REACT_APP_BACKEND_API_URL}Cad3dData/BatchUpdateEstimateResults`;

    await axios
      .put(apiUrl, reqData, { headers })
      .then((response) => {
        //
        setLoading(false);
      })
      .catch((error) => {
        // Handle errors
        ErrorMessage(error.message);

        // Reset buttons in case of an error
        targetDataArray?.forEach((cad3Data) => {
          const btn = document.getElementById('estimate-btn-' + cad3Data.id);
          if (btn) {
            btn.classList.remove('btn-loading');
            btn.classList.add('estimate-cond-btn');
          }
        });
      });
  };

  //　金額交渉する
  const orderAdjust = async (targetDataArray, selected2DFileNames) => {
    targetDataArray?.forEach((cad3Data) => {
      const btn = document.getElementById('estimate-btn-' + cad3Data.id);
      if (btn) {
        btn.classList.remove('estimate-cond-btn');
        btn.classList.add('btn-loading');
      }
    });

    const headers = getRequestHeader('application/json');

    // すべてのターゲットデータのリクエストパラメーターを準備する
    const reqData = targetDataArray.map((cad3Data) => ({
      Cad3dId: cad3Data.id,
      Orderconditions: cad3Data.orderConditions,
      EstimateResult: 4,
      FileName: cad3Data.name,
      File2DName: selected2DFileNames,
      IsOrder: 2, // ０：注文中、１：注文、２：金額交渉
    }));

    const apiUrl = `${process.env.REACT_APP_BACKEND_API_URL}Cad3dData/BatchUpdateEstimateResults`;

    //
    await axios
      .put(apiUrl, reqData, { headers })
      .then((response) => {
        //
        props?.homePageLoading(false);
      })
      .catch((error) => {
        // Handle errors
        ErrorMessage(error.message);

        // Reset buttons in case of an error
        targetDataArray?.forEach((cad3Data) => {
          const btn = document.getElementById('estimate-btn-' + cad3Data.id);
          if (btn) {
            btn.classList.remove('btn-loading');
            btn.classList.add('estimate-cond-btn');
          }
        });
      });
  };

  // まとめて注文すると金額交渉する処理を行う
  const confirmOk = async () => {
    setConfirm(false);
    if (btnType === EstimateFooterBtnClass.Order) {
      setSelectedOrderList(true);
      orderProcessing(props?.selectedRows, selected2DFileNames);
    } else if (btnType === EstimateFooterBtnClass.AdjustAmt) {
      if (props?.selectedRows?.length > 0) {
        props?.homePageLoading(true);
        await orderAdjust(props?.selectedRows, selected2DFileNames);
        // 画面切り替え
        props?.updateActionType('adjust');
        props.navigate('/home/:process-complete-msg');
      }
    }
  };

  // まとめて注文すると金額交渉する処理を行わない
  const confirmCancel = () => {
    setSelectedOrderList(false);
    setConfirm(false);
  };

  const orderColumns = [
    {
      title: 'No',
      key: 'id',
      width: 50,
      fixed: 'left',
      render: (_, __, index) => <div style={{ textAlign: 'center' }}>{index + 1}</div>,
    },
    {
      title: '2D展開図',
      dataIndex: 'orderStatus',
      width: 100,
      render: (_, record) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Tooltip
            title={record.orderStatus === FileUploadStatus2D.Success ? '2D展開図あり' : ''}
            placement="top"
            classNames={{ root: 'tooltip-text' }}
          >
            {/** 2Dアップロード成功の場合 */}
            <div
              className={record.orderStatus === FileUploadStatus2D.Success ? 'circle-status-green' : 'circle-status'}
              style={{ display: record.orderStatus === FileUploadStatus2D.Success ? '' : 'none', textAlign: 'center' }}
            ></div>
          </Tooltip>
          <Tooltip
            title={record.orderStatus === FileUploadStatus2D.Fail ? '2D展開図アップロード失敗' : ''}
            placement="top"
            classNames={{ root: 'tooltip-text' }}
          >
            {/** 2Dアップロード失敗の場合 */}
            <div
              className={record.orderStatus === FileUploadStatus2D.Fail ? 'circle-status-red' : 'circle-status'}
              style={{ display: record.orderStatus === FileUploadStatus2D.Fail ? '' : 'none' }}
            ></div>
          </Tooltip>
          <Tooltip
            title={record.orderStatus === FileUploadStatus2D.Blank ? '2D展開図なし' : ''}
            placement="top"
            classNames={{ root: 'tooltip-text' }}
          >
            {/** 2Dアップロードなしの場合 */}
            <CloseOutlined
              key={`${record.id}-icon-close`}
              style={{
                color: '#d32f2f',
                display: record.orderStatus === FileUploadStatus2D.Blank ? '' : 'none',
              }}
              className="estimate-amt-icon"
            />
          </Tooltip>
        </div>
      ),
    },
    {
      title: '見積内容',
      dataIndex: 'name',
      width: 250,
      render: (_, record) => (
        <div style={{ display: 'flex', paddingLeft: 10 }}>
          <div style={{ textAlign: 'center', backgroundColor: '#808080b8', width: 80, height: 90 }}></div>
          <div style={{ display: 'grid', marginLeft: 10 }}>
            <label className="history-tb-label">アップロードファイル名：</label>
            <label className="history-tb-label">{record.name}</label>
            <label className="history-tb-label">見積日：</label>
            <label className="history-tb-label">{record.estimated ? formatDate(record.estimated) : ''}</label>
          </div>
        </div>
      ),
    },
    {
      title: '数量',
      dataIndex: 'orderConditions',
      width: 70,
      render: (record) => (
        <div style={{ textAlign: 'center' }}>
          <label>{record.quantity}</label>
        </div>
      ),
    },
    {
      title: '見積条件',
      dataIndex: 'orderConditions',
      width: 120,
      render: (record) => (
        <div style={{ display: 'grid', padding: 7 }}>
          <label className="history-tb-label">
            発注分類：{getOrderConditionsName(record.order, props?.orderClass)}
          </label>
          <label className="history-tb-label"> 材質：{getMaterialName(record.material, props?.materialTypeIq3)}</label>
          <label className="history-tb-label">処理：{getProcessName(record.process)}</label>
          <label className="history-tb-label">
            公差：{getOrderConditionsName(record.tolerance, props?.toleranceClass)}
          </label>
          <label className="history-tb-label">
            仕上げレベル：{getOrderConditionsName(record.weldFinishLevel, props?.weldFinishLevel)}
          </label>
          <label className="history-tb-label">
            最終用途：{getOrderConditionsName(record.finalPurpos, props?.finalPurpos)}
          </label>
        </div>
      ),
    },
    {
      title: '見積金額',
      dataIndex: 'userEstimateTotalPrice',
      width: 120,
      render: (_, record) => (
        <div style={{ textAlign: 'center', color: record.isModifiedPrice ? 'red' : '' }}>
          {(() => {
            const estimateComp = [];
            let estimateTotalPrice = record.userEstimateTotalPrice
              ? Math.round(record.userEstimateTotalPrice).toLocaleString()
              : 0;
            let estimateUnitPrice = record.userEstimateUnitPrice
              ? Math.round(record.userEstimateUnitPrice).toLocaleString()
              : 0;
            {
              record.estimateResult === 7
                ? estimateComp.push(<label>{getEstimateStatusName(record.estimateResult)}</label>)
                : estimateComp.push(
                    <>
                      <div style={{ textAlign: 'center' }}>
                        <label style={{ fontWeight: 'bold' }}>{estimateTotalPrice + '円'}</label>
                      </div>
                      <div style={{ textAlign: 'center' }}>
                        <label style={{ fontWeight: 'bold' }}>{'（' + estimateUnitPrice + '円/' + '1個）'}</label>
                      </div>
                    </>
                  );
            }
            return estimateComp;
          })()}
        </div>
      ),
    },
    {
      title: '削除',
      dataIndex: 'estimateAmt',
      width: 100,
      render: (record, row) => (
        <div style={{ textAlign: 'center' }}>
          <Button
            className="history-search-btn"
            style={{ marginLeft: 10, width: 95 }}
            disabled={row.orderStatus !== FileUploadStatus2D.Success}
            onClick={() => fileDelete(row)}
          >
            {'ファイル削除'}
          </Button>
        </div>
      ),
    },
  ];

  const orderContent = (
    <>
      <div style={{ padding: 0 }}>
        <Layout className="order-layout-tb">
          <div
            style={{
              display: 'grid',
              // width: '1600px',
              // margin: '0px auto',
              // paddingTop: 5,
            }}
          >
            <label style={{ fontSize: '18px', fontWeight: '400', color: '#333333' }}>
              2D展開図のアップロードをお願いします。
            </label>
          </div>
          <Row style={{ padding: 9 }}>
            <Col span={20}>
              <input
                type="file"
                multiple
                accept={AllowedExtensions}
                ref={excelFileRef}
                style={{ display: 'none' }}
                onChange={(e) => {
                  const uploadedFiles = [...e.target.files];
                  handle2DFileUpload(uploadedFiles);
                }}
                onClick={(e) => (e.target.value = null)}
              />
              <Button
                key="upload2d"
                className="home-upload-btn"
                onClick={(e) => {
                  browseExcel2DFiles();
                }}
              >
                <UploadOutlined style={{ fontSize: '14px' }} />
                <span style={{ display: 'inline-block' }}>アップロード</span>
              </Button>
              <Button
                key="order-btn"
                onClick={sendMailOrder}
                className={
                  !selectedRows.some((row) => row.orderStatus === FileUploadStatus2D.Success)
                    ? 'history-btn-disabled'
                    : 'history-btn'
                }
                style={{ marginLeft: 10, width: 120 }}
                disabled={!selectedRows.some((row) => row.orderStatus === FileUploadStatus2D.Success)}
              >
                <ShopOutlined className="history-btn-icon" />
                <span style={{ display: 'inline-block' }}>注文確定する</span>
              </Button>
            </Col>
          </Row>
          <Table
            columns={orderColumns}
            dataSource={selectedRows}
            className="history-tb"
            pagination={false}
            scroll={{
              x: 600, // Horizontal scroll for smaller screens
              y: '60vh', // Vertical scroll within modal height
            }}
            loading={loading}
          />
        </Layout>
      </div>
    </>
  );

  const commonConfirmContent = (
    <p style={{ fontSize: '13px', marginBottom: '20px' }}>
      {btnType === EstimateFooterBtnClass.Order
        ? 'チェックを付けた明細について注文処理を行います。 '
        : 'チェックを付けた明細について金額交渉を行います（個別に担当者からご連絡いたします）。'}
      <br />
      本当によろしいですか？
    </p>
  );

  return (
    <>
      <div className="footer-layout-div">
        <Space size="small" style={{ width: '100%', justifyContent: 'center', flexWrap: 'wrap' }}>
          {/* チェックを付けた見積りをまとめて注文する */}
          <Button
            title="チェックを付けた見積りをまとめて注文する"
            disabled={props?.selectedRows?.length === 0 ? true : false}
            key="historyBtn1"
            className={props?.selectedRows?.length === 0 ? 'history-btn-disabled' : 'history-btn'}
            style={{ width: 135 }}
            onClick={(e) => handleBtnAction(EstimateFooterBtnClass.Order)}
          >
            <ShopOutlined className="history-btn-icon" />
            <p>まとめて注文する</p>
          </Button>
          {/* チェックを付けた見積りをまとめて金額交渉する */}
          <Button
            title="チェックを付けた見積りをまとめて金額交渉する"
            disabled={props?.selectedRows?.length === 0 ? true : false}
            key="historyBtn2"
            className={props?.selectedRows?.length === 0 ? 'history-btn-disabled' : 'history-btn'}
            style={{ width: 160 }}
            onClick={(e) => handleBtnAction(EstimateFooterBtnClass.AdjustAmt)}
          >
            <TransactionOutlined className="history-btn-icon" />
            <p>まとめて金額交渉する</p>
          </Button>
          {/* チェックを付けた見積りの見積書取得 */}
          <Button
            title="チェックを付けた見積りの見積書取得"
            disabled={true}
            key="historyBtn3"
            className={'history-btn-disabled'}
            style={{ width: 'auto' }} // Allow dynamic width
            onClick={(e) => {
              //handleDownloadPdf();
            }}
          >
            <VerticalAlignBottomOutlined className="history-btn-icon" />
            <p>見積書取得</p>
          </Button>
          {/* チェックを付けた見積りの部品一覧取得 */}
          <Button
            title="チェックを付けた見積りの部品一覧取得"
            disabled={true}
            key="historyBtn4"
            className={'history-btn-disabled'}
            style={{ width: 'auto' }} // Allow dynamic width
            onClick={(e) => {
              //handleDownloadEstimateList();
            }}
          >
            <VerticalAlignBottomOutlined className="history-btn-icon" />
            <p>部品一覧取得</p>
          </Button>
        </Space>
      </div>

      {/* 注文リスト */}
      {selectedOrderList
        ? commonResponsiveModal(selectedOrderList, orderModalTitle, null, 1600, null, onCancel, orderContent, null)
        : ''}
      {/* 確認 */}
      {confirm
        ? commonModal(
            confirm,
            deleteModalTitle,
            commonFooter(confirmOk, confirmCancel),
            null,
            400,
            confirmOk,
            confirmCancel,
            commonConfirmContent,
            null,
            null,
            false
          )
        : ''}
    </>
  );
});

export default WithRouter(EstimateFooter);
