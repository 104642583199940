/**
 * クラス名：システム全体の共通ファイル
 * 説明：システム全体に共通使っているファイルである。
 * 作成者：ナンス
 * 作成日：2024/11/25
 * バージョン：1.0
 */
import { calDandoriAmt, calDandoriTime, calSagyoAmt, calSagyoTime } from './IQ3KouteiCalculate_Common';
import { DataType, WorkType } from '../common/Constant';

// プログラム工程の計算
export const calProgramProcess = (addition, processInput, processSelect, curDeviceMst, calcParameters) => {
  let programAddition = addition;
  if (Object.keys(programAddition).length > 0) {
    let processMst = [];
    let deviceMst = []; //テスト用ID;
    let editData = [];
    let parameters = calcParameters;
    if (parameters?.process?.length > 0) {
      processMst = parameters?.process?.filter((item) => item.workType === WorkType.SmProgram)[0];
      if (parameters?.device?.length > 0) {
        deviceMst = parameters?.device?.filter((item) => processMst?.id == item?.processId);
      }
    }
    let patternInfo = processSelect;
    if (patternInfo == undefined) return;
    if (patternInfo?.length > 0 && patternInfo[0]?.isUsedForCalc == false) return;

    deviceMst = curDeviceMst[0];
    if (deviceMst == undefined) return;

    // プログラムの工程入力情報取得
    let programProcessInput = processInput;
    let detailItems = [];
    let editDetailItems = [];
    for (let j = 0; j < programProcessInput?.length; j++) {
      detailItems.push({
        id: 1,
        processName: processMst?.name,
        dandoriAmt: 0,
        dandoriTime: 0,
        sagyoAmt: 0,
        sagyoTime: 0,
        elementCnt: parseInt(programProcessInput?.[0]?.details?.[0]?.elements),
        formula: '',
      });
    }
    let calProgramAddition = {
      no: processMst?.id,
      processId: processMst?.id,
      dataType: programAddition?.dataType ? programAddition.dataType : DataType.Data,
      dataItems: detailItems,
      editItems: programAddition?.editItems ? programAddition.editItems : editDetailItems,
      totalDandori: {
        dataItemsAmt: 0,
        dataItemsTime: 0,
        editItemsAmt: 0,
        editItemsTime: 0,
      },
      totalSagyo: {
        dataItemsAmt: 0,
        dataItemsTime: 0,
        editItemsAmt: 0,
        editItemsTime: 0,
      },
      totalDataPrice: 0,
      totalDataTime: 0,
      totalEditPrice: 0,
      totalEditTime: 0,
      editDataFlg: false,
    };
    programAddition = calProgramAddition;
    //データ設定
    if (programAddition?.dataItems) {
      let orgData = JSON.parse(JSON.stringify(programAddition?.dataItems));
      if (deviceMst != undefined) {
        //要素数
        let dataItems_elementCnt = parseInt(programProcessInput?.[0]?.details?.[0]?.elements);
        // 要素数が0の場合、段取と加工を計算しない。
        if (dataItems_elementCnt > 0) {
          orgData[0].elementCnt = dataItems_elementCnt;
          /** 段取金額/時間 */
          // 初期段取時間
          let dandoriTimeSec = calDandoriTime(WorkType.SmProgram, deviceMst, dataItems_elementCnt); //プログラム装置の初期段取時間を取得。
          orgData[0].dandoriTime = dandoriTimeSec === undefined ? 0 : dandoriTimeSec;
          programAddition.dataItems[0].dandoriTime = dandoriTimeSec === undefined ? 0 : dandoriTimeSec;
          // 段取金額設定
          //段階時間＊時間チャージ
          let dandoriAmt = calDandoriAmt(dandoriTimeSec, deviceMst);
          orgData[0].dandoriAmt = dandoriAmt === undefined ? 0 : dandoriAmt;
          programAddition.dataItems[0].dandoriAmt = dandoriAmt === undefined ? 0 : dandoriAmt;
          /** 作業金額/時間 */
          // 作業時間
          //1要素当たりの作業時間＊要素数＊作業時間係数
          let cSagyoTimeSec = calSagyoTime(WorkType.SmProgram, deviceMst, dataItems_elementCnt);
          let cSagyoTimeHms = cSagyoTimeSec;
          orgData[0].sagyoTime = cSagyoTimeHms === undefined ? 0 : cSagyoTimeHms;
          programAddition.dataItems[0].sagyoTime = cSagyoTimeHms === undefined ? 0 : cSagyoTimeHms;
          // 作業金額
          //　作業時間＊時間チャージ
          let cSagyoAmt = calSagyoAmt(cSagyoTimeSec, deviceMst);
          orgData[0].sagyoAmt = cSagyoAmt === undefined ? 0 : cSagyoAmt;
          programAddition.dataItems[0].sagyoAmt = cSagyoAmt === undefined ? 0 : cSagyoAmt;
          programAddition.totalDandori.dataItemsAmt = Math.round(orgData[0]?.dandoriAmt);
          programAddition.totalSagyo.dataItemsAmt = Math.round(orgData[0]?.sagyoAmt);
          programAddition.totalDataPrice = Math.round(orgData[0]?.dandoriAmt) + Math.round(orgData[0]?.sagyoAmt);
          programAddition.totalDandori.dataItemsTime = orgData[0]?.dandoriTime;
          programAddition.totalSagyo.dataItemsTime = orgData[0]?.sagyoTime;
          programAddition.totalDataTime = orgData[0]?.dandoriTime + orgData[0]?.sagyoTime;
        }
        editData = JSON.parse(JSON.stringify(orgData));
      }
    }
    if (editData != undefined && editData?.length > 0) {
      programAddition.editItems = editData;
      programAddition.totalEditPrice = Math.round(editData[0]?.dandoriAmt) + Math.round(editData[0]?.sagyoAmt);
      programAddition.totalDandori.editItemsAmt = Math.round(editData[0]?.dandoriAmt);
      programAddition.totalSagyo.editItemsAmt = Math.round(editData[0]?.sagyoAmt);
      programAddition.totalEditTime = editData[0]?.dandoriTime + editData[0]?.sagyoTime;
      programAddition.totalDandori.editItemsTime = editData[0]?.dandoriTime;
      programAddition.totalSagyo.editItemsTime = editData[0]?.sagyoTime;
    }
  }
  return programAddition;
};
