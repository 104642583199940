/**
 * クラス名：システム全体の共通API接続ファイル
 * 説明：システム全体にAPIと接続する処理を共通使っているファイルである。
 * 作成者：ナンス
 * 作成日：2024/12/09
 * バージョン：1.0
 */
import { StatusCodes } from 'http-status-codes';
import { authorizeError, error } from './CommonModal';
import { ErrorMessage } from './Message';
import { getAccessToken, updateAccessToken } from './Common';
import { IQxRealGoku_AccessToken, IQxRealGoku_LoginUser, LoginPath } from './Constant';
import axios from 'axios';

// ログイン認証処理
export const login = async (loginId, password, remember) => {
  let loginInfo = { loginId: 0, userName: '', updateToken: '', userType: 0, usedFileSize: 0, prefecture: '' };
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'LoginManagement/Login';
    const otherParam = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
      body: JSON.stringify({
        loginId: loginId,
        password: password,
        rememberMe: remember,
      }),
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response.json();
      })
      .then(async (data) => {
        if (data && data?.statusCode === StatusCodes.OK) {
          loginInfo.loginId = data?.loginId;
          loginInfo.userName = data?.userName;
          loginInfo.updateToken = data?.updateToken;
          loginInfo.userType = data?.userType;
          loginInfo.prefecture = data?.prefecture;
          loginInfo.usedFileSize = data?.usedFileSize;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage()[data?.messageCode], true);
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          // 異常の場合、
          error(ErrorMessage()[data?.messageCode]);
        }
      })
      .catch((error) => {
        // 異常の場合、
        error(ErrorMessage().E010);
      });
  } catch (e) {
    // 異常の場合、
    error(ErrorMessage().E003);
    return e;
  }
  return loginInfo;
};

// ログイン後にユーザーを自動的にホーム画面にリダイレクトする処理のため、APIでトークン有効チェック処理
export const isAlive = async (token) => {
  let ret;
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'LoginManagement/IsAliveToken';
    const otherParam = {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response.json();
      })
      .then(async (data) => {
        if (data && data.statusCode === StatusCodes.OK) {
          ret = data;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          ret = ErrorMessage()[data?.messageCode];
        }
      })
      .catch((error) => {
        // 異常の場合、
        ret = ErrorMessage().E010;
      });
  } catch (error) {
    // 異常の場合、
    ret = error.message;
  }
  return ret;
};

// トークン有効情報でログインユーザーの情報を取得する
export const getCurrentUserInfo = async (accessToken) => {
  let ret = '';
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'LoginManagement/CurrentUserInfo';
    const otherParam = {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + accessToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        if (response?.status === StatusCodes.UNAUTHORIZED) {
          authorizeError(ErrorMessage().E006);
          return;
        } else {
          return await response?.json();
        }
      })
      .then((data) => {
        if (data) {
          // セクションにログインユーザー情報を保持する
          // Access value associated with the key
          let tokenString = JSON.stringify(data);
          localStorage.setItem(IQxRealGoku_LoginUser, encodeURI(tokenString));
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          ret = ErrorMessage().E004;
        }
      })
      .catch((error) => {
        ret = ErrorMessage().E010;
      });
  } catch (error) {
    ret = error.message;
  }
  return ret;
};

//　ログアウト処理
export const logOut = async () => {
  let result = true;
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'LoginManagement/Logout';
    const token = getAccessToken();
    const otherParam = {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
    };

    await fetch(Url, otherParam)
      .then((response) => {
        if (response?.status === StatusCodes.UNAUTHORIZED) {
          authorizeError(ErrorMessage().E006);
          return;
        } else {
          return response;
        }
      })
      .then((data) => {
        if (data && data?.status === StatusCodes.OK) {
          localStorage?.removeItem(IQxRealGoku_LoginUser);
          localStorage?.removeItem(IQxRealGoku_AccessToken);
          window.location.replace(LoginPath); // ログインへ遷移
        } else if (data && data?.status !== StatusCodes.OK) {
          error(data);
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return result;
};

// Cad3d情報の取得
export const getCad3dDatas = async (maxRecords, pageIndex, ascSortFlg, sortNm, filterData) => {
  let cad3dDatas = {
    data: [],
    totalCount: 0,
  };
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'Cad3dData/GetCad3dDatas';
    const otherParam = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
      body: JSON.stringify({
        maxRecords: maxRecords,
        pageIndex: pageIndex,
        sortOrder: ascSortFlg,
        sortNm: sortNm,
        filterData: filterData,
      }),
    };
    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          cad3dDatas.data = data?.data;
          cad3dDatas.totalCount = data?.totalRecords;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage()[data?.messageCode]);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          if (data?.messageCode) {
            error(ErrorMessage()[data?.messageCode]);
          } else {
            let errors = '';
            Object.entries(data?.errors)?.map(([key, data]) => {
              errors += data;
            });
            error('データ取得に失敗しました。' + errors);
          }
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return cad3dDatas;
};

// Cad3d情報をIDで取得
export const getCad3dDataById = async (cad3dId) => {
  let cad3dDatas = {
    data: [],
    totalCount: 0,
  };
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'Cad3dData/GetCad3dDatasById/' + cad3dId;
    const otherParam = {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          cad3dDatas.data = data?.data;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage().E006);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          if (data?.messageCode) {
            error(ErrorMessage()[data?.messageCode]);
          } else {
            let errors = '';
            Object.entries(data?.errors)?.map(([key, data]) => {
              errors += data;
            });
            error('データ取得に失敗しました。' + errors);
          }
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return cad3dDatas;
};

// Cad3d情報の登録
export const createCad3dData = async (fileName) => {
  let cad3dDatas = {
    data: [],
    totalCount: 0,
  };
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'Cad3dData/CreateCad3dData/' + fileName;
    const otherParam = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
    };
    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          cad3dDatas.data = data?.data;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage()[data?.messageCode]);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          if (data?.messageCode) {
            error(ErrorMessage()[data?.messageCode]);
          } else {
            let errors = '';
            Object.entries(data?.errors)?.map(([key, data]) => {
              errors += data;
            });
            error('データ取得に失敗しました。' + errors);
          }
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return cad3dDatas;
};

// Cad3d情報の取得
export const getCad3dDatasForAdmin = async (maxRecords, pageIndex, ascSortFlg, sortNm, filterData) => {
  let cad3dDatas = {
    data: [],
    totalCount: 0,
  };
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'Cad3dData/GetCad3dDatasForAdmin';
    const otherParam = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
      body: JSON.stringify({
        maxRecords: maxRecords,
        pageIndex: pageIndex,
        sortOrder: ascSortFlg,
        sortNm: sortNm,
        filterData: filterData,
      }),
    };
    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          cad3dDatas.data = data?.data;
          cad3dDatas.totalCount = data?.totalRecords;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage()[data?.messageCode]);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          if (data?.messageCode) {
            error(ErrorMessage()[data?.messageCode]);
          } else {
            let errors = '';
            Object.entries(data?.errors)?.map(([key, data]) => {
              errors += data;
            });
            error('データ取得に失敗しました。' + errors);
          }
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return cad3dDatas;
};

export const updateUserRequestAmount = async (id, price, unitPrice) => {
  let ret = undefined;
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'Cad3dData/UpdateAmount';
    const otherParam = {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
      body: JSON.stringify({
        Cad3dId: id,
        Price: price,
        UnitPrice: unitPrice,
      }),
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data.statusCode === StatusCodes.OK) {
          ret = data.data[0];
        } else if (data && (data.statusCode === StatusCodes.UNAUTHORIZED || data.statusCode === StatusCodes.CONFLICT)) {
          authorizeError(ErrorMessage()[data?.messageCode]);
          return;
        } else if (data && data.statusCode !== StatusCodes.OK) {
          error(ErrorMessage()[data?.messageCode]);
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return ret;
};

// Cad3d情報の取得
export const getCad3dDatasEstimateHistory = async (maxRecords, pageIndex, ascSortFlg, sortNm, filterData) => {
  let cad3dDatas = {
    data: [],
    totalCount: 0,
  };
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'Cad3dData/GetCad3dDatasEstimateHistory';
    const otherParam = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
      body: JSON.stringify({
        maxRecords: maxRecords,
        pageIndex: pageIndex,
        sortOrder: ascSortFlg,
        sortNm: sortNm,
        filterData: filterData,
      }),
    };
    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          cad3dDatas.data = data?.data;
          cad3dDatas.totalCount = data?.totalRecords;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage()[data?.messageCode]);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          if (data?.messageCode) {
            error(ErrorMessage()[data?.messageCode]);
          } else {
            let errors = '';
            Object.entries(data?.errors)?.map(([key, data]) => {
              errors += data;
            });
            error('データ取得に失敗しました。' + errors);
          }
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return cad3dDatas;
};

export const deleteCad2File = async (filename) => {
  let ret = undefined;
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'Cad3dData/DeleteCad2File';
    const otherParam = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
      body: JSON.stringify({
        FileName: filename,
      }),
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response.json();
      })
      .then((data) => {
        if (data) {
          // セクションにトークン情報を更新する
          updateAccessToken(data);
          ret = true;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return false;
      });
  } catch (e) {
    error(e.message);
    return false;
  }
  return ret;
};

export const getHubConnectionToken = async () => {
  let audiLoginToken = '';
  try {
    // AudiのログインAPIを呼び出して、トークン情報を取得
    let audiLoginInfo = {
      loginId: `${process.env.REACT_APP_AUDI_BACKEND_API_LOGIN_ID}`,
      password: `${process.env.REACT_APP_AUDI_BACKEND_API_LOGIN_PASSWORD}`,
      appCode: `${process.env.REACT_APP_AUDI_BACKEND_API_LOGIN_APPCODE}`,
      isRelogin: true,
    };
    const audiLoginURL = `${process.env.REACT_APP_AUDI_BACKEND_API_LOGIN_URL}`;
    const audiLoginHeaders = {
      'Content-Type': 'application/json',
    };
    const audiLoginResponse = await axios.post(audiLoginURL, audiLoginInfo, {
      audiLoginHeaders,
    });
    if (audiLoginResponse.status === 200) {
      let token = audiLoginResponse.data?.data?.token;
      audiLoginToken = token;
    } else {
      // Audiのログイン情報取得が失敗した場合
      // Audiのログイン処理失敗の通知をAudiから受領したタイミング、ゼロフォー運用者への通知
    }
  } catch (error) {
    console.error('Audiのログイン情報取得が失敗した場合 : ', error);
  }
  return audiLoginToken;
};

// ログイン認証処理
export const GetParameters = async () => {
  let calcParameters = null;
  try {
    const Url = process.env.REACT_APP_EST_API_URL;
    const otherParam = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
      body: JSON.stringify({
        loginId: process.env.REACT_APP_EST_API_LOGIN_ID,
        password: process.env.REACT_APP_EST_API_LOGIN_PASSWORD,
        appCode: process.env.REACT_APP_EST_API_LOGIN_APPCODE,
      }),
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response.json();
      })
      .then(async (data) => {
        if (data && data?.statusCode === StatusCodes.OK) {
          calcParameters = data;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage()[data?.messageCode], true);
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          // 異常の場合、
          error(ErrorMessage()[data?.messageCode]);
        }
      })
      .catch((error) => {
        // 異常の場合、
        error(ErrorMessage().E010);
      });
  } catch (e) {
    // 異常の場合、
    error(ErrorMessage().E003);
    return e;
  }
  return calcParameters;
};

// 資料請求フォームに関して問い合わせ処理
export const inquiry = async (inquiryInfos) => {
  let ret = false;
  const inquiryInfo = { 
    email: inquiryInfos?.email, 
    companyName: inquiryInfos?.company_name, 
    department: inquiryInfos?.department ? inquiryInfos?.department : '', 
    position: inquiryInfos?.position ? inquiryInfos?.position : '', 
    fullName: inquiryInfos?.fullName, 
    postalCode: inquiryInfos?.postalCode,
    address: inquiryInfos?.address,
    tel: inquiryInfos?.tel
  };
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'LoginManagement/Inquiry';
    const otherParam = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
      body: JSON.stringify(
        inquiryInfo
      ),
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response.json();
      })
      .then(async (data) => {
        if (data) {
          ret = data;
        } else {
          // 異常の場合、
          error(ErrorMessage()[data?.messageCode]);
        }
      })
      .catch((error) => {
        // 異常の場合、
        error(error);
      });
  } catch (e) {
    // 異常の場合、
    error(e);
    return ret;
  }
  return ret;
};
