/**
 * クラス名：アプリの初期起動画面
 * 説明：最初に起動するファイルであり、ルーティングページを指定する。
 * 作成者：ナンス
 * 作成日：2024/11/14
 * バージョン：1.0
 */
import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Redirect, Routes, Navigate, useParams } from 'react-router-dom';

import Login from './views/Login';
import MainLayout from './components/MainLayout';
import InquiryForm from './views/InquiryForm';
import InquiryComplete from './views/common/InquiryComplete';

const MainLayoutRoutes = ['/home/*', '/:admin/*'];

class App extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    // Activate the event listener
    this.setupBeforeUnloadListener();
    this.setupKeydownListener();
  }

  // Browser閉じるの場合、ログイン情報を削除
  doSomethingBeforeUnload = () => {
    localStorage?.removeItem('iQxWeb_LoginUser');
    // localStorage?.removeItem('iQxWeb_AccessToken');
    return '';
  };

  // Browser閉じるイベント
  setupBeforeUnloadListener = () => {
    window.addEventListener('beforeunload', (ev) => {
      return this.doSomethingBeforeUnload();
    });
  };

  setupKeydownListener = () => {
    document.addEventListener('keydown', this.handleKeydown);
  };

  handleKeydown = (e) => {
    // Check if the pressed key is F5
    if (e.key === 'F5') {
      e.preventDefault();
    }
  };
  render() {
    return (
      <Router>
        <Routes basename="real.goku">
          {/* ログイン */}
          <Route key="login" path="/login" element={<Login />} />
          {MainLayoutRoutes?.map((path, index) => (
            <Route key={index} path={path} element={<MainLayout loginId={this?.props?.location?.state?.loginId} />} />
          ))}
          <Route key="login2" path="*" element={<Navigate to="/login" replace />} />
          <Route key="inquiry" path="/inquiry" element={<InquiryForm />} />
          <Route key="inquiry-complete" path="/inquiry-complete" element={<InquiryComplete />} />
        </Routes>
      </Router>
    );
  }
}

export default App;
