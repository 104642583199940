/**
 * 説明：Enum値定義
 * 作成者：ナンス
 * 作成日：2024/11/20
 * バージョン：1.0
 */

// 取引先種別
export const EstimateType = Object.freeze({
  0: 'ここに見積金額が表示されます', // 顧客
  1: '見積金額算出中（しばらくお待ちください）', // 仕入れ先
  2: '見積失敗',
  7: '自動見積りができない加工です。別途担当者から\n2営業日以内に見積結果をご連絡いたします', // 外注先
});

export const PriorityClass = [
  { id: 0, name: '高' },
  { id: 1, name: '低' },
];

export const OrderClass = [
  { id: 0, name: '初回発注' },
  { id: 1, name: 'リピート発注' },
];

export const MaterialClass = [
  { id: 0, name: 'SPCC' },
  { id: 1, name: 'SECC' },
  { id: 2, name: 'SUS304' },
  { id: 3, name: 'SUS304鏡面' },
  { id: 4, name: 'SUS304HL' },
  { id: 5, name: 'AL5052' },
  { id: 6, name: 'その他（自由記入欄に記載）' },
];

export const WorkTypeGroup = {
  /// メッキ方法
  SmPlating: 10520,
  /// 塗装種類
  SmCoating: 10530,
};

export const ProcessType = [
  { id: 0, name: '', group: 0 },
  { id: 1, name: 'なし', group: 0 },
  { id: 2, name: 'メッキ', group: WorkTypeGroup.SmPlating },
  { id: 3, name: '塗装', group: WorkTypeGroup.SmCoating },
];

export const ToleranceClass = [
  { id: 0, name: 'A級' },
  { id: 1, name: 'B級（通常はB級）' },
  { id: 2, name: 'C級' },
];

export const WeldFinishLevelClass = [
  { id: 0, name: '#40' },
  { id: 1, name: '#80' },
  { id: 2, name: '#120' },
  { id: 3, name: '#180' },
];

export const FinalPurposClass = [
  { id: 0, name: '内部部品' },
  { id: 1, name: '仕上げ要素' },
  { id: 2, name: '水漏れ不可' },
  { id: 3, name: '強度重要' },
  { id: 4, name: 'その他（自由記入欄に記載）' },
];

export const DeliveryTimeClass = [
  { id: 0, name: '1週間以内' },
  { id: 1, name: '1週間～2週間' },
  { id: 2, name: '1週間～1か月' },
  { id: 3, name: '1か月～2か月' },
  { id: 4, name: '2か月以上' },
  { id: 5, name: '納期を指定する' },
  { id: 6, name: '急ぎ' },
];

export const Plating = [
  {
    name: 'クロメート',
    id: 0,
  },
  {
    name: '三価クロメート',
    id: 1,
  },
  {
    name: 'ユニクロ',
    id: 2,
  },
  {
    name: 'ニッケル',
    id: 3,
  },
  {
    name: '無電解ニッケル',
    id: 4,
  },
  {
    name: 'クロム',
    id: 5,
  },
  {
    name: 'アルマイト',
    id: 6,
  },
  {
    name: '亜鉛',
    id: 7,
  },
];
export const Painting = [
  {
    name: '粉体',
    id: 0,
  },
  {
    name: '溶剤',
    id: 1,
  },
  {
    name: 'エポキシ',
    id: 2,
  },
  {
    name: 'アクリル樹脂',
    id: 3,
  },
  {
    name: 'フッ素樹脂',
    id: 4,
  },
  {
    name: 'カチオン',
    id: 5,
  },
];

export const UploadStatus = [
  { id: 0, name: 'アップロード中' },
  { id: 1, name: '何かしらの理由でアップロードに失敗しました\n担当者から2営業日以内にご連絡いたします' },
  { id: 2, name: 'アップロード成功\n（容量オーバーのため自動見積対象外\n担当者から2営業日以内にご連絡いたします）' },
  { id: 3, name: 'アップロード成功' },
];

export const EstimateStatus = [
  { id: 0, name: '見積条件指定前' }, // 見積履歴画面に見積されたものだけ表示しているため、このステータスを利用しない。
  { id: 1, name: '見積中' },
  { id: 2, name: '見積失敗' },
  { id: 3, name: '見積済' },
  { id: 4, name: '注文処理中' },
  { id: 5, name: '注文済' },
  { id: 6, name: '注文確定' },
  { id: 7, name: '自動見積対象外' },
];

export const EstimatePriorityClass = {
  High: 0,
  Low: 1,
};

export const EstimateResultClass = {
  DisplayAmt: 0, // TOP画面の場合「ここに見積金額が表示されます」
  EstimateProcessing: 1, // TOP画面の場合「見積金額算出中（しばらくお待ちください）」、履歴画面の場合「見積中」
  EstimateFail: 2, // TOP画面の場合「自動見積りができない加工です。」、履歴画面の場合「見積失敗」
  DisplayEstimateResult: 3, // TOP画面の場合金額表示、履歴画面の場合「見積済」
  OrderProcessing: 4, // 注文ボタンor金額交渉ボタン押下された場合、履歴画面に「注文処理中」表示
  OrderComplete: 5, // 注文済（展開ファイルアップロード画面の注文ボタンを押した時に表示）
  OrderConfirmed: 6, // 注文確定 ゼロフォー側の承認したものとする
  AutoEstimateFail: 7, // （10001円以上の場合、自動見積できない条件の場合）
};

export const UserRole = {
  Administrator: 0,
  OU: 1,
  HE: 2,
};

// 表面保護タイプ
export const SurfaceProtectionType = Object.freeze({
  OneSideVinyl: 1, // 片面ビニール
  DoubleSideVinyl: 2, // 両面ビニール
});

// 材料表面加算単位
export const MaterialSurfaceBillingUnit = Object.freeze({
  Weight: 0, // 重量
  SquareMeter: 1, // 面積(平米)
  Work: 2, //　加工
});

export const EstimateFooterBtnClass = {
  Order: 0,
  AdjustAmt: 1,
};

export const AdminBtnClass = {
  Estimate: 0,
  AdjustAmt: 1,
  ConfirmOrder: 2,
};
