/**
 * クラス名：システム全体の共通ファイル
 * 説明：システム全体に共通使っているファイルである。
 * 作成者：ナンス
 * 作成日：2024/11/25
 * バージョン：1.0
 */
import { toDecimal } from '../common/Common';
import {
  calDandoriAmt,
  calDandoriTime,
  getDiffCoef,
  getDiffCoefByWeldFinish,
  getGasCoef,
  getHoseCoeff,
  getJointTm,
  getProductionQuantityCoeff,
  getSagyoSpeed,
  getSagyoSpeedByWeld,
  getSagyoSpeedByweld,
  getSetupCoeff,
  getSizeCoeff,
  getSubWebTm,
  getTempAttachTime,
} from './IQ3KouteiCalculate_Common';
import { DataType, ServiceClass, WorkType, WorkTypeGroup } from '../common/Constant';

// 溶接仕上工程の計算
export const calweldFinish = (
  curAddition,
  processInput,
  processSelect,
  curDeviceMst,
  calcParameters,
  curIQ3DataDetail,
  orderQuantity
) => {
  let weldFinishAddition = curAddition;
  if (Object.keys(weldFinishAddition).length > 0) {
    let editData = [];
    let totals = [];
    let toBEdit = [];
    let processMst = [];
    let weldProcessMst = [];
    let deviceMst = [];

    let parameters = calcParameters;
    if (parameters?.process?.length > 0) {
      processMst = parameters?.process
        ? parameters?.process?.filter(
            (item) => item?.class === ServiceClass.SheetMetal && item.workType === WorkType.SmWeldFinish
          )[0]
        : [];
    }

    let patternInfo = processSelect;
    if (patternInfo == undefined) return;
    if (patternInfo?.length > 0 && patternInfo[0]?.isUsedForCalc == false) return;

    deviceMst = curDeviceMst[0]; //テスト用ID;
    if (deviceMst == undefined) return;

    let weldingFinishAdditions = weldFinishAddition;

    // 溶接工程入力情報取得
    weldProcessMst = parameters?.process
      ? parameters?.process?.filter(
          (item) => item?.class === ServiceClass.SheetMetal && item.workType === WorkType.SmWelding
        )[0]
      : [];
    let weldingProcessInput = curIQ3DataDetail?.processInput?.filter((item) => item.processId == weldProcessMst?.id)[0];

    let detailItems = [];
    let editDetailItems = [];
    // 重量
    let weight = curIQ3DataDetail?.weight;
    // 面積
    let area = Number(curIQ3DataDetail?.totalSurfaceArea);
    let weldIndex = 0;
    //溶接情報設定
    for (let j = 0; j < weldingProcessInput?.details?.[0]?.weldingList?.length; j++) {
      let weldingList = weldingProcessInput?.details?.[0]?.weldingList[j];
      //溶接仕上が適用の場合のみ
      if (weldingList?.senchouYousetsushiage == 0) {
        //重量と面積が０の場合、計算しない。
        if (weight === 0 && area === 0) {
        } else {
          // 線長の場合、長さ/箇所が０の場合、計算しない。（IQX_WEBEST-54）
          // 仮付けの場合、長さ/箇所が０の場合、計算しない。（IQX_WEBEST-54）
          if (Number(weldingList?.karitsukeCnt) !== 0 || Number(weldingList?.senchou) !== 0) {
            let weldProcess = weldProcessMst?.details?.processDetailTypes?.filter(
              (item) => item.id == weldingList.yousetsuType
            )?.[0];
            detailItems.push({
              id: weldIndex + 1,
              processName: weldProcess?.name,
              processTypeId: weldProcess?.id,
              dandoriAmt: 0,
              dandoriTime: 0,
              dandoriDetails: [],
              sagyoAmt: 0,
              sagyoTime: 0,
              sagyoDetails: [],
              senchou: Number(weldingList.senchou), //溶接加工長
              senchouKaisu: Number(weldingList.senchouKaisu), //溶接加工長回数
              formula: '',
              yousetsuType: '',
            });
            //工程選択から処理情報取得
            let multiprocesses = patternInfo[0].multiprocesses;
            for (let i = 0; i < multiprocesses?.length; i++) {
              //溶接仕上工程マスター情報取得
              let processTypeNm = processMst?.details?.processDetailTypes?.filter(
                (item) => item.id == multiprocesses[i].processDetailTypeId
              )?.[0];
              if (processTypeNm == undefined || processTypeNm?.length == 0) continue;
              let dandoriDetails = {
                id: i + 1,
                processDetailTypeId: processTypeNm?.id,
                processDetailName: processTypeNm?.name,
                dandoriAmt: 0,
                dandoriTime: 0,
              };
              let sagyoDetail = {
                id: i + 1,
                processDetailTypeId: processTypeNm?.id,
                processDetailName: processTypeNm?.name,
                sagyoAmt: 0,
                sagyoTime: 0,
              };
              let detailItemsIndex = detailItems?.length === 0 ? 0 : detailItems?.length - 1;
              if (
                detailItems?.[detailItemsIndex]?.dandoriDetails?.filter(
                  (item) => item?.processDetailTypeId === dandoriDetails?.processDetailTypeId
                )?.length === 0
              ) {
                detailItems[detailItemsIndex]?.dandoriDetails?.push(dandoriDetails); // IQX_WEBEST-304 PMXからの見積りができない（「予期せぬエラーが発生しました」と表示される）
              }
              detailItems[detailItemsIndex]?.sagyoDetails?.push(sagyoDetail); // IQX_WEBEST-304 PMXからの見積りができない（「予期せぬエラーが発生しました」と表示される）
            }
            weldIndex++;
          }
        }
      }
    }
    let weldLen = weldingProcessInput?.details?.[0]?.weldingList?.reduce((total, item) => {
      let weldInfoLen = item.senchou > 0 && item.senchouYousetsushiage == 0 ? 1 : 0;
      return total + weldInfoLen;
    }, 0);
    let subWeldIndex = 0;
    // サブ溶接情報設定 IQX_WEBEST-271 サブ溶接にも溶接仕上を出来るようにしてほしい
    for (let j = 0; j < weldingProcessInput?.details?.[0]?.subWeldingList?.length; j++) {
      let subWeldingList = weldingProcessInput?.details?.[0]?.subWeldingList[j];
      //溶接仕上が適用の場合のみ
      if (subWeldingList?.yousetsushiage == 0) {
        //重量と面積が０の場合、計算しない。
        if (weight === 0 && area === 0) {
        } else {
          // 径又はサイズが０の場合、計算しない。（IQX_WEBEST-54）
          if (Number(subWeldingList?.total) !== 0) {
            let subWeldIndexId = weldLen + subWeldIndex + 1;
            let weldProcess = weldProcessMst?.details?.processDetailTypes?.filter(
              (item) => item.id == subWeldingList.subWeldingType
            )?.[0];
            detailItems.push({
              id: subWeldIndexId,
              processName: weldProcess?.name,
              processTypeId: weldProcess?.id,
              dandoriAmt: 0,
              dandoriTime: 0,
              dandoriDetails: [],
              sagyoAmt: 0,
              sagyoTime: 0,
              sagyoDetails: [],
              senchou: Number(subWeldingList.daimeter), //　径・サイズ
              senchouKaisu: Number(subWeldingList.total), //回数
              formula: '',
              yousetsuType: subWeldingList?.subWeldingGroupType,
            });
            //工程選択から処理情報取得
            let multiprocesses = patternInfo[0].multiprocesses;
            for (let i = 0; i < multiprocesses?.length; i++) {
              //溶接仕上工程マスター情報取得
              let processTypeNm = processMst?.details?.processDetailTypes?.filter(
                (item) => item.id == multiprocesses[i].processDetailTypeId
              )?.[0];
              if (processTypeNm == undefined || processTypeNm?.length == 0) continue;
              let dandoriDetails = {
                id: i + 1,
                processDetailTypeId: processTypeNm?.id,
                processDetailName: processTypeNm?.name,
                dandoriAmt: 0,
                dandoriTime: 0,
              };
              let sagyoDetail = {
                id: i + 1,
                processDetailTypeId: processTypeNm?.id,
                processDetailName: processTypeNm?.name,
                sagyoAmt: 0,
                sagyoTime: 0,
              };
              let detailItemsIndex = detailItems?.length === 0 ? 0 : detailItems?.length - 1;
              if (
                detailItems?.[detailItemsIndex]?.dandoriDetails?.filter(
                  (item) => item?.processDetailTypeId === dandoriDetails?.processDetailTypeId
                )?.length === 0
              ) {
                detailItems[detailItemsIndex]?.dandoriDetails?.push(dandoriDetails); // IQX_WEBEST-304 PMXからの見積りができない（「予期せぬエラーが発生しました」と表示される）
              }
              detailItems[detailItemsIndex]?.sagyoDetails?.push(sagyoDetail); // IQX_WEBEST-304 PMXからの見積りができない（「予期せぬエラーが発生しました」と表示される）
            }
          }
        }
      }
    }

    editDetailItems = [];
    let weldingFinishAddition = {
      no: processMst?.id,
      processId: processMst?.id,
      dataType: weldingFinishAdditions?.dataType ? weldingFinishAdditions?.dataType : DataType.Data,
      dataItems: detailItems,
      editItems: weldingFinishAdditions?.editItems ? weldingFinishAdditions?.editItems : editDetailItems,
      totalDandori: {
        dataItemsAmt: 0,
        dataItemsTime: 0,
        editItemsAmt: 0,
        editItemsTime: 0,
      },
      totalSagyo: {
        dataItemsAmt: 0,
        dataItemsTime: 0,
        editItemsAmt: 0,
        editItemsTime: 0,
      },
      totalDataPrice: 0,
      totalDataTime: 0,
      totalEditPrice: 0,
      totalEditTime: 0,
      editDataFlg: false,
    };
    weldingFinishAdditions = weldingFinishAddition;

    if (weldingFinishAdditions?.dataItems) {
      let orgData = JSON.parse(JSON.stringify(weldingFinishAdditions?.dataItems));
      if (deviceMst != undefined) {
        let totalDandori = 0;
        let totalSagyo = 0;
        let totalDandoriTm = 0;
        let dataItemsByProcessInput = [];
        let totalSagyoTm = 0;

        // 数量
        let quantity = orderQuantity;

        let partCnt = curIQ3DataDetail?.partCount;
        // 板厚
        let thickness = parameters?.materialIq3?.filter((item) => item.id == curIQ3DataDetail?.thickness)?.[0]?.info
          ?.thick;

        //材質
        let materialTypeIq3Id = curIQ3DataDetail?.materialTypeIq3Id;

        //材質区分
        let materialTypeId = parameters?.materialTypeIq3?.filter((item) => item.id == materialTypeIq3Id)?.[0]
          ?.materialTypeId;

        // 選択された部品の材質により難易度係数を取得
        let diffCoef = getDiffCoefByWeldFinish(deviceMst, materialTypeId);
        // サイズ係数
        let sizeCoef = getSizeCoeff(deviceMst, weight, area);

        // IQX_WEBEST-278 工程積算タブの表示に生産個数係数が含まれていなかったので、含むようにしてほしい
        let productionQuantityCoeff = getProductionQuantityCoeff(deviceMst, quantity, partCnt);
        let weldIndex = 0;

        //　段取はメイン溶接とサブ溶接の両方が存在している場合でも、いずれか一方のみであっても、段取時間は同じのため、溶接仕上処理により計算
        /** 段取金額/時間 */
        if (orgData?.length > 0) {
          for (let d = 0; d < orgData?.[0]?.dandoriDetails?.length; d++) {
            let dandoriDetailsData = orgData?.[0]?.dandoriDetails[d];
            // 基本段取時間＊サイズ係数
            let dandoriTimeSec = calDandoriTime(
              WorkType.SmWeldFinish,
              deviceMst,
              0,
              weight,
              area,
              dandoriDetailsData?.processDetailTypeId
            );
            let dandoriTime = dandoriTimeSec;
            orgData[0].dandoriDetails[d].dandoriTime = dandoriTime ? dandoriTime : 0;
            // 段取時間の合計
            orgData[0].dandoriTime += orgData[0].dandoriDetails[d].dandoriTime;

            // 段取金額設定
            //段階時間
            let mSetTimeMin = dandoriTimeSec / 60;
            //装置の段階時間チャージ
            let deviceCharges = Object.entries(deviceMst.details?.charges)?.filter(
              ([key, data]) => parseInt(key) === dandoriDetailsData?.processDetailTypeId
            )?.[0];
            let mSetHRate = 0;
            deviceCharges?.map((item, index) => {
              if (index > 0) {
                mSetHRate = item?.prep;
              }
            });
            let dandoriAmt = (mSetTimeMin * mSetHRate) / 60.0; //段階時間＊時間チャージ
            orgData[0].dandoriDetails[d].dandoriAmt = dandoriAmt ? dandoriAmt : 0;
            // 段取金額の合計
            orgData[0].dandoriAmt += orgData[0].dandoriDetails[d].dandoriAmt;
          }
          totalDandori = orgData[0].dandoriAmt ? Math.round(orgData[0].dandoriAmt) : 0;
          totalDandoriTm = orgData[0].dandoriTime;
        }

        //溶接情報設定
        for (let i = 0; i < weldingProcessInput?.details?.[0]?.weldingList?.length; i++) {
          //工程入力情報
          let weldingProcessInputInfo = weldingProcessInput?.details?.[0]?.weldingList[i];
          // 線長の場合、長さ/箇所が０の場合、計算しない。（IQX_WEBEST-54）
          // 仮付けの場合、長さ/箇所が０の場合、計算しない。（IQX_WEBEST-54）
          if (Number(weldingProcessInputInfo?.karitsukeCnt) !== 0 || Number(weldingProcessInputInfo?.senchou) !== 0) {
            let weldProcess = weldProcessMst?.details?.processDetailTypes?.filter(
              (item) => item.id == weldingProcessInputInfo.yousetsuType
            )?.[0];
            let dataItemByProcessInput = orgData?.filter((item) => item.processName === weldProcess?.name);
            if (dataItemByProcessInput?.length > 0) {
              dataItemByProcessInput = dataItemByProcessInput?.[0];
              // 作業時間（バフ加工時間	+	酸洗加工時間	+	サンダー加工時間）
              for (let s = 0; s < dataItemByProcessInput?.sagyoDetails?.length; s++) {
                let sagyoDetailData = dataItemByProcessInput?.sagyoDetails[s];
                // 加工長
                let sagyoLen = Number(weldingProcessInputInfo?.senchou);
                // 溶接線長により該当種類の加工速度を取得
                let sagyoSpeed = getSagyoSpeedByWeld(deviceMst, sagyoLen, sagyoDetailData?.processDetailTypeId);
                // 溶接時間（加工長*回数/加工速度＊難易度係数＊サイズ係数）
                let totalSagyoLen = sagyoLen * Number(weldingProcessInputInfo?.senchouKaisu);
                let sagyoTimeSec = Number.isFinite(totalSagyoLen / sagyoSpeed)
                  ? (totalSagyoLen / sagyoSpeed) * diffCoef * sizeCoef * 60 // IQX_WEBEST-331 加工速度の単位漏れ対応
                  : 0;
                sagyoTimeSec = sagyoTimeSec * productionQuantityCoeff; // IQX_WEBEST-278 加工時間＊生産個数係数
                dataItemByProcessInput.sagyoDetails[s].sagyoTime = sagyoTimeSec ? sagyoTimeSec : 0;
                dataItemByProcessInput.sagyoTime += sagyoTimeSec ? sagyoTimeSec : 0;

                // 作業金額
                let mSagyoTimeMin = sagyoTimeSec / 60; // IQX_WEBEST-331 加工速度の単位漏れ対応
                //装置の作業時間チャージ
                let mSagyoHRate = 0;
                //装置の段階時間チャージ
                let sagyoDeviceCharges = Object.entries(deviceMst.details?.charges)?.filter(
                  ([key, data]) => parseInt(key) === sagyoDetailData?.processDetailTypeId
                )?.[0];
                sagyoDeviceCharges?.map((item, index) => {
                  if (index > 0) {
                    mSagyoHRate = item?.work;
                  }
                });
                let sagyoAmt = (mSagyoTimeMin * mSagyoHRate) / 60.0; //作業時間＊時間チャージ
                dataItemByProcessInput.sagyoDetails[s].sagyoAmt = sagyoAmt ? sagyoAmt : 0;
                dataItemByProcessInput.sagyoAmt += sagyoAmt ? sagyoAmt : 0;
              }
              // 作業金額の合計
              totalSagyo += dataItemByProcessInput.sagyoAmt ? Math.round(dataItemByProcessInput.sagyoAmt) : 0;
              totalSagyoTm += dataItemByProcessInput.sagyoTime;
              dataItemsByProcessInput.push(dataItemByProcessInput);
            }
            weldIndex++;
          }
        }

        //サプ溶接情報設定 IQX_WEBEST-271 サブ溶接にも溶接仕上を出来るようにしてほしい
        for (let i = 0; i < weldingProcessInput?.details?.[0]?.subWeldingList?.length; i++) {
          //工程入力情報
          let weldingProcessInputInfo = weldingProcessInput?.details?.[0]?.subWeldingList[i];
          // 径又はサイズが０の場合、計算しない。（IQX_WEBEST-54）
          if (Number(weldingProcessInputInfo?.total) !== 0) {
            let weldProcess = weldProcessMst?.details?.processDetailTypes?.filter(
              (item) => item.id == weldingProcessInputInfo.subWeldingType
            )?.[0];
            let dataItemByProcessInput = orgData?.filter((item) => item.processName === weldProcess?.name);
            if (dataItemByProcessInput?.length > 0) {
              dataItemByProcessInput = dataItemByProcessInput?.[0];
              // 作業時間（バフ加工時間	+	酸洗加工時間	+	サンダー加工時間）
              for (let s = 0; s < dataItemByProcessInput?.sagyoDetails?.length; s++) {
                let sagyoDetailData = dataItemByProcessInput?.sagyoDetails[s];
                let daimeter = Number(weldingProcessInputInfo?.daimeter);
                // 作業時間（加工時間*回数）
                let sagyoKaisu = Number(weldingProcessInputInfo?.total);
                let sagyoTimeSec = calSagyoTimeByWeldFinsih(
                  deviceMst,
                  0,
                  thickness,
                  daimeter,
                  sagyoDetailData?.processDetailTypeId,
                  weldingProcessInputInfo?.subWeldingType,
                  weldingProcessInputInfo?.subWeldingGroupType
                );
                sagyoTimeSec = sagyoTimeSec * sagyoKaisu * diffCoef * sizeCoef; // IQX_WEBEST-271 加工時間＊回数＊難易度係数＊サイズ係数
                dataItemByProcessInput.sagyoDetails[s].sagyoTime = sagyoTimeSec ? sagyoTimeSec : 0;
                dataItemByProcessInput.sagyoTime += sagyoTimeSec ? sagyoTimeSec : 0;

                // 作業金額
                let mSagyoTimeMin = sagyoTimeSec / 60;
                //装置の作業時間チャージ
                let mSagyoHRate = 0;
                //装置の段階時間チャージ
                let sagyoDeviceCharges = Object.entries(deviceMst.details?.charges)?.filter(
                  ([key, data]) => parseInt(key) === sagyoDetailData?.processDetailTypeId
                )?.[0];
                sagyoDeviceCharges?.map((item, index) => {
                  if (index > 0) {
                    mSagyoHRate = item?.work;
                  }
                });
                let sagyoAmt = (mSagyoTimeMin * mSagyoHRate) / 60.0; //作業時間＊時間チャージ
                dataItemByProcessInput.sagyoDetails[s].sagyoAmt = sagyoAmt ? sagyoAmt : 0;
                dataItemByProcessInput.sagyoAmt += sagyoAmt ? sagyoAmt : 0;
              }
              // 作業金額の合計
              totalSagyo += dataItemByProcessInput.sagyoAmt ? Math.round(dataItemByProcessInput.sagyoAmt) : 0;
              totalSagyoTm += dataItemByProcessInput.sagyoTime;
              dataItemsByProcessInput.push(dataItemByProcessInput);
            }
          }
        }
        /** 　合計時間　*/
        let totalTimeSum = totalDandoriTm + totalSagyoTm;
        weldingFinishAdditions.totalDataTime = totalTimeSum;
        weldingFinishAdditions.totalDandori.dataItemsTime = totalDandoriTm;
        weldingFinishAdditions.totalSagyo.dataItemsTime = totalSagyoTm;

        /** 　合計金額　*/
        let totalSum = (totalDandori ? Math.round(totalDandori) : 0) + (totalSagyo ? Math.round(totalSagyo) : 0); // 画面表示用（四捨五入した金額を足す）
        weldingFinishAdditions.totalDataPrice = totalSum;
        weldingFinishAdditions.totalDandori.dataItemsAmt = totalDandori;
        weldingFinishAdditions.totalSagyo.dataItemsAmt = totalSagyo;

        orgData = dataItemsByProcessInput;
        weldingFinishAdditions.dataItems = orgData;
        weldFinishAddition = weldingFinishAdditions;
      }
    }
  }
  return weldFinishAddition;
};

const calSagyoTimeByWeldFinsih = (deviceMst, thickness, size, processDetailTypeId, weldTypeId, weldTypeGroupId) => {
  if (deviceMst == undefined || deviceMst?.length == 0) return 0;
  // 加工時間
  let kakouTimeItem = deviceMst?.details?.kakouTimeItems?.filter(
    (item) => item.kouteiTypeId === processDetailTypeId
  )?.[0];
  let instThMaster = [];
  let instThItems = [];
  if (weldTypeGroupId == WorkTypeGroup.SmInstThick) {
    instThMaster = kakouTimeItem?.instThickThMaster?.filter(
      (item) => (item.value == thickness || item.value > thickness) && !item.isOutOfRange
    )?.[0];
    if (kakouTimeItem?.instThickThItems) {
      instThItems = Object.entries(kakouTimeItem?.instThickThItems)?.filter(
        ([key, data]) => parseInt(key) === weldTypeId
      )?.[0];
    }
  } else {
    instThMaster = kakouTimeItem?.instSizeThMaster?.filter(
      (item) => (item.value == size || item.value > size) && !item.isOutOfRange
    )?.[0];
    if (kakouTimeItem?.instSizeThItems) {
      instThItems = Object.entries(kakouTimeItem?.instSizeThItems)?.filter(
        ([key, data]) => parseInt(key) === weldTypeId
      )?.[0];
    }
  }

  // 加工時間
  let sagyoTime = 0;
  instThItems?.map((item, index) => {
    if (index > 0) {
      sagyoTime = item?.filter((item) => parseInt(item.no) == instThMaster?.no && !item.isOutOfRange)?.[0]?.value;
    }
  });
  sagyoTime = sagyoTime ? sagyoTime : 0;
  return sagyoTime;
};
