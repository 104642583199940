import React, { useState } from 'react';
import { Form, Input, Button, Typography, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import { inquiry } from './common/CommonAPI';

const { Title } = Typography;

/**
 * クラス名：資料請求フォーム
 * 説明：資料請求フォームに関して問い合わせ
 * 作成者：ナンス
 * 作成日：2025/02/12
 * バージョン：1.0
 */
const InquireForm = () => {
  const [form] = Form.useForm();

  const [zip, setZip] = useState('');
  const [address, setAddress] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  /**
   * 指定された郵便番号に基づいて住所情報を取得します。
   *
   * @param {string} zipCode - 住所を取得するために使用される 7 桁の郵便番号。
   */
  const fetchAddress = async (zipCode) => {
    if (zipCode.length === 7) {
      try {
        const response = await fetch(`https://zipcloud.ibsnet.co.jp/api/search?zipcode=${zipCode}`);
        const data = await response.json();
        if (data.results) {
          const result = data.results[0];
          const fullAddress = `${result.address1} ${result.address2} ${result.address3}`;

          setAddress(fullAddress);
          form.setFieldsValue({ address: fullAddress });
        } else {
          setAddress('');
          form.setFieldsValue({ address: '' });
        }
      } catch (error) {
        console.error('住所取得エラー:', error);
      }
    }
  };

  /**
   * 資料請求フォームに関して問い合わせする
   *
   * @param {object} values - メールアドレス、会社名、部署名、役職、御名前、郵便番号、住所、TEL
   */
  const onFinish = async (values) => {
    // 送信開始する
    setLoading(true);
    try {
      // 問い合わせする
      const inquiryInfo = await inquiry(values);
      if (inquiryInfo) {
        // 初期状態に戻る
        form.resetFields();
        setZip('');
        setAddress('');
        // 問い合わせ完了画面に移動する
        navigate('/inquiry-complete');
      }
    } catch (error) {
      console.error('Inquiry submission failed:', error);
    } finally {
      // 送信終了する
      setLoading(false);
    }
  };

  return (
    <div style={{ maxWidth: 600, margin: '0 auto', padding: 30 }}>
      <Spin spinning={loading} tip="送信中..." size="large">
        <Title level={2} style={{ textAlign: 'center', fontWeight: 'bold' }}>
          資料請求フォーム
        </Title>
        <Form
          form={form}
          layout="horizontal"
          labelCol={{ span: 6, style: { textAlign: 'left' } }}
          wrapperCol={{ span: 19 }}
          onFinish={onFinish}
          className="inquiry-form"
        >
          {/* メールアドレス */}
          <Form.Item
            label="メールアドレス"
            name="email"
            className="form-item-with-dotted-line"
            style={{ paddingTop: 20 }}
            rules={[
              { required: true, message: 'メールアドレスを入力してください' },
              {
                type: 'email',
                message: '正しいメールアドレスを入力してください（例: example@example.com）',
              },
            ]}
          >
            <Input style={{ width: '80%' }} />
          </Form.Item>
          {/* 会社名 */}
          <Form.Item
            label="会社名"
            name="company_name"
            className="form-item-with-dotted-line"
            rules={[{ required: true, message: '会社名を入力してください' }]}
          >
            <Input style={{ width: '80%' }} />
          </Form.Item>
          {/* 部署名 */}
          <Form.Item label="部署名" name="department" className="form-item-with-dotted-line">
            <Input style={{ width: '80%' }} />
          </Form.Item>
          {/* 役職 */}
          <Form.Item label="役職" name="position" className="form-item-with-dotted-line">
            <Input style={{ width: '80%' }} />
          </Form.Item>
          {/* 御名前 */}
          <Form.Item
            label="御名前"
            name="fullName"
            className="form-item-with-dotted-line"
            rules={[{ required: true, message: '御名前を入力してください' }]}
            help={<span className="inquiry-help-text">※御名間には空白をお願いします。</span>}
          >
            <Input style={{ width: '80%' }} />
          </Form.Item>
          {/* 電話番号 */}
          {/* Landline: 0X-XXXX-XXXX (e.g., 03-1234-5678)
          Mobile: 090-1234-5678, 080-1234-5678, 070-1234-5678
          Without hyphens: 0312345678, 09012345678 */}
          <Form.Item
            label="TEL"
            name="tel"
            className="form-item-with-dotted-line"
            rules={[
              {
                required: true,
                message: '電話番号を入力してください',
              },
              {
                pattern: /^0\d{1,4}-?\d{1,4}-?\d{4}$/,
                message: '正しい電話番号を入力してください（例: 080-1234-5678）',
              },
            ]}
          >
            <Input style={{ width: '50%' }} />
          </Form.Item>
          {/* 郵便番号 */}
          <Form.Item
            label="郵便番号"
            name="postalCode"
            className="form-item-with-dotted-line"
            help={<span className="inquiry-help-text">※郵便番号から住所自動入力</span>}
          >
            <Input
              style={{ width: '50%' }}
              type="text"
              maxLength="7"
              value={zip}
              onChange={(e) => {
                const value = e.target.value.replace(/[^0-9]/g, '');
                setZip(value);
                fetchAddress(value);
              }}
            />
          </Form.Item>
          {/* 住所 */}
          <Form.Item label="住所" name="address" className="form-item-with-dotted-line">
            <Input style={{ width: '80%' }} value={address} />
          </Form.Item>
          {/* 送信ボタン */}
          <Form.Item wrapperCol={{ offset: 9 }} style={{ paddingTop: 10 }}>
            <Button className="inquiry-button" htmlType="submit">
              送信
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};

export default InquireForm;
