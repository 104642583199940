/**
 * クラス名：システム全体の共通ファイル
 * 説明：システム全体に共通使っているファイルである。
 * 作成者：ナンス
 * 作成日：2024/11/25
 * バージョン：1.0
 */
import dayjs from 'dayjs';
import {
  DataType,
  estimateInitInfo,
  iq3EstimateInitInfo,
  IQxRealGoku_AccessToken,
  WorkType,
  WorkTypeGroup,
  BendingTypes,
  BlankTypes,
  FRBend,
  Types,
  PatternType,
  ClientType,
  ServiceClass,
} from './Constant';
import {
  EstimateStatus,
  FinalPurposClass,
  MaterialClass,
  OrderClass,
  ProcessType,
  SurfaceProtectionType,
  ToleranceClass,
  UploadStatus,
  WeldFinishLevelClass,
} from './enums';
import axios from 'axios';

// セクションにトークン情報を更新する
export const updateAccessToken = (token) => {
  localStorage.setItem(IQxRealGoku_AccessToken, token);
};

// 最新のセクションにトークン情報を取得する
export const getAccessToken = () => {
  return localStorage?.getItem(IQxRealGoku_AccessToken);
};

export const secondsToHms = (totalIncSec) => {
  if (totalIncSec > 0) {
    var h = Math.floor(totalIncSec / 3600).toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
    var m = Math.floor((totalIncSec % 3600) / 60).toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
    var s = Math.round((totalIncSec % 3600) % 60).toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
    return h + ':' + m + ':' + s;
  } else {
    return '00:00:00';
  }
};

export const setTimetoSec = (timeStr) => {
  if (timeStr == undefined || timeStr == '') return 0;
  var splitTime = timeStr.split(':');
  var seconds = +splitTime[0] * 60 * 60 + +splitTime[1] * 60 + +splitTime[2];
  return seconds;
};

export const isNumber = (char) => {
  return /^\d$/.test(char);
};

// 現在の日付「YYYY/MM/DD」
export const today = new Date().toLocaleString('ja-JP', {
  timeZone: 'Asia/Tokyo',
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
});

// 現在の日付「YYYY/MM/DD hhmmss」
export const todayTime = () => {
  return new Date().toLocaleString('ja-JP', {
    timeZone: 'Asia/Tokyo',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    format: 'YYYYMMDD hhmmss',
  });
};

// YYYY/MM/DDへ変換
export function formatDate(date) {
  return new Date(date).toLocaleString('ja-JP', {
    timeZone: 'Asia/Tokyo',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
}

// 現在のタイム「時分秒」
export const currentTime = () => {
  return new Date().toLocaleString('ja-JP', {
    timeZone: 'Asia/Tokyo',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    format: 'hhmmss',
  });
};

// 現在の日付「年月日」
export const currentDate = () => {
  return new Date().toLocaleString('ja-JP', {
    timeZone: 'Asia/Tokyo',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    format: 'YYYYMMDD',
  });
};

export const generateTimestamp = () => {
  const now = new Date();
  const yyyy = now.getFullYear();
  const mm = String(now.getMonth() + 1).padStart(2, '0');
  const dd = String(now.getDate()).padStart(2, '0');
  const hh = String(now.getHours()).padStart(2, '0');
  const min = String(now.getMinutes()).padStart(2, '0');
  const ss = String(now.getSeconds()).padStart(2, '0');
  const fff = String(now.getMilliseconds()).padStart(3, '0');
  const randomDigits = Math.floor(100 + Math.random() * 900); // Random 3-digit number

  return `${yyyy}${mm}${dd}${hh}${min}${ss}${fff}${randomDigits}`;
};

export function formatDateString(date, sep = '') {
  let dateString = date.replace(/[/:]+/g, '');
  dateString = dateString ? dateString : '';
  return dateString;
}

// 先頭に０を埋める ####0
export function padWithLeadingZeros(num, totalLength) {
  return String(num).padStart(totalLength, '0');
}

// 小数点１へ変換する
export const toDecimal = (value, places = 1) => {
  if (!isDecimal(value)) return value;
  if (value?.length <= 0) value = 0;
  value = value ? Math.round(value * 100) / 100 : 0;
  value = value?.toFixed(places);
  return value;
};

// 小数点のチェック
export function isDecimal(val) {
  // 小数点
  let regStr = /^\d*\.?\d*$/;
  return regStr.test(val);
}

export const JPYs = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'JPY',
});

// ブランクの種類.形状により、データ設定
export const getSize = (id, size) => {
  return id === 3 || id === 4 ? toDecimal(size || 0, 2) : toDecimal(0, 2);
};
export const getDiameter = (id, xl) => {
  return id <= 2 ? toDecimal(parseFloat(xl) || 0, 2) : toDecimal(0, 2);
};

export const getRequestHeader = (contentType = 'multipart/form-data') => {
  const accessToken = getAccessToken();
  const header = {
    'Content-Type': contentType,
    Authorization: `Bearer ${accessToken}`,
    'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
  };
  return header;
};
export const getOrderConditionsName = (inputId, list) => {
  const order = list?.find((item) => item.id === inputId);
  return order ? order.name : '';
};

// 発注分類を取得する
export const getOrderName = (orderId) => {
  const order = OrderClass?.find((item) => item.id === orderId);
  return order ? order.name : '';
};

// 材質を取得する
export const getMaterialName = (materialId, list) => {
  const material = list?.find((item) => item.id === materialId);
  return material ? material.name : '';
};

// 処理を取得する
export const getProcessName = (processId) => {
  const process = ProcessType?.find((item) => item.id === processId);
  return process ? process.name : '';
};

// 公差を取得する
export const getToleranceName = (toleranceId) => {
  const tolerance = ToleranceClass?.find((item) => item.id === toleranceId);
  return tolerance ? tolerance.name : '';
};

// 仕上げレベルを取得する
export const getWeldFinishLevelName = (weldId) => {
  const weldFinish = WeldFinishLevelClass?.find((item) => item.id === weldId);
  return weldFinish ? weldFinish.name : '';
};

// 最終用途を取得する
export const getFinalPurposName = (finalPurposId) => {
  const finalPurpos = FinalPurposClass?.find((item) => item.id === finalPurposId);
  return finalPurpos ? finalPurpos.name : '';
};

// 状態を取得する
export const getStatusName = (statusId) => {
  const status = UploadStatus?.find((item) => item.id === statusId);
  return status ? status.name : '';
};

// 見積状態を取得する
export const getEstimateStatusName = (statusId) => {
  const status = EstimateStatus?.find((item) => item.id === statusId);
  return status ? status.name : '';
};

// 新規見積データ作成
export const getNewEstimateInfo = (iq3Count, newParameters, orderCondition) => {
  let orderCoeffs = [];
  let deliDateCoeffs = [];
  let purCoeffs = [];
  let mngCoeffs = [];
  let costLst = [];
  let labelLst = [];
  let memoLst = [];
  let dateLst = [];
  let clientInfo = newParameters.client;
  let companyInfo = newParameters.company;
  let processInfo = newParameters.process;
  let deviceMasterInfo = newParameters.device;
  let processPattern = newParameters.processPattern;
  let estimateStatus = newParameters.estimateStatus;
  let purchaseAllocation = newParameters.purchaseAllocation;
  let purchaseCategoryInfo = newParameters.purchaseCategory;
  let purchasesData = newParameters.purchase;
  let staffList = newParameters.staff;
  let selectedStaff = staffList?.filter((i) => i?.info?.isUsed);
  let userSettingData = newParameters.userSettings;
  let companySettingData = newParameters.companySettings;
  //板金パラメータ情報
  let materialIq3 = newParameters.materialIq3;
  let materialSizeIq3 = newParameters.materialSizeIq3;
  let materialSurfaceIq3 = newParameters.materialSurfaceIq3;
  let materialType = newParameters.materialType;
  let materialTypeIq3 = newParameters.materialTypeIq3;

  //見積条件により材料パラメータ情報取得
  // 該当材質情報
  let reqMaterialTypeIq3Id = orderCondition?.material;
  materialTypeIq3 = materialTypeIq3?.filter(
    (item) => item.id === reqMaterialTypeIq3Id && !item.isDeleted && item.info.isUsed
  );
  // 材料情報
  materialIq3 = materialIq3?.filter(
    (item) => item.info.isUsed && item.materialTypeIq3Id === (materialTypeIq3?.length > 0 ? materialTypeIq3[0]?.id : 0)
  );
  // 材料表面情報
  materialSurfaceIq3 = materialSurfaceIq3?.filter(
    (item) => item.info.isUsed && item.materialTypeIq3Id === (materialTypeIq3?.length > 0 ? materialTypeIq3[0]?.id : 0)
  );
  //　条件個数
  let orderConditionQuantity = orderCondition.quantity;

  let clientData = clientInfo?.filter((i) => !i.isDeleted && i?.info?.isUsed);
  let client = clientData?.length > 0 ? clientData[0] : {};
  if (companyInfo?.length > 0) {
    orderCoeffs = companyInfo[0].coeffOrders?.filter((i) => !i.isDeleted && i.isUsed);
    deliDateCoeffs = companyInfo[0].coeffDeliveryDates?.filter((i) => !i.isDeleted && i.isUsed);
    purCoeffs = companyInfo[0].coeffPurchases?.filter((i) => !i.isDeleted && i.isUsed);
    mngCoeffs = companyInfo[0].coeffManagements?.filter((i) => !i.isDeleted && i.isUsed);

    costLst = companyInfo[0]?.additionalItemCosts
      ?.filter((item) => item.isUsed && !item.isDeleted)
      ?.map((item) => {
        return initAdditionalItemCost(item.id, item.code, item.name);
      });

    labelLst = companyInfo[0]?.additionalItemLabels
      ?.filter((item) => item.isUsed && !item.isDeleted)
      ?.map((item) => {
        return initAdditionalItemLabel(item.id, item.code, item.name);
      });

    memoLst = companyInfo[0]?.additionalItemMemos
      ?.filter((item) => item.isUsed && !item.isDeleted)
      ?.map((item) => {
        return initAdditionalItemMemo(item.id, item.code, item.name);
      });

    dateLst = companyInfo[0]?.additionalItemDates
      ?.filter((item) => item.isUsed && !item.isDeleted)
      ?.map((item) => {
        return initAdditionalItemDate(item.id, item.code, item.name);
      });
  }

  let parameters = {
    company: companyInfo,
    client: clientInfo,
    process: processInfo,
    device: deviceMasterInfo,
    processPattern: processPattern,
    estimateStatus: estimateStatus,
    purchaseAllocation: purchaseAllocation,
    purchaseCategory: purchaseCategoryInfo,
    purchase: purchasesData,
    staff: staffList,
    materialIq3: materialIq3,
    materialSizeIq3: materialSizeIq3,
    materialSurfaceIq3: materialSurfaceIq3,
    materialType: materialType,
    materialTypeIq3: materialTypeIq3,
  };

  let settings = {
    userSettings: userSettingData,
    companySettings: companySettingData,
  };

  // 板金
  let iq3Data = [...iq3EstimateInitInfo];

  let newIQ3Data = getNewIQ3EstimateInfo(iq3Data, parameters);

  let newIQ3 = JSON.parse(JSON.stringify(newIQ3Data));
  const aggregatedObject = newIQ3?.reduce((result, currentItem) => {
    return { ...result, ...currentItem };
  }, {});
  const iq3ItemList = [];

  for (let i = 1; i <= iq3Count; i++) {
    let aggregatedObjectIQ3 = JSON.parse(JSON.stringify(aggregatedObject));
    //  CardDetailsの行により、板金データ作成
    iq3ItemList.push({ ...aggregatedObjectIQ3, no: i });
  }
  return {
    ...estimateInitInfo,
    estimateProducts: {
      ...estimateInitInfo.estimateProducts,
      drawingNo: 'Product-' + formatDateString(todayTime()),
      name: 'Product-' + formatDateString(todayTime()),
      quantity: orderConditionQuantity,
      clientId: client ? client?.id : 0,
      clientName: client ? client?.name : '',
      coeffOrdersId: orderCoeffs?.length > 0 ? orderCoeffs?.[0].id : undefined,
      coeffOrdersName: orderCoeffs?.length > 0 ? orderCoeffs?.[0].name : '',
      coeffDeliveryDatesId: deliDateCoeffs?.length > 0 ? deliDateCoeffs?.[0].id : undefined,
      coeffDeliveryDatesName: deliDateCoeffs?.length > 0 ? deliDateCoeffs?.[0].name : '',
      coeffPurchasesId: purCoeffs?.length > 0 ? purCoeffs?.[0].id : undefined,
      coeffPurchasesName: purCoeffs?.length > 0 ? purCoeffs?.[0].name : '',
      coeffManagementsId: mngCoeffs?.length > 0 ? mngCoeffs?.[0].id : undefined,
      coeffManagementsName: mngCoeffs?.length > 0 ? mngCoeffs?.[0].name : '',
      estimateNo: formatDateString(todayTime()),
      estimateStatusId: estimateStatus?.length > 0 ? estimateStatus?.[0]?.id : '',
      estimateStatus: estimateStatus?.length > 0 ? estimateStatus?.[0]?.name : '',
      otherProduct: {
        adjustment: 0,
        adjustmentMethod: '+',
        coeffClient: client
          ? toDecimal(client?.coeffClients?.find((item) => item.clientType === ClientType.Customer)?.coeff)
          : toDecimal(0, 2),
        coeffOrders: orderCoeffs?.length > 0 ? toDecimal(orderCoeffs?.[0].coeff) : 0.0,
        coeffDeliveryDates: deliDateCoeffs?.length > 0 ? toDecimal(deliDateCoeffs?.[0].coeff) : 0.0,
        coeffPurchases: purCoeffs?.length > 0 ? toDecimal(purCoeffs?.[0].coeff) : 0.0,
        coeffManagements: mngCoeffs?.length > 0 ? toDecimal(mngCoeffs?.[0].coeff) : 0.0,
      },
      // processInput: {
      //   surfaceProcessInputInfo: processInputSurfaceData,
      //   assenbleProcessInputInfo: {
      //     no: 1,
      //     calcType: 1,
      //     smPartsCount: 1,
      //     purchaseInstTime: 0,
      //     assyList: [{ no: 1, name: '', workTime: 0, workCount: 0, workTotalTime: 0 }],
      //     autoExtract: 1, // IQX_WEBEST-179
      //   },
      //   inspectionProcessInputInfo: {
      //     no: 1,
      //     calcType: 1,
      //     smPartsCount: 1,
      //     autoExtract: 1, // IQX_WEBEST-179
      //   },
      //   packingProcessInputInfo: {
      //     no: 1,
      //     calcType: 1,
      //     smPartsCount: 1,
      //     autoExtract: 1, // IQX_WEBEST-179
      //   },
      // },
      additions: [],
      purchases: [],
      byQuantities: {},
      calcParameters: {
        settings: settings,
        parameters: parameters,
      },
    },
    epCustomCosts: costLst,
    epCustomDates: dateLst,
    epCustomLabels: labelLst,
    epCustomMemos: memoLst,
    esIq3Info: iq3ItemList,
  };
};
// 板金見積データ作成
export const getNewIQ3EstimateInfo = (lists, parameters) => {
  const patternLst = parameters?.processPattern; // 工程パターンマスタデータ
  let kouteiPatternList = patternLst;
  const materialType = parameters?.materialTypeIq3; // 材質マスタデータ
  let materialTypeList = materialType?.filter((item) => item.info.isUsed);

  const material = parameters?.materialIq3; // 材料マスタデータ
  let materialList = material?.filter(
    (item) =>
      item.info.isUsed && item.materialTypeIq3Id === (materialTypeList?.length > 0 ? materialTypeList[0]?.id : 0)
  );

  const materialSurface = parameters?.materialSurfaceIq3; // 材料表面マスタデータ
  let materialSurfaceList = materialSurface?.filter(
    (item) =>
      item.info.isUsed && item.materialTypeIq3Id === (materialTypeList?.length > 0 ? materialTypeList[0]?.id : 0)
  );

  // 工程マスタ情報取得
  let processMaster = [];
  const processMstData = parameters?.process; // 工程マスタデータ
  processMaster = processMstData;
  let blankIsUsedData = [];
  processMaster?.filter((i) => {
    if (
      i.workType === WorkType.SmBlankCombi ||
      i.workType === WorkType.SmBlankLaser ||
      i.workType === WorkType.SmBlankPunch
    ) {
      blankIsUsedData.push(i.id);
      // return i.id;
    }
  });

  // 工程入力
  let processSelectDetails = kouteiPatternList?.[0]?.details;

  let kouteiInputArr = [];
  if (processSelectDetails) {
    kouteiInputArr = processSelectDetails?.map((item, index) => {
      const processMstInfo = processMaster?.filter((i) => i.id === item.processId)?.[0] || [];

      const workType = processMstInfo.workType || '';

      let data = {
        id: index + 1,
        processId: item.processId,
        details: getDetails(workType, parameters, processSelectDetails),
      };

      return data;
    });
  }

  //　工程積算
  let kouteiCalArr = [];
  if (processSelectDetails) {
    kouteiCalArr = processSelectDetails?.map((item, index) => {
      const processMstInfo = processMaster?.filter((i) => i.id === item.processId)?.[0] || [];

      const workType = processMstInfo.workType || '';
      let dataItems = [];
      if (item.multiprocesses?.length > 0) {
        for (let m = 0; m < item.multiprocesses?.length; m++) {
          let dataItem = {
            id: m + 1,
            processName: processMstInfo?.name,
            processDetailTypeNo: item.multiprocesses[m]?.processDetailTypeId,
            dandoriAmt: 0,
            dandoriTime: 0,
            sagyoAmt: 0,
            sagyoTime: 0,
            processDetailWorkTypeGroup: item.multiprocesses[m]?.processDetailGroup,
            kasho: 1,
            isCommission: false,
            supplier: '',
            supplierCode: '',
            formula: '',
          };
          dataItems.push(dataItem);
        }
      }

      let addition = {
        no: index + 1,
        processId: item.processId,
        dataType: DataType.Data,
        dataItems: dataItems,
        editItems: [],
        totalDandori: {
          dataItemsAmt: 0,
          dataItemsTime: 0,
          editItemsAmt: 0,
          editItemsTime: 0,
        },
        totalSagyo: {
          dataItemsAmt: 0,
          dataItemsTime: 0,
          editItemsAmt: 0,
          editItemsTime: 0,
        },
        totalDataPrice: 0,
        totalDataTime: 0,
        totalEditPrice: 0,
        totalEditTime: 0,
      };

      return addition;
    });
  }

  let blankProcess = getProcessRecord(
    ServiceClass.SheetMetal,
    [WorkType.SmBlankLaser, WorkType.SmBlankPunch, WorkType.SmBlankCombi], // レーザー、パンチ、複合機
    processMstData
  );
  // 板金工程選択にブランクを使用している場合、
  let usedBlankProcess = processSelectDetails?.find(
    (selectItem) => selectItem.isUsedForCalc && blankProcess?.findIndex((item) => item.id === selectItem.processId) > -1
  );
  // 使用している装置の情報を取得する
  let usedDeviceInfo = parameters?.device?.find((device) => device.id === usedBlankProcess?.deviceId);
  let grabbingX = 0;
  let grabbingY = 0;
  let pierWidthX = 0;
  let pierWidthY = 0;
  let worlRangeX = 0;
  let worlRangeY = 0;
  // 装置がある場合、
  if (usedDeviceInfo) {
    // 装置から材料配置情報
    let materialArrange = usedDeviceInfo?.details?.materialArrange;
    grabbingX = materialArrange?.graspAllowanceX;
    grabbingY = materialArrange?.graspAllowanceY;
    pierWidthX = materialArrange?.xWidth;
    pierWidthY = materialArrange?.yWidth;
    worlRangeX = materialArrange?.workRangeX;
    worlRangeY = materialArrange?.workRangeY;
  }

  // 材料詳細パターン情報を準備する
  let materialDetailPatterns = [];

  // 材料詳細サイズリスト情報を準備する
  let materialDetailSizes = [];
  let materialSizeIq3Infos = parameters?.materialSizeIq3;

  let data = JSON.parse(JSON.stringify(lists));
  const updatedList = data?.map((i, index) => {
    if (i?.id === 0) {
      materialDetailSizes = [];
      let materialTypeIq3Id = materialTypeList?.length > 0 ? materialTypeList[0]?.id : 0;
      if (materialSizeIq3Infos) {
        materialDetailSizes = materialSizeIq3Infos
          ?.filter(
            (item) =>
              item.info.isUsed &&
              item.name !== 'Free' &&
              item?.materialTypeIq3Id?.findIndex(
                (id) => id === (i.materialTypeIq3Id === 0 ? materialTypeIq3Id : i.materialTypeIq3Id)
              ) > -1
          )
          ?.map((item, index) => {
            let data = getInitMaterialSizeInfo(index, item);
            let copyItem = JSON.parse(JSON.stringify(item));
            if (data?.sizeName === 'Other') {
              data = { ...data, isUsed: true };
              // /材料サイズX計算
              let sheetX = Number(0) + Number(pierWidthX) + Number(grabbingX);
              // /材料サイズY計算
              let sheetY = Number(0) + Number(pierWidthY) + Number(grabbingY);
              copyItem = { ...copyItem, info: { ...copyItem.info, sizeX: sheetX, sizeY: sheetY } };
            }
            materialDetailPatterns = getInitMaterialPatternsInfo(materialDetailPatterns, copyItem);
            return data;
          });
      }

      i.no = 1;
      i.creationType = 1;
      i.drawingNo = 'Part_' + formatDateString(todayTime());
      i.name = 'Part_' + padWithLeadingZeros(index + 1, 5);
      i.surfaceProtection = SurfaceProtectionType.OneSideVinyl;
      i.materialIq3Id = materialList?.length > 0 ? materialList[0]?.id : 0;
      i.materialName = materialList?.length > 0 ? materialList[0]?.name : '';
      i.materialTypeIq3Id = materialTypeList?.length > 0 ? materialTypeList[0]?.id : 0;
      i.materialTypeName = materialTypeList?.length > 0 ? materialTypeList[0]?.name : '';
      i.materialSurfaceIq3Id = materialSurfaceList?.length > 0 ? materialSurfaceList[0]?.id : 0;
      i.materialSurfaceName = materialSurfaceList?.length > 0 ? materialSurfaceList[0]?.name : '';
      i.thickness = materialList?.length > 0 ? materialList[0]?.id : 0;
      i.processSelect = {
        id: kouteiPatternList?.length > 0 ? kouteiPatternList[0]?.id : 0,
        name: kouteiPatternList?.length > 0 ? kouteiPatternList[0]?.name : '',
        details: kouteiPatternList?.length > 0 ? kouteiPatternList[0]?.details : [],
        modified: kouteiPatternList?.length > 0 ? kouteiPatternList[0]?.modified : dayjs().format(),
      };
      i.processInput = kouteiInputArr;
      i.purchases = [];
      i.additions = kouteiCalArr;
      i.materialDetailPatterns = materialDetailPatterns;
      i.materialDetailSizes = materialDetailSizes;
      i.weight = 0;
      i.totalSurfaceArea = 0;
      i.partSizeX = 0;
      i.partSizeY = 0;
      i.partCount = 1;
      i.grabbingX = grabbingX;
      i.grabbingY = grabbingY;
      i.pierWidthX = pierWidthX;
      i.pierWidthY = pierWidthY;
      i.worlRangeX = worlRangeX;
      i.worlRangeY = worlRangeY;
      i.workCostUnitPrice = 0;
      i.workCostTotalPrice = 0;
      i.costUnitPrice = 0;
      i.costTotalPrice = 0;
      i.surfaceTotalPrice = 0;
      i.surfaceUnitPrice = 0;
      i.purchaseUnitPrice = 0;
      i.purchaseTotalPrice = 0;
      i.materialCostUnitPrice = 0;
      i.materialCostTotalPrice = 0;
    }
    return i;
  });
  return updatedList;
};

// 工程マスタのレコードIDを取得する
const getProcessRecord = (classId, processTypes, mstInfo) => {
  // 選択された、種別と工程より工程マスタレコードIDを得る
  let mstRecordInfo = [...mstInfo]?.filter(
    (item) => item?.class === classId && processTypes?.findIndex((workType) => workType === item?.workType) > -1
  );
  return mstRecordInfo;
};

// 板金見積の工程入力データ作成
export const getDetails = (workType, param, processSelect) => {
  let parameters = param;

  let subWeldingType = 0;
  let subWeldingGroupType = 0;
  let weldingType = 0;
  let weldingGroupType = 0;
  let secondaryWorkProcessDetailsId = 1;
  let secondaryWorkProcessDetailsGroup = 10310;
  const weldingDetails = parameters?.process?.filter((i) => i.workType === WorkType.SmWelding);
  const secondaryWorkDetails = parameters?.process?.filter((i) => i.workType === WorkType.SmSecondaryWork);
  if (secondaryWorkDetails?.length > 0) {
    secondaryWorkProcessDetailsId = secondaryWorkDetails?.[0]?.details?.processDetailTypes?.[0]?.id;
    secondaryWorkProcessDetailsGroup = secondaryWorkDetails?.[0]?.details?.processDetailTypes?.[0]?.group;
  }
  if (weldingDetails?.length > 0) {
    let subYousetsu = weldingDetails?.[0]?.details?.processDetailTypes?.filter(
      (i) => (i.group === WorkTypeGroup.SmInstSize && i.isUsed) || (i.group === WorkTypeGroup.SmInstThick && i.isUsed)
    );
    if (subYousetsu?.length > 0) {
      subWeldingType = subYousetsu?.[0]?.id;
      subWeldingGroupType = subYousetsu?.[0]?.group;
    }

    let yousetsu = weldingDetails?.[0]?.details?.processDetailTypes?.filter(
      (i) => i.group === WorkTypeGroup.SmWelding && i.isUsed
    );
    if (yousetsu?.length > 0) {
      weldingType = yousetsu?.[0]?.id;
      weldingGroupType = yousetsu?.[0]?.group;
    }
  }

  // 表面処理
  const surfaceDetails = parameters?.process?.filter((i) => i.workType === WorkType.SmSurface);
  let surfaceProcessId = 0;
  if (surfaceDetails?.length > 0) {
    surfaceProcessId = surfaceDetails?.[0].id;
  }
  let surface = [];
  surface = processSelect?.filter((i) => i.processId === surfaceProcessId);
  let surfaceDataFromSelect = [];
  if (surface?.length > 0) {
    surfaceDataFromSelect = surface[0]?.multiprocesses?.filter(
      (i) => i.processDetailGroup != 0 && i.processDetailTypeId != 0
    );
  }
  // ***

  if (workType === WorkType.SmProgram) {
    return [{ elements: 0, elementsAuto: 0 }];
  } else if (
    workType === WorkType.SmBlankCombi ||
    workType === WorkType.SmBlankLaser ||
    workType === WorkType.SmBlankPunch
  ) {
    return [
      {
        dimensionData: {
          outerDia: toDecimal(0, 2),
          outerAutoExtract: toDecimal(0, 2),
          innerDia: toDecimal(0, 2),
          innerAutoExtract: toDecimal(0, 2),
          airFeedDistance: toDecimal(0, 2),
          airFeedAutoExtract: toDecimal(0, 2),
          etchingLength: toDecimal(0, 2),
        },
        quantityList: [
          {
            id: 1,
            types: 1,
            typesAutoExtract: '',
            diameter: toDecimal(0, 2),
            diameterAutoExtract: '',
            xsize: toDecimal(0, 2),
            xsizeAutoExtract: '',
            ysize: toDecimal(0, 2),
            ysizeAutoExtract: '',
            perimeter: toDecimal(0, 2),
            perimeterAutoExtract: '',
            count: 1,
            countAutoExtract: '',
          },
        ],
      },
    ];
  } else if (workType === WorkType.SmSecondaryWork) {
    return [
      {
        id: 1,
        types: secondaryWorkProcessDetailsId,
        typesAutoExtract: '',
        diameter: toDecimal(0, 2),
        diameterAutoExtract: '',
        xsize: toDecimal(0, 2),
        xsizeAutoExtract: '',
        ysize: toDecimal(0, 2),
        ysizeAutoExtract: '',
        perimeter: toDecimal(0, 2),
        perimeterAutoExtract: '',
        count: 1,
        countAutoExtract: '',
        blankFlag: false,
        blankFlagAutoExtract: '',
        workTypeGroup: secondaryWorkProcessDetailsGroup,
        workTypeGroupAutoExtract: '',
      },
    ];
  } else if (workType === WorkType.SmBending) {
    return [
      {
        bendingData: { maxBendLength: 0, maxBendAutoExtract: 0 },
        bendingList: [
          {
            id: 1,
            types: 1,
            typesAutoExtract: '',
            bendLength: toDecimal(0, 2),
            bendLengthAutoExtract: '',
            lines: 1,
            linesAutoExtract: '',
            bendCount: 1,
            bendCountAutoExtract: '',
          },
        ],
      },
    ];
  } else if (workType === WorkType.SmDeburringAuto) {
    return [
      {
        id: 1,
        types: '自動',
        area: toDecimal(0, 2),
        areaAutoExtract: toDecimal(0, 2),
        weight: toDecimal(0, 2),
        weightAutoExtract: toDecimal(0, 2),
        count: 1,
        countAutoExtract: '',
      },
    ];
  } else if (workType === WorkType.SmDeburringManual) {
    return [
      {
        id: 1,
        types: '手動',
        target: '外形',
        length: toDecimal(0, 2),
        autoExtract: 0,
        count: 1,
        countAutoExtract: '',
      },
      {
        id: 2,
        types: '手動',
        target: '内形',
        length: toDecimal(0, 2),
        autoExtract: 0,
        count: 1,
        countAutoExtract: '',
      },
    ];
  } else if (workType === WorkType.SmWelding) {
    return [
      {
        weldingList: [
          {
            id: 1,
            yousetsuType: weldingType,
            yousetsuTypeAutoExtract: '',
            senchou: toDecimal(0, 2),
            senchouAutoExtract: '',
            senchouKaisu: 1,
            senchouKaisuAutoExtract: '',
            senchouYousetsushiage: 0,
            senchouYousetsushiageAutoExtract: '',
            total1: toDecimal(0, 2),

            karitsukeCnt: 0,
            karitsukeCntAutoExtract: '',
            karitsukeKaisu: 1,
            karitsukeKaisuAutoExtract: '',
            karitsukeYousetsushiage: 0,
            karitsukeYousetsushiageAutoExtract: '',
            total2: 0,
          },
        ],
        subWeldingList: [
          {
            id: 1,
            subWeldingType: subWeldingType,
            subWeldingTypeAutoExtract: '',
            daimeter: subWeldingGroupType === WorkTypeGroup.SmInstThick ? toDecimal(1, 2) : toDecimal(0, 2),
            daimeterAutoExtract: '',
            number: 0,
            numberAutoExtract: '',
            count: 1,
            countAutoExtract: '',
            yousetsushiage: 0,
            total: toDecimal(0, 2),
            subWeldingGroupType: subWeldingGroupType,
            subWeldingGroupTypeAutoExtract: '',
          },
        ],
      },
    ];
  } else if (workType === WorkType.SmSurface) {
    // if (surfaceDataFromSelect?.length > 0) {
    //   let list = [];
    //   for (let i = 0; i < surfaceDataFromSelect?.length; i++) {
    //     const newSurface = {
    //       no: 1,
    //       calcType: 1,
    //       workType: 300,
    //       areaCount: 1,
    //       totalArea: toDecimal(0, 2),
    //       totalWeight: toDecimal(0, 2),
    //       weightCount: 1,
    //       areaUnitPrice: toDecimal(0, 2),
    //       areaAutoExtract: 0,
    //       weightUnitPrice: toDecimal(0, 2),
    //       weightAutoExtract: 0,
    //       processDetailTypeNo: 0,
    //       processDetailWorkTypeGroup: 0,
    //     };
    //     list.push(newSurface);
    //   }
    //   return list;
    // } else {
    //   return [];
    // }
    return [
      {
        no: 1,
        calcType: 1,
        workType: 300,
        areaCount: 1,
        areaCountAutoExtract: '',
        totalArea: toDecimal(0, 2),
        totalWeight: toDecimal(0, 2),
        weightCount: 1,
        weightCountAutoExtract: '',
        areaUnitPrice: toDecimal(0, 2),
        areaAutoExtract: 0,
        weightUnitPrice: toDecimal(0, 2),
        weightAutoExtract: 0,
        processDetailTypeNo: 0,
        processDetailWorkTypeGroup: 0,
      },
    ];
  } else if (workType === WorkType.SmShearing || workType === WorkType.SmInspection) {
    return [{ numOfCuts: 1, autoExtract: '' }];
  } else {
    return []; // Default empty details for other deviceId values
  }
};

export const convertCadDataToEstimateData = (estimateData, cardData) => {
  let processMst = estimateData?.estimateProducts?.calcParameters?.parameters?.process;
  let bendingTypes = [];
  let weldingTypes = [];
  let subWeldingTypes = [];
  let secWorkTypes = [];
  const bendingDetails = processMst?.filter((i) => i.workType === WorkType.SmBending);
  if (bendingDetails?.length > 0) {
    bendingTypes = bendingDetails[0]?.details?.processDetailTypes?.filter((i) => i.isUsed);
  }
  const weldingDetails = processMst?.filter((i) => i.workType === WorkType.SmWelding);
  if (weldingDetails?.length > 0) {
    weldingTypes = weldingDetails[0].details?.processDetailTypes?.filter(
      (i) => i.group === WorkTypeGroup.SmWelding && i.isUsed
    );

    subWeldingTypes = weldingDetails[0].details?.processDetailTypes?.filter(
      (i) => (i.group === WorkTypeGroup.SmInstSize && i.isUsed) || (i.group === WorkTypeGroup.SmInstThick && i.isUsed)
    );
  }
  const secWorkDetails = processMst?.filter((i) => i.workType === WorkType.SmSecondaryWork);
  if (secWorkDetails?.length > 0) {
    secWorkTypes = secWorkDetails[0].details?.processDetailTypes?.filter(
      (i) =>
        (i.group === WorkTypeGroup.SmSecondaryWorkSize && i.isUsed) ||
        (i.group === WorkTypeGroup.SmSecondaryWorkMillimeter && i.isUsed)
    );
  }
  // IQX_WEBEST-272 PMX取込時にPMXの付加情報を見積に反映させてほしい
  const customerInfo = estimateData?.estimateProducts?.calcParameters?.parameters?.client;
  estimateData.estimateProducts.name = cardData?.name;
  estimateData.estimateProducts.drawingNo = cardData?.drawingNo;
  let customerNm = customerInfo?.filter((item) => item?.info?.isUsed && item?.name === cardData?.customer);
  if (customerNm?.length > 0) {
    estimateData.estimateProducts.clientName = customerNm?.[0]?.name;
    estimateData.estimateProducts.clientId = customerNm?.[0]?.id;
  } else {
    if (customerInfo?.length > 0) {
      estimateData.estimateProducts.clientName = customerInfo?.[0]?.name;
      estimateData.estimateProducts.clientId = customerInfo?.[0]?.id;
    }
  }

  for (let i = 0; i < estimateData?.esIq3Info?.length; i++) {
    let cardMainDataDetails = cardData?.details?.[i];
    let cardDetails = cardMainDataDetails?.processingInfo ? JSON.parse(cardMainDataDetails?.processingInfo) : {};
    estimateData.esIq3Info[i].partCount = cardMainDataDetails?.quantity;
    estimateData.esIq3Info[i].drawingNo = cardMainDataDetails?.drawingNo;
    estimateData.esIq3Info[i].name = cardMainDataDetails?.name;
    let pmxMaterialNm = cardMainDataDetails?.material;
    // 材料リスト
    let materialList = estimateData?.estimateProducts?.calcParameters?.parameters?.materialIq3;
    let materialSurfaceLst = estimateData?.estimateProducts?.calcParameters?.parameters?.materialSurfaceIq3;
    let materialNm = materialList?.filter((item) => item.name === pmxMaterialNm && item.info.isUsed);

    let materialTypeList = estimateData?.estimateProducts?.calcParameters?.parameters?.materialTypeIq3;
    let materialSurfaceList = estimateData?.estimateProducts?.calcParameters?.parameters?.materialSurfaceIq3;
    let gravity = 0.0;
    // 材料情報設定
    estimateData.esIq3Info[i].materialIq3Id =
      materialNm?.length > 0 ? materialNm?.[0]?.id : estimateData.esIq3Info[i].materialIq3Id;
    estimateData.esIq3Info[i].materialName =
      materialNm?.length > 0 ? materialNm?.[0]?.name : estimateData.esIq3Info[i].materialName;

    estimateData.esIq3Info[i].materialTypeIq3Id =
      materialNm?.length > 0 ? materialNm?.[0]?.materialTypeIq3Id : estimateData.esIq3Info[i].materialTypeIq3Id;
    let materialType = materialTypeList?.filter((mType) => mType.id === estimateData.esIq3Info[i].materialTypeIq3Id);
    estimateData.esIq3Info[i].materialTypeName =
      materialType?.length > 0 ? materialType?.[0]?.name : estimateData?.esIq3Info?.[i]?.materialTypeName;

    estimateData.esIq3Info[i].thickness = materialNm?.length > 0 ? materialNm?.[0]?.id : materialList?.[0]?.id; // IQX_WEBEST-68
    gravity = materialNm?.length > 0 ? materialNm?.[0]?.info?.gravity : materialList?.[0]?.info?.gravity;
    let materialSurface = materialSurfaceLst?.filter(
      (item) => item.materialTypeIq3Id === materialNm?.[0]?.materialTypeIq3Id && item.info.isUsed
    );
    estimateData.esIq3Info[i].materialSurfaceIq3Id =
      materialSurface?.length > 0 ? materialSurface[0]?.id : materialSurfaceLst?.[0]?.id;
    estimateData.esIq3Info[i].materialSurfaceName =
      materialSurface?.length > 0 ? materialSurface[0]?.name : materialSurfaceLst?.[0]?.name;

    //　面積・重量設定
    //重量(kg) = 板厚(mm) X Xサイズ(mm) X Yサイズ(mm) ÷ 1000000 X 比重(g/㎤)
    let sizeX = cardDetails?.boundaryBoxSize?.width
      ? toDecimal(cardDetails?.boundaryBoxSize?.width, 2)
      : toDecimal(0, 2);
    let sizeY = cardDetails?.boundaryBoxSize?.height
      ? toDecimal(cardDetails?.boundaryBoxSize?.height, 2)
      : toDecimal(0, 2);

    const thicknessInfo = materialList?.find(
      (item) => item.materialTypeIq3Id === estimateData?.esIq3Info?.[i]?.materialTypeIq3Id
    );
    let thicknessVal = 0;
    if (thicknessInfo != undefined) {
      thicknessVal = parseFloat(thicknessInfo?.info?.thick);
    }
    let weight = ((thicknessVal * parseInt(sizeX) * parseInt(sizeY)) / 1000000) * gravity;
    let reCalArea = Number.isFinite((sizeX * sizeY) / 100) ? (sizeX * sizeY) / 100 : toDecimal(0, 2);
    // IQX_WEBEST-84(Cardの面積(mm)をcmに変換)
    let cardDetailsArea = Number.isFinite(cardDetails?.area / 100) ? cardDetails?.area / 100 : reCalArea;
    cardDetailsArea = cardDetailsArea ? toDecimal(cardDetailsArea, 2) : toDecimal(0, 2);

    estimateData.esIq3Info[i].partSizeX = sizeX;
    estimateData.esIq3Info[i].partSizeY = sizeY;
    estimateData.esIq3Info[i].totalSurfaceArea = cardDetailsArea;
    if (sizeX && sizeY && weight) {
      estimateData.esIq3Info[i].weight = Number.isFinite(weight) ? weight : toDecimal(0, 2);
    }

    //板金子部品より親部品の重量と面積データ更新
    if (estimateData?.esIq3Info[i]?.weight) {
      estimateData.estimateProducts.totalWeight += estimateData?.esIq3Info[i]?.weight
        ? estimateData?.esIq3Info[i]?.weight
        : 0;
    }
    if (estimateData?.esIq3Info[i]?.totalSurfaceArea) {
      estimateData.estimateProducts.totalSurfaceArea += estimateData?.esIq3Info[i]?.totalSurfaceArea
        ? Number(estimateData?.esIq3Info[i]?.totalSurfaceArea)
        : 0;
    }

    //工程入力情報設定
    let updateProcessInputs = [];
    let processInputs = JSON.parse(JSON.stringify(estimateData?.esIq3Info[i]?.processInput));
    for (let j = 0; j < processInputs?.length; j++) {
      let curWorkType = processMst?.filter((item) => item.id == processInputs[j].processId)?.[0]?.workType;
      // IQX_WEBEST-179
      processInputs[j].autoExtractXSize = sizeX ? Number(sizeX) : 0;
      processInputs[j].autoExtractYSize = sizeY ? Number(sizeY) : 0;
      if (curWorkType === WorkType.SmProgram) {
        let programProcessInput = processInputs[j];
        programProcessInput.details[0].elements = cardDetails?.elements;
        programProcessInput.details[0].elementsAuto = cardDetails?.elements; // IQX_WEBEST-179
        updateProcessInputs.push(programProcessInput);
      } else if (
        curWorkType === WorkType.SmBlankCombi ||
        curWorkType === WorkType.SmBlankLaser ||
        curWorkType === WorkType.SmBlankPunch
      ) {
        let blankProcessInput = processInputs[j];
        blankProcessInput.details[0].dimensionData.outerDia = cardDetails?.contourLength?.outerLength
          ? toDecimal(cardDetails?.contourLength?.outerLength, 2)
          : toDecimal(0, 2);
        blankProcessInput.details[0].dimensionData.innerDia = cardDetails?.contourLength?.innerLength
          ? toDecimal(cardDetails?.contourLength?.innerLength, 2)
          : toDecimal(0, 2);
        blankProcessInput.details[0].dimensionData.airFeedDistance = cardDetails?.airDistance
          ? toDecimal(cardDetails?.airDistance, 2)
          : toDecimal(0, 2);
        blankProcessInput.details[0].dimensionData.etchingLength = cardDetails?.etchingLength
          ? toDecimal(cardDetails?.etchingLength, 2)
          : toDecimal(0, 2);
        // IQX_WEBEST-179
        blankProcessInput.details[0].dimensionData.outerAutoExtract = cardDetails?.contourLength?.outerLength
          ? toDecimal(cardDetails?.contourLength?.outerLength, 2)
          : toDecimal(0, 2);
        blankProcessInput.details[0].dimensionData.innerAutoExtract = cardDetails?.contourLength?.innerLength
          ? toDecimal(cardDetails?.contourLength?.innerLength, 2)
          : toDecimal(0, 2);
        blankProcessInput.details[0].dimensionData.airFeedAutoExtract = cardDetails?.airDistance
          ? toDecimal(cardDetails?.airDistance, 2)
          : toDecimal(0, 2);

        let qtyLen = blankProcessInput.details[0].quantityList?.length;
        if (cardDetails?.contourLength?.holes?.length > 0) {
          blankProcessInput.details[0].quantityList.shift();
          for (let k = 0; k < cardDetails?.contourLength?.holes?.length; k++) {
            let typeId = Types?.filter((item) => item.value === 'その他')?.[0]?.id;
            let holdType = BlankTypes?.filter(
              (item) => item.value === cardDetails?.contourLength?.holes?.[k]?.name
            )?.[0]?.label;
            let blanktypes = Types?.filter((item) => item.value === holdType)?.[0];
            if (blanktypes) {
              typeId = blanktypes?.id;
            }
            const hold = cardDetails?.contourLength?.holes?.[k];
            const newListRow = {
              id: k + 1 + qtyLen,
              types: typeId,
              typesAutoExtract: typeId,
              diameter: getDiameter(typeId, hold?.xl),
              diameterAutoExtract: getDiameter(typeId, hold?.xl),
              xsize: getSize(typeId, hold?.xl),
              xsizeAutoExtract: getSize(typeId, hold?.xl),
              ysize: getSize(typeId, hold?.yl),
              ysizeAutoExtract: getSize(typeId, hold?.yl),
              perimeter: hold?.length && hold?.number ? toDecimal(hold?.length / hold?.number, 2) : toDecimal(0, 2),
              perimeterAutoExtract:
                hold?.length && hold?.number ? toDecimal(hold?.length / hold?.number, 2) : toDecimal(0, 2),
              count: hold?.number,
              countAutoExtract: hold?.number,
            };
            blankProcessInput.details[0].quantityList?.push(newListRow);
          }
        } else {
          blankProcessInput.details[0].quantityList[0].types = 1;
          blankProcessInput.details[0].quantityList[0].typesAutoExtract = 1;
          blankProcessInput.details[0].quantityList[0].diameter = toDecimal(0, 2);
          blankProcessInput.details[0].quantityList[0].diameterAutoExtract = toDecimal(0, 2);
          blankProcessInput.details[0].quantityList[0].xsize = toDecimal(0, 2);
          blankProcessInput.details[0].quantityList[0].xsizeAutoExtract = toDecimal(0, 2);
          blankProcessInput.details[0].quantityList[0].ysize = toDecimal(0, 2);
          blankProcessInput.details[0].quantityList[0].ysizeAutoExtract = toDecimal(0, 2);
          blankProcessInput.details[0].quantityList[0].perimeter = toDecimal(0, 2);
          blankProcessInput.details[0].quantityList[0].perimeterAutoExtract = toDecimal(0, 2);
          blankProcessInput.details[0].quantityList[0].count = 1;
          blankProcessInput.details[0].quantityList[0].countAutoExtract = 1;
        }
        updateProcessInputs.push(blankProcessInput);
      } else if (curWorkType === WorkType.SmBending) {
        let bendProcessInput = processInputs[j];
        const maxBendLength = cardDetails?.bendInfo
          ? cardDetails?.bendInfo?.reduce((max, item) => {
              return item.width > max ? item.width : max;
            }, 0)
          : 0; // IQX_WEBEST-84
        bendProcessInput.details[0].bendingData.maxBendLength = maxBendLength
          ? toDecimal(maxBendLength, 2)
          : toDecimal(0, 2);
        // IQX_WEBEST-179
        bendProcessInput.details[0].bendingData.maxBendAutoExtract = maxBendLength
          ? toDecimal(maxBendLength, 2)
          : toDecimal(0, 2);
        let bendLen = bendProcessInput.details[0].bendingList?.length;
        if (cardDetails?.bendInfo?.length > 0) {
          bendProcessInput?.details[0]?.bendingList?.shift();
          for (let k = 0; k < cardDetails?.bendInfo?.length; k++) {
            const bend = cardDetails?.bendInfo[k];

            let typeId = bendingTypes?.[0]?.id;

            let bendId = BendingTypes?.filter((itemType) => itemType.value === bend?.name)?.[0]?.id;
            let result = bendingTypes?.filter((subWeldItem) => subWeldItem.id === bendId)?.[0];

            if (result) {
              typeId = result?.id;
            }

            const newListRow = {
              id: k + 1 + bendLen,
              types: typeId,
              typesAutoExtract: typeId,
              bendLength: bend?.width ? toDecimal(bend?.width, 2) : toDecimal(0, 2),
              bendLengthAutoExtract: bend?.width ? toDecimal(bend?.width, 2) : toDecimal(0, 2),
              lines: bend?.number, // 曲げ回数（曲げ線数）// IQX_WEBEST-210
              linesAutoExtract: bend?.number, // 曲げ回数（曲げ線数）// IQX_WEBEST-210
              bendCount: bend?.name === FRBend ? bend?.amount : 1, // FR曲げ回数
              bendCountAutoExtract: bend?.name === FRBend ? bend?.amount : 1, // FR曲げ回数
            };
            bendProcessInput?.details[0]?.bendingList?.push(newListRow);
          }
        } else {
          bendProcessInput.details[0].bendingList[0].types = 1;
          bendProcessInput.details[0].bendingList[0].typesAutoExtract = 1;
          bendProcessInput.details[0].bendingList[0].bendLength = toDecimal(0, 2);
          bendProcessInput.details[0].bendingList[0].bendLengthAutoExtract = toDecimal(0, 2);
          bendProcessInput.details[0].bendingList[0].lines = 1;
          bendProcessInput.details[0].bendingList[0].linesAutoExtract = 1;
          bendProcessInput.details[0].bendingList[0].bendCount = 1;
          bendProcessInput.details[0].bendingList[0].bendCountAutoExtract = 1;
        }
        updateProcessInputs.push(bendProcessInput);
      } else if (curWorkType === WorkType.SmWelding) {
        let weldProcessInput = processInputs?.[j];

        let weldLen = weldProcessInput?.details?.[0].weldingList?.length;
        if (cardDetails?.weldInfo?.length > 0) {
          weldProcessInput?.details?.[0]?.weldingList?.shift();
          for (let k = 0; k < cardDetails?.weldInfo?.length; k++) {
            const weld = cardDetails?.weldInfo?.[k];
            let typeId = weldingTypes?.[0]?.id;
            let weldId = weld?.weldType + 1;
            const result = weldingTypes?.find((weldItem) => {
              return weldItem.id === weldId && weldItem.isUsed;
            });
            if (result) {
              typeId = result?.id;
            }

            const newListRow = {
              id: k + 1 + weldLen,
              yousetsuType: typeId,
              yousetsuTypeAutoExtract: typeId,
              senchou: weld?.length ? toDecimal(weld?.length, 2) : toDecimal(0, 2), //連続/タップ溶接の長さ // IQX_WEBEST-193
              senchouAutoExtract: weld?.length ? toDecimal(weld?.length, 2) : toDecimal(0, 2), //連続/タップ溶接の長さ // IQX_WEBEST-193
              senchouKaisu: weld?.number ? weld?.number : 1, // 連続/タップ回数 // IQX_WEBEST-193
              senchouKaisuAutoExtract: weld?.number ? weld?.number : 1, // 連続/タップ回数 // IQX_WEBEST-193
              senchouYousetsushiage: 0,
              senchouYousetsushiageAutoExtract: 0,
              total1: weld?.length && weld?.number ? toDecimal(weld?.length * weld?.number, 2) : toDecimal(0, 2), // IQX_WEBEST-193

              karitsukeCnt: weld?.tackLocations ? parseInt(weld?.tackLocations) : 0, // 仮付箇所数 // IQX_WEBEST-193
              karitsukeCntAutoExtract: weld?.tackLocations ? parseInt(weld?.tackLocations) : 0, // 仮付箇所数 // IQX_WEBEST-193
              karitsukeKaisu: weld?.tackNumber ? parseInt(weld?.tackNumber) : 1, // 仮付回数 // IQX_WEBEST-193
              karitsukeKaisuAutoExtract: weld?.tackNumber ? parseInt(weld?.tackNumber) : 1, // 仮付回数 // IQX_WEBEST-193
              karitsukeYousetsushiage: 0,
              karitsukeYousetsushiageAutoExtract: 0,
              total2: weld?.tackLocations && weld?.tackNumber ? parseInt(weld?.tackLocations * weld?.tackNumber) : 0, // IQX_WEBEST-193
            };
            weldProcessInput?.details[0]?.weldingList?.push(newListRow);
          }
        } else {
          weldProcessInput.details[0].weldingList[0].yousetsuType = weldingTypes?.[0]?.id;
          weldProcessInput.details[0].weldingList[0].yousetsuTypeAutoExtract = weldingTypes?.[0]?.id;
          weldProcessInput.details[0].weldingList[0].senchou = toDecimal(0, 2);
          weldProcessInput.details[0].weldingList[0].senchouAutoExtract = toDecimal(0, 2);
          weldProcessInput.details[0].weldingList[0].senchouKaisu = 1;
          weldProcessInput.details[0].weldingList[0].senchouKaisuAutoExtract = 1;
          weldProcessInput.details[0].weldingList[0].senchouYousetsushiage = 0;
          weldProcessInput.details[0].weldingList[0].senchouYousetsushiageAutoExtract = 0;
          weldProcessInput.details[0].weldingList[0].total1 = toDecimal(0, 2);

          weldProcessInput.details[0].weldingList[0].karitsukeCnt = 0;
          weldProcessInput.details[0].weldingList[0].karitsukeCntAutoExtract = 0;
          weldProcessInput.details[0].weldingList[0].karitsukeKaisu = 1;
          weldProcessInput.details[0].weldingList[0].karitsukeKaisuAutoExtract = 1;
          weldProcessInput.details[0].weldingList[0].karitsukeYousetsushiage = 0;
          weldProcessInput.details[0].weldingList[0].karitsukeYousetsushiageAutoExtract = 0;
          weldProcessInput.details[0].weldingList[0].total2 = 0;
        }
        // サプ溶接種類
        let subWeldLen = weldProcessInput?.details?.[0]?.subWeldingList?.length;
        if (cardDetails?.subWeldInfo?.length > 0) {
          weldProcessInput?.details?.[0]?.subWeldingList?.shift();
          for (let k = 0; k < cardDetails?.subWeldInfo?.length; k++) {
            const subWeld = cardDetails?.subWeldInfo?.[k];
            let typeId = subWeldingTypes?.[0]?.id;
            let typeGroup = subWeldingTypes?.[0]?.group;
            // ***
            let subWeldType = SubWeldTypes?.filter(
              (itemTyp) => itemTyp.value === cardDetails?.subWeldInfo?.[k]?.name
            )?.[0]?.id;
            let result = subWeldingTypes?.filter((subWeldItem) => subWeldItem.id == subWeldType)?.[0];

            if (result) {
              typeId = result?.id;
              typeGroup = result?.group;
            }

            const newListRow = {
              id: k + 1 + subWeldLen,
              subWeldingType: typeId,
              subWeldingTypeAutoExtract: typeId,
              daimeter: subWeld?.xl ? toDecimal(subWeld?.xl, 2) : toDecimal(0, 2),
              daimeterAutoExtract: subWeld?.xl ? toDecimal(subWeld?.xl, 2) : toDecimal(0, 2),
              number: subWeld?.number ? toDecimal(subWeld?.number, 2) : toDecimal(0, 2),
              numberAutoExtract: subWeld?.number ? toDecimal(subWeld?.number, 2) : toDecimal(0, 2),
              count: 1,
              countAutoExtract: 1,
              yousetsushiage: 0,
              total: subWeld?.xl && subWeld?.number ? toDecimal(subWeld?.xl * subWeld?.number, 2) : toDecimal(0, 2), // IQX_WEBEST-208
              subWeldingGroupType: typeGroup,
              subWeldingGroupTypeAutoExtract: typeGroup,
            };
            weldProcessInput.details[0].subWeldingList.push(newListRow);
          }
        } else {
          weldProcessInput.details[0].subWeldingList[0].subWeldingType = subWeldingTypes?.[0]?.id;
          weldProcessInput.details[0].subWeldingList[0].subWeldingTypeAutoExtract = subWeldingTypes?.[0]?.id;
          (weldProcessInput.details[0].subWeldingList[0].daimeter = toDecimal(0, 2)),
            (weldProcessInput.details[0].subWeldingList[0].daimeterAutoExtract = toDecimal(0, 2)),
            (weldProcessInput.details[0].subWeldingList[0].number = toDecimal(0, 2)),
            (weldProcessInput.details[0].subWeldingList[0].numberAutoExtract = toDecimal(0, 2)),
            (weldProcessInput.details[0].subWeldingList[0].count = 1),
            (weldProcessInput.details[0].subWeldingList[0].countAutoExtract = 1),
            (weldProcessInput.details[0].subWeldingList[0].yousetsushiage = 0),
            (weldProcessInput.details[0].subWeldingList[0].total = toDecimal(0, 2));
          weldProcessInput.details[0].subWeldingList[0].subWeldingGroupType = subWeldingTypes?.[0]?.group;
          weldProcessInput.details[0].subWeldingList[0].subWeldingGroupTypeAutoExtract = subWeldingTypes?.[0]?.group;
        }

        updateProcessInputs.push(weldProcessInput);
      } else if (curWorkType === WorkType.SmSecondaryWork) {
        let secWorkProcessInput = processInputs[j];
        let secWorkLen = secWorkProcessInput?.details?.length;
        if (cardDetails?.burringTappings?.length > 0) {
          secWorkProcessInput?.details?.shift();
          for (let k = 0; k < cardDetails?.burringTappings?.length; k++) {
            const secWork = cardDetails?.burringTappings?.[k];
            let typeId = secWorkTypes?.[0]?.id;
            let typeGroup = secWorkTypes?.[0]?.group;
            // ***
            let secWorkType = SecondaryWorkTypes?.filter(
              (itemType) => itemType.value === cardDetails?.burringTappings?.[k]?.name
            )?.[0]?.id;
            let result = secWorkTypes?.filter((subWeldItem) => subWeldItem.id == secWorkType)?.[0];

            if (result) {
              typeId = result?.id;
              typeGroup = result?.group;
            }

            const newListRow = {
              id: k + 1 + secWorkLen,
              types: typeId,
              typesAutoExtract: typeId,
              diameter: secWork?.size ? toDecimal(secWork?.size, 2) : toDecimal(0, 2),
              diameterAutoExtract: secWork?.size ? toDecimal(secWork?.size, 2) : toDecimal(0, 2),
              xsize: secWork?.cp?.x ? toDecimal(secWork?.cp?.x, 2) : toDecimal(0, 2),
              xsizeAutoExtract: secWork?.cp?.x ? toDecimal(secWork?.cp?.x, 2) : toDecimal(0, 2),
              ysize: secWork?.cp?.y ? toDecimal(secWork?.cp?.y, 2) : toDecimal(0, 2),
              ysizeAutoExtract: secWork?.cp?.y ? toDecimal(secWork?.cp?.y, 2) : toDecimal(0, 2),
              perimeter: toDecimal(0, 2),
              perimeterAutoExtract: toDecimal(0, 2),
              count: secWork?.number ? Number(secWork?.number) : 1, // IQX_WEBEST-209
              countAutoExtract: secWork?.number ? Number(secWork?.number) : 1, // IQX_WEBEST-209
              blankFlag: false,
              blankFlagAutoExtract: false,
              workTypeGroup: typeGroup,
              workTypeGroupAutoExtract: typeGroup,
            };
            secWorkProcessInput.details.push(newListRow);
          }
        } else {
          secWorkProcessInput.details[0].types = secWorkTypes?.[0]?.id;
          secWorkProcessInput.details[0].typesAutoExtract = secWorkTypes?.[0]?.id;
          secWorkProcessInput.details[0].diameter = toDecimal(0, 2);
          secWorkProcessInput.details[0].diameterAutoExtract = toDecimal(0, 2);
          secWorkProcessInput.details[0].xsize = toDecimal(0, 2);
          secWorkProcessInput.details[0].xsizeAutoExtract = toDecimal(0, 2);
          secWorkProcessInput.details[0].ysize = toDecimal(0, 2);
          secWorkProcessInput.details[0].ysizeAutoExtract = toDecimal(0, 2);
          secWorkProcessInput.details[0].perimeter = toDecimal(0, 2);
          secWorkProcessInput.details[0].perimeterAutoExtract = toDecimal(0, 2);
          secWorkProcessInput.details[0].count = 1;
          secWorkProcessInput.details[0].countAutoExtract = 1;
          secWorkProcessInput.details[0].blankFlag = false;
          secWorkProcessInput.details[0].blankFlagAutoExtract = false;
          secWorkProcessInput.details[0].workTypeGroup = secWorkTypes?.[0]?.group;
          secWorkProcessInput.details[0].workTypeGroupAutoExtract = secWorkTypes?.[0]?.group;
        }
        updateProcessInputs.push(secWorkProcessInput);
      } else if (curWorkType === WorkType.SmDeburringAuto) {
        let autoDeburProcessInput = processInputs[j];
        autoDeburProcessInput.details[0].area = cardDetailsArea;
        autoDeburProcessInput.details[0].areaAutoExtract = cardDetailsArea; // IQX_WEBEST-179
        // if (weight) {
        autoDeburProcessInput.details[0].weight = Number.isFinite(weight) ? toDecimal(weight, 2) : toDecimal(0, 2);
        autoDeburProcessInput.details[0].weightAutoExtract = Number.isFinite(weight)
          ? toDecimal(weight, 2)
          : toDecimal(0, 2); // IQX_WEBEST-179
        // }
        autoDeburProcessInput.details[0].countAutoExtract = 1;
        updateProcessInputs.push(autoDeburProcessInput);
      } else if (curWorkType === WorkType.SmDeburringManual) {
        let manualDeburProcessInput = processInputs[j];
        manualDeburProcessInput.details[0].length = cardDetails?.contourLength?.outerLength
          ? toDecimal(cardDetails?.contourLength?.outerLength, 2)
          : toDecimal(0, 2);
        manualDeburProcessInput.details[0].autoExtract = cardDetails?.contourLength?.outerLength
          ? toDecimal(cardDetails?.contourLength?.outerLength, 2)
          : toDecimal(0, 2); // IQX_WEBEST-179
        manualDeburProcessInput.details[0].count = 1;
        manualDeburProcessInput.details[0].countAutoExtract = 1;

        manualDeburProcessInput.details[1].length = cardDetails?.contourLength?.innerLength
          ? toDecimal(cardDetails?.contourLength?.innerLength, 2)
          : toDecimal(0, 2);
        manualDeburProcessInput.details[1].autoExtract = cardDetails?.contourLength?.innerLength
          ? toDecimal(cardDetails?.contourLength?.innerLength, 2)
          : toDecimal(0, 2); // IQX_WEBEST-179
        manualDeburProcessInput.details[1].count = 1;
        manualDeburProcessInput.details[1].countAutoExtract = 1;
        updateProcessInputs.push(manualDeburProcessInput);
      } else if (curWorkType === WorkType.SmSurface) {
        let surfaceProcessInput = processInputs[j];
        if (surfaceProcessInput?.details) {
          surfaceProcessInput.details[0].areaUnitPrice = cardDetailsArea;
          surfaceProcessInput.details[0].areaAutoExtract = cardDetailsArea; // IQX_WEBEST-179
          surfaceProcessInput.details[0].totalArea = cardDetailsArea * 1;
          surfaceProcessInput.details[0].areaCount = 1;
          surfaceProcessInput.details[0].areaCountAutoExtract = 1;
          surfaceProcessInput.details[0].weightUnitPrice = Number.isFinite(weight)
            ? toDecimal(weight, 2)
            : toDecimal(0, 2);
          surfaceProcessInput.details[0].weightAutoExtract = Number.isFinite(weight)
            ? toDecimal(weight, 2)
            : toDecimal(0, 2); // IQX_WEBEST-179
          surfaceProcessInput.details[0].totalWeight = Number.isFinite(weight)
            ? toDecimal(weight, 2) * 1
            : toDecimal(0, 2);
          surfaceProcessInput.details[0].weightCount = 1;
          surfaceProcessInput.details[0].weightCountAutoExtract = 1;
        }
        updateProcessInputs.push(surfaceProcessInput);
      } else if (curWorkType === WorkType.SmShearing) {
        let shearingProcessInput = processInputs[j];
        shearingProcessInput.details[0].numOfCuts = 0;
        shearingProcessInput.details[0].autoExtract = 0; // IQX_WEBEST-179
        updateProcessInputs.push(shearingProcessInput);
      } else if (curWorkType === WorkType.SmInspection) {
        let inspectionProcessInput = processInputs[j];
        inspectionProcessInput.details[0].autoExtract = 1;
        updateProcessInputs.push(inspectionProcessInput);
      }
    }
    //updateProcessInputs
    estimateData.esIq3Info[i].processInput = updateProcessInputs;
  }
  let totalWeight = 0;
  let totalArea = 0;
  let totalPartCount = 0;
  // IQX_WEBEST-68
  let iq3List = estimateData.esIq3Info;
  totalWeight = iq3List?.reduce((total, item) => {
    return total + Number(item.weight) * Number(item.partCount); // IQX_WEBEST-308 親工程 －表面処置、工程入力画面の「面積/重量」の値変更
  }, 0);
  totalArea = iq3List?.reduce((total, item) => {
    return total + Number(item.totalSurfaceArea) * Number(item.partCount); // IQX_WEBEST-308 親工程 －表面処置、工程入力画面の「面積/重量」の値変更
  }, 0);
  totalPartCount = iq3List?.reduce((total, item) => {
    return total + Number(item.partCount);
  }, 0);
  estimateData.estimateProducts.totalWeight = totalWeight;
  estimateData.estimateProducts.totalWeightSm = totalWeight;
  estimateData.estimateProducts.totalSurfaceArea = totalArea;

  return estimateData;
};

// 追加項目・費用の初期値オブジェクト
export const initAdditionalItemCost = (id, code, name) => {
  return {
    additionalItemId: id,
    additionalItemCode: code,
    additionalItemName: name,
    unitPrice: 0,
    isUseUnit: true,
    totalPrice: 0,
  };
};

// 追加項目・ラベルの初期値オブジェクト
export const initAdditionalItemLabel = (id, code, name) => {
  return {
    additionalItemId: id,
    additionalItemCode: code,
    additionalItemName: name,
    label: '',
  };
};

// 追加項目・メモの初期値オブジェクト
export const initAdditionalItemMemo = (id, code, name) => {
  return {
    additionalItemId: id,
    additionalItemCode: code,
    additionalItemName: name,
    memo: '',
  };
};

// 追加項目・日付の初期値オブジェクト
export const initAdditionalItemDate = (id, code, name) => {
  return {
    additionalItemId: id,
    additionalItemCode: code,
    additionalItemName: name,
    date: dayjs(today).format('YYYY-MM-DD'),
  };
};

// 見積もり用、追加項目・費用の初期値オブジェクト
export const initEpCustomCost = (id, code, name) => {
  return {
    id: 0,
    created: dayjs(today).format('YYYY-MM-DD'),
    modified: dayjs(today).format('YYYY-MM-DD'),
    modifier: '',
    isDeleted: false,
    companyId: '',
    estimateProductId: '',
    additionalItemId: id,
    additionalItemCode: code,
    additionalItemName: name,
    unitPrice: 0,
    isUseUnit: false,
    totalPrice: 0,
    info: { isUsed: true, remarks: '', sortNum: 0 },
  };
};

// 見積もり用、追加項目・ラベルの初期値オブジェクト
export const initEpCustomLabel = (id, code, name) => {
  return {
    id: 0,
    created: dayjs(today).format('YYYY-MM-DD'),
    modified: dayjs(today).format('YYYY-MM-DD'),
    modifier: '',
    isDeleted: false,
    companyId: '',
    estimateProductId: '',
    additionalItemId: id,
    additionalItemCode: code,
    additionalItemName: name,
    label: '',
    info: { isUsed: true, remarks: '', sortNum: 0 },
  };
};

// 見積もり用、追加項目・メモの初期値オブジェクト
export const initEpCustomMemo = (id, code, name) => {
  return {
    id: 0,
    created: dayjs(today).format('YYYY-MM-DD'),
    modified: dayjs(today).format('YYYY-MM-DD'),
    modifier: '',
    isDeleted: false,
    companyId: '',
    estimateProductId: '',
    additionalItemId: id,
    additionalItemCode: code,
    additionalItemName: name,
    memo: '',
    info: { isUsed: true, remarks: '', sortNum: 0 },
  };
};

// 見積もり用、追加項目・日付の初期値オブジェクト
export const initEpCustomDate = (id, code, name) => {
  return {
    id: 0,
    created: dayjs(today).format('YYYY-MM-DD'),
    modified: dayjs(today).format('YYYY-MM-DD'),
    modifier: '',
    isDeleted: false,
    companyId: '',
    estimateProductId: '',
    additionalItemId: id,
    additionalItemCode: code,
    additionalItemName: name,
    date: dayjs(today).format('YYYY-MM-DD'),
    info: { isUsed: true, remarks: '', sortNum: 0 },
  };
};

// 見積もりのパラメータ情報から材料サイズマスタ情報を得る
export const getMaterialSizeData = (estimateProducts) => {
  let materialSizeData = [];
  if (estimateProducts) {
    const estimateParameters = estimateProducts?.calcParameters?.parameters;
    materialSizeData = estimateParameters?.materialSizeIq3;
    materialSizeData = materialSizeData
      ?.filter((item) => item.info.isUsed)
      ?.map((item) => {
        return {
          id: item.id,
          name: item.name,
          sizeX: item.info.sizeX,
          sizeY: item.info.sizeY,
        };
      });
  }
  return materialSizeData;
};

// 材料詳細の新材料サイズの場合、初期値
export const getInitMaterialSizeInfo = (index, item) => {
  let data = {
    no: index + 1,
    materialSizeIq3Id: item.id,
    isUsed: false,
    sizeName: item.name,
    materialQuantity: 0,
    price: 0,
    yield: 0,
    pattern1Rate: 0,
    pattern1Quantity: 0,
    pattern2Rate: 0,
    pattern2Quantity: 0,
    maxYield: 0,
    maxQuantity: 0,
    yRotate: false,
  };
  return data;
};

// 材料詳細の新材料サイズの場合、材料パータンの初期値
export const getInitMaterialPatternsInfo = (materialDetailPatterns, item) => {
  new Array(2).fill(null)?.map((_, pIndex) => {
    let data = {
      patternType: pIndex === 0 ? PatternType.Pattern1 : PatternType.Pattern2,
      totalPrice: 0,
      yield: 0,
      materialQuantity: 0,
      materialSizeIq3Id: item?.id,
      sizeName: item?.name,
      sizeX: item?.info?.sizeX,
      sizeY: item?.info?.sizeY,
      ySetting: true,
      topFit: false,
      rightFit: false,
    };
    materialDetailPatterns.push(data);
  });
  return materialDetailPatterns;
};

// 材料詳細の新Free材料サイズの場合、初期値
export const getInitFreeMaterialSizeInfo = () => {
  let data = {
    no: 0,
    materialSizeIq3Id: 0,
    isUsed: false,
    sizeName: 'Free',
    materialQuantity: 0,
    price: 0,
    yield: 0,
    pattern1Rate: 0,
    pattern1Quantity: 0,
    pattern2Rate: 0,
    pattern2Quantity: 0,
    maxYield: 0,
    maxQuantity: 0,
    yRotate: false,
  };
  return data;
};

// 材料の自動サイズを得る
export const getMaterialAutoSize = (iq3Info, yRotate, isXSize = true) => {
  let pSizeX = yRotate ? Number(iq3Info?.partSizeY) : Number(iq3Info?.partSizeX);
  let sizeX = pSizeX + Number(iq3Info?.pierWidthX) + Number(iq3Info?.grabbingX);
  let pSizeY = yRotate ? Number(iq3Info?.partSizeX) : Number(iq3Info?.partSizeY);
  let sizeY = pSizeY + Number(iq3Info?.pierWidthY) + Number(iq3Info?.grabbingY);
  return isXSize ? sizeX : sizeY;
};

// 一番合計歩留まりが良い材料サイズ情報
export const getMaxYieldRecord = (sizeLists) => {
  let maxYieldRecord = sizeLists?.reduce((prev, current) => (prev && prev.yield > current.yield ? prev : current));
  return maxYieldRecord;
};

// 材料詳細の再計算モード
export const reCalMaterial = {
  allRecordCal: 1, // 板金子部品全部の材料を再計算
  oneRecordCal: 2, // 選択中の板金子部品のみ材料を再計算
  noCal: 3, // 計算しない
};

export const blobToBase64 = async (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result.split(',')[1]); // Base64部分のみ取得
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(blob);
  });
};

export const extractFileName = (path) => {
  // パスを分割して最後の要素を取得
  return path.split('\\').pop().split('/').pop();
};

export const getFileExtension = (name) => {
  const parts = name.split('.');
  let fileExtension = parts.length > 1 ? parts.pop() : ''; // 最後の要素を返す
  fileExtension = '.' + fileExtension;
  return fileExtension;
};

// 値はInfinityであるかチェック
export const isInfinity = (value) => {
  return value === Number.POSITIVE_INFINITY || value === Number.NEGATIVE_INFINITY;
};
