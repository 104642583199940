/**
 * クラス名：見積り済みの履歴画面
 * 説明：見積りを成功した履歴情報を表示。
 * 作成者：ナンス
 * 作成日：2024/11/18
 * バージョン：1.0
 */
import { Input, Button, Row, Col, Table, Layout, DatePicker, Spin, Typography } from 'antd';
import React, { forwardRef, useEffect, useState, useImperativeHandle } from 'react';
import dayjs from 'dayjs';
import { WithRouter } from '../components/WithRouter';

import '../assets/styles/common.css';
// import iq3Img_pmx1_1 from '../assets/images/iq3Img_pmx1_1.png';
import EstimateFooter from '../components/EstimateFooter';
import {
  formatDate,
  getEstimateStatusName,
  getMaterialName,
  getOrderConditionsName,
  getOrderName,
  getProcessName,
} from './common/Common';
import { getCad3dDatasEstimateHistory } from './common/CommonAPI';
import {
  FileUploadStatus2D,
  IQxRealGoku_CalParameters,
  ORDER_DATE_INPUT,
  pageSizes,
  ServiceClass,
  WorkType,
} from './common/Constant';
import { EstimateResultClass, UserRole } from './common/enums';

const EstimateHistory = forwardRef((props, ref) => {
  const [pageSize, setPageSize] = useState(30);
  const [cusCurrentPage, setCusCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [filterDatas, setFilterDatas] = useState({
    fileName: '',
    estimateDate: '',
  });
  const [selectedRows, setSelectedRows] = useState([]);
  const [estimateDate, setEstimateDate] = useState('');
  const [fileNm, setFileNm] = useState('');
  const [estimateHistoryList, setEstimateHistoryList] = useState([]);
  const [selectedFileNames, setSelectedFileNames] = useState([]);
  const [spinLoading, setSpinLoading] = useState(false);
  const [tblLoading, setTblLoading] = useState(false);
  const [calParameters, setCalParameters] = useState({});
  const [orderClass, setOrderClass] = useState([]);
  const [toleranceClass, setToleranceClass] = useState([]);
  const [weldFinishLevel, setWeldFinishLevel] = useState([]);
  const [finalPurpos, setFinalPurpos] = useState([]);
  const [deliveryTime, setDeliveryTime] = useState([]);
  const [materialClass, setMaterialClass] = useState([]);
  const [processMst, setProcessMst] = useState([]);
  const [tableHeight, setTableHeight] = useState(0);

  // 見積もり履歴データを取得する
  useEffect(() => {
    // 見積もり履歴データを取得する
    getEstimateHistoryData();
    // 見積もるパラメータ情報をSessionから取得
    let paramData = JSON.parse(decodeURI(localStorage?.getItem(IQxRealGoku_CalParameters)));
    setCalParameters(paramData);
    if (Object.keys(paramData)?.length > 0) {
      let companyInfo = paramData.company;
      // 受注条件設定
      // 受注種類
      let coeffOrderTypes = companyInfo[0]?.coeffOrderTypes?.filter((i) => !i.isDeleted && i.isUsed);
      setOrderClass(coeffOrderTypes);
      //公差
      let toleranceClass = companyInfo[0]?.coeffTolerance?.filter((i) => !i.isDeleted && i.isUsed);
      setToleranceClass(toleranceClass);
      //仕上げレベル
      let weldFinishLevel = companyInfo[0]?.coeffWeldFinishLevel?.filter((i) => !i.isDeleted && i.isUsed);
      setWeldFinishLevel(weldFinishLevel);
      //最終用途
      let finalPurpos = companyInfo[0]?.coeffFinalPurpose?.filter((i) => !i.isDeleted && i.isUsed);
      setFinalPurpos(finalPurpos);
      // 発注後納期
      let deliveryTime = companyInfo[0]?.coeffDeliveryDates?.filter((i) => !i.isDeleted && i.isUsed);
      setDeliveryTime(deliveryTime);
      // 材質
      let materialClass = paramData.materialTypeIq3?.filter((i) => !i.isDeleted && i.info.isUsed);
      setMaterialClass(materialClass);
      // 処理
      let process = paramData.process?.filter(
        (i) => !i.isDeleted && i.info.isUsed && i.class === ServiceClass.SheetMetal && i.workType === WorkType.SmSurface
      );
      setProcessMst(process);
    }
  }, []);

  useEffect(() => {
    const updateTableHeight = () => {
      const headerHeight = document.querySelector('.responsive-table .ant-table-thead')?.offsetHeight || 55;
      const titleDivHeight = document.querySelector('.home-title-div')?.offsetHeight || 50;
      const titleBtnDivHeight = document.querySelector('.home-tilte-btn-div')?.offsetHeight || 50;

      const paginationElement = document.querySelector('.ant-table-pagination');
      const paginationMarginTop = paginationElement
        ? parseInt(getComputedStyle(paginationElement)?.marginTop || '0', 10)
        : 10;
      const paginationMarginBottom = paginationElement
        ? parseInt(getComputedStyle(paginationElement)?.marginBottom || '0', 10)
        : 10;
      const paginationHeight = document.querySelector('.ant-table-pagination')?.offsetHeight || 32;

      const availableHeight =
        window.innerHeight -
        headerHeight -
        titleDivHeight -
        titleBtnDivHeight -
        paginationHeight -
        paginationMarginTop -
        paginationMarginBottom -
        60; // Adjust 20px padding/margin if needed
      setTableHeight(availableHeight);
    };

    updateTableHeight();
    window.addEventListener('resize', updateTableHeight);

    return () => window.removeEventListener('resize', updateTableHeight);
  }, []);

  // 見積もり履歴データを取得する
  const getEstimateHistoryData = async () => {
    const cad3dDatas = await getCad3dDatasEstimateHistory(pageSize, 0, 'ASC', '', filterDatas);
    setEstimateHistoryList(cad3dDatas.data);
    if (cad3dDatas) {
      setTotalCount(cad3dDatas?.totalCount);
    }
  };
  useImperativeHandle(ref, () => ({
    handleSearch: handleSearch,
  }));

  // 表示ボタンの押下
  const handleSearch = async () => {
    const filterData = {
      fileName: fileNm,
      estimateDate: estimateDate,
    };
    setFilterDatas(filterData);
    const cad3dDatas = await getCad3dDatasEstimateHistory(pageSize, 0, 'ASC', '', filterData);
    setEstimateHistoryList(cad3dDatas.data);
    if (cad3dDatas) {
      setTotalCount(cad3dDatas?.totalCount);
    }
  };

  // 検索をクリアボタンの押下
  const handleClearSearch = async () => {
    setFileNm('');
    setEstimateDate('');

    const filterData = {
      fileName: '',
      estimateDate: '',
    };
    setFilterDatas(filterData);
    const cad3dDatas = await getCad3dDatasEstimateHistory(pageSize, 0, 'ASC', '', filterData);
    setEstimateHistoryList(cad3dDatas.data);
    if (cad3dDatas) {
      setTotalCount(cad3dDatas?.totalCount);
    }
  };

  // レコードを選択する
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      // 選択して一時保存する
      const updatedRows = selectedRows?.map((row) => ({
        ...row,
        orderStatus: FileUploadStatus2D.Blank,
      }));
      setSelectedRows(updatedRows);
      // ファイル名を取得する
      const selectedNames = selectedRows?.map((row) => row.name);
      // 選択されていないファイル名を除外する
      setSelectedFileNames((prevState) => {
        const newNames = selectedNames;
        // 選択されていないファイル名を除外する
        return newNames;
      });
    },
    // ステータスが（見積済と注文処理中）以外の場合、選択できるように設定する
    getCheckboxProps: (record) => ({
      disabled:
        (record.estimateResult !== EstimateResultClass.DisplayEstimateResult &&
          record.estimateResult !== EstimateResultClass.OrderProcessing) ||
        (record.estimateResult === EstimateResultClass.DisplayEstimateResult && record.userEstimateTotalPrice <= 0) ||
        (record.estimateResult === EstimateResultClass.OrderProcessing && record.userEstimateTotalPrice <= 0),
    }),
  };

  // ページネーションでページサイズの変更する
  const handlePageSizeChange = (current, pagesize) => {
    setPageSize(pagesize);
  };

  const handleTableChange = async (pagination, filters, sorter) => {
    setTblLoading(true); // Waitingダイアログを表示にする
    let pageBefore = pagination?.current - 1;
    const cad3dDatas = await getCad3dDatasEstimateHistory(pagination?.pageSize, pageBefore, 'ASC', '', filterDatas);
    setEstimateHistoryList(cad3dDatas.data);
    if (cad3dDatas) {
      setTotalCount(cad3dDatas?.totalCount);
    }
    setTblLoading(false); // Waitingダイアログを表示にする
  };

  const columns = [
    {
      key: 'id',
      title: 'No',
      width: 50,
      fixed: 'left',
      render: (_, __, index) => (
        <div style={{ textAlign: 'center' }}>{(cusCurrentPage - 1) * pageSize + index + 1}</div>
      ),
    },
    {
      key: 'status',
      title: 'ステータス',
      dataIndex: 'status',
      width: 150,
      render: (_, record) => (
        <div style={{ textAlign: 'center' }}>
          <label>{getEstimateStatusName(record.estimateResult)}</label>
        </div>
      ),
    },
    {
      key: 'name',
      title: '見積内容',
      dataIndex: 'name',
      width: 220,
      // width: '20%',
      render: (_, record) => (
        <div style={{ display: 'flex', paddingLeft: 10 }}>
          <div style={{ display: 'grid', marginLeft: 0 }}>
            <label className="history-tb-label">アップロードファイル名：</label>
            <Typography.Text className="history-tb-label" id={'typographyTxt'} ellipsis={{ tooltip: [record.name] }}>
              {record.name}
            </Typography.Text>
            <label className="history-tb-label">見積日：</label>
            <label className="history-tb-label">{record.estimated ? formatDate(record.estimated) : ''}</label>
          </div>
        </div>
      ),
    },
    {
      key: 'quantity',
      title: '数量',
      dataIndex: 'orderConditions',
      width: 150,
      render: (record) => (
        <div style={{ textAlign: 'center' }}>
          <label>{record.quantity}</label>
        </div>
      ),
    },
    {
      key: 'orderConditionsStatus',
      title: '見積条件',
      dataIndex: 'orderConditions',
      width: '20%',
      height: '150px',
      responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      width: 220,
      render: (record) => (
        <div style={{ display: 'grid', padding: 7 }}>
          <label className="history-tb-label">発注分類：{getOrderConditionsName(record.order, orderClass)}</label>
          <label className="history-tb-label"> 材質：{getMaterialName(record.material, materialClass)}</label>
          <label className="history-tb-label">処理：{getProcessName(record.process)}</label>
          <label className="history-tb-label">公差：{getOrderConditionsName(record.tolerance, toleranceClass)}</label>
          <label className="history-tb-label">
            仕上げレベル：{getOrderConditionsName(record.weldFinishLevel, weldFinishLevel)}
          </label>
          <label className="history-tb-label">
            最終用途：{getOrderConditionsName(record.finalPurpos, finalPurpos)}
          </label>
        </div>
      ),
    },
    {
      key: 'userEstimateTotalPrice',
      title: '見積金額',
      dataIndex: 'userEstimateTotalPrice',
      width: 220,
      render: (_, record) => (
        <div key={`${record.id}-color`} style={{ textAlign: 'center', color: record.isModifiedPrice ? 'red' : '' }}>
          {(() => {
            const estimateComp = [];
            let estimateTotalPrice = record.userEstimateTotalPrice
              ? Math.round(record.userEstimateTotalPrice).toLocaleString()
              : 0;
            let estimateUnitPrice = record.userEstimateUnitPrice
              ? Math.round(record.userEstimateUnitPrice).toLocaleString()
              : 0;

            if (record.estimateResult === 7) {
              estimateComp.push(
                <label key={`${record.id}-status`}>{getEstimateStatusName(record.estimateResult)}</label>
              );
            } else {
              estimateComp.push(
                <div key={`${record.id}-estimate`} style={{ textAlign: 'center' }}>
                  <div key={`${record.id}-total`}>
                    <label style={{ fontWeight: 'bold' }}>{estimateTotalPrice}円</label>
                  </div>
                  <div key={`${record.id}-unit`}>
                    <label style={{ fontWeight: 'bold' }}>（{estimateUnitPrice}円/1個）</label>
                  </div>
                </div>
              );
            }

            return estimateComp;
          })()}
        </div>
      ),
    },
    {
      key: 'deliveryTimeCls',
      title: '希望納期',
      dataIndex: 'orderConditions',
      width: 150,
      render: (record) => (
        <div style={{ textAlign: 'center' }}>
          <label>
            {deliveryTime?.filter((i) => i.id === record?.deliveryDateTypes)?.[0]?.name === ORDER_DATE_INPUT
              ? record?.deliveryTimeCls
                ? dayjs(record.deliveryTimeCls).format('YYYY年MM月DD日')
                : ''
              : deliveryTime?.filter((i) => i.id === record?.deliveryDateTypes)?.[0]?.name}
          </label>
        </div>
      ),
    },
    {
      key: 'deliveryDate',
      title: '回答納期',
      dataIndex: 'orderConditions',
      width: 150,
      render: (record) => (
        <div style={{ textAlign: 'center' }}>
          <Typography.Text className={record?.deliveryDate ? '' : 'wrap-text'} id={'typographyTxt'}>
            {record?.deliveryDate ? (
              dayjs(record.deliveryDate).format('YYYY年MM月DD日')
            ) : (
              <>
                発注後に加工業者から <br /> 回答された納期が表示されます
              </>
            )}
          </Typography.Text>
        </div>
      ),
    },
  ];

  return (
    <Spin spinning={spinLoading} tip="処理中..." size="large">
      <div style={{ padding: 0 }}>
        <Layout className="history-layout">
          <Layout className="history-layout-tb">
            <div className="home-tilte-div">
              <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', width: '60%' }}>
                <label style={{ fontSize: '18px', fontWeight: '500', color: '#333333', marginLeft: 7 }}>見積履歴</label>
              </div>
            </div>
            <div className="home-tilte-btn-div">
              <Row>
                <Col span={12}>
                  {props?.loginUser?.userType === UserRole.HE ? (
                    ''
                  ) : (
                    <EstimateFooter
                      selectedRows={selectedRows}
                      page={'history'}
                      updateActionType={props?.updateActionType}
                      selectedFileNames={selectedFileNames}
                      setEstimateHistoryList={setEstimateHistoryList}
                      estimateHistoryList={estimateHistoryList}
                      setSelectedRows={setSelectedRows}
                      homePageLoading={setSpinLoading}
                      orderClass={orderClass}
                      toleranceClass={toleranceClass}
                      weldFinishLevel={weldFinishLevel}
                      finalPurpos={finalPurpos}
                      materialTypeIq3={materialClass}
                      processMst={processMst}
                    />
                  )}
                </Col>
                <Col span={12}>
                  <div className="history-search-div">
                    <label style={{ fontSize: '13px', fontWeight: '600', color: '#333333' }}>
                      アップロードファイル名
                    </label>
                    <Input
                      key="fileNm"
                      id="fileNm"
                      name="fileNm"
                      value={fileNm}
                      style={{ marginLeft: 0, width: 200, marginLeft: 5 }}
                      allowClear
                      onChange={(e) => {
                        setFileNm(e.target.value);
                      }}
                    />
                    <label style={{ fontSize: '13px', fontWeight: '600', color: '#333333', marginLeft: 10 }}>
                      見積日
                    </label>
                    <DatePicker
                      locale="ja"
                      format={'YYYY年MM月DD日'}
                      style={{ marginLeft: 0, marginLeft: 5 }}
                      value={estimateDate ? dayjs(estimateDate, 'YYYY-MM-DD') : ''}
                      placeholder="選択してください"
                      onChange={(_, dateString) => {
                        setEstimateDate(dateString);
                      }}
                    />
                    <Button
                      key="search"
                      className="history-search-btn"
                      onClick={(e) => handleSearch()}
                      style={{ marginLeft: 10, backgroundColor: '#017bc5', color: 'white' }}
                    >
                      検索
                    </Button>
                    <Button
                      key="clear"
                      className="history-search-btn"
                      style={{ marginLeft: 10, width: 95 }}
                      onClick={(e) => handleClearSearch()}
                    >
                      検索をクリア
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>

            <div className="responsive-table">
              <Table
                rowSelection={{
                  type: 'checkbox',
                  ...rowSelection,
                }}
                rowKey={(record) => record?.id}
                columns={columns}
                dataSource={estimateHistoryList}
                className="admin-history-tb"
                pagination={{
                  position: ['topLeft'],
                  total: totalCount ? totalCount : 1,
                  showTotal: (total, range) =>
                    totalCount
                      ? `${selectedRows?.length}件を選択中、全${total}件中${range[0]}〜${range[1]}件を表示中`
                      : `${0}件を選択中、全${0}件中${0}〜${0}件を表示中`,
                  defaultCurrent: 1,
                  pageSize: pageSize,
                  pageSizeOptions: pageSizes,
                  showSizeChanger: true,
                  onShowSizeChange: handlePageSizeChange,
                  current: cusCurrentPage,
                  onChange: (page) => {
                    setCusCurrentPage(page);
                  },
                }}
                onChange={handleTableChange}
                loading={tblLoading}
                scroll={{
                  x: estimateHistoryList.length > 0 ? 'max-content' : 'auto',
                  y: estimateHistoryList.length > 0 ? tableHeight : 'auto',
                }}
              />
            </div>
          </Layout>
        </Layout>
      </div>
    </Spin>
  );
});

export default WithRouter(EstimateHistory);
