/**
 * クラス名：システム全体の共通ファイル
 * 説明：システム全体に共通使っているファイルである。
 * 作成者：ナンス
 * 作成日：2024/11/25
 * バージョン：1.0
 */
import { toDecimal } from '../common/Common';
import {
  calDandoriAmt,
  calDandoriTime,
  calPunchCntByCombi,
  calSagyoAmt,
  calSagyoAmtJoint,
  calSagyoAmtPunch,
  calSagyoAmtTwoStepHole,
  calSagyoTime,
  getGasCoef,
  getJointTm,
  getProductionQuantityCoeff,
  getSagyoIn,
  getSagyoOut,
  getSagyoPiercingTm,
  getSagyoPunches,
  getSagyoPunchTm,
  getSagyoSpeed,
  getSetupCoeff,
  getSizeCoeff,
  getSpeedCoef,
} from './IQ3KouteiCalculate_Common';
import {
  BendingTypes,
  BlankTypes,
  ClientType,
  DataType,
  FRBend,
  IQxRealGoku_AccessToken,
  ServiceClass,
  Types,
  WorkType,
  WorkTypeGroup,
} from '../common/Constant';

// 自動バリ工程の計算
export const calAutoBari = (
  curAddition,
  processInput,
  processSelect,
  curDeviceMst,
  calcParameters,
  curIQ3DataDetail,
  orderQuantity
) => {
  let autoBariAddition = curAddition;
  if (Object.keys(autoBariAddition).length > 0) {
    let processMst = [];
    let deviceMst = []; //テスト用ID;

    let parameters = calcParameters;
    processMst = parameters?.process
      ? parameters.process?.filter((item) => item.workType === WorkType.SmDeburringAuto)[0]
      : [];
    let patternInfo = processSelect;
    if (patternInfo == undefined) return;
    if (patternInfo?.length > 0 && patternInfo[0]?.isUsedForCalc == false) return;
    deviceMst = curDeviceMst[0]; //テスト用ID;
    let deburringAutoAdditions = autoBariAddition;
    // 自動バリ工程入力情報取得
    let autoDeburProcessInput = processInput[0];
    let deburringAutoAddition = {};
    let detailItems = [];
    let editDetailItems = [];
    let processName = '自動バリ金額/時間';
    // 自動バリ詳細情報
    detailItems.push({
      id: 1,
      processName: processName,
      dandoriAmt: 0,
      dandoriTime: 0,
      sagyoAmt: 0,
      sagyoTime: 0,
      area: Number(autoDeburProcessInput?.details?.[0]?.area),
      weight: Number(autoDeburProcessInput?.details?.[0]?.weight),
      processCount: Number(autoDeburProcessInput?.details?.[0]?.count),
      kihon: true,
      formula: '',
    });

    deburringAutoAddition = {
      no: processMst?.id,
      processId: processMst?.id,
      dataType: deburringAutoAdditions?.dataType ? deburringAutoAdditions?.dataType : DataType.Data,
      dataItems: detailItems,
      editItems: deburringAutoAdditions?.editItems ? deburringAutoAdditions?.editItems : editDetailItems,
      totalDandori: {
        dataItemsAmt: 0,
        dataItemsTime: 0,
        editItemsAmt: 0,
        editItemsTime: 0,
      },
      totalSagyo: {
        dataItemsAmt: 0,
        dataItemsTime: 0,
        editItemsAmt: 0,
        editItemsTime: 0,
      },
      totalDataPrice: 0,
      totalDataTime: 0,
      totalEditPrice: 0,
      totalEditTime: 0,
      editDataFlg: false,
    };
    deburringAutoAdditions = deburringAutoAddition;

    if (deburringAutoAdditions?.dataItems) {
      let orgData = JSON.parse(JSON.stringify(deburringAutoAdditions?.dataItems));
      if (deviceMst != undefined && orgData?.length > 0) {
        // 重量
        let weight = Number(autoDeburProcessInput?.details?.[0]?.weight);
        // 面積
        let area = Number(autoDeburProcessInput?.details?.[0]?.area);
        orgData[0].area = area === undefined ? 0 : area;
        if (weight === 0 && area === 0) {
          deburringAutoAdditions.dataItems[0].dandoriTime = 0;
          orgData[0].dandoriTime = 0;

          let dandoriAmt = 0;
          deburringAutoAdditions.dataItems[0].dandoriAmt = dandoriAmt;
          orgData[0].dandoriAmt = dandoriAmt === dandoriAmt;

          let processCount = 0;
          let cSagyoTimeSec = 0;
          let cSagyoTime = cSagyoTimeSec;
          deburringAutoAdditions.dataItems[0].sagyoTime = cSagyoTime;
          orgData[0].sagyoTime = cSagyoTime;

          let cSagyoAmt = 0;
          deburringAutoAdditions.dataItems[0].sagyoAmt = cSagyoAmt;
          orgData[0].sagyoAmt = cSagyoAmt;

          let totalSum = 0;
          deburringAutoAdditions.totalDataPrice = totalSum;
          deburringAutoAdditions.totalDandori.dataItemsAmt = dandoriAmt;
          deburringAutoAdditions.totalSagyo.dataItemsAmt = cSagyoAmt;

          let totalTimeSec = 0;
          deburringAutoAdditions.totalDataTime = totalTimeSec;
          deburringAutoAdditions.totalDandori.dataItemsTime = 0;
          deburringAutoAdditions.totalSagyo.dataItemsTime = 0;
        } else {
          let quantity = orderQuantity;
          let partCnt = curIQ3DataDetail?.partCount;
          // サイズ係数
          let sizeCoef = getSizeCoeff(deviceMst, weight, area);

          /** 段取金額/時間 */
          // 段取時間設定
          //　基本段取時間＊サイズ係数
          let dandoriTimeSec = calDandoriTime(WorkType.SmDeburringAuto, deviceMst, 0, weight, area);
          dandoriTimeSec = dandoriTimeSec ? dandoriTimeSec : 0;
          dandoriTimeSec = dandoriTimeSec * getSetupCoeff(deviceMst, quantity, partCnt); // 基本段取時間＊段取個数係数
          let dandoriTimeHms = dandoriTimeSec;
          deburringAutoAdditions.dataItems[0].dandoriTime = dandoriTimeHms;
          orgData[0].dandoriTime = dandoriTimeHms === undefined ? 0 : dandoriTimeHms;

          // 段取金額設定
          //段階時間＊時間チャージ
          let dandoriAmt = calDandoriAmt(dandoriTimeSec, deviceMst);
          dandoriAmt = dandoriAmt ? dandoriAmt : 0;
          deburringAutoAdditions.dataItems[0].dandoriAmt = dandoriAmt;
          orgData[0].dandoriAmt = dandoriAmt === undefined ? 0 : dandoriAmt;

          /** 作業金額/時間 */
          // 作業時間｛加工時間	+	(加工補正時間	*	加工補正係数)｝	*	サイズ係数	*	回数
          let processCount = Number(autoDeburProcessInput?.details?.[0]?.count);
          let workTime = calSagyoTime(WorkType.SmDeburringAuto, deviceMst, 0, 0, 0, 0, 0, area);
          workTime = workTime * getProductionQuantityCoeff(deviceMst, quantity, partCnt); // 作業時間＊生産個数係数
          let cSagyoTimeSec = workTime * sizeCoef * processCount;
          cSagyoTimeSec = cSagyoTimeSec ? cSagyoTimeSec : 0;
          let cSagyoTime = cSagyoTimeSec;
          deburringAutoAdditions.dataItems[0].sagyoTime = cSagyoTime;
          orgData[0].sagyoTime = cSagyoTime === undefined ? 0 : cSagyoTime;

          // 作業金額
          //　作業時間＊時間チャージ
          let cSagyoAmt = calSagyoAmt(cSagyoTimeSec, deviceMst);
          deburringAutoAdditions.dataItems[0].sagyoAmt = cSagyoAmt === undefined ? 0 : cSagyoAmt;
          orgData[0].sagyoAmt = cSagyoAmt === undefined ? 0 : cSagyoAmt;
          let totalSum = (dandoriAmt ? Math.round(dandoriAmt) : 0) + (cSagyoAmt ? Math.round(cSagyoAmt) : 0); // 画面表示用（四捨五入した金額を足す）
          deburringAutoAdditions.totalDataPrice = totalSum;
          deburringAutoAdditions.totalDandori.dataItemsAmt = dandoriAmt ? Math.round(dandoriAmt) : 0;
          deburringAutoAdditions.totalSagyo.dataItemsAmt = cSagyoAmt ? Math.round(cSagyoAmt) : 0;
          let totalTimeSec = dandoriTimeSec + cSagyoTimeSec;
          deburringAutoAdditions.totalDataTime = totalTimeSec;
          deburringAutoAdditions.totalDandori.dataItemsTime = dandoriTimeSec;
          deburringAutoAdditions.totalSagyo.dataItemsTime = cSagyoTimeSec;
          deburringAutoAdditions.dataItems = orgData;
          autoBariAddition = deburringAutoAdditions;
        }
      }
    }
  }
  return autoBariAddition;
};
