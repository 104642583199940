import { Input, Button, Row, Col, Table, Layout, DatePicker, Spin, Typography } from 'antd';
import React, { forwardRef, useEffect, useState } from 'react';
import { WithRouter } from '../components/WithRouter';
import dayjs from 'dayjs';
import axios from 'axios';
import * as signalR from '@microsoft/signalr';

import '../assets/styles/common.css';
import {
  blobToBase64,
  convertCadDataToEstimateData,
  formatDate,
  generateTimestamp,
  getEstimateStatusName,
  getFileExtension,
  getMaterialName,
  getNewEstimateInfo,
  getOrderConditionsName,
  getProcessName,
  getRequestHeader,
} from './common/Common';
import { getCad3dDatasForAdmin, getHubConnectionToken, updateUserRequestAmount } from './common/CommonAPI';
import { IQxRealGoku_CalParameters, pageSizes, ServiceClass, WorkType } from './common/Constant';
import { ErrorMessage } from './common/Message';
import { AdminBtnClass, EstimatePriorityClass, EstimateResultClass } from './common/enums';
import { calEstimateInfo } from './estimate/Estimate';
import { commonFooter, commonModal, deleteModalTitle } from './common/CommonModal';

const AdminManagement = forwardRef((props, ref) => {
  const [pageSize, setPageSize] = useState(30);
  const [cusCurrentPage, setCusCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [filterDatas, setFilterDatas] = useState({
    companyName: '',
    fileName: '',
    estimateDate: '',
  });
  const [loading, setLoading] = useState(false);
  const [estimateDate, setEstimateDate] = useState('');
  const [estimateHistoryList, setEstimateHistoryList] = useState([]);
  const [companyNmSearch, setcompanyNmSearch] = useState('');
  const [fileNm, setFileNm] = useState('');
  const [hubConnection, setHubConnection] = useState(null);
  const [hubConnectionToken, setHubConnectionToken] = useState(null);
  const [modifiedValues, setModifiedValues] = useState({});
  const [spinLoading, setSpinLoading] = useState(false);
  const [calParameters, setCalParameters] = useState({});
  const [orderClass, setOrderClass] = useState([]);
  const [toleranceClass, setToleranceClass] = useState([]);
  const [weldFinishLevel, setWeldFinishLevel] = useState([]);
  const [finalPurpos, setFinalPurpos] = useState([]);
  const [deliveryTime, setDeliveryTime] = useState([]);
  const [tableHeight, setTableHeight] = useState(0);
  const [confirm, setConfirm] = useState(false);
  const [btnType, setBtnType] = useState(AdminBtnClass.AdjustAmt);
  const [curRecord, setCurRecord] = useState({});
  const [materialClass, setMaterialClass] = useState([]);
  const [processMst, setProcessMst] = useState([]);

  // CAD 3D データを初期化するための関数呼び出しする
  useEffect(() => {
    settingCad3dDatas();
    // 見積もるパラメータ情報をSessionから取得
    let paramData = JSON.parse(decodeURI(localStorage?.getItem(IQxRealGoku_CalParameters)));
    setCalParameters(paramData);
    if (Object.keys(paramData)?.length > 0) {
      let companyInfo = paramData.company;
      // 受注条件設定
      // 受注種類
      let coeffOrderTypes = companyInfo[0]?.coeffOrderTypes?.filter((i) => !i.isDeleted && i.isUsed);
      setOrderClass(coeffOrderTypes);
      //公差
      let toleranceClass = companyInfo[0]?.coeffTolerance?.filter((i) => !i.isDeleted && i.isUsed);
      setToleranceClass(toleranceClass);
      //仕上げレベル
      let weldFinishLevel = companyInfo[0]?.coeffWeldFinishLevel?.filter((i) => !i.isDeleted && i.isUsed);
      setWeldFinishLevel(weldFinishLevel);
      //最終用途
      let finalPurpos = companyInfo[0]?.coeffFinalPurpose?.filter((i) => !i.isDeleted && i.isUsed);
      setFinalPurpos(finalPurpos);
      // 発注後納期
      let deliveryTime = companyInfo[0]?.coeffDeliveryDates?.filter((i) => !i.isDeleted && i.isUsed);
      setDeliveryTime(deliveryTime);
      // 材質
      let materialClass = paramData.materialTypeIq3?.filter((i) => !i.isDeleted && i.info.isUsed);
      setMaterialClass(materialClass);
      // 処理
      let process = paramData.process?.filter(
        (i) => !i.isDeleted && i.info.isUsed && i.class === ServiceClass.SheetMetal && i.workType === WorkType.SmSurface
      );
      setProcessMst(process);
    }
  }, []);

  useEffect(() => {
    const updateTableHeight = () => {
      const headerHeight = document.querySelector('.responsive-table .ant-table-thead')?.offsetHeight || 55;
      const fixedHeight = document.querySelector('.home-tilte-div')?.offsetHeight || 0;
      const searchDivHeight = document.querySelector('.admin-search-div')?.offsetHeight || 0;
      const paginationElement = document.querySelector('.ant-table-pagination');
      const paginationMarginTop = paginationElement
        ? parseInt(getComputedStyle(paginationElement)?.marginTop || '0', 10)
        : 10;
      const paginationMarginBottom = paginationElement
        ? parseInt(getComputedStyle(paginationElement)?.marginBottom || '0', 10)
        : 10;
      const paginationHeight = document.querySelector('.ant-table-pagination')?.offsetHeight || 32;

      const availableHeight =
        window.innerHeight -
        headerHeight -
        fixedHeight -
        searchDivHeight -
        paginationHeight -
        paginationMarginTop -
        paginationMarginBottom -
        60; // Adjust 20px padding/margin if needed;
      setTableHeight(availableHeight);
    };

    updateTableHeight();
    window.addEventListener('resize', updateTableHeight);

    return () => window.removeEventListener('resize', updateTableHeight);
  }, []);

  // getHubConnection関数を呼び出してハブへの接続を確立する
  useEffect(() => {
    getHubConnection();
  }, []);

  // SignalRハブへの接続を確立する機能
  const getHubConnection = async () => {
    if (hubConnection === null) {
      try {
        // AudiのログインAPIを呼び出して、トークン情報を取得
        let audiLoginToken = await getHubConnectionToken();
        if (audiLoginToken && audiLoginToken != '') {
          setHubConnectionToken(audiLoginToken);
          // SignalRハブへの接続を確立する
          const newConnection = new signalR.HubConnectionBuilder()
            .withUrl(process.env.REACT_APP_HUB_CONNECTION_URL, {
              accessTokenFactory: () => audiLoginToken,
            })
            .withAutomaticReconnect()
            .build();
          newConnection
            .start()
            .then(() => {
              console.log('SignalR Connected.');
              setHubConnection(newConnection);
            })
            .catch((err) => console.error('Connection Error:', err));

          return () => {
            if (newConnection) {
              newConnection.stop();
            }
          };
        }
      } catch (error) {
        console.error('Audiのログイン情報取得が失敗した場合 : ', error);
      }
    }
  };

  // リスト画面表示のため、DBに保存された3Dファイル情報を取得する
  const settingCad3dDatas = async () => {
    setLoading(true);
    let cad3dDatas = await getCad3dDatasForAdmin(pageSize, 0, 'ASC', '', filterDatas);
    if (cad3dDatas) {
      setTotalCount(cad3dDatas?.totalCount);
      cad3dDatas = cad3dDatas.data?.map((item) => ({
        ...item,
        inputValue: modifiedValues[item.id] || '',
      }));
      setEstimateHistoryList(cad3dDatas);
      setLoading(false);
    }
  };

  // 表示ボタンの押下して、検索する
  const handleSearch = async () => {
    const filterData = {
      companyName: companyNmSearch,
      fileName: fileNm,
      estimateDate: estimateDate,
    };
    setFilterDatas(filterData);
    setLoading(true);
    let cad3dDatas = await getCad3dDatasForAdmin(pageSize, 0, 'ASC', '', filterData);
    if (cad3dDatas) {
      setTotalCount(cad3dDatas?.totalCount);
      cad3dDatas = cad3dDatas.data?.map((item) => ({
        ...item,
        inputValue: modifiedValues[item.id] || '',
      }));
      setEstimateHistoryList(cad3dDatas);
      setLoading(false);
    }
  };

  // 検索をクリアボタンの押下して、初期化する
  const handleClearSearch = async () => {
    setcompanyNmSearch('');
    setEstimateDate('');
    setFileNm('');
    const filterData = {
      companyName: '',
      fileName: '',
      estimateDate: '',
    };
    setFilterDatas(filterData);
    setLoading(true);
    // 3Dファイル情報を取得する
    let cad3dDatas = await getCad3dDatasForAdmin(pageSize, 0, 'ASC', '', filterData);
    if (cad3dDatas) {
      setTotalCount(cad3dDatas?.totalCount);
      cad3dDatas = cad3dDatas.data?.map((item) => ({
        ...item,
        inputValue: modifiedValues[item.id] || '',
      }));
      setEstimateHistoryList(cad3dDatas);
      setLoading(false);
    }
  };

  // 価格交渉後の金額変更
  const handleValueChange = (value, id) => {
    setEstimateHistoryList((prev) => prev?.map((item) => (item.id === id ? { ...item, inputValue: value } : item)));

    // 金額交渉中ページネーション切り替えする時、入力データを保存
    setModifiedValues((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  // 価格交渉後の金額変更して、更新する
  const updateAmount = async (data) => {
    let unitPrice = Number.isFinite(Number(data.inputValue) / Number(data.orderConditions.quantity))
      ? Number(data.inputValue) / Number(data.orderConditions.quantity)
      : 0;
    // 更新する
    let result = await updateUserRequestAmount(data.id, Number(data.inputValue), Number(unitPrice));
    if (result) {
      setEstimateHistoryList((prev) =>
        prev?.map((item) =>
          item.id === data.id
            ? {
                ...item,
                userEstimateTotalPrice: Number(data.inputValue),
                userEstimateUnitPrice: Number(unitPrice),
                isModifiedPrice: result.isModifiedPrice,
              }
            : item
        )
      );
    }
  };

  // 注文確定する
  const orderConfirm = async (targetData) => {
    const headers = getRequestHeader('application/json');
    const reqData = { cad3dId: targetData.id, orderconditions: targetData.orderConditions, estimateResult: 6 };
    const apiUrl = `${process.env.REACT_APP_BACKEND_API_URL}Cad3dData/UpdateEstimateResultByAdmin`;
    await axios
      .put(apiUrl, reqData, { headers })
      .then(async (response) => {
        setEstimateHistoryList((prev) =>
          prev?.map((item) => (item.id === targetData.id ? { ...item, estimateResult: 6 } : item))
        );
      })
      .catch(async (error) => {
        // 異常の場合、
        ErrorMessage(error.message);
        return error;
      });
  };

  // 自動見積外を実施ボタンを押下された時
  const autoEstimateBtnProcess = async () => {
    try {
      if (hubConnection.state === signalR.HubConnectionState.Disconnected) {
        console.warn('SignalR is disconnected. Attempting to reconnect...');
        await hubConnection.start();
      }
      setSpinLoading(true);
      // 自動見積外のものを取得。
      let headers = getRequestHeader();
      const cad3dFileUrl =
        `${process.env.REACT_APP_BACKEND_API_URL}Cad3dData/GetCad3dDatasByAutoEstimateFail` +
        '?autoEstimateFlg=' +
        true;
      const cad3dFileResponse = await axios.get(cad3dFileUrl, { headers });
      if (cad3dFileResponse.status === 200) {
        let autoEstimateFailInfos = cad3dFileResponse.data?.data;
        if (autoEstimateFailInfos && autoEstimateFailInfos?.length > 0) {
          // 全自動見積しない加工の見積をPromise.allで処理する
          await Promise.all(
            autoEstimateFailInfos?.map(async (autoEstimateFailInfo, i) => {
              let cad3dData = autoEstimateFailInfo;
              // Audiへ3Dファイルアップロードのため、RealGokuのストレージに保持した3Dファイル情報を取得
              const cad3dFormData = new FormData();
              cad3dFormData.append('fileName', cad3dData.name);
              cad3dFormData.append('loginId', cad3dData.loginId);
              const cad3dFileUrl = `${process.env.REACT_APP_BACKEND_API_URL}Cad3dData/GetCad3dFile`;
              const cad3dFileResponse = await axios.post(cad3dFileUrl, cad3dFormData, {
                headers: headers,
                responseType: 'blob',
              });
              // Audiへ3Dファイルアップロードするための3Dファイル情報取得が成功した場合
              if (cad3dFileResponse.status === 200) {
                // 見積対象データにPMX情報がない場合、Audiへ3DファイルアップロードしてPMX情報を取得
                if (cad3dFileResponse.data) {
                  // 命名規則：yyyymmddhhmmssfff+ランダム生成された3桁の数字+拡張子
                  let cad3dFileName = generateTimestamp() + getFileExtension(cad3dData.name);
                  // Blob から File オブジェクトを作成
                  let cad3dFile = new File([cad3dFileResponse.data], cad3dFileName, {
                    type: 'application/octet-stream',
                  });
                  if (!hubConnection || !cad3dFile) {
                    console.error('Connection or file is missing.');
                  } else {
                    if (hubConnection.state === signalR.HubConnectionState.Disconnected) {
                      console.warn('SignalR is disconnected. Attempting to reconnect...');
                      await hubConnection.start();
                    }
                    // Audiへ3Dファイルアップロード
                    const cad3dFileFormData = new FormData();
                    cad3dFileFormData.append('file', cad3dFile);
                    cad3dFileFormData.append('parentDirectory', process.env.REACT_APP_AUDI_FILE_UPLOAD_URL);
                    const generatePMXURL = process.env.REACT_APP_AUDI_BACKEND_API_GENERATE_PMX_URL;
                    const generatePMXRes = await axios.post(generatePMXURL, cad3dFileFormData, {
                      headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${hubConnectionToken}`,
                      },
                      reportProgress: true,
                      responseType: 'json',
                    });
                    // Audiへ3Dファイルアップロードが成功してPMXファイルが生成された場合、
                    if (generatePMXRes.status === 200) {
                      //AudiからPMXファイルと画像を取得する（Audiが提供するAPIをコールすることにより3Dファイルと画像を取得する）
                      // PMX情報取得API（AudiからPMX取得）
                      let route = '?filePath=' + generatePMXRes?.data?.data?.fullPath;
                      const audiPMXUrl = process.env.REACT_APP_AUDI_BACKEND_API_DOWNLOAD_PMX_URL + route;
                      const audiPMXResponse = await axios.get(audiPMXUrl, {
                        headers: {
                          Authorization: `Bearer ${hubConnectionToken}`,
                        },
                        responseType: 'blob',
                      });
                      // PMXファイルダウンロードが成功した場合
                      if (audiPMXResponse.status === 200) {
                        let audiPMXFile = audiPMXResponse.data;
                        if (audiPMXFile) {
                          // StreamをBlobに変換
                          const blob = new Blob([audiPMXFile], { type: 'application/octet-stream' });
                          const base64String = await blobToBase64(blob);
                          const pmxFileNm = cad3dData?.name + '.PMX';
                          const pmxFormData = new FormData();
                          pmxFormData.append('cad3dId', cad3dData.id);
                          pmxFormData.append('pmxBase64String', base64String);
                          pmxFormData.append('fileName', pmxFileNm);
                          const pmxUrl = `${process.env.REACT_APP_BACKEND_API_URL}Cad3dData/GetPMXFileData`;
                          await axios
                            .post(pmxUrl, pmxFormData, { headers })
                            .then(async (pmxDataResponse) => {
                              // AudiからのPMXファイルを解析した結果
                              let cadData = pmxDataResponse.data?.data;
                              // 解析したデータを見積もる
                              calEstimatePMXData(cad3dData, cadData);
                            })
                            .catch(async (error) => {
                              // PMX解析情報取得失敗の場合、Audiから受領したタイミング、ゼロフォー運用者への通知
                              // 異常の場合、
                              //見積処理失敗の場合、失敗ステータスを画面に表示
                              await UpdateEstimateResult(cad3dData, EstimateResultClass.EstimateFail, false);
                            });
                        }
                      } else {
                        // PMXファイルダウンロードが失敗した場合
                        // PMXファイルダウンロード失敗の通知をAudiから受領したタイミング、ゼロフォー運用者への通知
                        //見積処理失敗の場合、失敗ステータスを画面に表示
                        await UpdateEstimateResult(cad3dData, EstimateResultClass.EstimateFail, false);
                      }
                    } else {
                      // Audiへ3Dファイルアップロードが失敗した場合
                      // Audiへ3Dファイルアップロード失敗の通知をAudiから受領したタイミング、ゼロフォー運用者への通知
                      //見積処理失敗の場合、失敗ステータスを画面に表示
                      await UpdateEstimateResult(cad3dData, EstimateResultClass.EstimateFail, false);
                    }
                  }
                }
              } else {
                // Audiへ3Dファイルアップロードするための3Dファイル情報取得が失敗した場合
                // 3Dファイル情報取得処理失敗の通知をAudiから受領したタイミング、ゼロフォー運用者への通知
                //見積処理失敗の場合、失敗ステータスを画面に表示
                await UpdateEstimateResult(cad3dData, EstimateResultClass.EstimateFail, false);
              }
            })
          );
          setSpinLoading(false);
          // ④自動見積しない加工（PriorityLevel: low）について金額算出が終了したタイミング、ゼロフォー運用者への通知
          const mailSendInfo = new FormData();
          mailSendInfo.append('mailFlg', true);
          const mailSendApiUrl = `${process.env.REACT_APP_BACKEND_API_URL}Cad3dData/SendMailToZFOperatorByAutoEstimateFailResult`;
          await axios.post(mailSendApiUrl, mailSendInfo, { headers });
        } else {
          // 自動見積外のものがない場合、画面に情報表示
          setSpinLoading(false);
        }
      } else {
        // 自動見積外のものを取得に失敗
        setSpinLoading(false);
      }
    } catch (error) {
      setSpinLoading(false);
      console.error('Error uploading files:', error);
      //return upCad3dData;
    }
  };

  // PMX情報で見積処理を行う。
  const calEstimatePMXData = async (cad3dData, cadData) => {
    try {
      if (cadData != null) {
        // 見積計算のため見積データ作成
        let newEstimateInfo = getNewEstimateInfo(cadData?.details?.length, calParameters, cad3dData.orderConditions);
        if (newEstimateInfo) {
          let estimateData = convertCadDataToEstimateData(newEstimateInfo, cadData);
          // 見積計算
          estimateData = calEstimateInfo(estimateData, cad3dData.orderConditions);
          if (estimateData) {
            // 見積もった結果をDBに保持するため、データ準備
            // 自動見積もり結果の算出ができてもユーザー向けの画面には表示しないでください
            let upCad3dData = cad3dData;
            //upCad3dData.estimateResult = EstimateResultClass.DisplayEstimateResult; //金額表示フラグ
            upCad3dData.estimateTotalPrice = estimateData?.estimateProducts?.estimateTotalPrice;
            upCad3dData.estimateUnitPrice = estimateData?.estimateProducts?.estimateUnitPrice;

            // 見積もった結果をDBに保持
            const upCad3dDataReq = {
              upCad3d: {
                id: upCad3dData.id,
                estimateUnitPrice: upCad3dData.estimateUnitPrice,
                estimateTotalPrice: upCad3dData.estimateTotalPrice,
                estimateResult: upCad3dData?.estimateResult,
                orderConditions: upCad3dData?.orderConditions,
              },
              estimateProducts: estimateData.estimateProducts,
              esIq3Info: estimateData.esIq3Info,
              upCad3dLoginId: upCad3dData.loginId,
            };
            let headers = getRequestHeader('application/json');
            const saveApiUrl = `${process.env.REACT_APP_BACKEND_API_URL}Cad3dData/SaveEstimateData`;
            const saveApiResponse = await axios.post(saveApiUrl, upCad3dDataReq, { headers });
            // 見積データ保持が成功した場合
            if (saveApiResponse.status === 200) {
              let upCad3dData = saveApiResponse.data?.data;
              if (upCad3dData && upCad3dData?.length > 0) {
                if (upCad3dData[0]?.estimateTotalPrice > 0) {
                  setEstimateHistoryList((prevData) => {
                    // 既存のデータを更新
                    // const updatedList = prevData.map((prevItem) => {
                    //   const matchingItem = upCad3dData?.find((item) => item.id === prevItem.id); // 一意な識別子で比較
                    //   return matchingItem ? matchingItem : prevItem; // 一致すれば新しいデータに置き換える
                    // });
                    // 既存のデータを含むか確認
                    const newData = upCad3dData?.filter(
                      (item) => !prevData.some((prevItem) => prevItem.id === item.id) // 一意な識別子 (id) で比較
                    );
                    // 更新済みリストと新規データを結合
                    return [...prevData, ...newData];
                  });
                }
              }
            } else {
              // 見積データ保持の際に失敗の場合、Audiから受領したタイミング、ゼロフォー運用者への通知
              // 見積データ計算の際にエラーが発生した場合ゼロフォー運用者への通知
              await UpdateEstimateResult(cad3dData, EstimateResultClass.EstimateFail, false);
            }
          }
        }
      }
    } catch (error) {
      //見積処理失敗の場合、失敗ステータスを画面に表示
      // 見積データ計算の際にエラーが発生した場合ゼロフォー運用者への通知
      await UpdateEstimateResult(cad3dData, EstimateResultClass.EstimateFail, false);
    }
  };

  // 見積結果種類を更新する
  const UpdateEstimateResult = async (cad3dData, estResult, orderFlg) => {
    let headers = getRequestHeader('application/json');
    const reqData = {
      cad3dId: cad3dData.id,
      orderConditions: cad3dData.orderConditions,
      estimateResult: estResult,
      orderFlg: orderFlg,
    };
    const apiUrl = `${process.env.REACT_APP_BACKEND_API_URL}Cad3dData/UpdateEstimateResult`;
    const processingStatusResponse = await axios.put(apiUrl, reqData, { headers });
    if (processingStatusResponse?.status === 200) {
      let processingStatus = processingStatusResponse?.data?.data;
      if (processingStatus && processingStatus?.length > 0) {
        processingStatus = processingStatus[0];
        if (processingStatus?.estimateResult === EstimateResultClass.EstimateFail) {
          // 管理者へメール通知
          const mailApiUrl = `${process.env.REACT_APP_BACKEND_API_URL}Cad3dData/SendMailToZFOperatorByUpdateEstimateResult`;
          await axios.put(mailApiUrl, reqData, { headers });
        }
      }
    }
  };

  // ページサイズの変更を処理する
  const handlePageSizeChange = (current, pagesize) => {
    setPageSize(pagesize);
  };

  // テーブルのページネーションの変更を処理する
  const handleTableChange = async (pagination, filters, sorter) => {
    setLoading(true); // Waitingダイアログを表示にする
    let pageBefore = pagination?.current - 1;
    let cad3dDatas = await getCad3dDatasForAdmin(pagination?.pageSize, pageBefore, 'ASC', '', filterDatas);
    if (cad3dDatas) {
      setTotalCount(cad3dDatas?.totalCount);
      cad3dDatas = cad3dDatas.data?.map((item) => ({
        ...item,
        inputValue: modifiedValues[item.id] || '',
      }));
      setEstimateHistoryList(cad3dDatas);
      setLoading(false);
    }
    setLoading(false); // Waitingダイアログを表示にする
  };

  // ボタンを押下された場合
  const handleBtnAction = (type, record = {}) => {
    if (Object.keys(record)?.length > 0) {
      setCurRecord(record);
    }
    setBtnType(type);
    setConfirm(true);
  };

  // 対象ボタンより処理を行う
  const confirmOk = async () => {
    setConfirm(false);
    if (btnType === AdminBtnClass.AdjustAmt) {
      updateAmount(curRecord);
    } else if (btnType === AdminBtnClass.ConfirmOrder) {
      orderConfirm(curRecord);
    } else if (btnType === AdminBtnClass.Estimate) {
      autoEstimateBtnProcess();
    }
  };

  // 対象ボタンより処理を行わない
  const confirmCancel = () => {
    setConfirm(false);
  };

  // 画面に表示するリスト
  const columns = [
    {
      title: 'No',
      key: 'id',
      width: 70,
      fixed: 'left',
      render: (_, __, index) => (
        <div style={{ textAlign: 'center' }}>{(cusCurrentPage - 1) * pageSize + index + 1}</div>
      ),
    },
    {
      title: '会社名・氏名',
      dataIndex: 'login',
      width: 150,
      render: (record) => (
        <div style={{ textAlign: 'left' }}>
          <div>
            <Typography.Text
              className="history-tb-label"
              id={'typographyTxt'}
              ellipsis={{ tooltip: [record.userInfo.companyName] }}
            >
              {record.userInfo.companyName}
            </Typography.Text>
          </div>
          <div>
            <Typography.Text
              className="history-tb-label"
              id={'typographyTxt'}
              ellipsis={{ tooltip: [record.userInfo.lastName + record.userInfo.firstName] }}
            >
              {record.userInfo.lastName}
              {record.userInfo.firstName}
            </Typography.Text>
          </div>
        </div>
      ),
    },
    {
      title: '見積日',
      dataIndex: 'estimated',
      width: 130,
      render: (record) => (
        <div style={{ textAlign: 'center' }}>
          <label>{record ? formatDate(record) : ''}</label>
        </div>
      ),
    },
    {
      title: '見積内容',
      dataIndex: 'name',
      width: 220,
      render: (_, record) => (
        <div style={{ display: 'flex' }}>
          <div style={{ display: 'grid', marginLeft: 0 }}>
            <label className="history-tb-label">アップロードファイル名：</label>
            <Typography.Text className="history-tb-label" id={'typographyTxt'} ellipsis={{ tooltip: [record.name] }}>
              {record.name}
            </Typography.Text>
          </div>
        </div>
      ),
    },
    {
      title: '数量',
      dataIndex: 'orderConditions',
      width: 90,
      render: (record) => (
        <div style={{ textAlign: 'center' }}>
          <label>{record.quantity}</label>
        </div>
      ),
    },
    {
      title: '見積条件',
      dataIndex: 'orderConditions',
      width: 180,
      render: (record) => (
        <div style={{ display: 'grid', padding: 7 }}>
          <label className="history-tb-label">発注分類：{getOrderConditionsName(record.order, orderClass)}</label>
          <label className="history-tb-label"> 材質：{getMaterialName(record.material, materialClass)}</label>
          <label className="history-tb-label">処理：{getProcessName(record.process)}</label>
          <label className="history-tb-label">公差：{getOrderConditionsName(record.tolerance, toleranceClass)}</label>
          <label className="history-tb-label">
            仕上げレベル：{getOrderConditionsName(record.weldFinishLevel, weldFinishLevel)}
          </label>
          <label className="history-tb-label">
            最終用途：{getOrderConditionsName(record.finalPurpos, finalPurpos)}
          </label>
        </div>
      ),
    },
    {
      title: 'ユーザーに表示中の金額',
      dataIndex: 'userEstimateTotalPrice',
      width: 180,
      render: (_, record) => (
        <div style={{ color: record.isModifiedPrice ? 'red' : '' }}>
          {(() => {
            const estimateComp = [];
            let estimateTotalPrice = record.userEstimateTotalPrice
              ? Math.round(record.userEstimateTotalPrice).toLocaleString()
              : 0;
            let estimateUnitPrice = record.userEstimateUnitPrice
              ? Math.round(record.userEstimateUnitPrice).toLocaleString()
              : 0;
            {
              estimateComp.push(
                <>
                  <div style={{ textAlign: 'center' }}>
                    <label style={{ fontWeight: 'bold' }}>{estimateTotalPrice + '円'}</label>
                  </div>
                  <div style={{ textAlign: 'center' }}>
                    <label style={{ fontWeight: 'bold' }}>{'（' + estimateUnitPrice + '円/' + '1個）'}</label>
                  </div>
                </>
              );
            }
            return estimateComp;
          })()}
        </div>
      ),
    },
    {
      title: '自動見積金額',
      dataIndex: 'estimateTotalPrice',
      width: 180,
      render: (_, record) => (
        <>
          {(() => {
            const estimateComp = [];
            let estimateTotalPrice = record.estimateTotalPrice
              ? Math.round(record.estimateTotalPrice).toLocaleString()
              : 0;
            let estimateUnitPrice = record.estimateUnitPrice
              ? Math.round(record.estimateUnitPrice).toLocaleString()
              : 0;
            {
              estimateComp.push(
                <>
                  <div style={{ textAlign: 'center' }}>
                    <label style={{ fontWeight: 'bold' }}>{estimateTotalPrice + '円'}</label>
                  </div>
                  <div style={{ textAlign: 'center' }}>
                    <label style={{ fontWeight: 'bold' }}>{'（' + estimateUnitPrice + '円/' + '1個）'}</label>
                  </div>
                </>
              );
            }
            return estimateComp;
          })()}
        </>
      ),
    },
    {
      title: 'ステータス',
      dataIndex: 'estimateResult',
      width: 150,
      render: (_, record) => (
        <div style={{ textAlign: 'center' }}>
          <label>{getEstimateStatusName(record.estimateResult)}</label>
        </div>
      ),
    },
    {
      title: '金額修正',
      dataIndex: 'estimateAmt',
      width: 250,
      //fixed: 'right',
      render: (_, record, row) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div style={{ textAlign: 'right' }}>
            <Input
              value={record.inputValue}
              disabled={
                record.estimateResult === EstimateResultClass.OrderConfirmed ||
                record.estimateResult === EstimateResultClass.OrderComplete
              }
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d*$/.test(value)) {
                  // 価格交渉後の金額変更
                  handleValueChange(value, record.id);
                }
              }}
              style={{ width: 130 }}
            />
          </div>
          <div style={{ textAlign: 'left' }}>
            <Button
              className="estimate-amt-change-btn"
              disabled={
                record.estimateResult === EstimateResultClass.OrderConfirmed ||
                record.estimateResult === EstimateResultClass.OrderComplete
              }
              onClick={() => handleBtnAction(AdminBtnClass.AdjustAmt, record)}
            >
              {'価格変更'}
            </Button>
          </div>
        </div>
      ),
    },
    //「注文確定
    {
      title: '注文確定',
      dataIndex: 'estimateAmt',
      width: 250,
      //fixed: 'right',
      render: (_, record, row) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div style={{ textAlign: 'right' }}>
            <DatePicker
              locale="ja"
              placeholder="回答納期日を選択してください"
              style={{ marginLeft: 0, marginLeft: 10 }}
              disabled={record.estimateResult !== EstimateResultClass.OrderComplete}
              value={
                record?.orderConditions?.deliveryDate
                  ? dayjs(record?.orderConditions?.deliveryDate, 'YYYY-MM-DD')
                  : null
              }
              onChange={(_, dateString) => {
                if (dateString) {
                  const formattedDate = dayjs(dateString).format('YYYY-MM-DD');
                  const updatedRecord = {
                    ...record,
                    orderConditions: {
                      ...record.orderConditions,
                      deliveryDate: formattedDate,
                    },
                  };

                  setEstimateHistoryList((prevList) => {
                    const updatedList = prevList.map((item) => (item.id === updatedRecord.id ? updatedRecord : item));
                    return updatedList;
                  });
                }
              }}
              disabledDate={(current) => {
                return current && current < dayjs(record?.orderConditions?.deliveryTimeCls).startOf('day');
              }}
            />
          </div>
          <div style={{ textAlign: 'left' }}>
            <Button
              className="order-complete-btn"
              disabled={record.estimateResult !== EstimateResultClass.OrderComplete}
              onClick={() => handleBtnAction(AdminBtnClass.ConfirmOrder, record)}
            >
              {'注文確定'}
            </Button>
          </div>
        </div>
      ),
    },
  ];

  const commonConfirmContent = (
    <p style={{ fontSize: '13px', marginBottom: '20px' }}>
      {btnType === AdminBtnClass.AdjustAmt ? 'ユーザー向け画面でも価格が変更されて表示されます。' : ''}
      {btnType === AdminBtnClass.ConfirmOrder ? 'チェックを付けたデータの注文を確定します。' : ''}
      {btnType === AdminBtnClass.Estimate ? '自動見積対象外のファイルについて見積処理を実行します。' : ''}
      <br />
      本当によろしいですか？
    </p>
  );

  return (
    <Spin spinning={spinLoading} tip="見積金額算出中..." size="large">
      <div style={{ padding: 0 }}>
        <Layout className="history-layout">
          <Layout className="history-layout-tb">
            <div className="home-tilte-div">
              <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', width: '60%' }}>
                <label style={{ fontSize: '18px', fontWeight: '500', color: '#333333', marginLeft: 7 }}>
                  自動見積履歴
                </label>
              </div>
            </div>
            <Row>
              <Col span={8}>
                <div className="admin-tilte-btn-div">
                  <Button
                    key="login"
                    className="admin-estimate-btn"
                    onClick={(e) => handleBtnAction(AdminBtnClass.Estimate)}
                    title="見積条件により自動見積対象外のものを実施します"
                  >
                    自動見積外を実行
                  </Button>
                </div>
              </Col>
              <Col span={16}>
                <div className="admin-search-div">
                  <label style={{ fontSize: '13px', fontWeight: '600', color: '#333333' }}>会社名</label>
                  <Input
                    id="companyName"
                    name="companyName"
                    value={companyNmSearch}
                    style={{ marginLeft: 0, width: 230, marginLeft: 10 }}
                    allowClear
                    onChange={(e) => {
                      setcompanyNmSearch(e.target.value);
                    }}
                  />
                  <label style={{ fontSize: '13px', fontWeight: '600', color: '#333333', marginLeft: 10 }}>
                    アップロードファイル名
                  </label>
                  <Input
                    id="fileNm"
                    name="fileNm"
                    value={fileNm}
                    style={{ marginLeft: 0, width: 240, marginLeft: 10 }}
                    allowClear
                    onChange={(e) => {
                      setFileNm(e.target.value);
                    }}
                  />
                  <label style={{ fontSize: '13px', fontWeight: '600', color: '#333333', marginLeft: 10 }}>
                    見積日
                  </label>
                  <DatePicker
                    locale="ja"
                    format={'YYYY年MM月DD日'}
                    placeholder="選択してください"
                    style={{ marginLeft: 0, marginLeft: 10 }}
                    value={estimateDate ? dayjs(estimateDate, 'YYYY-MM-DD') : ''}
                    onChange={(_, dateString) => {
                      setEstimateDate(dateString);
                    }}
                  />
                  <Button
                    key="login"
                    className="history-search-btn"
                    style={{ backgroundColor: '#017bc5', color: 'white', marginLeft: 10 }}
                    onClick={(e) => handleSearch()}
                  >
                    検索
                  </Button>
                  <Button
                    key="login"
                    className="history-search-btn"
                    style={{
                      marginLeft: 10,
                      width: 95,
                    }}
                    onClick={(e) => handleClearSearch()}
                  >
                    検索をクリア
                  </Button>
                </div>
              </Col>
            </Row>

            <div className="responsive-table">
              <Table
                columns={columns}
                dataSource={estimateHistoryList}
                className="admin-history-tb"
                scroll={{
                  x: estimateHistoryList.length > 0 ? 'max-content' : 'auto',
                  y: estimateHistoryList.length > 0 ? tableHeight : 'auto',
                }}
                loading={loading}
                pagination={{
                  position: ['topleft'],
                  total: totalCount ? totalCount : 1,
                  showTotal: (total, range) =>
                    totalCount ? `全${total}件中${range[0]}件〜${range[1]}件を表示` : `全${0}件中${0}件〜${0}件を表示`,
                  defaultCurrent: 1,
                  pageSize: pageSize,
                  pageSizeOptions: pageSizes,
                  showSizeChanger: true,
                  onShowSizeChange: handlePageSizeChange,
                  current: cusCurrentPage,
                  onChange: (page) => {
                    setCusCurrentPage(page);
                  },
                }}
                onChange={handleTableChange}
              />
            </div>
          </Layout>
        </Layout>
      </div>
      {/* 確認 */}
      {confirm
        ? commonModal(
            confirm,
            deleteModalTitle,
            commonFooter(confirmOk, confirmCancel),
            null,
            400,
            confirmOk,
            confirmCancel,
            commonConfirmContent,
            null,
            null,
            false
          )
        : ''}
    </Spin>
  );
});

export default WithRouter(AdminManagement);
