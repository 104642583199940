/**
 * クラス名：ホーム画面
 * 説明：ログイン後ホーム画面である。
 * 作成者：ナンス
 * 作成日：2024/11/19
 * バージョン：1.0
 */
import { Layout, Image, Space, Drawer, Input, DatePicker, Button } from 'antd';
import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  CalculatorOutlined,
  CloseOutlined,
  FileDoneOutlined,
  LogoutOutlined,
  SettingOutlined,
  UserOutlined,
} from '@ant-design/icons';

import logo from '../assets/images/logo.png';
import menu from '../assets/images/menu.png';
import EstimateHistory from '../views/EstimateHistory';
import ProcessCompleteInfo from '../views/common/ProcessCompleteInfo';
import UploadCapacityLimitation from '../views/common/UploadCapacityLimitation';
import Home from '../views/Home';
import { logOut } from '../views/common/CommonAPI';
import { IQxRealGoku_LoginUser, totalUploadFileSizeLimitMB } from '../views/common/Constant';
import AdminManagement from '../views/AdminManagement';
import { UserRole } from '../views/common/enums';

const { Header, Content } = Layout;

const MainLayout = React.forwardRef((props, ref) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [actionType, setActionType] = useState('order');
  const [open, setOpen] = useState(false);
  const [loginUser, setLoginUser] = useState();

  useEffect(() => {
    const loginUserInfo = JSON.parse(decodeURI(localStorage?.getItem(IQxRealGoku_LoginUser)));
    setLoginUser(loginUserInfo);
  }, []);

  const onClose = () => {
    setOpen(false);
  };

  const updateActionType = (type) => {
    setActionType(type);
  };

  // ログアウトボタンの押下
  const logoutEvent = async (e) => {
    e.preventDefault();
    const result = await logOut();
    if (result) {
      navigate('/login');
    }
  };

  return (
    <Layout className="main-layout">
      {/** ヘッダ部 */}
      <Header>
        <div className="header-menu-div">
          {loginUser?.userType === UserRole.Administrator ? (
            // ||
            // (loginUser?.userType === UserRole.HE && loginUser?.usedFileSize >= totalUploadFileSizeLimitMB)
            ''
          ) : (
            <Image
              preview={false}
              width={24}
              src={menu}
              className="menu-icon"
              onClick={(e) => {
                setOpen(true);
              }}
            />
          )}
          <div
            className="header-menu-div"
            style={{
              marginLeft:
                loginUser?.userType === UserRole.Administrator ||
                (loginUser?.userType === UserRole.HE && loginUser?.usedFileSize >= totalUploadFileSizeLimitMB)
                  ? '0px'
                  : '12px',
            }}
          >
            <Image preview={false} width={50} src={logo} />
            <span className="header-logo-label">
              RealGoku{loginUser?.userType === UserRole.Administrator ? '（管理者向け画面）' : ''}
            </span>
          </div>
        </div>
        <div className="header-userNm-div">
          <UserOutlined className="header-icon" />
          <span className="header-icon-label">{loginUser ? loginUser?.userName : ''}</span>
          <div className="logout-div header-userNm-div" onClick={(e) => logoutEvent(e)} style={{ marginLeft: 13 }}>
            <LogoutOutlined className="header-icon" />
            <span className="logout-div-label header-icon-label">ログアウト</span>
          </div>
        </div>
      </Header>
      {/** マイン画面 */}
      {loginUser?.userType === UserRole.HE && loginUser?.usedFileSize >= totalUploadFileSizeLimitMB ? (
        <UploadCapacityLimitation />
      ) : (loginUser?.userType === UserRole.OU || loginUser?.userType === UserRole.HE) &&
        location?.pathname?.includes('/home') ? (
        <Layout className="main-layout">
          <Content>
            {/** 左メニューDrawer */}
            <Drawer title="Basic Drawer" onClose={onClose} open={open} placement="left">
              <div className="drawer-close-div">
                <CloseOutlined
                  className="drawer-close-icon"
                  onClick={(e) => {
                    setOpen(false);
                  }}
                />
              </div>
              <div
                className="main-drawer-div"
                onClick={(e) => {
                  navigate('/home');
                  setOpen(false);
                }}
              >
                <Space size="middle">
                  <CalculatorOutlined className="main-drawer-icon" />
                  TOP＆見積・注文
                </Space>
              </div>
              <div
                className="main-drawer-div"
                onClick={(e) => {
                  navigate('/home/:estimate-history', { state: { userName: loginUser ? loginUser?.userName : '' } });
                  setOpen(false);
                }}
              >
                <Space size="middle">
                  <FileDoneOutlined className="main-drawer-icon" />
                  見積履歴
                </Space>
              </div>
              <div
                className="main-drawer-div"
                onClick={(e) => {
                  setOpen(false);
                }}
              >
                <Space size="middle">
                  <UserOutlined className="main-drawer-icon" />
                  会員登録情報編集
                </Space>
              </div>
              <div
                className="main-drawer-div"
                onClick={(e) => {
                  navigate('/home/:toppage');
                  setOpen(false);
                }}
              >
                <Space size="middle">
                  <SettingOutlined className="main-drawer-icon" />
                  ユーザー設定
                </Space>
              </div>
            </Drawer>
            {/** TOP＆見積・注文画面/ 見積履歴画面 */}
            {location?.pathname == '/home' ? <Home updateActionType={updateActionType} loginUser={loginUser} /> : ''}
            {location?.pathname == '/home/:estimate-history' ? (
              <EstimateHistory updateActionType={updateActionType} loginUser={loginUser} />
            ) : (
              ''
            )}
            {location?.pathname == '/home/:process-complete-msg' ? <ProcessCompleteInfo actionType={actionType} /> : ''}
            {location?.pathname == '/home/:upload-capacity-limitation' ? <UploadCapacityLimitation /> : ''}
          </Content>
        </Layout>
      ) : loginUser?.userType === UserRole.Administrator ? (
        <AdminManagement />
      ) : (
        ''
      )}
    </Layout>
  );
});

export default MainLayout;
