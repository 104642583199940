/**
 * クラス名：処理完了メッセージ表示画面
 * 説明：処理完了メッセージを表示。
 * 作成者：ナンス
 * 作成日：2024/11/18
 * バージョン：1.0
 */
import { Button } from 'antd';
import React, { forwardRef, useEffect, useState } from 'react';

import { WithRouter } from '../../components/WithRouter';
import '../../assets/styles/common.css';

const ProcessCompleteInfo = forwardRef((props, ref) => {
  useEffect(() => {}, []);

  return (
    <div
      style={{
        height: '80vh',
        overflowY: 'auto',
        alignContent: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        display: 'grid',
      }}
    >
      <div
        style={{
          textAlign: 'center',
          justifyContent: 'center',
          display: 'grid',
          border: '3px solid #000000b3',
          borderRadius: 3,
          padding: 40,
        }}
      >
        {props?.actionType === 'order' ? (
          <p style={{ fontSize: '22px', fontWeight: '600', color: '#333333' }}>ご注文ありがとうございます。</p>
        ) : (
          <p style={{ fontSize: '22px', fontWeight: '600', color: '#333333' }}>ありがとうございます。</p>
        )}

        <p style={{ fontSize: '13px', fontWeight: '600', color: '#333333', marginTop: 5 }}>
          今後の流れは担当者から別途ご案内します。
        </p>
        <div
          style={{
            display: 'flex',
            marginTop: '30px',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          <Button
            key="top"
            className="go-to-top-btn"
            onClick={(e) => {
              props?.navigate('/home');
            }}
          >
            TOPへ戻る
          </Button>
          <Button
            key="history"
            className="go-to-top-btn"
            style={{ marginLeft: 10 }}
            onClick={(e) => {
              props?.navigate('/home/:estimate-history');
            }}
          >
            見積履歴へ戻る
          </Button>
        </div>
      </div>
    </div>
  );
});

export default WithRouter(ProcessCompleteInfo);
