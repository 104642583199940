/**
 * クラス名：システム全体の共通ファイル
 * 説明：システム全体に共通使っているファイルである。
 * 作成者：ナンス
 * 作成日：2024/11/25
 * バージョン：1.0
 */
import { toDecimal } from '../common/Common';
import {
  calDandoriAmt,
  calDandoriTime,
  calPunchCntByCombi,
  calSagyoAmt,
  calSagyoAmtJoint,
  calSagyoAmtPunch,
  calSagyoAmtTwoStepHole,
  calSagyoTime,
  getGasCoef,
  getHoseCoeff,
  getJointTm,
  getProductionQuantityCoeff,
  getSagyoIn,
  getSagyoOut,
  getSagyoPiercingTm,
  getSagyoPunches,
  getSagyoPunchTm,
  getSagyoSpeed,
  getSetupCoeff,
  getSizeCoeff,
  getSpeedCoef,
  getSpeedLenTime,
} from './IQ3KouteiCalculate_Common';
import {
  BendingTypes,
  BlankTypes,
  ClientType,
  DataType,
  FRBend,
  IQxRealGoku_AccessToken,
  ServiceClass,
  Types,
  WorkType,
  WorkTypeGroup,
} from '../common/Constant';

// 手動バリ工程の計算
export const calMenualBari = (
  curAddition,
  processInput,
  processSelect,
  curDeviceMst,
  calcParameters,
  curIQ3DataDetail,
  orderQuantity
) => {
  let menualBariAddition = curAddition;
  if (Object.keys(menualBariAddition).length > 0) {
    let totals = [];
    let processMst = [];
    let deviceMst = [];
    let editData = [];

    let parameters = calcParameters;
    processMst = parameters?.process
      ? parameters.process?.filter((item) => item.workType === WorkType.SmDeburringManual)[0]
      : [];
    let patternInfo = processSelect;
    if (patternInfo == undefined) return;
    if (patternInfo?.length > 0 && patternInfo[0]?.isUsedForCalc == false) return;
    deviceMst = curDeviceMst[0]; //テスト用ID;
    let deburringManualAdditions = menualBariAddition;
    // 手動バリ工程入力情報取得
    let manualDeburProcessInput = processInput[0];

    let deburringAutoAddition = {};
    let detailItems = [];
    let editDetailItems = [];
    let processName = '手動バリ金額/時間';

    // 手動バリ詳細情報
    detailItems.push({
      id: 1,
      processName: processName,
      dandoriAmt: 0,
      dandoriTime: 0,
      sagyoAmt: 0,
      sagyoTime: 0,
      sagyoAmtOut: 0,
      sagyoTimeOut: 0,
      sagyoAmtIn: 0,
      sagyoTimeIn: 0,
      speedOut: 0,
      lenghtOut: Number(manualDeburProcessInput?.details?.[0]?.length),
      processCountOut: manualDeburProcessInput?.details?.[0]?.count,
      speedIn: 0,
      lenghtIn: Number(manualDeburProcessInput?.details?.[1]?.length),
      processCountIn: manualDeburProcessInput?.details?.[1]?.count,
      formula: '',
    });

    deburringAutoAddition = {
      no: processMst?.id,
      processId: processMst.id,
      dataType: deburringManualAdditions?.dataType ? deburringManualAdditions?.dataType : DataType.Data,
      dataItems: detailItems,
      editItems: deburringManualAdditions?.editItems ? deburringManualAdditions?.editItems : editDetailItems,
      totalDandori: {
        dataItemsAmt: 0,
        dataItemsTime: 0,
        editItemsAmt: 0,
        editItemsTime: 0,
      },
      totalSagyo: {
        dataItemsAmt: 0,
        dataItemsTime: 0,
        editItemsAmt: 0,
        editItemsTime: 0,
      },
      totalDataPrice: 0,
      totalDataTime: 0,
      totalEditPrice: 0,
      totalEditTime: 0,
      editDataFlg: false,
    };
    deburringManualAdditions = deburringAutoAddition;

    if (deburringManualAdditions?.dataItems) {
      let orgData = JSON.parse(JSON.stringify(deburringManualAdditions?.dataItems));
      if (deviceMst != undefined && orgData?.length > 0) {
        // 重量
        let weight = curIQ3DataDetail?.weight;
        // 面積
        let area = Number(curIQ3DataDetail?.totalSurfaceArea);
        //総重量が0、子部品数が0の場合、段取と加工を計算しない。
        if (weight === 0 && area === 0) {
          let dandoriTimeSec = 0;
          let dandoriTimeHms = dandoriTimeSec;
          orgData[0].dandoriTime = dandoriTimeHms;
          deburringManualAdditions.dataItems[0].dandoriTime = 0;

          let dandoriAmt = 0;
          orgData[0].dandoriAmt = 0;
          deburringManualAdditions.dataItems[0].dandoriAmt = 0;

          orgData[0].sagyoTimeOut = 0;
          deburringManualAdditions.dataItems[0].sagyoTimeOut = 0;

          let cSagyoAmtOut = 0;
          orgData[0].sagyoAmtOut = 0;
          deburringManualAdditions.dataItems[0].sagyoAmtOut = 0;

          let cSagyoTimeInPerSec = 0;
          let cSagyoTimeInSec = cSagyoTimeInPerSec;
          orgData[0].sagyoTimeIn = cSagyoTimeInSec ? cSagyoTimeInSec : 0;
          deburringManualAdditions.dataItems[0].sagyoTimeIn = cSagyoTimeInSec ? cSagyoTimeInSec : 0;

          // 作業金額
          //　作業時間＊時間チャージ
          let cSagyoAmtIn = 0;
          orgData[0].sagyoAmtIn = 0;
          deburringManualAdditions.dataItems[0].sagyoAmtIn = 0;

          // 作業時間（外周切断時間 + 内周切断時間）
          orgData[0].sagyoTime = 0;

          // 作業金額
          //　作業時間＊時間チャージ
          orgData[0].sagyoAmt = 0;
          deburringManualAdditions.totalDataPrice = 0;
          deburringManualAdditions.totalDandori.dataItemsAmt = 0;
          deburringManualAdditions.totalSagyo.dataItemsAmt = 0;
          deburringManualAdditions.totalDataTime = 0;
          deburringManualAdditions.totalDandori.dataItemsTime = 0;
          deburringManualAdditions.totalSagyo.dataItemsTime = 0;
        } else {
          let quantity = orderQuantity;
          let partCnt = curIQ3DataDetail?.partCount;
          // IQX_WEBEST-278 工程積算タブの表示に生産個数係数が含まれていなかったので、含むようにしてほしい
          let productionQuantityCoeff = getProductionQuantityCoeff(deviceMst, quantity, partCnt);
          // 外周切断長
          let outCutLen = Number(manualDeburProcessInput?.details?.[0]?.length);
          let outCutProcessCount = Number(manualDeburProcessInput?.details?.[0]?.count);
          // 内周切断長
          let inCutLen = Number(manualDeburProcessInput?.details?.[1]?.length);
          let inCutProcessCount = Number(manualDeburProcessInput?.details?.[1]?.count);
          if (outCutLen === 0 && inCutLen === 0) {
          } else {
            // 板厚
            let thickness = parameters?.materialIq3?.filter((item) => item.id == curIQ3DataDetail?.thickness)?.[0]?.info
              ?.thick;
            // サイズ係数
            let sizeCoef = getSizeCoeff(deviceMst, weight, area);

            /** 段取金額/時間 */
            // 段取時間設定
            //　基本段取時間＊サイズ係数
            let dandoriTimeSec = calDandoriTime(WorkType.SmDeburringManual, deviceMst, 0, weight, area);
            let dandoriTimeHms = dandoriTimeSec;
            orgData[0].dandoriTime = dandoriTimeHms === undefined ? 0 : dandoriTimeHms;
            deburringManualAdditions.dataItems[0].dandoriTime = dandoriTimeHms ? dandoriTimeHms : 0;

            // 段取金額設定
            //段階時間＊時間チャージ
            let dandoriAmt = calDandoriAmt(dandoriTimeSec, deviceMst);
            orgData[0].dandoriAmt = dandoriAmt === undefined ? 0 : dandoriAmt;
            deburringManualAdditions.dataItems[0].dandoriAmt = dandoriAmt ? dandoriAmt : 0;

            /** 作業金額/時間 */
            // 作業時間{(外周切断長	/	加工速度	＊	加工速度係数)	+	(加工補正時間	*	加工補正係数)｝	*	サイズ係数	*	回数
            // 加工速度
            let cSagyoSpeed = getSpeedLenTime(deviceMst, outCutLen, thickness);
            orgData[0].speedOut = cSagyoSpeed === undefined ? 0 : cSagyoSpeed;
            deburringManualAdditions.dataItems[0].speedOut = orgData[0].speedOut;
            // 加工速度係数
            let workSpeedCoeffLengthThMaster = deviceMst?.details?.workSpeedCoeffLengthThMaster?.filter(
              (item) => (item.value == thickness || item.value > thickness) && !item.isOutOfRange
            );
            let cSagyoSpeedOutCoef = deviceMst?.details?.workSpeedCoeffExternalWorkItems?.filter(
              (item) => item.no == workSpeedCoeffLengthThMaster?.[0]?.no && !item.isOutOfRange
            )?.[0]?.value;

            // 加工補正時間
            let modifyTimeQtyThMaster = deviceMst?.details?.modifyTimeQtyThMaster?.filter(
              (item) => (item.value == area || item.value > area) && !item.isOutOfRange
            )?.[0];
            let hoseTime = deviceMst?.details?.modifyTimeItems?.filter(
              (item) => item.no == modifyTimeQtyThMaster?.no && !item.isOutOfRange
            )?.[0]?.value;
            // 加工補正係数
            let hoseCoeff = getHoseCoeff(deviceMst, weight, area);
            let cSagyoTimeOutSec = 0;
            let cSagyoAmtOut = 0;
            // 外周作業時間{(外周切断長	/	加工速度	＊	加工速度係数)	+	(加工補正時間	*	加工補正係数)｝	*	サイズ係数	*	回数
            if (outCutLen !== 0 && outCutProcessCount !== 0) {
              let xOut = Number.isFinite(outCutLen / cSagyoSpeed) ? (outCutLen / cSagyoSpeed) * cSagyoSpeedOutCoef : 0;
              let yOut = hoseTime * hoseCoeff;
              let cSagyoTimeOutPerSec = (xOut + yOut) * sizeCoef * outCutProcessCount * 60;
              cSagyoTimeOutSec = cSagyoTimeOutPerSec * productionQuantityCoeff; // IQX_WEBEST-278 加工時間＊生産個数係数

              // 作業金額
              //　作業時間＊時間チャージ
              cSagyoAmtOut = calSagyoAmt(cSagyoTimeOutSec, deviceMst);
            }
            orgData[0].sagyoTimeOut = cSagyoTimeOutSec ? cSagyoTimeOutSec : 0;
            deburringManualAdditions.dataItems[0].sagyoTimeOut = cSagyoTimeOutSec ? cSagyoTimeOutSec : 0;

            orgData[0].sagyoAmtOut = cSagyoAmtOut ? cSagyoAmtOut : 0;
            deburringManualAdditions.dataItems[0].sagyoAmtOut = cSagyoAmtOut ? cSagyoAmtOut : 0;

            let cSagyoTimeInSec = 0;
            let cSagyoAmtIn = 0;
            // 作業時間{(内周切断長	/	加工速度	＊	加工速度係数)	+	(加工補正時間	*	加工補正係数)｝	*	サイズ係数	*	回数
            // 加工速度
            // 指定された内径最小矩形周長より手動バリ対象かチェック
            let rectangleDia = deviceMst?.details?.rectangleDia ? deviceMst?.details?.rectangleDia : 0;
            let cSagyoSpeedIn = 0;
            // 指定された内径最小矩形周長以上手動バリ対応とする
            if (rectangleDia <= inCutLen) {
              cSagyoSpeedIn = getSpeedLenTime(deviceMst, inCutLen, thickness);
              orgData[0].speedIn = cSagyoSpeedIn ? cSagyoSpeedIn : 0;
              deburringManualAdditions.dataItems[0].speedIn = orgData[0].speedIn;
              // 加工速度係数
              let workSpeedCoeffLengthThMasterIn = deviceMst?.details?.workSpeedCoeffLengthThMaster?.filter(
                (item) => (item.value == thickness || item.value > thickness) && !item.isOutOfRange
              );
              let cSagyoSpeedInCoef = deviceMst?.details?.workSpeedCoeffInternalWorkItems?.filter(
                (item) => item.no == workSpeedCoeffLengthThMasterIn?.[0]?.no && !item.isOutOfRange
              )?.[0]?.value;

              if (inCutLen !== 0 && inCutProcessCount !== 0) {
                // 内周作業時間{(外周切断長	/	加工速度	＊	加工速度係数)	+	(加工補正時間	*	加工補正係数)｝	*	サイズ係数	*	回数
                let xIn = Number.isFinite(inCutLen / cSagyoSpeedIn)
                  ? (inCutLen / cSagyoSpeedIn) * cSagyoSpeedInCoef
                  : 0;
                let yIn = hoseTime * hoseCoeff;
                let cSagyoTimeInPerSec = (xIn + yIn) * sizeCoef * inCutProcessCount * 60;
                cSagyoTimeInSec = cSagyoTimeInPerSec * productionQuantityCoeff; // IQX_WEBEST-278 加工時間＊生産個数係数

                // 作業金額
                //　作業時間＊時間チャージ
                cSagyoAmtIn = calSagyoAmt(cSagyoTimeInSec, deviceMst);
              }

              orgData[0].sagyoTimeIn = cSagyoTimeInSec ? cSagyoTimeInSec : 0;
              deburringManualAdditions.dataItems[0].sagyoTimeIn = cSagyoTimeInSec ? cSagyoTimeInSec : 0;
              orgData[0].sagyoAmtIn = cSagyoAmtIn ? cSagyoAmtIn : 0;
              deburringManualAdditions.dataItems[0].sagyoAmtIn = cSagyoAmtIn ? cSagyoAmtIn : 0;
            } else {
              // 指定された内径最小矩形周長以下の場合手動バリ対応外とする
              cSagyoSpeedIn = 0;
              orgData[0].speedIn = 0;
              cSagyoTimeInSec = 0;
              cSagyoAmtIn = 0;
              orgData[0].sagyoTimeIn = 0;
              deburringManualAdditions.dataItems[0].sagyoTimeIn = 0;
              orgData[0].sagyoAmtIn = 0;
              deburringManualAdditions.dataItems[0].sagyoAmtIn = 0;
            }

            // 作業時間（外周切断時間 + 内周切断時間）
            let cSagyoTimeSec = orgData[0].sagyoTimeIn + orgData[0].sagyoTimeOut;
            let cSagyoTime = cSagyoTimeSec;
            orgData[0].sagyoTime = cSagyoTime === undefined ? 0 : cSagyoTime;

            // 作業金額
            //　作業時間＊時間チャージ
            let cSagyoAmt = cSagyoAmtIn + cSagyoAmtOut;
            orgData[0].sagyoAmt = cSagyoAmt === undefined ? 0 : cSagyoAmt;

            let totalSum = (dandoriAmt ? Math.round(dandoriAmt) : 0) + (cSagyoAmt ? Math.round(cSagyoAmt) : 0); // 画面表示用（四捨五入した金額を足す）
            deburringManualAdditions.totalDataPrice = totalSum ? totalSum : 0;
            deburringManualAdditions.totalDandori.dataItemsAmt = dandoriAmt ? Math.round(dandoriAmt) : 0;
            deburringManualAdditions.totalSagyo.dataItemsAmt = cSagyoAmt ? Math.round(cSagyoAmt) : 0;
            let totalTimeSec = dandoriTimeSec + cSagyoTimeSec;
            deburringManualAdditions.totalDataTime = totalTimeSec ? totalTimeSec : 0;
            deburringManualAdditions.totalDandori.dataItemsTime = dandoriTimeSec ? dandoriTimeSec : 0;
            deburringManualAdditions.totalSagyo.dataItemsTime = cSagyoTimeSec ? cSagyoTimeSec : 0;
          }
        }
        deburringManualAdditions.dataItems = orgData;
        menualBariAddition = deburringManualAdditions;
      }
    }
  }
  return menualBariAddition;
};
