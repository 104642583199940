/**
 * クラス名：処理完了メッセージ表示画面
 * 説明：処理完了メッセージを表示。
 * 作成者：ナンス
 * 作成日：2024/11/18
 * バージョン：1.0
 */
import React, { forwardRef, useEffect } from 'react';

import { WithRouter } from '../../components/WithRouter';
import '../../assets/styles/common.css';

const UploadCapacityLimitation = forwardRef((props, ref) => {

  useEffect(() => {}, []);

  return (
    <div
      style={{
        height: '80vh',
        overflowY: 'auto',
        alignContent: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        display: 'grid',
      }}
    >
      <div
        style={{
          textAlign: 'left',
          justifyContent: 'center',
          display: 'grid',
          border: '3px solid #000000b3',
          borderRadius: 3,
          padding: 40,
        }}
      >
        <div>
          <p style={{ fontSize: '22px', fontWeight: '600', color: '#333333' }}>
            1か月のご利用従量に達しました。<br />
            次回ご利用可能になるのは翌月1日からです。
          </p>
        </div>
        <div>
          <p style={{ fontSize: '22px', fontWeight: '600', color: '#333333' }}>
            パッケージソフトはご利用無制限！
            <br />
            ご購入検討のお客様は以下電話番号までお問い合わせください！
          </p>
          <p style={{ fontSize: '22px', fontWeight: '600', color: '#333333' }}>
            000-0000-0000
          </p>
        </div>
      </div>
    </div>
  );
});

export default WithRouter(UploadCapacityLimitation);

