/**
 * クラス名：システム全体の共通ファイル
 * 説明：システム全体に共通使っているファイルである。
 * 作成者：ナンス
 * 作成日：2024/11/25
 * バージョン：1.0
 */
import {
  calDandoriAmt,
  calDandoriTime,
  calSagyoAmt,
  calSagyoTime,
  getProductionQuantityCoeff,
  getSetupCoeff,
  getSizeCoeff,
} from './IQ3KouteiCalculate_Common';
import { DataType, FRBend, ServiceClass, WorkType } from '../common/Constant';

export const calBending = (
  curAddition,
  processInput,
  processSelect,
  curDeviceMst,
  calcParameters,
  curIQ3DataDetail,
  orderQuantity
) => {
  let bendingAddition = curAddition;
  if (Object.keys(bendingAddition).length > 0) {
    let processMst = [];
    let deviceMst = []; //テスト用ID;
    let parameters = calcParameters;
    // 数量
    let quantity = orderQuantity;
    // 数量
    let partCount = curIQ3DataDetail?.partCount;
    if (parameters?.process?.length > 0) {
      processMst = parameters?.process
        ? parameters?.process?.filter(
            (item) => item?.class === ServiceClass.SheetMetal && item.workType === WorkType.SmBending
          )[0]
        : [];
    }
    let patternInfo = processSelect;
    if (patternInfo == undefined) return;
    if (patternInfo?.length > 0 && patternInfo[0]?.isUsedForCalc == false) return;
    deviceMst = curDeviceMst[0]; //テスト用ID;
    if (deviceMst == undefined) return;
    let bendingAdditions = bendingAddition;
    // ベンディング工程入力情報取得
    let bendingProcessInput = processInput[0];

    let detailItems = [];
    let editDetailItems = [];
    //ベンディング種類情報設定
    for (let j = 0; j < bendingProcessInput?.details?.[0]?.bendingList?.length; j++) {
      let bendingListProcessInput = bendingProcessInput?.details?.[0]?.bendingList[j];
      let processTypeNm = processMst?.details?.processDetailTypes?.filter(
        (item) => item.id == bendingListProcessInput.types && item.isUsed
      )?.[0];
      detailItems.push({
        id: j + 1,
        processName: 'ベンディング処理(No' + (j + 1) + ')',
        processTypeId: processTypeNm?.id,
        processType: processTypeNm?.name,
        dandoriAmt: 0,
        dandoriTime: 0,
        sagyoAmt: 0,
        sagyoTime: 0,
        partCnt: processTypeNm?.name?.includes(FRBend)
          ? Number(bendingListProcessInput.bendCount)
          : Number(bendingListProcessInput.lines), //曲げ回数/FR曲げ回数　（IQX_WEBEST-196）
        workTimeSize: 0, //1つ当たりの加工時間
        bendinglen: Number(bendingListProcessInput.bendLength), //曲げ長
        bendCount: Number(bendingListProcessInput.bendCount), //FR曲げ回数
        programAmt: 0,
        programTime: 0,
        mageDandoriAmt: 0,
        mageDandoriTime: 0,
        formula: '',
      });
    }
    editDetailItems = [];
    let newBendingAddition = {
      no: processMst?.id,
      processId: processMst?.id,
      dataType: bendingAdditions?.dataType ? bendingAdditions.dataType : DataType.Data,
      dataItems: detailItems,
      editItems: bendingAdditions?.editItems ? bendingAdditions.editItems : editDetailItems,
      totalDandori: {
        dataItemsAmt: 0,
        dataItemsTime: 0,
        editItemsAmt: 0,
        editItemsTime: 0,
      },
      totalSagyo: {
        dataItemsAmt: 0,
        dataItemsTime: 0,
        editItemsAmt: 0,
        editItemsTime: 0,
      },
      totalDataPrice: 0,
      totalDataTime: 0,
      totalEditPrice: 0,
      totalEditTime: 0,
      editDataFlg: false,
    };
    bendingAdditions = newBendingAddition;
    if (bendingAdditions?.dataItems) {
      let orgData = JSON.parse(JSON.stringify(bendingAdditions?.dataItems));
      if (deviceMst != undefined) {
        let totalDandori = 0;
        let totalSagyo = 0;
        let totalSagyoTm = 0;
        let totalDandoriTm = 0;
        let dataItemsByProcessInput = [];

        // 板厚
        let thickness = parameters?.materialIq3?.filter((item) => item.id == curIQ3DataDetail?.thickness)?.[0]?.info
          ?.thick;
        // 重量
        let weight = curIQ3DataDetail?.weight;
        // 面積
        let area = Number(curIQ3DataDetail?.totalSurfaceArea);
        // IQX_WEBEST-278 工程積算タブの表示に生産個数係数が含まれていなかったので、含むようにしてほしい
        let productionQuantityCoeff = getProductionQuantityCoeff(deviceMst, quantity, partCount);
        //総重量が0、子部品数が0の場合、段取と加工を計算しない。
        if (!(weight === 0 && area === 0)) {
          // サイズ係数
          let sizeCoef = getSizeCoeff(deviceMst, weight, area);
          //　曲げ係数
          let bendingCoef = getBendingCoeff(deviceMst, weight, area);
          // 曲げ補正時間係数
          let bendingModifyCoef = getBendingModifyCoeff(deviceMst, weight, area);
          let totalBendLines = 0;
          let totalBendLength = 0;
          let bendTypesArr = [];
          let bendTypesAllArr = bendingProcessInput?.details?.[0]?.bendingList?.map((item) => item.types);
          for (let i = 0; i < bendingProcessInput?.details?.[0]?.bendingList?.length; i++) {
            //工程入力情報
            let bendingProcessInputInfo = bendingProcessInput?.details?.[0]?.bendingList[i];
            if (orgData?.length == 0) continue;
            let dataItemByProcessInput = orgData[i];
            if (Number(bendingProcessInputInfo?.bendLength) > 0) {
              totalBendLength += Number(bendingProcessInputInfo?.bendLength);
              let processTypeNm = processMst?.details?.processDetailTypes?.filter(
                (item) => item.id == bendingProcessInputInfo.types
              )?.[0];
              dataItemByProcessInput.processType = processTypeNm?.name;
              /** 段取金額/時間 */
              // 曲げ_段取時間＊サイズ係数
              let dandoriTimeSec = calDandoriTime(
                WorkType.SmBending,
                deviceMst,
                0,
                0,
                0,
                bendingProcessInputInfo.types,
                thickness,
                sizeCoef
              );
              dandoriTimeSec = dandoriTimeSec * getSetupCoeff(deviceMst); // 基本段取時間＊段取個数係数
              let dandoriTime = dandoriTimeSec;
              dataItemByProcessInput.dandoriTime = dandoriTime ? dandoriTime : 0;
              // 段取時間の合計
              // 段取金額設定
              let dandoriAmt = calDandoriAmt(dandoriTimeSec, deviceMst); //段階時間＊時間チャージ
              dataItemByProcessInput.dandoriAmt = dandoriAmt ? dandoriAmt : 0;
              // 段取金額の合計
              if (!bendTypesArr.includes(bendingProcessInputInfo.types)) {
                bendTypesArr.push(bendingProcessInputInfo.types);
                totalDandoriTm += dandoriTime ? dandoriTime : 0;
                totalDandori += dandoriAmt ? dandoriAmt : 0;
                let index = bendTypesAllArr.indexOf(bendingProcessInputInfo.types);
                if (dataItemsByProcessInput[index]) {
                  dataItemsByProcessInput[index]['dandoriTime'] = dataItemByProcessInput.dandoriTime;
                  dataItemsByProcessInput[index]['dandoriAmt'] = dataItemByProcessInput.dandoriAmt;
                }
              }
              // 作業時間（{(1つ当たりの加工時間	*	曲げ長さ係数)	+	(曲げ補正時間	*	曲げ補正係数)｝	*	曲げ回数	*	サイズ係数）
              let sagyoTimePerPartCntSec = calSagyoTime(
                WorkType.SmBending,
                deviceMst,
                0,
                0,
                bendingProcessInputInfo.types,
                0,
                0,
                0,
                0,
                thickness
              );
              //(1つ当たりの加工時間	*	曲げ長さ係数)
              let sagyoBendCoef = sagyoTimePerPartCntSec * bendingCoef;
              let totalSagyoTimeSec = 0;
              // 曲げ回数判断(IQX_WEBEST-196)
              let bendingCount = 1;
              processMst?.details?.processDetailTypes
                ?.filter((item) => item.name.includes(FRBend))
                ?.map((item) => {
                  //FR曲げの場合、FR曲げ回数を取得
                  if (item.id === bendingProcessInputInfo?.types) {
                    bendingCount = bendingProcessInputInfo?.bendCount; // FR曲げ回数
                  } else {
                    bendingCount = bendingProcessInputInfo?.lines; // 曲げ回数
                  }
                });
              bendingCount = bendingCount ? Number(bendingCount) : 1;
              //員数によって作業時間取得
              for (let i = 1; i <= partCount; i++) {
                // 曲げ補正時間
                let modifyTimeQtyThMaster = deviceMst?.details?.modifyTimeQtyThMaster?.filter(
                  (item) => (item.value == i || item.value > i) && !item.isOutOfRange
                );
                let bendingModifyTime = deviceMst?.details?.modifyTimeItems?.filter(
                  (item) => item.no == modifyTimeQtyThMaster?.[0]?.no && !item.isOutOfRange
                )?.[0]?.value;
                // 作業時間（{(1つ当たりの加工時間	*	曲げ長さ係数)	+	(曲げ補正時間	*	曲げ補正係数)｝	*	曲げ回数	*	サイズ係数）
                //(曲げ補正時間	*	曲げ補正係数)
                let multiModify = bendingModifyTime * bendingModifyCoef;
                totalSagyoTimeSec += (sagyoBendCoef + multiModify) * (bendingCount * sizeCoef);
              }
              let sagyoTimeSec = Math.ceil(totalSagyoTimeSec / partCount);
              // 1つ当たりの加工時間
              let sagyoTimePerSec = Math.ceil(sagyoTimeSec / bendingCount);
              dataItemByProcessInput.sagyoTime = sagyoTimeSec ? sagyoTimeSec * productionQuantityCoeff : 0; // IQX_WEBEST-278 加工時間＊生産個数係数
              dataItemByProcessInput.workTimeSize = sagyoTimePerSec ? sagyoTimePerSec : 0;
              totalSagyoTm += dataItemByProcessInput.sagyoTime; // IQX_WEBEST-278 加工時間＊生産個数係数
              // 作業金額
              let sagyoAmt = calSagyoAmt(sagyoTimeSec, deviceMst); //作業時間＊時間チャージ
              dataItemByProcessInput.sagyoAmt = sagyoAmt ? sagyoAmt : 0;
              // 作業金額の合計
              totalSagyo += sagyoAmt ? Math.round(sagyoAmt) : 0;
              totalBendLines += bendingCount;
            } else {
              // 曲げ長さ"0"の時は見積計算から省いてほしい（IQX_WEBEST-54）
              dataItemByProcessInput.dandoriAmt = 0;
              dataItemByProcessInput.dandoriTime = 0;
              dataItemByProcessInput.sagyoTime = 0;
              dataItemByProcessInput.workTimeSize = 0;
              dataItemByProcessInput.sagyoAmt = 0;
              dataItemsByProcessInput.push(dataItemByProcessInput);
              continue;
            }
            let processTypeNm = processMst?.details?.processDetailTypes?.filter(
              (item) => item.id == bendingProcessInputInfo.types
            )?.[0];
            dataItemByProcessInput.processType = processTypeNm?.name;
            dataItemsByProcessInput.push(dataItemByProcessInput);
          }
          // 曲げ長さ"0"の時は見積計算から省いてほしい（IQX_WEBEST-54）
          if (dataItemsByProcessInput?.length > 0 && totalBendLines > 0) {
            // 曲げ長さが0以外の行数を取得(IQX_WEBEST-143)
            let validBendingList = bendingProcessInput?.details?.[0]?.bendingList?.filter(
              (item) => Number(item.bendLength) > 0
            );
            // プログラム作成時間
            let programCreateTimeCountfThMaster = deviceMst?.details?.programCreateTimeCountfThMaster?.filter(
              (item) =>
                (item.value == validBendingList?.length || item.value > validBendingList?.length) && !item.isOutOfRange
            );
            let programTimeSec = deviceMst?.details?.programCreateTimeItems?.filter(
              (item) => item.no == programCreateTimeCountfThMaster?.[0]?.no && !item.isOutOfRange
            )?.[0]?.value;
            dataItemsByProcessInput[0].programTime = programTimeSec === undefined ? 0 : programTimeSec;
            // プログラム作成金額
            let programAmtt = calDandoriAmt(programTimeSec, deviceMst); //装置の加工金額
            dataItemsByProcessInput[0].programAmt = programAmtt === undefined ? 0 : programAmtt;
            // 曲長段取時間
            let prepBendTimeLengthMaster = deviceMst?.details?.prepBendTimeLengthMaster?.filter(
              (item) =>
                (item.value == Number(bendingProcessInput?.details?.[0]?.bendingData?.maxBendLength) ||
                  item.value > Number(bendingProcessInput?.details?.[0]?.bendingData?.maxBendLength)) &&
                !item.isOutOfRange
            );
            let bendingTimeSec = deviceMst?.details?.prepBendTimeItems?.filter(
              (item) => item.no == prepBendTimeLengthMaster?.[0]?.no
            )?.[0]?.value;
            dataItemsByProcessInput[0].mageDandoriTime = bendingTimeSec === undefined ? 0 : bendingTimeSec;
            // 曲長段取金額
            let mageDandoriAmtt = calDandoriAmt(bendingTimeSec, deviceMst); //装置の加工金額
            dataItemsByProcessInput[0].mageDandoriAmt = mageDandoriAmtt === undefined ? 0 : mageDandoriAmtt;
            totalDandori = Math.round(totalDandori) + Math.round(programAmtt) + Math.round(mageDandoriAmtt);
            totalDandoriTm = totalDandoriTm + programTimeSec + bendingTimeSec;
            // 段取金額の合計
            bendingAdditions.totalDandori.dataItemsAmt = totalDandori ? totalDandori : 0;
            // 段取時間の合計
            bendingAdditions.totalDandori.dataItemsTime = totalDandoriTm ? totalDandoriTm : 0;
            // 作業金額の合計
            bendingAdditions.totalSagyo.dataItemsAmt = totalSagyo ? totalSagyo : 0;
            bendingAdditions.totalSagyo.dataItemsTime = totalSagyoTm ? totalSagyoTm : 0;
          }
          /** 　合計時間　*/
          let totalTimeSum = totalDandoriTm + totalSagyoTm;
          bendingAdditions.totalDataTime = totalTimeSum;
          /** 　合計金額　*/
          let totalSum = (totalDandori ? Math.round(totalDandori) : 0) + (totalSagyo ? Math.round(totalSagyo) : 0); // 画面表示用（四捨五入した金額を足す）
          bendingAdditions.totalDataPrice = totalSum ? totalSum : 0;
          orgData = dataItemsByProcessInput;
        }
        bendingAdditions.dataItems = JSON.parse(JSON.stringify(orgData));
        bendingAddition = bendingAdditions;
      }
    }
  }
  return bendingAddition;
};

const getBendingCoeff = (deviceMst, weight, area) => {
  if (deviceMst == undefined || deviceMst?.length == 0) return 0;
  // 段取時間設定

  // 重量
  let bendTimeCoeffWeightThMaster = deviceMst?.details?.bendTimeCoeffWeightThMaster?.filter(
    (item) => (item.value == weight || item.value > weight) && !item.isOutOfRange
  );
  // 面積
  let bendTimeCoeffLenThMaster = deviceMst?.details?.bendTimeCoeffLenThMaster?.filter(
    (item) => (item.value == area || item.value > area) && !item.isOutOfRange
  );
  let bendTimeCoeffItems = Object.entries(deviceMst?.details?.bendTimeCoeffItems)?.filter(
    ([key, data]) => parseInt(key) === bendTimeCoeffWeightThMaster?.[0]?.no && !data.isOutOfRange
  )?.[0];
  let bendTimeCoeffs = [];
  bendTimeCoeffItems?.map((item, index) => {
    if (index > 0) {
      bendTimeCoeffs = item;
    }
  });

  // サイズ係数
  let bendTimeCoeff = bendTimeCoeffs?.filter((item) => item.no == bendTimeCoeffLenThMaster?.[0]?.no)?.[0]?.value;
  bendTimeCoeff = bendTimeCoeff ? bendTimeCoeff : 0;
  return bendTimeCoeff;
};

const getBendingModifyCoeff = (deviceMst, weight, area) => {
  if (deviceMst == undefined || deviceMst?.length == 0) return 0;
  // 段取時間設定

  // 重量
  let modifyCoeffWeightThMaster = deviceMst?.details?.modifyCoeffWeightThMaster?.filter(
    (item) => (item.value == weight || item.value > weight) && !item.isOutOfRange
  );
  // 面積
  let modifyCoeffLenThMaster = deviceMst?.details?.modifyCoeffLenThMaster?.filter(
    (item) => (item.value == area || item.value > area) && !item.isOutOfRange
  );
  let childPartsCoeffs =
    modifyCoeffWeightThMaster?.length > 0 && modifyCoeffLenThMaster?.length > 0
      ? deviceMst?.details?.modifyCoeffItems?.filter((item) => item.weightId == modifyCoeffWeightThMaster[0]?.no)
      : deviceMst?.details?.modifyCoeffItems[0];

  // 補正係数
  let partsCoeff =
    childPartsCoeffs?.length > 0
      ? childPartsCoeffs?.[0]?.lengthCoeffList?.filter(
          (item) => item.no == modifyCoeffLenThMaster?.[0]?.no && !item.isOutOfRange
        )?.[0]?.value
      : childPartsCoeffs?.[0]?.lengthCoeffList?.[0]?.value;
  partsCoeff = partsCoeff ? partsCoeff : 0;
  return partsCoeff;
};
