/**
 * 説明：Constant値定義
 * 作成者：ナンス
 * 作成日：2024/12/20
 * バージョン：1.0
 */
import dayjs from 'dayjs';
import { today } from '../common/Common';
// ログインユーザー情報
export const IQxRealGoku_LoginUser = 'IQxRealGoku_LoginUser';

// Sessionアクセストークン情報
export const IQxRealGoku_AccessToken = 'IQxRealGoku_AccessToken';

// パラメータ情報
export const IQxRealGoku_CalParameters = 'IQxRealGoku_CalParameters';

export const LoginPath = process.env.REACT_APP_FRONTEND_URL;

export const AllowedExtensions = ['.step', '.stp', '.X_T', '.sldprt', '.sldasm'];

// サイズ制限 (例えば 5MB)
export const FileSizeLimitMB = 5 * 1024 * 1024; // 5MB in bytes

// export const FileSizeLimitKB = 200 * 1024; // 100KB in bytes
export const FileSizeLimitKB = 100 * 1024;

// サイズ制限 (例えば 10MB)
export const totalUploadFileSizeLimitMB = 10 * 1024 * 1024; // 5MB in bytes

// 注文のデフォルト設定
export const DefaultOrderConditions = {
  order: 0,
  comment: '',
  process: 0,
  material: 0,
  quantity: 1,
  tolerance: 0,
  finalPurpos: 0,
  deliveryDate: new Date(),
  processDetail: 0,
  deliveryTimeCls: 0,
  weldFinishLevel: 0,
};

// 新しいCd3データ設定
export const NewCad3dData = {
  id: 0,
  name: '',
  cad3dFilePath: '',
  status: 0,
  orderConditions: DefaultOrderConditions,
  sizeX: 0,
  sizeY: 0,
  sizeZ: 0,
  totalWeight: 0,
  totalSurfaceArea: 0,
  estimateUnitPrice: 0,
  estimateTotalPrice: 0,
  priorityLevel: 0,
  estimateResult: 0,
};

export const processInputSurfaceData = [
  {
    no: 1,
    calcType: 1,
    processDetailWorkTypeGroup: 0,
    processDetailTypeNo: 0,
    areaUnitPrice: 0,
    weightUnitPrice: 0,
    weightCount: 1,
    areaCount: 1,
    areaAutoExtract: 0,
    weightAutoExtract: 0,
    totalArea: 0,
    totalWeight: 0,
  },
];
export const processInputKumitateData = {
  no: 1,
  calcType: 1,
  smPartsCount: 1,
  purchaseInstTime: 0,
  assyList: [{ no: 1, name: '', workTime: 0, workCount: 0, workTotalTime: 0 }],
  autoExtract: 1, // IQX_WEBEST-179
};
export const processInputKensaData = {
  no: 1,
  calcType: 1,
  smPartsCount: 1,
  autoExtract: 1, // IQX_WEBEST-179
};
export const processInputKonpouData = {
  no: 1,
  calcType: 1,
  smPartsCount: 1,
  autoExtract: 1, // IQX_WEBEST-179
};

// 親部品見積の初期値
export const estimateInitInfo = {
  estimateProducts: {
    id: 0,
    created: dayjs(today).format('YYYY-MM-DD'),
    modified: dayjs(today).format('YYYY-MM-DD'),
    modifierId: 0,
    modifier: '',
    drawingNo: '',
    name: '',
    clientId: 0,
    clientName: '',
    quantity: 1,
    coeffOrdersId: 0,
    coeffOrdersName: '',
    coeffDeliveryDatesId: 0,
    coeffDeliveryDatesName: '',
    coeffPurchasesId: 0,
    coeffPurchasesName: '',
    coeffManagementsId: 0,
    coeffManagementsName: '',
    estimateStatusId: 0,
    estimateStatus: '',
    productType: '',
    totalWeight: 0.0,
    totalSurfaceArea: 0.0,
    createrId: 0,
    creater: '',
    sizeX: 0,
    sizeY: 0,
    sizeZ: 0,
    estimateNo: '',
    comment: '',
    capacity: 0,
    totalWeightParts: 0,
    totalWeightSm: 0,
    totalWeightBm: 0,
    totalWeightMa: 0,
    partTypesParts: 0,
    partTypesSm: 0,
    partTypesBm: 0,
    partTypesMa: 0,
    totalQuantityParts: 0,
    totalQuantitySm: 0,
    totalQuantityBm: 0,
    totalQuantityMa: 0,
    costUnitPrice: 0,
    costTotalPrice: 0,
    estimateUnitPrice: 0,
    estimateTotalPrice: 0,
    grossUnitPrice: 0,
    grossTotalPrice: 0,
    grossRate: 0,
    materialCostUnitPrice: 0,
    materialCostTotalPrice: 0,
    workCostUnitPrice: 0,
    workCostTotalPrice: 0,
    purchaseUnitPrice: 0,
    purchaseTotalPrice: 0,
    manufacturingCostTotalPrice: 0,
    setupCostTotalPrice: 0,
    managementFeeTotalPrice: 0,
    otherProduct: {
      adjustment: 0,
      coeffClient: 0,
      coeffOrders: 0,
      coeffDeliveryDatesId: 0,
      coeffPurchases: 0,
      coeffManagements: 0,
    },
    processSelect: {
      id: 0,
      name: '',
      details: [],
      modified: dayjs().format(),
    },
    // processInput: {
    //   surfaceProcessInputInfo: processInputSurfaceData,
    //   assenbleProcessInputInfo: processInputKumitateData,
    //   inspectionProcessInputInfo: processInputKensaData,
    //   packingProcessInputInfo: processInputKonpouData,
    // },
    additions: [],
    purchases: [],
    attachments: {
      id: 0,
      name: '',
      extention: '',
      byte: 0,
      created: '2023-08-31T02:36:11.4145609Z',
      modified: '2023-08-31T02:36:11.4145609Z',
    },
    memo: '',
    byQuantities: [],
    calcParameters: {
      settings: {},
      parameters: {},
    },
    info: {
      isUsed: true,
      remarks: '',
      sortNum: 0,
    },
  },
  esIq3Info: [],
  epCustomCosts: [],
  epCustomDates: [],
  epCustomLabels: [],
  epCustomMemos: [],
};

// 板金部品見積の初期値
export const iq3EstimateInitInfo = [
  {
    id: 0,
    created: dayjs().format(),
    modified: dayjs().format(),
    modifierId: 0,
    modifier: '',
    estimateProductId: 0,
    no: 0,
    creationType: 1,
    imageUrl: '',
    imageId: '',
    drawingNo: '',
    name: '',
    partCount: 1,
    surfaceProtection: 0,
    weight: 0,
    totalSurfaceArea: 0,
    partSizeX: 0,
    partSizeY: 0,
    materialIq3Id: 0,
    materialName: '',
    materialTypeIq3Id: 0,
    materialTypeName: '',
    materialSurfaceIq3Id: 0,
    materialSurfaceName: '',
    thickness: 0,
    grabbingX: 0,
    grabbingY: 0,
    pierWidthX: 0,
    pierWidthY: 0,
    worlRangeX: 0,
    worlRangeY: 0,
    yield: 0,
    materialQuantity: 0,
    weightUnitPrice: 0,
    surfaceAddPrice: 0,
    weightAddPrice: 0,
    scrapPrice: 0,
    costUnitPrice: 0,
    costTotalPrice: 0,
    materialCostUnitPrice: 0,
    materialCostTotalRate: 0,
    materialCostTotalPrice: 0,
    workCostUnitPrice: 0,
    workCostTotalPrice: 0,
    purchaseUnitPrice: 0,
    purchaseTotalPrice: 0,
    surfaceUnitPrice: 0,
    surfaceTotalPrice: 0,
    createrId: 0,
    creater: '',
    processSelect: {
      id: 0,
      name: '',
      details: [],
      modified: dayjs().format(),
    },
    processInput: [],
    additions: [],
    purchases: [],
    materialDetailPatterns: [],
    materialDetailSizes: [],
    calcParameters: {
      settings: {},
      parameters: {},
    },
    info: {
      isUsed: true,
      remarks: '',
      sortNum: 0,
    },
  },
];

// 工程種別
export const WorkType = Object.freeze({
  // ＜板金子部品＞
  SmProgram: 10100, // プログラム
  SmBlankLaser: 10210, // ブランク（レーザー）
  SmBlankPunch: 10220, // ブランク（パンチ）
  SmBlankCombi: 10230, // ブランク（複合機）
  SmSecondaryWork: 10300, // 2次加工
  SmBending: 10400, // ベンディング
  SmDeburringAuto: 10510, // バリ取（自動）
  SmDeburringManual: 10520, // バリ取（手動）
  SmWelding: 10600, // 溶接
  SmWeldFinish: 10700, // 溶接仕上
  SmSurface: 10800, // 表面処理
  SmShearing: 10900, // シャーリング
  SmInspection: 11000, // 検査
  SmCustom: 11100, // 追加工程（カスタム）
});

// 工程種別グループ
export const WorkTypeGroup = Object.freeze({
  None: 0, // グループ無し
  // 板金子部品
  SmWorkGeneral: 10510, // 処理全般
  SmPlating: 10520, // メッキ方法
  SmCoating: 10530, // 塗装種類
  SmWelding: 10710, // 溶接種類
  SmInstThick: 10720, // 取付種類（板厚判断）
  SmInstSize: 10730, // 取付種類（サイズ判断）
  SmSecondaryWorkSize: 10310, // 2次加工種類（サイズ判断）
  SmSecondaryWorkMillimeter: 10320, // 2次加工種類（ミリメートル判断）
});

// データと編集の切り分け
export const DataType = Object.freeze({
  Data: 0, // データ
  Edit: 1, // 編集
});

// 取引先種別
export const ClientType = Object.freeze({
  Customer: 0x10, // 顧客
  Supplier: 0x100, // 仕入れ先
  Contractor: 0x1000, // 外注先
});

// パターンタイプ
export const PatternType = Object.freeze({
  Pattern1: 1, // パターン1
  Pattern2: 2, // パターン2
});

// サービス分類
export const ServiceClass = Object.freeze({
  Parent: 0, // 親部品
  SheetMetal: 1, // 板金子部品
  Beams: 2, // 形鋼
  Machining: 3, // 機械加工
});

export const BlankTypes = [
  { id: 1, value: 'SQ', label: '角' },
  { id: 2, value: 'OB', label: '長丸' },
  { id: 3, value: 'RE', label: '長角' },
  { id: 4, value: 'RR', label: 'R付角' },
  { id: 5, value: 'CP', label: 'センタポンチ' },
  { id: 6, value: 'SD', label: 'シングルD' },
  { id: 7, value: 'DD', label: 'ダブルD' },
  { id: 8, value: 'RO', label: '丸' },
];

export const Types = [
  { id: 1, value: '丸', label: '丸' },
  { id: 2, value: '角', label: '角' },
  { id: 3, value: '長角', label: '長角' },
  { id: 4, value: '長丸', label: '長丸' },
  { id: 5, value: 'その他', label: 'その他' },
];

// ベンディング種類
export const BendingTypes = [
  { id: 1, value: 'V' },
  { id: 2, value: 'R' },
  { id: 3, value: 'FR' },
  { id: 4, value: '3R' },
  { id: 5, value: 'HM' },
  { id: 6, value: 'Z' },
  { id: 7, value: 'AMA' },
  { id: 8, value: 'B1' },
  { id: 9, value: 'B2' },
  { id: 10, value: 'B3' },
];

// サプ溶接種類
export const SubWeldTypes = [
  { id: 11, value: 'WE1' },
  { id: 12, value: 'WE2' },
  { id: 13, value: 'WE3' },
  { id: 14, value: 'WE4' },
  { id: 15, value: 'WE5' },
  { id: 16, value: 'WE6' },
  { id: 17, value: 'WE7' },
  { id: 18, value: 'WE8' },
];

export const FRBend = 'FR';

// 2次加工種類
export const SecondaryWorkTypes = [
  { id: 1, value: 'BR' },
  { id: 2, value: 'TP' },
  { id: 3, value: 'COUNTERSINK' },
  { id: 4, value: 'OTHER' },
  { id: 5, value: 'HL1' },
  { id: 6, value: 'HL2' },
  { id: 7, value: 'HL3' },
  { id: 8, value: 'HL4' },
];

export const PRIORITY_HIGH = 0;
export const PRIORITY_LOW = 1;

// ページネーション
export const pageSizes = ['30', '50', '100'];

// ２Dファイルアップロード状況
export const FileUploadStatus2D = {
  Success: '成功', // アップロード成功
  Fail: '失敗', // アップロード失敗
  Blank: '', // 2Dと3Dファイル名が合っていない
};

export const ORDER_FITST = '初回発注';
export const ORDER_REPEAT = 'リピート発注';
export const ORDER_DATE_INPUT = '納期を指定する';
export const ORDER_DATE_URGENT = '急ぎ';
export const EST_FAIL_AMT_LIMIT = 10000001; // 10001
export const MIRROR_SURFACE = '鏡面';
export const PICKLING = '酸洗い';
export const HL = 'HL';
