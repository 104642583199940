/**
 * クラス名：システム全体の共通ファイル
 * 説明：システム全体に共通使っているファイルである。
 * 作成者：ナンス
 * 作成日：2024/11/25
 * バージョン：1.0
 */
import { toDecimal } from '../common/Common';
import {
  calDandoriAmt,
  calDandoriTime,
  getGasCoef,
  getHoseCoeff,
  getJointTm,
  getProductionQuantityCoeff,
  getSetupCoeff,
  getSizeCoeff,
} from './IQ3KouteiCalculate_Common';
import { DataType, ServiceClass, WorkType, WorkTypeGroup } from '../common/Constant';

// 2次工程の計算
export const cal2jikakou = (
  curAddition,
  processInput,
  processSelect,
  curDeviceMst,
  calcParameters,
  curIQ3DataDetail,
  orderQuantity
) => {
  let nijiAddition = curAddition;
  if (Object.keys(nijiAddition).length > 0) {
    let processMst = [];
    let deviceMst = []; //テスト用ID;
    let totalSum = 0;
    let totalTimeSum = 0;

    let parameters = calcParameters;
    // 数量
    let quantity = orderQuantity;
    // 員数
    let partCnt = curIQ3DataDetail?.partCount ? Number(curIQ3DataDetail?.partCount) : 1;
    if (parameters?.process?.length > 0) {
      processMst = parameters?.process
        ? parameters?.process?.filter(
            (item) => item?.class === ServiceClass.SheetMetal && item.workType === WorkType.SmSecondaryWork
          )[0]
        : [];
      if (parameters?.device?.length > 0) {
        deviceMst = parameters?.device?.filter((item) => processMst?.id == item?.processId);
      }
    }

    let patternInfo = processSelect;
    if (patternInfo == undefined) return;
    if (patternInfo?.length > 0 && patternInfo[0]?.isUsedForCalc == false) return;

    deviceMst = curDeviceMst[0]; //テスト用ID;
    if (deviceMst == undefined) return;

    let secondaryWorkAdditions = nijiAddition;

    // 2次加工工程入力情報取得
    let secondaryWorkProcessInput = processInput[0];

    let detailItems = [];
    let editDetailItems = [];
    //2次加工種類（サイズ判断）設定
    let sizeProcessInputs = secondaryWorkProcessInput?.details?.filter(
      (item) => item.workTypeGroup == WorkTypeGroup.SmSecondaryWorkSize
    );
    for (let j = 0; j < sizeProcessInputs?.length; j++) {
      let sizeProcessInput = sizeProcessInputs[j];
      let processTypeNm = processMst?.details?.processDetailTypes?.filter(
        (item) => item.id == sizeProcessInput.types && item.group == sizeProcessInput.workTypeGroup
      )?.[0];
      detailItems.push({
        id: j + 1,
        processName: '2次加工処理(No' + (j + 1) + ')',
        processTypeId: processTypeNm?.id,
        processType: processTypeNm?.name,
        dandoriAmt: 0,
        dandoriTime: 0,
        sagyoAmt: 0,
        sagyoTime: 0,
        workTimeSize: 0, //1つ当たりの加工時間
        diameter: Number(sizeProcessInput.diameter), //径
        processCnt: Number(sizeProcessInput.count), // 数
        blankFlag: sizeProcessInput.blankFlag, // ブランクフラグ
        secondaryWorkType: sizeProcessInput.workTypeGroup, // 2次加工種類（サイズ判断）/ 2次加工種類（ミリメートル判断）
        // processCnt: partCnt,
        formula: '',
      });
    }
    //2次加工種類（ミリメートル判断）設定
    let millimeterProcessInputs = secondaryWorkProcessInput?.details?.filter(
      (item) => item.workTypeGroup == WorkTypeGroup.SmSecondaryWorkMillimeter
    );
    for (let j = 0; j < millimeterProcessInputs?.length; j++) {
      let millimeterProcessInput = millimeterProcessInputs[j];
      let processTypeNm = processMst?.details?.processDetailTypes?.filter(
        (item) => item.id == millimeterProcessInput.types && item.group == millimeterProcessInput.workTypeGroup
      )?.[0];
      let detailItemsId = detailItems?.length == 0 ? 0 : detailItems?.length;
      detailItems.push({
        id: detailItemsId + j + 1,
        processName: '2次加工処理(No' + (j + 1) + ')',
        processTypeId: processTypeNm?.id,
        processType: processTypeNm?.name + '(M' + millimeterProcessInput.diameter + ')',
        dandoriAmt: 0,
        dandoriTime: 0,
        sagyoAmt: 0,
        sagyoTime: 0,
        workTimeSize: 0, //1つ当たりの加工時間
        diameter: Number(millimeterProcessInput.diameter), //径
        processCnt: Number(millimeterProcessInput.count), // 数
        blankFlag: millimeterProcessInput.blankFlag, // ブランクフラグ
        secondaryWorkType: millimeterProcessInput.workTypeGroup, // 2次加工種類（サイズ判断）/ 2次加工種類（ミリメートル判断）
        // processCnt: processCnt,
        formula: '',
      });
    }

    editDetailItems = [];
    let secondaryWorkAddition = {
      no: processMst?.id,
      processId: processMst?.id,
      dataType: secondaryWorkAdditions?.dataType ? secondaryWorkAdditions?.dataType : DataType.Data,
      dataItems: detailItems,
      editItems: secondaryWorkAdditions?.editItems ? secondaryWorkAdditions?.editItems : editDetailItems,
      totalDandori: {
        dataItemsAmt: 0,
        dataItemsTime: 0,
        editItemsAmt: 0,
        editItemsTime: 0,
      },
      totalSagyo: {
        dataItemsAmt: 0,
        dataItemsTime: 0,
        editItemsAmt: 0,
        editItemsTime: 0,
      },
      totalDataPrice: 0,
      totalDataTime: 0,
      totalEditPrice: 0,
      totalEditTime: 0,
      editDataFlg: false,
    };
    secondaryWorkAdditions = secondaryWorkAddition;

    if (secondaryWorkAdditions?.dataItems) {
      let orgData = JSON.parse(JSON.stringify(secondaryWorkAdditions?.dataItems));
      if (deviceMst != undefined) {
        let totalDandori = 0;
        let totalSagyo = 0;
        let totalSagyoTm = 0;
        let totalDandoriTm = 0;
        let dataItemsByProcessInput = [];
        // 板厚
        let thickness = parameters?.materialIq3?.filter((item) => item.id == curIQ3DataDetail?.thickness)?.[0]?.info
          ?.thick;
        // 重量
        let weight = Number(curIQ3DataDetail?.weight);
        // 面積
        let area = Number(curIQ3DataDetail?.totalSurfaceArea);

        //総重量が0、子部品数が0の場合、段取と加工を計算しない。
        if (weight === 0 && area === 0) {
          let dandoriSameTypeArr = [];
          let mainSecondaryWorkAdditions = orgData?.filter(
            (item) => item.secondaryWorkType == WorkTypeGroup.SmSecondaryWorkSize
          );
          let mainSecondaryWorkProcessInput = secondaryWorkProcessInput?.details?.filter(
            (item) => item.workTypeGroup == WorkTypeGroup.SmSecondaryWorkSize
          );
          for (let i = 0; i < mainSecondaryWorkProcessInput?.length; i++) {
            //工程入力情報
            let secondaryWorkProcessInputInfo = mainSecondaryWorkProcessInput[i];

            let dataItemByProcessInput = mainSecondaryWorkAdditions[i];

            let processTypeNm = processMst?.details?.processDetailTypes?.filter(
              (item) =>
                item.id == secondaryWorkProcessInputInfo.types &&
                item.group == secondaryWorkProcessInputInfo?.workTypeGroup
            )?.[0];
            dataItemByProcessInput.processType = processTypeNm?.name;

            let isSameType = dandoriSameTypeArr?.filter((item) => item == secondaryWorkProcessInputInfo?.types);
            if (isSameType?.length == 0 || dandoriSameTypeArr?.length == 0) {
              let dandoriTimeSec = 0;
              let dandoriTime = dandoriTimeSec;
              dataItemByProcessInput.dandoriTime = dandoriTime;

              totalDandoriTm += dandoriTime;

              let dandoriAmt = 0;
              dataItemByProcessInput.dandoriAmt = dandoriAmt;
              totalDandori += dandoriAmt;
            } else {
              dataItemByProcessInput.dandoriTime = 0;
              dataItemByProcessInput.dandoriAmt = 0;
            }

            let sagyoTimeSec = 0;
            dataItemByProcessInput.sagyoTime = 0;
            dataItemByProcessInput.workTimeSize = 0;
            totalSagyoTm += sagyoTimeSec;

            let sagyoAmt = 0;
            dataItemByProcessInput.sagyoAmt = sagyoAmt;

            // 作業金額の合計
            totalSagyo += sagyoAmt;
            dataItemsByProcessInput.push(dataItemByProcessInput);
            dandoriSameTypeArr.push(secondaryWorkProcessInputInfo?.types);
          }

          let millimeterSecondaryWorkAdditions = orgData?.filter(
            (item) => item.secondaryWorkType == WorkTypeGroup.SmSecondaryWorkMillimeter
          );
          let millimeterSecondaryProcessType = secondaryWorkProcessInput?.details?.filter(
            (item) => item.workTypeGroup == WorkTypeGroup.SmSecondaryWorkMillimeter
          );
          for (let i = 0; i < millimeterSecondaryProcessType?.length; i++) {
            //工程入力情報
            let secondaryWorkProcessInputInfo = millimeterSecondaryProcessType[i];
            if (millimeterSecondaryWorkAdditions?.length == 0) continue;
            //径がない場合、計算しない。
            if (Number(secondaryWorkProcessInputInfo?.diameter) === 0) continue;

            let dataItemByProcessInput = millimeterSecondaryWorkAdditions[i];

            let processTypeNm = processMst?.details?.processDetailTypes?.filter(
              (item) =>
                item.id == secondaryWorkProcessInputInfo.types &&
                item.group == secondaryWorkProcessInputInfo?.workTypeGroup
            )?.[0];
            dataItemByProcessInput.processType = processTypeNm?.name;

            /** 段取金額/時間 */
            // 基本段取時間＊サイズ係数
            let isSameType = dandoriSameTypeArr?.filter((item) => item == secondaryWorkProcessInputInfo?.types);
            if (isSameType?.length == 0 || dandoriSameTypeArr?.length == 0) {
              let dandoriTimeSec = 0;
              let dandoriTime = dandoriTimeSec;
              dataItemByProcessInput.dandoriTime = 0;
              // 段取時間の合計
              totalDandoriTm += dandoriTime;

              let dandoriAmt = 0;

              dataItemByProcessInput.dandoriAmt = 0;
              // 段取金額の合計
              totalDandori += dandoriAmt;
            } else {
              dataItemByProcessInput.dandoriTime = 0;
              dataItemByProcessInput.dandoriAmt = 0;
            }

            // 作業時間（{(1つ当たりの加工時間	+	(加工補正時間	*	加工補正係数)｝	*	サイズ係数）
            // 1つ当たりの加工時間
            let sagyoTimePerSec = 0;

            let sagyoTimeSec = 0;
            dataItemByProcessInput.sagyoTime = sagyoTimeSec;
            dataItemByProcessInput.workTimeSize = sagyoTimePerSec;

            let sagyoAmt = 0;
            dataItemByProcessInput.sagyoAmt = sagyoAmt;

            // 作業金額の合計
            totalSagyo += sagyoAmt;
            dataItemsByProcessInput.push(dataItemByProcessInput);
            dandoriSameTypeArr.push(secondaryWorkProcessInputInfo?.types);
          }
          // 段取金額の合計
          secondaryWorkAdditions.totalDandori.dataItemsAmt = 0;
          // 段取時間の合計
          secondaryWorkAdditions.totalDandori.dataItemsTime = 0;
          // 作業金額の合計
          secondaryWorkAdditions.totalSagyo.dataItemsAmt = 0;
          secondaryWorkAdditions.totalSagyo.dataItemsTime = 0;
          /** 　合計時間　*/
          let totalTimeSum = 0;
          secondaryWorkAdditions.totalDataTime = totalTimeSum;
          /** 　合計金額　*/
          let totalSum = 0;
          secondaryWorkAdditions.totalDataPrice = totalSum;
          orgData = dataItemsByProcessInput;
        } else {
          // IQX_WEBEST-278 工程積算タブの表示に生産個数係数が含まれていなかったので、含むようにしてほしい
          let productionQuantityCoeff = getProductionQuantityCoeff(deviceMst, quantity, partCnt);
          //材質
          let materialTypeIq3Id = curIQ3DataDetail?.materilaTypeIq3Id;

          //材質区分
          let materialTypeId = parameters?.materialTypeIq3?.filter((item) => item.id == materialTypeIq3Id)?.[0]
            ?.materialTypeId;

          // サイズ係数
          let sizeCoef = getSizeCoeff(deviceMst, weight, area);

          let dandoriSameTypeArr = [];

          let mainSecondaryWorkAdditions = orgData?.filter(
            (item) => item.secondaryWorkType == WorkTypeGroup.SmSecondaryWorkSize
          );
          let mainSecondaryWorkProcessInput = secondaryWorkProcessInput?.details?.filter(
            (item) => item.workTypeGroup == WorkTypeGroup.SmSecondaryWorkSize
          );

          for (let i = 0; i < mainSecondaryWorkProcessInput?.length; i++) {
            //工程入力情報
            let secondaryWorkProcessInputInfo = mainSecondaryWorkProcessInput[i];

            if (mainSecondaryWorkAdditions?.length === 0) continue;
            //ブランク"チェックONの場合、計算しない。
            if (secondaryWorkProcessInputInfo?.blankFlag) continue;
            // Mサイズ・径が０の場合、計算しない。（IQX_WEBEST-54）
            let diameter = Number(secondaryWorkProcessInputInfo?.diameter);
            if (diameter === 0) continue;

            let dataItemByProcessInput = mainSecondaryWorkAdditions[i];

            let processTypeNm = processMst?.details?.processDetailTypes?.filter(
              (item) =>
                item.id == secondaryWorkProcessInputInfo.types &&
                item.group == secondaryWorkProcessInputInfo?.workTypeGroup
            )?.[0];
            dataItemByProcessInput.processType = processTypeNm?.name;

            let deviceCharges = Object.entries(deviceMst?.details?.charges)?.filter(
              ([key, data]) => parseInt(key) === secondaryWorkProcessInputInfo?.types
            )?.[0];
            /** 段取金額/時間 */
            // 基本段取時間＊サイズ係数
            let isSameType = dandoriSameTypeArr?.filter((item) => item.types === secondaryWorkProcessInputInfo?.types);
            if (isSameType?.length == 0 || dandoriSameTypeArr?.length == 0) {
              let dandoriTimeSec = calDandoriTime(
                WorkType.SmSecondaryWork,
                deviceMst,
                0,
                weight,
                area,
                secondaryWorkProcessInputInfo?.types
              );
              dandoriTimeSec = dandoriTimeSec ? dandoriTimeSec : 0;
              dandoriTimeSec = dandoriTimeSec * getSetupCoeff(deviceMst, quantity, partCnt); // 基本段取時間＊段取個数係数
              let dandoriTime = dandoriTimeSec;
              dataItemByProcessInput.dandoriTime = dandoriTime;
              // 段取時間の合計
              totalDandoriTm += dandoriTime;

              // 段取金額設定
              //段階時間
              let mSetTimeMin = Number.isFinite(dandoriTimeSec / 60) ? dandoriTimeSec / 60 : 0;
              //装置の段階時間チャージ

              let mSetHRate = 0;
              deviceCharges?.map((item, index) => {
                if (index > 0) {
                  mSetHRate = item?.prep;
                }
              });
              let dandoriAmt = (mSetTimeMin * mSetHRate) / 60.0; //段階時間＊時間チャージ =
              dandoriAmt = Number.isFinite(dandoriAmt) ? dandoriAmt : 0;

              dataItemByProcessInput.dandoriAmt = dandoriAmt;
              // 段取金額の合計
              totalDandori += dandoriAmt;
            } else {
              dataItemByProcessInput.dandoriTime = 0;
              dataItemByProcessInput.dandoriAmt = 0;
            }

            // 作業時間（{(1つ当たりの加工時間	+	(加工補正時間	*	加工補正係数)｝	*	サイズ係数）
            // 1つ当たりの加工時間
            let sagyoTimePerSec = calSagyoTimeBy2ji(
              deviceMst,
              0,
              Number(secondaryWorkProcessInputInfo?.diameter),
              secondaryWorkProcessInputInfo?.types,
              secondaryWorkProcessInputInfo?.workTypeGroup
            );
            sagyoTimePerSec = sagyoTimePerSec ? sagyoTimePerSec : 0;

            let sagyoTimeSec = 0;
            let sagyoTimePerCount = 0;
            let sameTypeIndex = 0;
            let sameTypeCnt = 1;
            let manufacturingCnt = quantity * partCnt;
            for (let m = 0; m < manufacturingCnt; m++) {
              let hoseTime = 0;
              let curTypeCount = Number(secondaryWorkProcessInputInfo?.count);

              let sameTypeCntByCurType = mainSecondaryWorkProcessInput?.filter(
                (item) => item.types === secondaryWorkProcessInputInfo?.types
              );
              for (let s = 0; s < sameTypeCntByCurType?.length; s++) {
                if (m > 0 || sameTypeCntByCurType[s]?.id !== secondaryWorkProcessInputInfo?.id) {
                  sameTypeCnt += Number(sameTypeCntByCurType[s]?.count) + m;
                } else {
                  break;
                }
              }
              sameTypeIndex = sameTypeCnt;
              for (let i = 0; i < curTypeCount; i++) {
                let hoseCount = sameTypeIndex + i;
                // 加工補正時間
                let modifyTimeQtyThMaster = deviceMst?.details?.modifyTimeQtyThMaster?.filter(
                  (item) => (item.value == hoseCount || item.value > hoseCount) && !item.isOutOfRange
                )?.[0];
                hoseTime = deviceMst?.details?.modifyTimeItems?.filter(
                  (item) => item.no == modifyTimeQtyThMaster?.no && !item.isOutOfRange
                )?.[0]?.value;

                // 加工補正係数
                let hoseCoeff = getHoseCoeff(deviceMst, weight, area);
                // 作業時間（{(1つ当たりの加工時間	+	(加工補正時間	*	加工補正係数)｝	*数量*	サイズ係数）
                let hoseMultiply = hoseCoeff * hoseTime;
                sagyoTimePerCount += (sagyoTimePerSec + hoseMultiply) * sizeCoef;
              }
            }
            // 加工時間　÷　製造数
            sagyoTimePerCount = Number.isFinite(sagyoTimePerCount / manufacturingCnt)
              ? sagyoTimePerCount / manufacturingCnt
              : 0;
            sagyoTimeSec = sagyoTimePerCount ? sagyoTimePerCount * productionQuantityCoeff : 0; // IQX_WEBEST-278 加工時間＊生産個数係数
            dataItemByProcessInput.sagyoTime = sagyoTimeSec;
            dataItemByProcessInput.workTimeSize = sagyoTimePerSec;
            totalSagyoTm += sagyoTimeSec;
            // 作業金額
            let mSagyoTimeMin = Number.isFinite(sagyoTimeSec / 60) ? sagyoTimeSec / 60 : 0;
            //装置の作業時間チャージ
            let mSagyoHRate = 0;
            deviceCharges?.map((item, index) => {
              if (index > 0) {
                mSagyoHRate = item?.work;
              }
            });
            let sagyoAmt = (mSagyoTimeMin * mSagyoHRate) / 60.0; //作業時間＊時間チャージ
            sagyoAmt = Number.isFinite(sagyoAmt) ? sagyoAmt : 0;
            dataItemByProcessInput.sagyoAmt = sagyoAmt;

            // 作業金額の合計
            totalSagyo += sagyoAmt ? Math.round(sagyoAmt) : 0;
            dataItemsByProcessInput.push(dataItemByProcessInput);
            dandoriSameTypeArr.push(secondaryWorkProcessInputInfo);
          }

          let millimeterSecondaryWorkAdditions = orgData?.filter(
            (item) => item.secondaryWorkType == WorkTypeGroup.SmSecondaryWorkMillimeter
          );
          let millimeterSecondaryProcessType = secondaryWorkProcessInput?.details?.filter(
            (item) => item.workTypeGroup == WorkTypeGroup.SmSecondaryWorkMillimeter
          );
          for (let i = 0; i < millimeterSecondaryProcessType?.length; i++) {
            //工程入力情報
            let secondaryWorkProcessInputInfo = millimeterSecondaryProcessType[i];
            if (millimeterSecondaryWorkAdditions?.length == 0) continue;
            //径がない場合、計算しない。
            if (Number(secondaryWorkProcessInputInfo?.diameter) === 0) continue;

            let dataItemByProcessInput = millimeterSecondaryWorkAdditions[i];

            let processTypeNm = processMst?.details?.processDetailTypes?.filter(
              (item) =>
                item.id == secondaryWorkProcessInputInfo.types &&
                item.group == secondaryWorkProcessInputInfo?.workTypeGroup
            )?.[0];
            dataItemByProcessInput.processType = processTypeNm?.name;

            let deviceCharges = Object.entries(deviceMst?.details?.charges)?.filter(
              ([key, data]) => parseInt(key) === secondaryWorkProcessInputInfo?.types
            )?.[0];
            /** 段取金額/時間 */
            // 基本段取時間＊サイズ係数
            let isSameType = dandoriSameTypeArr?.filter((item) => item == secondaryWorkProcessInputInfo?.types);
            if (isSameType?.length == 0 || dandoriSameTypeArr?.length == 0) {
              let dandoriTimeSec = calDandoriTime(
                WorkType.SmSecondaryWork,
                deviceMst,
                weight,
                area,
                secondaryWorkProcessInputInfo?.types
              );
              let dandoriTime = dandoriTimeSec;
              dataItemByProcessInput.dandoriTime = dandoriTime === undefined ? 0 : dandoriTime;
              // 段取時間の合計
              totalDandoriTm += dandoriTime;

              // 段取金額設定
              //段階時間
              let mSetTimeMin = Number.isFinite(dandoriTimeSec / 60) ? dandoriTimeSec / 60 : 0;
              //装置の段階時間チャージ

              let mSetHRate = 0;
              deviceCharges?.map((item, index) => {
                if (index > 0) {
                  mSetHRate = item?.prep;
                }
              });
              let dandoriAmt = (mSetTimeMin * mSetHRate) / 60.0; //段階時間＊時間チャージ
              dandoriAmt = Number.isFinite(dandoriAmt) ? dandoriAmt : 0;

              dataItemByProcessInput.dandoriAmt = dandoriAmt === undefined ? 0 : dandoriAmt;
              // 段取金額の合計
              totalDandori += dandoriAmt ? Math.round(dandoriAmt) : 0;
            } else {
              dataItemByProcessInput.dandoriTime = 0;
              dataItemByProcessInput.dandoriAmt = 0;
            }

            // 作業時間（{(1つ当たりの加工時間	+	(加工補正時間	*	加工補正係数)｝	*	サイズ係数）
            // 1つ当たりの加工時間
            let sagyoTimePerSec = calSagyoTimeBy2ji(
              deviceMst,
              Number(secondaryWorkProcessInputInfo?.diameter),
              secondaryWorkProcessInputInfo?.types,
              secondaryWorkProcessInputInfo?.workTypeGroup
            );
            sagyoTimePerSec = sagyoTimePerSec ? sagyoTimePerSec : 0;
            // 加工補正時間
            let modifyTimeQtyThMaster = deviceMst?.details?.modifyTimeQtyThMaster?.filter(
              (item) =>
                (item.value == Number(secondaryWorkProcessInputInfo?.count) ||
                  item.value > Number(secondaryWorkProcessInputInfo?.count)) &&
                !item.isOutOfRange
            )?.[0];
            let hoseTime = deviceMst?.details?.modifyTimeItems?.filter(
              (item) => item.no == modifyTimeQtyThMaster?.no && !item.isOutOfRange
            )?.[0]?.value;
            // 加工補正係数
            let hoseCoeff = getHoseCoeff(deviceMst, weight, area);
            // 作業時間（{(1つ当たりの加工時間	+	(加工補正時間	*	加工補正係数)｝	*	サイズ係数）
            let hoseMultiply = hoseCoeff * hoseTime;
            let sagyoTimeSec =
              (sagyoTimePerSec + hoseMultiply) * Number(secondaryWorkProcessInputInfo?.count) * sizeCoef;
            dataItemByProcessInput.sagyoTime = sagyoTimeSec === undefined ? 0 : sagyoTimeSec * productionQuantityCoeff; // IQX_WEBEST-278 加工時間＊生産個数係数
            dataItemByProcessInput.workTimeSize = sagyoTimePerSec === undefined ? 0 : sagyoTimePerSec;

            // 作業金額
            let mSagyoTimeMin = dataItemByProcessInput?.sagyoTime / 60;
            //装置の作業時間チャージ
            let mSagyoHRate = 0;
            deviceCharges?.map((item, index) => {
              if (index > 0) {
                mSagyoHRate = item?.work;
              }
            });
            let sagyoAmt = (mSagyoTimeMin * mSagyoHRate) / 60.0; //作業時間＊時間チャージ
            dataItemByProcessInput.sagyoAmt = sagyoAmt === undefined ? 0 : sagyoAmt;

            // 作業金額の合計
            totalSagyoTm += dataItemByProcessInput?.sagyoTime;
            totalSagyo += sagyoAmt ? Math.round(sagyoAmt) : 0;
            dataItemsByProcessInput.push(dataItemByProcessInput);
            dandoriSameTypeArr.push(secondaryWorkProcessInputInfo?.types);
          }
          // 段取金額の合計
          secondaryWorkAdditions.totalDandori.dataItemsAmt = totalDandori;
          // 段取時間の合計
          secondaryWorkAdditions.totalDandori.dataItemsTime = totalDandoriTm;
          // 作業金額の合計
          secondaryWorkAdditions.totalSagyo.dataItemsAmt = totalSagyo;
          secondaryWorkAdditions.totalSagyo.dataItemsTime = totalSagyoTm;

          /** 　合計時間　*/
          totalTimeSum = totalDandoriTm + totalSagyoTm;
          secondaryWorkAdditions.totalDataTime = totalTimeSum;

          /** 　合計金額　*/
          totalSum = (totalDandori ? Math.round(totalDandori) : 0) + (totalSagyo ? Math.round(totalSagyo) : 0); // 画面表示用（四捨五入した金額を足す）
          secondaryWorkAdditions.totalDataPrice = totalSum;

          orgData = dataItemsByProcessInput;
        }
        secondaryWorkAdditions.dataItems = orgData;
        nijiAddition = secondaryWorkAdditions;
      }
    }
  }
  return nijiAddition;
};

const calSagyoTimeBy2ji = (deviceMst, sizeMm, typeId, groupTypeId) => {
  if (deviceMst == undefined || deviceMst?.length == 0) return 0;
  let thMaster = [];
  let workTime = 0;
  // IQX_WEBEST-295 詳細入力の値を見積計算で使用してほしい
  let parameters = props?.selectedEstimateData?.estimateProducts?.calcParameters?.parameters;
  //材質
  let materialTypeIq3Id = props?.selectedIQ3DataDetail?.materialTypeIq3Id;
  //材質区分
  let materialTypeId = parameters?.materialTypeIq3?.filter((item) => item.id == materialTypeIq3Id)?.[0]?.materialTypeId;
  // 板厚
  let thickness = parameters?.materialIq3?.filter((item) => item.id == props?.selectedIQ3DataDetail?.thickness)?.[0]
    ?.info?.thick;

  if (groupTypeId === WorkTypeGroup.SmSecondaryWorkSize) {
    thMaster = deviceMst?.details?.sizeThMaster?.filter(
      (item) => (item.value == sizeMm || item.value > sizeMm) && !item.isOutOfRange
    )?.[0];

    let workTimeSizeThItems = Object.entries(deviceMst?.details?.workTimeSizeThItems)?.filter(
      ([key, data]) => parseInt(key) === typeId && !data.isOutOfRange
    )?.[0];

    workTimeSizeThItems?.map((item, index) => {
      if (index > 0) {
        // IQX_WEBEST-295 詳細入力の値を見積計算で使用してほしい
        let workTimeSizeThItem = item?.filter((item) => parseInt(item.no) == thMaster?.no && !item.isOutOfRange)?.[0];
        if (workTimeSizeThItem) {
          if (deviceMst?.details?.isUsedEasyWorkSpeed === undefined || deviceMst?.details?.isUsedEasyWorkSpeed) {
            workTime = workTimeSizeThItem?.value;
          } else {
            let sizeThicknessId = deviceMst?.details?.sizeThicknessThMaster?.filter(
              (item) => (item.value == thickness || item.value > thickness) && !item?.isOutOfRange
            )?.[0];
            let workTimeItem = workTimeSizeThItem?.workTimeThickThItems[materialTypeId]?.filter(
              (item) => item.no == sizeThicknessId?.no && !item?.isOutOfRange
            )?.[0];
            if (workTimeItem) {
              workTime = workTimeItem?.value;
            }
          }
        }
      }
    });
  } else {
    thMaster = deviceMst?.details?.mmThMaster?.filter(
      (item) => (item.value == sizeMm || item.value > sizeMm) && !item.isOutOfRange
    )?.[0];

    let workTimeSizeHoleThItems = Object.entries(deviceMst?.details?.workTimeSizeHoleThItems)?.filter(
      ([key, data]) => parseInt(key) === typeId && !data.isOutOfRange
    )?.[0];

    workTimeSizeHoleThItems?.map((item, index) => {
      if (index > 0) {
        // IQX_WEBEST-295 詳細入力の値を見積計算で使用してほしい
        let workTimeSizeThItem = item?.filter((item) => parseInt(item.no) == thMaster?.no && !item.isOutOfRange)?.[0];
        if (workTimeSizeThItem) {
          if (deviceMst?.details?.isUsedEasyWorkSpeed === undefined || deviceMst?.details?.isUsedEasyWorkSpeed) {
            workTime = workTimeSizeThItem?.value;
          } else {
            let sizeThicknessId = deviceMst?.details?.sizeThicknessThMaster?.filter(
              (item) => (item.value == thickness || item.value > thickness) && !item?.isOutOfRange
            )?.[0];
            let workTimeItem = workTimeSizeThItem?.workTimeThickThItems[materialTypeId]?.filter(
              (item) => item.no == sizeThicknessId?.no && !item?.isOutOfRange
            )?.[0];
            if (workTimeItem) {
              workTime = workTimeItem?.value;
            }
          }
        }
      }
    });
  }
  workTime = workTime ? workTime : 0;
  return workTime;
};
