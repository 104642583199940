/**
 * クラス名：システム全体の共通ファイル
 * 説明：システム全体に共通使っているファイルである。
 * 作成者：ナンス
 * 作成日：2024/11/25
 * バージョン：1.0
 */
import { toDecimal } from '../common/Common';
import {
  calDandoriAmt,
  calPunchCntByCombi,
  calSagyoAmt,
  calSagyoAmtCombi,
  calSagyoAmtJoint,
  calSagyoAmtPunch,
  calSagyoAmtTwoStepHole,
  getGasCoef,
  getJointTm,
  getProductionQuantityCoeff,
  getSagyoIn,
  getSagyoOut,
  getSagyoPiercingTm,
  getSagyoPunches,
  getSagyoPunchTm,
  getSagyoSpeed,
  getSetupCoeff,
  getSizeCoeff,
  getSpeedCoef,
} from './IQ3KouteiCalculate_Common';
import {
  BendingTypes,
  BlankTypes,
  ClientType,
  DataType,
  FRBend,
  IQxRealGoku_AccessToken,
  ServiceClass,
  Types,
  WorkType,
  WorkTypeGroup,
} from '../common/Constant';

// ブラック（複合機）工程の計算
export const calBlankCombi = (
  curAddition,
  processInput,
  processSelect,
  curDeviceMst,
  calcParameters,
  curIQ3DataDetail,
  orderQuantity
) => {
  let blankCombiAddition = curAddition;
  if (Object.keys(blankCombiAddition).length > 0) {
    let orgData = [];
    let processMst = [];
    let deviceMst = curDeviceMst[0]; //テスト用ID;
    let parameters = calcParameters;
    let nijiProcessMst;
    if (parameters?.process?.length > 0) {
      processMst = parameters?.process
        ? parameters?.process?.filter(
            (item) => item?.class === ServiceClass.SheetMetal && item.workType === WorkType.SmBlankCombi
          )[0]
        : [];

      nijiProcessMst = parameters?.process
        ? parameters?.process?.filter(
            (item) => item?.class === ServiceClass.SheetMetal && item.workType === WorkType.SmSecondaryWork
          )[0]
        : [];
    }
    let patternInfo = processSelect;
    if (patternInfo == undefined) return;
    if (deviceMst == undefined) return;
    let combiAddition = blankCombiAddition;
    // プログラムの工程入力情報取得
    let combiProcessInput = processInput;
    //外径含む1から始まる
    let piercingcount = 1;
    let diameter = 0;
    let perimeter = 0;
    if (combiProcessInput.length > 0) {
      if (combiProcessInput?.[0]?.details?.[0]?.quantityList) {
        let quantityList = combiProcessInput?.[0]?.details?.[0]?.quantityList;
        quantityList?.forEach((e) => {
          if (Number(e.perimeter) !== 0) {
            piercingcount += Number(e.count);
          }
          diameter += Number(e.diameter);
          perimeter += Number(e.perimeter);
        });
      }
    }

    let secondaryWorkProcessInput;
    let secondaryUsedForCalc = curIQ3DataDetail?.processSelect?.details?.filter(
      (i) => i.processId === nijiProcessMst?.id
    )?.[0]?.isUsedForCalc;

    if (nijiProcessMst !== undefined && secondaryUsedForCalc) {
      secondaryWorkProcessInput = curIQ3DataDetail?.processInput?.filter(
        (item) => item.processId == nijiProcessMst?.id
      )[0];
    }
    let nijiCnt = 0;
    if (secondaryWorkProcessInput !== undefined && secondaryWorkProcessInput?.details !== undefined) {
      secondaryWorkProcessInput?.details?.forEach((e) => {
        if (e.blankFlag && Number(e.diameter) > 0) {
          nijiCnt += Number(e.count);
        }
      });
    }
    let detailItems = [];
    let editDetailItems = [];
    let dandoriDetail = {
      dandoriAmtBasic: 0,
      dandoriTmBasic: 0,
      dandoriAmtMold: 0,
      dandoriTmMold: 0,
      dandoriMoldCnt: 0, // IQX_WEBEST-321 工程積算タブ内、パンチ工程と複合機工程の表示に「使用金型本数」行を追加
    };
    let sagyoInDetail = {
      sagyoAmtInS: 0,
      sagyoTmInS: 0,
      speedInS: 0,
      speedInSLen: 0,
      sagyoAmtInM: 0,
      sagyoTmInM: 0,
      speedInM: 0,
      speedInMLen: 0,
      sagyoAmtInL: 0,
      sagyoTmInL: 0,
      speedInL: 0,
      speedInLLen: 0,
    };
    let sagyoDetail = {
      sagyoAmtOut: 0,
      sagyoTmOut: 0,
      speedOut: 0,
      speedOutLen: 0,
      sagyoAmtIn: 0,
      sagyoTmIn: 0,
      speedIn: 0,
      speedInLen: 0,
      sagyoAmtOutTwiceCut: 0, // IQX_WEBEST-359 材料表面、レーザー2度切りの対応
      sagyoTmOutTwiceCut: 0, // IQX_WEBEST-359 材料表面、レーザー2度切りの対応
      speedOutTwiceCut: 0, // IQX_WEBEST-359 材料表面、レーザー2度切りの対応
      speedOutTwiceCutLen: 0, // IQX_WEBEST-359 材料表面、レーザー2度切りの対応
      sagyoAmtPunch: 0,
      sagyoTmPunch: 0,
      punchTm: 0,
      punchCnt: 0,
      sagyoAmtAirDeli: 0,
      sagyoTmAirDeli: 0,
      speedAirDeli: 0,
      speedAirDeliLen: 0,
      sagyoAmtPiercing: 0,
      sagyoTmPiercing: 0,
      speedPiercing: 0,
      speedPiercingLen: 0,
      sagyoAmtScribing: 0,
      sagyoTmScribing: 0,
      speedScribing: 0,
      speedScribingLen: 0,
      sagyoAmtTwoStepHole: 0,
      sagyoTmTwoStepHole: 0,
      twoStepHoleCnt: 0,
      twoStepHoleTm: 0,
      sagyoAmtJoint: 0,
      sagyoTmJoint: 0,
      jointCnt: 0,
      jointTm: 0,
      sagyoInDetails: sagyoInDetail,
    };
    detailItems.push({
      id: 1,
      processName: processMst?.name,
      dandoriAmt: 0,
      dandoriTime: 0,
      dandoriDetails: dandoriDetail,
      sagyoAmt: 0,
      sagyoTime: 0,
      sagyoDetails: sagyoDetail,
      formula: '',
    });

    editDetailItems = [];
    let newCombiAddition = {
      no: processMst?.id,
      processId: processMst?.id,
      dataType: combiAddition?.dataType ? combiAddition.dataType : DataType.Data,
      dataItems: detailItems,
      editItems: combiAddition?.editItems ? combiAddition.editItems : editDetailItems,
      totalDandori: {
        dataItemsAmt: 0,
        dataItemsTime: 0,
        editItemsAmt: 0,
        editItemsTime: 0,
      },
      totalSagyo: {
        dataItemsAmt: 0,
        dataItemsTime: 0,
        editItemsAmt: 0,
        editItemsTime: 0,
      },
      totalDataPrice: 0,
      totalDataTime: 0,
      totalEditPrice: 0,
      totalEditTime: 0,
      editDataFlg: false,
    };
    combiAddition = newCombiAddition;
    let laserTmSum = 0;
    let laserAmtSum = 0;
    if (combiAddition?.dataItems) {
      //データ設定
      orgData = JSON.parse(JSON.stringify(combiAddition?.dataItems));
      if (deviceMst != undefined) {
        let totalSagyo = 0;
        let totalSagyoTm = 0;
        let punchCount = 0;
        // 数量
        let quantity = orderQuantity;
        let partCnt = curIQ3DataDetail?.partCount;
        // 板厚
        let thickness = parameters?.materialIq3?.filter((item) => item.id == curIQ3DataDetail?.thickness)?.[0]?.info
          ?.thick;
        // 重量
        let weight = curIQ3DataDetail?.weight;
        // 面積
        let area = Number(curIQ3DataDetail?.totalSurfaceArea);

        //総重量が0、子部品数が0の場合、段取と加工を計算しない。
        if (weight === 0 && area === 0) {
          combiAddition.dataItems = orgData;
          combiAddition.editItems = orgData;
        } else {
          // 生産個数係数取得
          let productionQuantityCoeff = getProductionQuantityCoeff(deviceMst, quantity, partCnt);
          let punchRes = calPunchCntByCombi(
            combiProcessInput?.[0]?.details?.[0],
            deviceMst,
            parameters,
            curIQ3DataDetail
          );

          punchCount = punchRes.punchCnt;
          let onePunchArr = punchRes.onePunchArr;

          let speedOutLen = Number(combiProcessInput?.[0]?.details?.[0]?.dimensionData?.outerDia);
          let speedInLen = Number(combiProcessInput?.[0]?.details?.[0]?.dimensionData?.innerDia);
          let speedAirDeliLen = Number(combiProcessInput?.[0]?.details?.[0]?.dimensionData?.airFeedDistance);
          let speedScribingLen = Number(combiProcessInput?.[0]?.details?.[0]?.dimensionData?.etchingLength); //PMX用ケガキ線長
          let punchTypeArr = combiProcessInput?.[0]?.details?.[0].quantityList?.map((item) => item.types);
          let onlyOnePunch =
            combiProcessInput?.[0]?.details?.[0].quantityList.length === onePunchArr.length ? true : false;
          if (!onlyOnePunch && punchTypeArr.includes(1)) {
            let qtyList = combiProcessInput?.[0]?.details?.[0].quantityList;
            qtyList?.forEach((e, index) => {
              let perimeter = Number(e.perimeter) * Number(e?.count);
              if (e.types === 1 && onePunchArr.includes(index)) {
                if (perimeter !== undefined && perimeter !== null && perimeter !== 0) {
                  speedInLen -= perimeter;
                }
              }
            });
          } else if (onlyOnePunch) {
            speedInLen = 0;
          }

          //材質
          let materialTypeIq3Id = curIQ3DataDetail?.materialTypeIq3Id;

          //材質区分
          let materialTypeId = parameters?.materialTypeIq3?.filter((item) => item.id == materialTypeIq3Id)?.[0]
            ?.materialTypeId;

          // サイズ係数
          let sizeCoef = getSizeCoeff(deviceMst, weight, area);

          //ガス係数
          let gasCoef = getGasCoef(deviceMst, thickness, materialTypeId);

          // 基本段取金額/時間
          let basicSetupTimeSec = deviceMst?.details?.prepBasicTime * getSetupCoeff(deviceMst, quantity, partCnt); // IQX_WEBEST-278 基本段取時間＊段取個数係数
          orgData[0].dandoriDetails.dandoriTmBasic = basicSetupTimeSec === undefined ? 0 : basicSetupTimeSec;
          let danAmtBasic = calDandoriAmt(basicSetupTimeSec, deviceMst);
          orgData[0].dandoriDetails.dandoriAmtBasic = danAmtBasic === undefined ? 0 : danAmtBasic;

          // IQX_WEBEST-294 ブランク工程の金型段取時間の参照する値が間違っている
          let moldQty = 0;
          let punchesCondiVal = getSagyoPunches(deviceMst, thickness, materialTypeId);
          let moldList = combiProcessInput?.[0]?.details?.[0]?.quantityList?.filter(
            (item) =>
              item.types === 1 &&
              item.diameter !== undefined &&
              item.diameter !== null &&
              Number(item.diameter) !== 0 &&
              Number(item.diameter) <= punchesCondiVal
          );
          let moldInfo = moldList?.reduce((a, e) => {
            a[e.diameter] = ++a[e.diameter] || 0;
            return a;
          }, {});
          if (moldInfo) {
            moldQty = Object.keys(moldInfo)?.length;
          }
          // IQX_WEBEST-321 工程積算タブ内、パンチ工程と複合機工程の表示に「使用金型本数」行を追加
          if (orgData[0].dandoriDetails.dandoriMoldCnt !== undefined) {
            orgData[0].dandoriDetails.dandoriMoldCnt = moldQty;
          }
          // 金型段取金額/時間
          let moldSetupTimeSec = 0;
          if (moldQty > 0) {
            let prepMoldQtyCoeffThMaster = deviceMst?.details?.prepMoldQtyCoeffThMaster?.filter(
              (item) => (item.value == moldQty || item.value > moldQty) && !item.isOutOfRange // IQX_WEBEST-216
            )?.[0];
            let prepMoldQtyCoeffItems = deviceMst?.details?.prepMoldQtyCoeffItems?.filter(
              (item) => item.no == prepMoldQtyCoeffThMaster?.no && !item.isOutOfRange
            )?.[0];
            moldSetupTimeSec = prepMoldQtyCoeffItems?.value;
          }
          moldSetupTimeSec = moldSetupTimeSec === undefined ? 0 : moldSetupTimeSec;

          orgData[0].dandoriDetails.dandoriTmMold = moldSetupTimeSec === undefined ? 0 : moldSetupTimeSec;
          let danAmtMold = calDandoriAmt(moldSetupTimeSec, deviceMst);
          orgData[0].dandoriDetails.dandoriAmtMold = danAmtMold === undefined ? 0 : danAmtMold;

          /** 段取金額/時間 (基本段取時間	+	金型段取時間)*/
          orgData[0].dandoriTime = basicSetupTimeSec + moldSetupTimeSec;
          orgData[0].dandoriAmt = orgData[0].dandoriDetails.dandoriAmtBasic + orgData[0].dandoriDetails.dandoriAmtMold;

          // 2工程穴加工金額/時間
          let twoStepHoleTm = deviceMst?.details?.twoStepHoleworkTime;
          let twoStepHoleCnt = nijiCnt; //確認必要？
          orgData[0].sagyoDetails.twoStepHoleTm = twoStepHoleTm === undefined ? 0 : twoStepHoleTm;
          orgData[0].sagyoDetails.twoStepHoleCnt = twoStepHoleCnt === undefined ? 0 : twoStepHoleCnt;
          // 2工程穴加工時間	*	個数
          let twoStepHoleTmsec = twoStepHoleTm * twoStepHoleCnt * productionQuantityCoeff; // IQX_WEBEST-278 加工時間＊生産個数係数
          orgData[0].sagyoDetails.sagyoTmTwoStepHole = twoStepHoleTmsec === undefined ? 0 : twoStepHoleTmsec;
          let sagyoTwoStepAmt = calSagyoAmtTwoStepHole(twoStepHoleTmsec, deviceMst);
          orgData[0].sagyoDetails.sagyoAmtTwoStepHole = sagyoTwoStepAmt === undefined ? 0 : sagyoTwoStepAmt;

          // ジョイントバラシ加工金額/時間
          let jointTimePerSec = getJointTm(deviceMst, thickness, materialTypeId);
          let jointQtySizeThMaster = deviceMst?.details?.jointQtySizeThMaster?.filter(
            (item) => (item.value == area || item.value > area) && !item.isOutOfRange
          )?.[0];
          jointQtySizeThMaster = jointQtySizeThMaster
            ? jointQtySizeThMaster
            : deviceMst?.details?.jointQtySizeThMaster?.[deviceMst?.details?.jointQtySizeThMaster.length - 1];

          let jointCnt = deviceMst?.details?.jointQtyItems?.filter(
            (item) => item.no == jointQtySizeThMaster?.no && !item.isOutOfRange
          )?.[0]?.value;
          orgData[0].sagyoDetails.jointTm = jointTimePerSec === undefined ? 0 : jointTimePerSec;
          orgData[0].sagyoDetails.jointCnt = jointCnt === undefined ? 0 : jointCnt;
          // ジョイントバラシ加工時間	*	個数
          let jointTimeSec = jointTimePerSec * jointCnt * productionQuantityCoeff; // IQX_WEBEST-278 加工時間＊生産個数係数
          orgData[0].sagyoDetails.sagyoTmJoint = jointTimeSec === undefined ? 0 : jointTimeSec;
          let jointSagyoAmt = calSagyoAmtJoint(jointTimeSec, deviceMst);
          orgData[0].sagyoDetails.sagyoAmtJoint = jointSagyoAmt === undefined ? 0 : jointSagyoAmt;

          // 外周切断時間（外周切断長	/	加工速度	＊	加工速度係数	*	ガス係数	*	サイズ係数）
          // 加工速度係数
          let speedOutCoef = getSpeedCoef(deviceMst, speedOutLen, 0);
          let speedOut = 0;
          // IQX_WEBEST-295 詳細入力の値を見積計算で使用してほしい
          if (deviceMst?.details?.isUsedEasyWorkSpeed === undefined || deviceMst?.details?.isUsedEasyWorkSpeed) {
            speedOut = getSagyoSpeed(deviceMst, thickness, materialTypeId);
          } else {
            speedOut = getSagyoOut(deviceMst, thickness, materialTypeId);
          }

          orgData[0].sagyoDetails.speedOut = speedOut === undefined ? 0 : speedOut;

          orgData[0].sagyoDetails.speedOutLen = speedOutLen === undefined ? toDecimal(0, 2) : toDecimal(speedOutLen, 2);
          speedOut = speedOut * speedOutCoef * gasCoef; // IQX_WEBEST-188
          let sagyoTmOutPerMin = Number.isFinite((speedOutLen * sizeCoef) / speedOut)
            ? ((speedOutLen * sizeCoef) / speedOut) * 60
            : 0;
          sagyoTmOutPerMin = sagyoTmOutPerMin * productionQuantityCoeff; // IQX_WEBEST-278 加工時間＊生産個数係数
          orgData[0].sagyoDetails.sagyoTmOut = sagyoTmOutPerMin === undefined ? 0 : sagyoTmOutPerMin;
          let sagyoTmO = calSagyoAmtCombi(sagyoTmOutPerMin, deviceMst);
          orgData[0].sagyoDetails.sagyoAmtOut = sagyoTmO === undefined ? 0 : sagyoTmO;

          // 内周切断時間（内周切断長	/	加工速度	＊	加工速度係数	*	ガス係数	*	サイズ係数）
          // IQX_WEBEST-295 詳細入力の値を見積計算で使用してほしい
          if (deviceMst?.details?.isUsedEasyWorkSpeed === undefined || deviceMst?.details?.isUsedEasyWorkSpeed) {
            // 加工速度係数
            let speedInCoef = getSpeedCoef(deviceMst, speedInLen, 1);
            let speedIn = !onlyOnePunch ? getSagyoSpeed(deviceMst, thickness, materialTypeId) : 0;
            orgData[0].sagyoDetails.speedIn = speedIn === undefined ? 0 : speedIn;

            orgData[0].sagyoDetails.speedInLen = speedInLen === undefined ? toDecimal(0, 2) : toDecimal(speedInLen, 2);
            speedIn = speedIn * speedInCoef * gasCoef; // IQX_WEBEST-188
            let sagyoTmInPerMin = Number.isFinite((speedInLen * sizeCoef) / speedIn)
              ? ((speedInLen * sizeCoef) / speedIn) * 60
              : 0;
            sagyoTmInPerMin = sagyoTmInPerMin * productionQuantityCoeff; // IQX_WEBEST-278 加工時間＊生産個数係数
            orgData[0].sagyoDetails.sagyoTmIn = sagyoTmInPerMin === undefined ? 0 : sagyoTmInPerMin;
            let sagyoAmtIn = calSagyoAmtCombi(sagyoTmInPerMin, deviceMst);
            orgData[0].sagyoDetails.sagyoAmtIn = sagyoAmtIn === undefined ? 0 : sagyoAmtIn;
          } else {
            let sagyoInDetails = getSagyoIn(
              deviceMst,
              thickness,
              materialTypeId,
              orgData[0].sagyoDetails,
              combiProcessInput?.[0]?.details?.[0]?.quantityList,
              gasCoef,
              sizeCoef
            );
            orgData[0].sagyoDetails.sagyoTmIn = sagyoInDetails?.sagyoTmIn === undefined ? 0 : sagyoInDetails?.sagyoTmIn;
            orgData[0].sagyoDetails.sagyoAmtIn =
              sagyoInDetails?.sagyoAmtIn === undefined ? 0 : sagyoInDetails?.sagyoAmtIn;
            orgData[0].sagyoDetails.sagyoInDetails = sagyoInDetails?.sagyoInDetails;
          }

          // IQX_WEBEST-359 材料表面、レーザー2度切りの対応
          // ２度切り切断時間（外周切断長＊サイズ係数 /	2度切り加工速度	＊	加工速度係数	*	ガス係数）
          orgData[0].sagyoDetails.speedOutTwiceCutLen =
            speedOutLen === undefined ? toDecimal(0, 2) : toDecimal(speedOutLen, 2);
          let materialSurfaceIq3Id = curIQ3DataDetail?.materialSurfaceIq3Id;
          let materialSurface = parameters?.materialSurfaceIq3?.filter(
            (item) => item.id === materialSurfaceIq3Id && item.materialTypeIq3Id === materialTypeIq3Id
          );
          let isTwiceCut = materialSurface?.length > 0 ? materialSurface?.[0]?.info?.isTwiceCut : false;
          if (isTwiceCut) {
            let twiceCutWorkSpeed = materialSurface?.length > 0 ? materialSurface?.[0]?.info?.workSpeed : 0;
            let twiceCutSpeedOut = twiceCutWorkSpeed * speedOutCoef * gasCoef;
            let sagyoTmOutTwiceCutPerMin = Number.isFinite((speedOutLen * sizeCoef) / twiceCutSpeedOut)
              ? ((speedOutLen * sizeCoef) / twiceCutSpeedOut) * 60
              : 0;
            sagyoTmOutTwiceCutPerMin = sagyoTmOutTwiceCutPerMin * productionQuantityCoeff; // IQX_WEBEST-278 加工時間＊生産個数係数
            orgData[0].sagyoDetails.sagyoTmOutTwiceCut =
              sagyoTmOutTwiceCutPerMin === undefined ? 0 : sagyoTmOutTwiceCutPerMin;
            let sAmtOutTwiceCut = calSagyoAmtCombi(sagyoTmOutTwiceCutPerMin, deviceMst);
            orgData[0].sagyoDetails.sagyoAmtOutTwiceCut = sAmtOutTwiceCut === undefined ? 0 : sAmtOutTwiceCut;
            orgData[0].sagyoDetails.speedOutTwiceCut = twiceCutWorkSpeed;
          }

          // ピアス加工時間（1ピアス加工時間	*	箇所数	＊	ガス係数）
          let sagyoTmPiercingPerSec = getSagyoPiercingTm(deviceMst, thickness, materialTypeId);
          orgData[0].sagyoDetails.speedPiercing = sagyoTmPiercingPerSec === undefined ? 0 : sagyoTmPiercingPerSec;
          let speedPiercingLen = piercingcount;
          orgData[0].sagyoDetails.speedPiercingLen = speedPiercingLen === undefined ? 0 : speedPiercingLen;
          let sagyoTmPiercing = sagyoTmPiercingPerSec * speedPiercingLen * gasCoef * productionQuantityCoeff; // IQX_WEBEST-278 加工時間＊生産個数係数
          orgData[0].sagyoDetails.sagyoTmPiercing = sagyoTmPiercing === undefined ? 0 : sagyoTmPiercing;
          let sagyoAmtPierce = calSagyoAmtCombi(sagyoTmPiercing, deviceMst);
          orgData[0].sagyoDetails.sagyoAmtPiercing = sagyoAmtPierce === undefined ? 0 : sagyoAmtPierce;

          // ケガキ加工時間（ケガキ線長	/	加工速度）
          let speedScribing = deviceMst?.details?.scribingSpeed;
          orgData[0].sagyoDetails.speedScribing = speedScribing === undefined ? 0 : speedScribing;
          orgData[0].sagyoDetails.speedScribingLen = speedScribingLen;
          let sagyoTmScribing = Number.isFinite(speedScribingLen / speedScribing)
            ? speedScribingLen / speedScribing
            : 0;
          sagyoTmScribing = sagyoTmScribing === undefined ? 0 : sagyoTmScribing * 60;
          sagyoTmScribing = sagyoTmScribing * productionQuantityCoeff; // IQX_WEBEST-278 加工時間＊生産個数係数
          orgData[0].sagyoDetails.sagyoTmScribing = sagyoTmScribing;
          let sagyoAmtScribe = calSagyoAmtCombi(sagyoTmScribing, deviceMst);
          orgData[0].sagyoDetails.sagyoAmtScribing = sagyoAmtScribe === undefined ? 0 : sagyoAmtScribe;

          // パンチ加工時間（1パンチ加工時間	*	パンチ数	*	サイズ係数）
          // パンチ数
          let punchCnt = punchCount; //確認必要
          orgData[0].sagyoDetails.punchCnt = punchCnt === undefined ? 0 : punchCnt;
          let punchTm = getSagyoPunchTm(deviceMst, thickness, materialTypeId);
          orgData[0].sagyoDetails.punchTm = punchTm === undefined ? 0 : punchTm;
          let sagyoTmPunchPerSec = punchTm * punchCnt * sizeCoef * productionQuantityCoeff; // IQX_WEBEST-278 加工時間＊生産個数係数
          orgData[0].sagyoDetails.sagyoTmPunch = sagyoTmPunchPerSec === undefined ? 0 : sagyoTmPunchPerSec;
          let sAmtPunch = calSagyoAmtPunch(sagyoTmPunchPerSec, deviceMst); // IQX_WEBEST-216
          orgData[0].sagyoDetails.sagyoAmtPunch = sAmtPunch == undefined ? 0 : sAmtPunch;

          // 空送時間（空送距離	/	送り速度）
          let speedAirDeli = deviceMst?.details?.feedSpeed;
          orgData[0].sagyoDetails.speedAirDeli = speedAirDeli === undefined ? 0 : speedAirDeli;

          orgData[0].sagyoDetails.speedAirDeliLen = speedAirDeliLen === undefined ? 0 : speedAirDeliLen;
          let sagyoTmAirDeli = Number.isFinite(speedAirDeliLen / speedAirDeli) ? speedAirDeliLen / speedAirDeli : 0;
          sagyoTmAirDeli = sagyoTmAirDeli === undefined ? 0 : sagyoTmAirDeli * 60 * productionQuantityCoeff; // IQX_WEBEST-278 加工時間＊生産個数係数
          orgData[0].sagyoDetails.sagyoTmAirDeli = sagyoTmAirDeli;
          let airDeliAmt = calSagyoAmtCombi(sagyoTmAirDeli, deviceMst);
          orgData[0].sagyoDetails.sagyoAmtAirDeli = airDeliAmt === undefined ? 0 : airDeliAmt;

          laserAmtSum =
            orgData[0].sagyoDetails.sagyoAmtIn +
            orgData[0].sagyoDetails.sagyoAmtOut +
            orgData[0].sagyoDetails.sagyoAmtOutTwiceCut + // IQX_WEBEST-359 材料表面、レーザー2度切りの対応
            orgData[0].sagyoDetails.sagyoAmtScribing +
            orgData[0].sagyoDetails.sagyoAmtPiercing;
          //作業合計金額・時間
          orgData[0].sagyoAmt =
            orgData[0].sagyoDetails.sagyoAmtPunch + orgData[0].sagyoDetails.sagyoAmtAirDeli + laserAmtSum;

          laserTmSum =
            orgData[0].sagyoDetails.sagyoTmIn +
            orgData[0].sagyoDetails.sagyoTmOut +
            orgData[0].sagyoDetails.sagyoTmOutTwiceCut + // IQX_WEBEST-359 材料表面、レーザー2度切りの対応
            orgData[0].sagyoDetails.sagyoTmScribing +
            orgData[0].sagyoDetails.sagyoTmPiercing;
          orgData[0].sagyoTime =
            orgData[0].sagyoDetails.sagyoTmPunch + orgData[0].sagyoDetails.sagyoTmAirDeli + laserTmSum;

          totalSagyo =
            (orgData[0].sagyoAmt ? Math.round(orgData[0].sagyoAmt) : 0) +
            (orgData[0].sagyoDetails.sagyoAmtTwoStepHole
              ? Math.round(orgData[0].sagyoDetails.sagyoAmtTwoStepHole)
              : 0) +
            (orgData[0].sagyoDetails.sagyoAmtJoint ? Math.round(orgData[0].sagyoDetails.sagyoAmtJoint) : 0); // 画面表示用（四捨五入した金額を足す）
          totalSagyoTm =
            orgData[0].sagyoTime + orgData[0].sagyoDetails.sagyoTmTwoStepHole + orgData[0].sagyoDetails.sagyoTmJoint;

          /** 　合計時間　*/
          let totalTimeSum = orgData[0].dandoriTime + totalSagyoTm;
          combiAddition.totalDataTime = totalTimeSum;
          combiAddition.totalDandori.dataItemsTime = orgData[0].dandoriTime;
          combiAddition.totalSagyo.dataItemsTime = totalSagyoTm;

          /** 　合計金額　*/
          let totalSum = (orgData[0].dandoriAmt ? Math.round(orgData[0].dandoriAmt) : 0) + totalSagyo; // 画面表示用（四捨五入した金額を足す）
          combiAddition.totalDataPrice = totalSum;
          combiAddition.totalDandori.dataItemsAmt = orgData[0].dandoriAmt ? Math.round(orgData[0].dandoriAmt) : 0; // 画面表示用（四捨五入した金額を足す）
          combiAddition.totalSagyo.dataItemsAmt = totalSagyo;
          combiAddition.dataItems = orgData;
          combiAddition.editItems = orgData;
        }
        blankCombiAddition = combiAddition;
      }
    }
  }
  return blankCombiAddition;
};
