/**
 * クラス名：問い合わせ処理完了メッセージ表示画面
 * 説明：問い合わせ処理完了メッセージを表示。
 * 作成者：ナンス
 * 作成日：2025/02/12
 * バージョン：1.0
 */
import React, { forwardRef } from 'react';

import { WithRouter } from '../../components/WithRouter';
import '../../assets/styles/common.css';

const InquiryComplete = forwardRef((props, ref) => {

  return (
    <div
      style={{
        height: '80vh',
        overflowY: 'auto',
        alignContent: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        display: 'grid',
      }}
    >
      <div
        style={{
          textAlign: 'left',
          justifyContent: 'center',
          display: 'grid',
          border: '3px solid #000000b3',
          borderRadius: 3,
          padding: 40,
        }}
      >
        <div>
          <p style={{ fontSize: '22px', fontWeight: '600', color: '#333333' }}>
            資料請求ありがとうございます。<br />
            担当者からご連絡いたします。
          </p>
        </div>
      </div>
    </div>
  );
});

export default WithRouter(InquiryComplete);

