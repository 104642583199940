/**
 * クラス名：システム全体の共通ファイル
 * 説明：システム全体に共通使っているファイルである。
 * 作成者：ナンス
 * 作成日：2024/11/25
 * バージョン：1.0
 */
import { toDecimal } from '../common/Common';
import {
  calDandoriAmt,
  calPunchCntByPunch,
  calSagyoAmt,
  calSagyoAmtJoint,
  calSagyoAmtPunch,
  calSagyoAmtTwoStepHole,
  getGasCoef,
  getJointTm,
  getProductionQuantityCoeff,
  getSagyoIn,
  getSagyoOut,
  getSagyoPiercingTm,
  getSagyoPunches,
  getSagyoPunchTm,
  getSagyoSpeed,
  getSetupCoeff,
  getSizeCoeff,
  getSpeedCoef,
} from './IQ3KouteiCalculate_Common';
import {
  BendingTypes,
  BlankTypes,
  ClientType,
  DataType,
  FRBend,
  IQxRealGoku_AccessToken,
  ServiceClass,
  Types,
  WorkType,
  WorkTypeGroup,
} from '../common/Constant';

// ブラック（複合機）工程の計算
export const calBlankPunch = (
  curAddition,
  processInput,
  processSelect,
  curDeviceMst,
  calcParameters,
  curIQ3DataDetail,
  orderQuantity
) => {
  let blankPunchAddition = curAddition;
  if (Object.keys(blankPunchAddition).length > 0) {
    let orgData = [];
    let processMst = [];
    let deviceMst = curDeviceMst[0]; //テスト用ID;
    let parameters = calcParameters;
    let nijiProcessMst;
    if (parameters?.process?.length > 0) {
      processMst = parameters?.process
        ? parameters?.process?.filter(
            (item) => item?.class === ServiceClass.SheetMetal && item.workType === WorkType.SmBlankPunch
          )[0]
        : [];

      nijiProcessMst = parameters?.process
        ? parameters?.process?.filter(
            (item) => item?.class === ServiceClass.SheetMetal && item.workType === WorkType.SmSecondaryWork
          )[0]
        : [];
    }
    let patternInfo = processSelect;
    if (patternInfo == undefined) return;
    if (deviceMst == undefined) return;
    let punchAddition = blankPunchAddition;
    // プログラムの工程入力情報取得
    let punchProcessInput = processInput;
    //外径含む1から始まる
    let piercingcount = 1;
    let diameter = 0;
    let perimeter = 0;
    if (punchProcessInput.length > 0) {
      if (punchProcessInput?.[0]?.details?.[0]?.quantityList) {
        let quantityList = punchProcessInput?.[0]?.details?.[0]?.quantityList;
        quantityList?.forEach((e) => {
          if (Number(e.perimeter) !== 0) {
            piercingcount += Number(e.count);
          }
          diameter += Number(e.diameter);
          perimeter += Number(e.perimeter);
        });
      }
    }

    let secondaryWorkProcessInput;
    let secondaryUsedForCalc = curIQ3DataDetail?.processSelect?.details?.filter(
      (i) => i.processId === nijiProcessMst?.id
    )?.[0]?.isUsedForCalc;

    if (nijiProcessMst !== undefined && secondaryUsedForCalc) {
      secondaryWorkProcessInput = curIQ3DataDetail?.processInput?.filter(
        (item) => item.processId == nijiProcessMst?.id
      )[0];
    }
    let nijiCnt = 0;
    if (secondaryWorkProcessInput !== undefined && secondaryWorkProcessInput?.details !== undefined) {
      secondaryWorkProcessInput?.details?.forEach((e) => {
        if (e.blankFlag && Number(e.diameter) > 0) {
          nijiCnt += Number(e.count);
        }
      });
    }
    let detailItems = [];
    let editDetailItems = [];
    let dandoriDetail = {
      dandoriAmtBasic: 0,
      dandoriTmBasic: 0,
      dandoriAmtMold: 0,
      dandoriTmMold: 0,
      dandoriMoldCnt: 0, // IQX_WEBEST-321 工程積算タブ内、パンチ工程と複合機工程の表示に「使用金型本数」行を追加
    };
    let sagyoDetail = {
      sagyoAmtPunch: 0,
      sagyoTmPunch: 0,
      punchTm: 0,
      punchCnt: 0,
      sagyoAmtAirDeli: 0,
      sagyoTmAirDeli: 0,
      speedAirDeli: 0,
      speedAirDeliLen: 0,
      sagyoAmtScribing: 0,
      sagyoTmScribing: 0,
      speedScribing: 0,
      speedScribingLen: 0,
      sagyoAmtTwoStepHole: 0,
      sagyoTmTwoStepHole: 0,
      twoStepHoleCnt: 0,
      twoStepHoleTm: 0,
      sagyoAmtJoint: 0,
      sagyoTmJoint: 0,
      jointCnt: 0,
      jointTm: 0,
    };
    detailItems.push({
      id: 1,
      processName: processMst?.name,
      dandoriAmt: 0,
      dandoriTime: 0,
      dandoriDetails: dandoriDetail,
      sagyoAmt: 0,
      sagyoTime: 0,
      sagyoDetails: sagyoDetail,
      formula: '',
    });
    let newPunchAddition = {
      no: processMst?.id,
      processId: processMst?.id,
      dataType: punchAddition?.dataType ? punchAddition.dataType : DataType.Data,
      dataItems: detailItems,
      editItems: punchAddition?.editItems ? punchAddition.editItems : editDetailItems,
      totalDandori: {
        dataItemsAmt: 0,
        dataItemsTime: 0,
        editItemsAmt: 0,
        editItemsTime: 0,
      },
      totalSagyo: {
        dataItemsAmt: 0,
        dataItemsTime: 0,
        editItemsAmt: 0,
        editItemsTime: 0,
      },
      totalDataPrice: 0,
      totalDataTime: 0,
      totalEditPrice: 0,
      totalEditTime: 0,
      editDataFlg: false,
    };
    punchAddition = newPunchAddition;
    if (punchAddition?.dataItems) {
      //データ設定
      orgData = JSON.parse(JSON.stringify(punchAddition?.dataItems));
      if (deviceMst != undefined) {
        let totalSagyo = 0;
        let totalSagyoTm = 0;
        let punchCount = 0;
        // 数量
        let quantity = orderQuantity;
        let partCnt = curIQ3DataDetail?.partCount;
        // 板厚
        let thickness = parameters?.materialIq3?.filter((item) => item.id == curIQ3DataDetail?.thickness)?.[0]?.info
          ?.thick;
        // 重量
        let weight = curIQ3DataDetail?.weight;
        // 面積
        let area = Number(curIQ3DataDetail?.totalSurfaceArea);
        //総重量が0、子部品数が0の場合、段取と加工を計算しない。
        if (weight === 0 && area === 0) {
          punchAddition.dataItems = orgData;
          punchAddition.editItems = orgData;
        } else {
          // IQX_WEBEST-278 工程積算タブの表示に生産個数係数が含まれていなかったので、含むようにしてほしい
          let productionQuantityCoeff = getProductionQuantityCoeff(deviceMst, quantity, partCnt);
          punchCount = calPunchCntByPunch(punchProcessInput?.[0]?.details?.[0], deviceMst);
          let speedAirDeliLen = Number(punchProcessInput?.[0]?.details?.[0]?.dimensionData?.airFeedDistance);
          let speedScribingLen = Number(punchProcessInput?.[0]?.details?.[0]?.dimensionData?.etchingLength); //PMX用ケガキ線長

          //材質
          let materialTypeIq3Id = curIQ3DataDetail?.materialTypeIq3Id;

          //材質区分
          let materialTypeId = parameters?.materialTypeIq3?.filter((item) => item.id == materialTypeIq3Id)?.[0]
            ?.materialTypeId;

          // サイズ係数
          let sizeCoef = getSizeCoeff(deviceMst, weight, area);

          // 基本段取金額/時間
          let basicSetupTimeSec = deviceMst?.details?.prepBasicTime * getSetupCoeff(deviceMst, quantity, partCnt); // IQX_WEBEST-278 基本段取時間＊段取個数係数
          orgData[0].dandoriDetails.dandoriTmBasic = basicSetupTimeSec === undefined ? 0 : basicSetupTimeSec;
          let danAmtBasic = calDandoriAmt(basicSetupTimeSec, deviceMst);
          orgData[0].dandoriDetails.dandoriAmtBasic = danAmtBasic === undefined ? 0 : danAmtBasic;

          // IQX_WEBEST-294 ブランク工程の金型段取時間の参照する値が間違っている
          let moldQty = 0;
          moldQty = getKanagataCnt(
            deviceMst,
            curIQ3DataDetail,
            punchProcessInput?.[0]?.details?.[0]?.quantityList,
            punchProcessInput?.[0]?.details?.[0]
          );
          // IQX_WEBEST-321 工程積算タブ内、パンチ工程と複合機工程の表示に「使用金型本数」行を追加
          if (orgData[0].dandoriDetails.dandoriMoldCnt !== undefined) {
            orgData[0].dandoriDetails.dandoriMoldCnt = moldQty;
          }
          // 金型段取金額/時間
          let prepMoldQtyCoeffThMaster = deviceMst?.details?.prepMoldQtyCoeffThMaster?.filter(
            (item) => (item.value == moldQty || item.value > moldQty) && !item.isOutOfRange // IQX_WEBEST-217
          )?.[0];
          let prepMoldQtyCoeffItems = deviceMst?.details?.prepMoldQtyCoeffItems?.filter(
            (item) => item.no == prepMoldQtyCoeffThMaster?.no && !item.isOutOfRange
          )?.[0];
          let moldSetupTimeSec = prepMoldQtyCoeffItems?.value;
          moldSetupTimeSec = moldSetupTimeSec === undefined ? 0 : moldSetupTimeSec; // IQX_WEBEST-217
          orgData[0].dandoriDetails.dandoriTmMold = moldSetupTimeSec === undefined ? 0 : moldSetupTimeSec;
          let danAmtMold = calDandoriAmt(moldSetupTimeSec, deviceMst);
          orgData[0].dandoriDetails.dandoriAmtMold = danAmtMold === undefined ? 0 : danAmtMold;

          /** 段取金額/時間 (基本段取時間	+	金型段取時間)*/
          orgData[0].dandoriTime = basicSetupTimeSec + moldSetupTimeSec;
          orgData[0].dandoriAmt = orgData[0].dandoriDetails.dandoriAmtBasic + orgData[0].dandoriDetails.dandoriAmtMold;

          // 2工程穴加工金額/時間
          let twoStepHoleTm = deviceMst?.details?.twoStepHoleworkTime;
          let twoStepHoleCnt = nijiCnt; //確認必要？
          orgData[0].sagyoDetails.twoStepHoleTm = twoStepHoleTm === undefined ? 0 : twoStepHoleTm;
          orgData[0].sagyoDetails.twoStepHoleCnt = twoStepHoleCnt === undefined ? 0 : twoStepHoleCnt;
          // 2工程穴加工時間	*	個数
          let twoStepHoleTmsec = twoStepHoleTm * twoStepHoleCnt * productionQuantityCoeff; // IQX_WEBEST-278 加工時間＊生産個数係数
          orgData[0].sagyoDetails.sagyoTmTwoStepHole = twoStepHoleTmsec === undefined ? 0 : twoStepHoleTmsec;
          let sagyoAmt2Step = calSagyoAmtTwoStepHole(twoStepHoleTmsec, deviceMst);
          orgData[0].sagyoDetails.sagyoAmtTwoStepHole = sagyoAmt2Step === undefined ? 0 : sagyoAmt2Step;

          // ジョイントバラシ加工金額/時間
          let jointTimePerSec = getJointTm(deviceMst, thickness, materialTypeId);
          let jointQtySizeThMaster = deviceMst?.details?.jointQtySizeThMaster?.filter(
            (item) => (item.value == area || item.value > area) && !item.isOutOfRange
          )?.[0];
          jointQtySizeThMaster = jointQtySizeThMaster
            ? jointQtySizeThMaster
            : deviceMst?.details?.jointQtySizeThMaster?.[deviceMst?.details?.jointQtySizeThMaster.length - 1];
          let jointCnt = deviceMst?.details?.jointQtyItems?.filter(
            (item) => item.no == jointQtySizeThMaster?.no && !item.isOutOfRange
          )?.[0]?.value;
          orgData[0].sagyoDetails.jointTm = jointTimePerSec === undefined ? 0 : jointTimePerSec;
          orgData[0].sagyoDetails.jointCnt = jointCnt === undefined ? 0 : jointCnt;
          // ジョイントバラシ加工時間	*	個数
          let jointTimeSec = jointTimePerSec * jointCnt * productionQuantityCoeff; // IQX_WEBEST-278 加工時間＊生産個数係数
          orgData[0].sagyoDetails.sagyoTmJoint = jointTimeSec === undefined ? 0 : jointTimeSec;
          orgData[0].sagyoDetails.sagyoAmtJoint = calSagyoAmtJoint(jointTimeSec, deviceMst);

          // パンチ加工時間（1パンチ加工時間	*	パンチ数	*	サイズ係数）
          // パンチ数
          let punchCnt = punchCount; //確認必要
          orgData[0].sagyoDetails.punchCnt = punchCnt === undefined ? 0 : punchCnt;
          let punchTm = getSagyoPunchTm(deviceMst, thickness, materialTypeId);
          orgData[0].sagyoDetails.punchTm = punchTm === undefined ? 0 : punchTm;
          let sagyoTmPunchPerSec = punchTm * punchCnt * sizeCoef * productionQuantityCoeff; // IQX_WEBEST-278 加工時間＊生産個数係数
          orgData[0].sagyoDetails.sagyoTmPunch = sagyoTmPunchPerSec === undefined ? 0 : sagyoTmPunchPerSec;
          let sagyoPunchAmt = calSagyoAmt(sagyoTmPunchPerSec, deviceMst);
          orgData[0].sagyoDetails.sagyoAmtPunch = sagyoPunchAmt === undefined ? 0 : sagyoPunchAmt;

          // 空送時間（空送距離	/	送り速度）
          let speedAirDeli = deviceMst?.details?.feedSpeed;
          orgData[0].sagyoDetails.speedAirDeli = speedAirDeli === undefined ? 0 : speedAirDeli;

          orgData[0].sagyoDetails.speedAirDeliLen = speedAirDeliLen === undefined ? 0 : speedAirDeliLen;
          let sagyoTmAirDeli = Number.isFinite(speedAirDeliLen / speedAirDeli) ? speedAirDeliLen / speedAirDeli : 0;
          sagyoTmAirDeli = sagyoTmAirDeli === undefined ? 0 : sagyoTmAirDeli * 60 * productionQuantityCoeff; // IQX_WEBEST-278 加工時間＊生産個数係数
          orgData[0].sagyoDetails.sagyoTmAirDeli = sagyoTmAirDeli;
          let sagyoAirFeedAmt = calSagyoAmt(sagyoTmAirDeli, deviceMst);
          orgData[0].sagyoDetails.sagyoAmtAirDeli = sagyoAirFeedAmt === undefined ? 0 : sagyoAirFeedAmt;

          // ケガキ加工時間（ケガキ線長	/	加工速度）
          let speedScribing = deviceMst?.details?.workSpeed;
          orgData[0].sagyoDetails.speedScribing = speedScribing === undefined ? 0 : speedScribing;
          orgData[0].sagyoDetails.speedScribingLen = speedScribingLen === undefined ? 0 : speedScribingLen;
          let sagyoTmScribing = Number.isFinite(speedScribingLen / speedScribing)
            ? speedScribingLen / speedScribing
            : 0;
          sagyoTmScribing = sagyoTmScribing === undefined ? 0 : sagyoTmScribing * 60 * productionQuantityCoeff; // IQX_WEBEST-278 加工時間＊生産個数係数
          orgData[0].sagyoDetails.sagyoTmScribing = sagyoTmScribing;
          let sAmtSubscribing = calSagyoAmt(sagyoTmScribing, deviceMst);
          orgData[0].sagyoDetails.sagyoAmtScribing = sAmtSubscribing === undefined ? 0 : sAmtSubscribing;

          //作業合計金額・時間
          orgData[0].sagyoAmt =
            orgData[0].sagyoDetails.sagyoAmtPunch +
            orgData[0].sagyoDetails.sagyoAmtAirDeli +
            orgData[0].sagyoDetails.sagyoAmtScribing;
          orgData[0].sagyoTime =
            orgData[0].sagyoDetails.sagyoTmPunch +
            orgData[0].sagyoDetails.sagyoTmAirDeli +
            orgData[0].sagyoDetails.sagyoTmScribing;

          totalSagyo =
            (orgData[0].sagyoAmt ? Math.round(orgData[0].sagyoAmt) : 0) +
            (orgData[0].sagyoDetails.sagyoAmtTwoStepHole
              ? Math.round(orgData[0].sagyoDetails.sagyoAmtTwoStepHole)
              : 0) +
            (orgData[0].sagyoDetails.sagyoAmtJoint ? Math.round(orgData[0].sagyoDetails.sagyoAmtJoint) : 0);
          totalSagyoTm =
            orgData[0].sagyoTime + orgData[0].sagyoDetails.sagyoTmTwoStepHole + orgData[0].sagyoDetails.sagyoTmJoint;

          /** 　合計時間　*/
          let totalTimeSum = orgData[0].dandoriTime + totalSagyoTm;
          punchAddition.totalDataTime = totalTimeSum;
          punchAddition.totalDandori.dataItemsTime = orgData[0].dandoriTime;
          punchAddition.totalSagyo.dataItemsTime = totalSagyoTm;

          /** 　合計金額　*/
          let totalSum =
            (orgData[0].dandoriAmt ? Math.round(orgData[0].dandoriAmt) : 0) + (totalSagyo ? Math.round(totalSagyo) : 0); // 画面表示用（四捨五入した金額を足す）
          punchAddition.totalDataPrice = totalSum;
          punchAddition.totalDandori.dataItemsAmt = orgData[0].dandoriAmt ? Math.round(orgData[0].dandoriAmt) : 0;
          punchAddition.totalSagyo.dataItemsAmt = totalSagyo;
          punchAddition.dataItems = orgData;
          punchAddition.editItems = orgData;
        }
        blankPunchAddition = punchAddition;
      }
    }
  }
  return blankPunchAddition;
};

// ブランク工程の金型段取時間の参照する値取得
const getKanagataCnt = (deviceMst, iq3Info, blankTypeList, inputArr) => {
  if (deviceMst == undefined || deviceMst?.length == 0) return 0;
  let kanagataCnt = 0;
  let kanagataType = {
    maru: 0,
    kaku: 0,
    nagakaku: 0,
    other: 0,
    twoPitch: 0,
    overTakingCon: [],
  };
  // 外周使用金型本数のカウント
  let xSize = Number(iq3Info?.partSizeX); // 矩形サイズX
  let ySize = Number(iq3Info?.partSizeY); // 矩形サイズY
  // IQX_WEBEST-332 パンチ工程、外径のパンチ数算出方法の変更
  if ((xSize + ySize) * 2 === Number(inputArr.dimensionData.outerDia)) {
    //『（矩形Xサイズ +矩形Yサイズ) * 2 = 外形周長』の場合
    // Xサイズの追い抜き条件の加工辺長の値を元に金型情報取得
    let overtakeItems = deviceMst?.details?.odOvertakeItems?.filter(
      (item) => (Number(item.value) === xSize || item.value > xSize) && !item?.isOutOfRange
    );
    let overtakeMold =
      overtakeItems?.length > 0
        ? deviceMst?.details?.odOvertakeMoldMaster?.filter((item) => item.no == overtakeItems[0]?.no)
        : [deviceMst?.details?.odOvertakeMoldMaster[0]];
    kanagataType.overTakingCon.push(overtakeMold[0]);
    // Yサイズの追い抜き条件の加工辺長の値を元に金型情報取得
    overtakeItems = deviceMst?.details?.odOvertakeItems?.filter(
      (item) => (Number(item.value) === ySize || item.value > ySize) && !item?.isOutOfRange
    );
    overtakeMold =
      overtakeItems?.length > 0
        ? deviceMst?.details?.odOvertakeMoldMaster?.filter((item) => item.no == overtakeItems[0]?.no)
        : [deviceMst?.details?.odOvertakeMoldMaster[0]];
    kanagataType.overTakingCon.push(overtakeMold[0]);
  } else {
    //『（矩形Xサイズ +矩形Yサイズ) * 2 != 外形周長』の場合
    // 利用金型：「外径周長/４」線長の金型
    let outerDiaX = Number(inputArr?.dimensionData?.outerDia) / 4;
    let overtakeItems = deviceMst?.details?.odOvertakeItems?.filter(
      (item) => (Number(item.value) === outerDiaX || item.value > outerDiaX) && !item?.isOutOfRange
    );
    let overtakeMold =
      overtakeItems?.length > 0
        ? deviceMst?.details?.odOvertakeMoldMaster?.filter((item) => item.no == overtakeItems[0]?.no)
        : [deviceMst?.details?.odOvertakeMoldMaster[0]];
    kanagataType.overTakingCon.push(overtakeMold[0]);
  }
  // 内周使用金型本数のカウント
  // パンチ条件
  let punchesArr = deviceMst?.details?.punches;
  for (let i = 0; i < blankTypeList?.length; i++) {
    let item = blankTypeList[i];
    if (item.types === 1 && item.diameter !== undefined && item.diameter !== null && Number(item.diameter) !== 0) {
      // 丸（径を元に決定）
      if (Number(item.diameter) <= Number(punchesArr?.punchHoleDia)) {
        // 丸種類
        kanagataType.maru += 1;
      } else {
        // 二プリング種類
        kanagataType.twoPitch += 1;
      }
    } else if (
      item.types === 2 &&
      item.perimeter !== undefined &&
      item.perimeter !== null &&
      Number(item.perimeter) !== 0 &&
      item.diameter !== undefined &&
      item.diameter !== null &&
      Number(item.diameter) !== 0
    ) {
      // 角（辺長を元に決定）
      if (Number(item.perimeter) <= Number(punchesArr?.punchRectanglePer)) {
        // 角種類
        kanagataType.kaku += 1;
      } else {
        // 追い抜き条件種類
        overtakeItems = deviceMst?.details?.odOvertakeItems?.filter(
          (odOvertakeItem) =>
            (Number(odOvertakeItem.value) === Number(item.diameter) ||
              Number(odOvertakeItem.value) > Number(item.diameter)) &&
            !item?.isOutOfRange
        );
        overtakeMold =
          overtakeItems?.length > 0
            ? deviceMst?.details?.odOvertakeMoldMaster?.filter(
                (odOvertakeItem) => odOvertakeItem.no === overtakeItems[0]?.no
              )
            : [deviceMst?.details?.odOvertakeMoldMaster[0]];
        kanagataType.overTakingCon.push(overtakeMold[0]);
      }
    } else if (
      item.types === 3 &&
      item.perimeter !== undefined &&
      item.perimeter !== null &&
      Number(item.perimeter) !== 0 &&
      item.xsize !== undefined &&
      item.xsize !== null &&
      Number(item.xsize) !== 0 &&
      item.ysize !== undefined &&
      item.ysize !== null &&
      Number(item.ysize) !== 0
    ) {
      // 長角
      if (Number(item.perimeter) <= Number(punchesArr.punchRectanglePer)) {
        // 長角種類
        kanagataType.nagakaku += 1;
      } else {
        //（XサイズとYサイズを元に決定）
        // Xサイズ（mm）
        // 追い抜き条件種類
        overtakeItems = deviceMst?.details?.odOvertakeItems?.filter(
          (odOvertakeItem) =>
            (Number(odOvertakeItem.value) === Number(item.xsize) ||
              Number(odOvertakeItem.value) > Number(item.xsize)) &&
            !odOvertakeItem?.isOutOfRange
        );
        overtakeMold =
          overtakeItems?.length > 0
            ? deviceMst?.details?.odOvertakeMoldMaster?.filter(
                (odOvertakeItem) => odOvertakeItem.no === overtakeItems?.[0]?.no
              )
            : [deviceMst?.details?.odOvertakeMoldMaster[0]];
        kanagataType.overTakingCon.push(overtakeMold[0]);
        // Yサイズ（mm）
        // 追い抜き条件種類
        overtakeItems = deviceMst?.details?.odOvertakeItems?.filter(
          (odOvertakeItem) =>
            (Number(odOvertakeItem.value) === Number(item.ysize) ||
              Number(odOvertakeItem.value) > Number(item.ysize)) &&
            !odOvertakeItem?.isOutOfRange
        );
        overtakeMold =
          overtakeItems?.length > 0
            ? deviceMst?.details?.odOvertakeMoldMaster?.filter(
                (odOvertakeItem) => odOvertakeItem.no === overtakeItems[0]?.no
              )
            : [deviceMst?.details?.odOvertakeMoldMaster[0]];
        kanagataType.overTakingCon.push(overtakeMold[0]);
      }
    } else if (
      item.types === 4 &&
      item.xsize !== undefined &&
      item.xsize !== null &&
      Number(item.xsize) !== 0 &&
      item.ysize !== undefined &&
      item.ysize !== null &&
      Number(item.ysize) !== 0
    ) {
      // 長丸（XサイズとYサイズを元に決定）
      let squareXSize = Number(item.xsize) - Number(item.ysize);
      let overtakeItemsSq = deviceMst?.details?.odOvertakeItems?.filter(
        (odOvertakeItem) =>
          (Number(odOvertakeItem.value) === squareXSize || Number(odOvertakeItem.value) > squareXSize) &&
          !odOvertakeItem?.isOutOfRange
      );
      let overtakeMoldSq =
        overtakeItemsSq?.length > 0
          ? deviceMst?.details?.odOvertakeMoldMaster?.filter(
              (odOvertakeItem) => odOvertakeItem.no === overtakeItemsSq[0]?.no
            )
          : [deviceMst?.details?.odOvertakeMoldMaster[0]];
      // 追い抜き条件種類
      kanagataType.overTakingCon.push(overtakeMoldSq[0]); // X線分の追い抜き条件情報
      // 円弧部分(二プリング種類)
      kanagataType.twoPitch += 1;
    } else if (
      item.types === 5 &&
      item.perimeter !== undefined &&
      item.perimeter !== null &&
      Number(item.perimeter) !== 0
    ) {
      // その他（周長を元に決定）
      if (Number(item.perimeter) <= Number(punchesArr?.punchRectanglePer)) {
        // その他種類
        kanagataType.other += 1;
      } else {
        // 追い抜き条件種類
        overtakeItems = deviceMst?.details?.odOvertakeItems?.filter(
          (odOvertakeItem) =>
            (Number(odOvertakeItem.value) === Number(item.perimeter) ||
              Number(odOvertakeItem.value) > Number(item.perimeter)) &&
            !odOvertakeItem?.isOutOfRange
        );
        overtakeMold =
          overtakeItems?.length > 0
            ? deviceMst?.details?.odOvertakeMoldMaster?.filter(
                (odOvertakeItem) => odOvertakeItem.no === overtakeItems[0]?.no
              )
            : [deviceMst?.details?.odOvertakeMoldMaster[0]];
        kanagataType.overTakingCon.push(overtakeMold[0]);
      }
    }
  }
  // 使用追い抜き条件種類数カウント
  let overTakingConCnt = 0;
  let overTakingConInfo = kanagataType.overTakingCon?.reduce((a, e) => {
    a[e.name] = ++a[e.name] || 0;
    return a;
  }, {});
  if (overTakingConInfo) {
    overTakingConCnt = Object.keys(overTakingConInfo)?.length;
  }
  // 二プリング（不使用時：０，使用時：１）
  if (kanagataType.twoPitch > 0) {
    kanagataType.twoPitch = 1;
  } else {
    kanagataType.twoPitch = 0;
  }
  // 使用金型種類数
  kanagataCnt =
    kanagataType.maru +
    kanagataType.kaku +
    kanagataType.nagakaku +
    kanagataType.other +
    kanagataType.twoPitch +
    overTakingConCnt;
  kanagataCnt = kanagataCnt ? kanagataCnt : 0;
  return kanagataCnt;
};
